<template>
  <div class="canvas-container">
    <canvas ref="chart" class="budgetlinechart"></canvas>
  </div>
</template>

<script>
/* eslint-disable */
import Chart from 'chart.js/auto';  // Importing the default auto build of Chart.js


export default {
  props: {
    selectedMonth: {
      type: Number,
      required: true
    },
    selectedYear: {
      type: Number,
      required: true
    },
    chartData: {
      type: Object,
      required: true,
      validator: function(value) {
        return value.hasOwnProperty('labels') && value.hasOwnProperty('datasets');
      }
    }
  },

  data() {
    return {
      chartInitialized: false, // Flag to indicate if the chart has been initialized
    };
  },


  watch: {
    chartData(newData) {
      if (!this.chartInitialized) {
        console.log('Skipping initial chartData update.');
        return;
      }
      console.log('chartData has been updated:', newData);
      if (this.chartInstance) {
        this.chartInstance.destroy();  
      }
      this.createChart(newData);  
    },
    selectedMonth(newMonth) {
      console.log('Selected Month:', newMonth);
    },
    selectedYear(newYear) {
      console.log('Selected Year:', newYear);
    },
  },
  mounted() {
    console.log("Received chartData:", this.chartData);
      this.$nextTick(() => {
      setTimeout(() => {
        this.createChart(this.chartData);
        this.chartInitialized = true; // Set the flag to true after the initial chart creation
      }, 100);  // Delay for 500ms
    });
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
  methods: {
    getOrdinalSuffix(n) {
        if (n > 3 && n < 21) return 'th';
        switch (n % 10) {
          case 1:  return 'st';
          case 2:  return 'nd';
          case 3:  return 'rd';
          default: return 'th';
        }
    },
    getMonthName(monthNumber) {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return monthNames[monthNumber];
    },
    createChart(data) {

      if (!data || !data.datasets || data.datasets.length < 2) {
        console.error("Invalid chart data:", data);
        return;
      }

      if (this.chartInstance) {
        this.chartInstance.destroy();  // Destroy the old chart instance if it exists.
      }

      console.log("Creating chart with data:", data);

      const ctx = this.$refs.chart.getContext('2d');

      // Create a gradient for the 'Spend' dataset
      const gradientFill = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      gradientFill.addColorStop(0, '#4c6a94');  // Top color
      gradientFill.addColorStop(1, 'rgba(205, 255, 255, 0)');  // Middle: Transparent

      

      // Apply the gradient to the 'Spend' dataset backgroundColor property
      data.datasets[1].backgroundColor = gradientFill;
      data.datasets[1].borderWidth = 1;

      const vm = this;

      // Center Text Plugin
      const centerBudgetTextPlugin = {
        id: 'centerBudgetTextPlugin',
        afterDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const budgetDataset = chart.data.datasets[0]; // Assuming dataset[0] corresponds to 'Budget'
            const spendDataset = chart.data.datasets[1]; // Assuming dataset[1] corresponds to 'Spend'
            const budgetValue = budgetDataset.data[activePoint.index];
            const spendValue = spendDataset.data[activePoint.index];

            // Get the x and y coordinates for the line
            const x = activePoint.element.x;
            const yBudget = chart.scales.y.getPixelForValue(budgetValue);
            const ySpend = chart.scales.y.getPixelForValue(spendValue);

            const ctx = chart.ctx;
            ctx.save();

            // Draw the dashed line
            ctx.beginPath();
            ctx.setLineDash([10, 5]);
            ctx.moveTo(x, yBudget);
            ctx.lineTo(x, ySpend);
            ctx.strokeStyle = '#000';
            ctx.stroke();

            // Existing text drawing code
            const day = chart.data.labels[activePoint.index];
            const monthName = vm.getMonthName(vm.selectedMonth);
            const ordinalDay = day + vm.getOrdinalSuffix(day);
            const label = `${monthName} ${ordinalDay}`;
            const valueNumber = budgetDataset.data[activePoint.index];
            const spendValueNumber = spendDataset.data[activePoint.index];

            const formattedValue = `$${Math.round(valueNumber).toLocaleString('en-US')}`;
            const formattedSpendValue = `$${Math.round(spendValueNumber).toLocaleString('en-US')}`;

            const centerX = (chart.chartArea.left);
            const centerY = (chart.chartArea.top + 80);

            const difference = spendValueNumber - valueNumber;
            const overOrUnder = difference > 0 ? "Over" : "Under";
            const formattedDifference = `$${Math.abs(Math.round(difference)).toLocaleString('en-US')}`;

            const chartWidth = chart.chartArea.right - chart.chartArea.left;
            const thresholdX = chart.chartArea.left + 0.8 * chartWidth; // 80% mark

            let textX = x + 10; // Default position to the right of the line
            if (x > thresholdX) {
              textX = x - 120; // Position to the left of the line
              ctx.textAlign = 'right';
            }

            const textY = (yBudget + ySpend) / 2; // Middle of the line

            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = overOrUnder === "Over" ? "#FF0000" : "#00CD00";
            ctx.font = 'bold 28px Arial';
            ctx.fillText(`${overOrUnder}: ${formattedDifference}`, textX, textY);

            ctx.font = '18px Arial';
            ctx.fillStyle = 'Grey';
            ctx.fillText(label, textX, textY + 25); // Position the date text under the Over/Under text

            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000';
            ctx.font = 'bold 24px Arial';
            ctx.fillText(formattedSpendValue, centerX, centerY);
            ctx.fillText(formattedValue, centerX, centerY + 50);

            ctx.font = '12px Arial';
            ctx.fillText(`Spent`, centerX, centerY + 20);
            ctx.fillText(`Budget`, centerX, centerY + 70);

            // ctx.fillStyle = overOrUnder === "Over" ? "#FF0000" : "#00FF00";
            // ctx.font = '14px Arial';
            // ctx.fillText(`${overOrUnder}: ${formattedDifference}`, centerX, centerY + 90);

            // ctx.font = '16px Arial';
            // ctx.fillStyle = 'Grey'
            // ctx.fillText(label, centerX, centerY + 120);

            ctx.restore();
          }
        }
      };


      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 2000, // Change this value to make the animation slower or faster
          },
          scales: {
            y: {
              display: false,  // Hide the y-axis
              beginAtZero: true,
              grid: {
                display: false  // This hides the y-axis gridlines
              }
            },
            x: {
              display: false,  // Hide the x-axis
              grid: {
                display: false  // This hides the x-axis gridlines
              }
            }
          },
          elements: {
            point: {
              radius: 0,  // This hides the data points on the line by default
              hoverRadius: 5,  // This sets the radius of the point when hovered
              hoverBorderWidth: 2,  // Optional: This sets a border width to the hover point
              hoverBorderColor: 'black'  // Optional: This sets a border color to the hover point
            },
            line: {
              tension: 0.4  // This makes the line smoother
            }
          },
          plugins: {
            tooltip: {
              enabled: false,  // This disables the tooltip
              mode: 'index',
              intersect: false,
              axis: 'x'  // This ensures it uses the x-axis to find the nearest point
            },
            legend: {
              display: false
            },
            datalabels: {
              display: false // This ensures the data labels aren't shown
            }
          },
          hover: {
            mode: 'index',  // Affect the nearest item on the x-axis
            intersect: false
          },
        },
        plugins: [
          {
            id: 'customGradientBackground',
            beforeDraw: (chart) => {
              const {ctx, chartArea: {top, bottom}, scales: {y}} = chart;
              
              // Get the min and max values from the spend dataset
              const spendData = chart.data.datasets[1].data;
              const minValue = Math.min(...spendData);
              const maxValue = Math.max(...spendData);

              // Create gradient using the data range
              const gradientFill = ctx.createLinearGradient(0, y.getPixelForValue(maxValue), 0, y.getPixelForValue(minValue));
              gradientFill.addColorStop(0, '#4c6a94');  // Top color
              gradientFill.addColorStop(1, 'rgba(205, 255, 255, 0)');  // Bottom: Transparent

              // Apply gradient to 'Spend' dataset
              chart.data.datasets[1].backgroundColor = gradientFill;
              chart.data.datasets[1].fill = 'start';
            }
          },
          centerBudgetTextPlugin
        ]
      });
    }
  },
}
</script>

<style scoped>
.canvas-container {
  /* padding: 50px; */
  margin-right: 50px;
}

.budgetlinechart {
  width: 600px;
  height: 500px;
}

@media screen and (max-width: 768px) {
  .budgetlinechart {
    width: 95vw !important;
    height: 50vh !important;
    margin: 0 auto;
  }
  .canvas-container {
    margin-right: 0px;
  }
}


</style>
