<template>
  <div class="onboarding-style-recurring">
    <h1 class="fade-slide-down" id="welcome-text">Track your regular payments</h1>
    <h3 class="fade-slide-down" id="import-text-recurring">We've successfully imported your transactions. Next, let's take a look at your regular expenses and subscriptions to help you get a better handle on your finances.</h3>
  </div>


  <button class="find-recurring-button-onboarding" @click="findRecurringTransactions" :disabled="isLoading">
    {{ buttonLabel }}    
  </button>   
  <button class="done-onboarding" @click="finishOnboarding" >Finish Onboarding</button>   
  
  <div class="div8" id="4th-row-left">
    <span v-if="isLoading" class="rules-message">Loading...</span>
    <div v-if="showSuccessMessage" class="rules-message">Recurring transaction has been added 🎉</div>
    <div v-if="addingRule" class="rules-message">⏳ Adding recurring transaction...</div>
    <div v-if="noRecurringRulesFound" class="rules-message">
      🤷‍♀️ No recurring rules found.
    </div>

    <div class="new-recurrings-list"  v-if="matchedTransactions.length && showingResults">
      <!-- Found Recurring Transactions -->

      <div class="new-recurrings-list" v-if="matchedTransactions.length && showingResults">
          <!-- Found Recurring Transactions -->


        <div class="new-recurring-row" v-for="item in matchedTransactions" :key="item.id" :class="{ 'new-recurring-row-expanded': newRecurringBeingTracked === item.id }"> 
          <div class="current-recurrings" @click="toggleNewRecurringHistory(item)">

            <div class="left-side">
              <p class="recurring-name">{{ capitalizeFirstLetter(item.name) }}</p>
              <p class="recurring-frequency">{{ item.frequency }}</p>
            </div>

            <div class="right-side">
              <p class="recurring-next-amount">{{ formatCurrency(item.expectedAmount) }}</p>
              <p class="recurring-next-date">{{ daysUntilDue(item.nextExpectedDate) }} on {{ formatDate(item.nextExpectedDate) }}</p>
            </div>
          </div>

          <div class="recurring-history" v-if="newRecurringBeingTracked === item.id">
            <div class="rule-details">
              <div class="rule-summary left-side">
              Occurred {{ item.numberOfTransactions }} times for a total of {{ formatCurrency(item.total) }}. <br>
              Ranges between {{ formatCurrency(item.minAmount) }} and {{ formatCurrency(item.maxAmount) }}. <br>
            </div>
            <button class="add-recurring-button" @click.stop.prevent="addRecurringRule(item)">➕ADD</button>


              <div class="annual-summary right-side">
                2023: {{ formatCurrency(item['2023']) }} <br>
                2022: {{ formatCurrency(item['2022']) }} <br>
                2021: {{ formatCurrency(item['2021']) }} <br>
              </div>
            </div>
            <table class="recurring-table">
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">Date</th>
                  <th class="text-center">Name</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Category</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in item.transactions" :key="transaction.id">
                  <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td>
                  <td>{{ formatDate(transaction.date) }}</td>
                  <td>{{ transaction.name }}</td>
                  <td class="text-center">{{ formatCurrency(transaction.amount) }}</td>
                  <td class="text-right">{{ transaction.category }}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>  
      </div>  
      
    </div>

    <div class="recurrings-list">
      <div class="recurring-row" 
        v-for="rule in recurringRules" 
        :key="rule.docId" 
        :class="{ 'recurring-row-expanded': recurringBeingTracked === rule.docId }">

        <div class="current-recurrings" @click="toggleRecurringHistory(rule)">

            <div class="left-side">
              <div class="edit-rule-buttons">
                <div v-if="editingRuleId !== rule.docId" @click.stop="startEditing(rule)" class="recurring-name">{{ rule.name }}</div>
                <input class="recurring-input rename-rule-input" 
                    v-if="editingRuleId === rule.docId" 
                    v-model="editName" 
                    ref="editInput"
                    @blur="handleBlur(rule)" 
                    @keydown.enter.prevent="handleEnter(rule)"
                />

                <button 
                  class="edit-button" 
                  v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" 
                  @click="deleteRecurringRule(rule)">
                  x
                </button>

              </div>
                <p class="recurring-frequency">{{ rule.frequency }}</p>
            </div>

            <div class="right-side">
                <p class="recurring-next-amount">{{ formatCurrency(rule.expectedAmount) }}</p>
                <p class="recurring-next-date">
                    {{ daysUntilDue(rule.nextExpectedDate, rule.isActive) }} 
                    <template v-if="rule.isActive">on {{ formatDate(rule.nextExpectedDate) }}</template>
                </p>
            </div>
        </div>

        <!-- Recurring History Table -->
        <div class="recurring-history" v-if="recurringBeingTracked === rule.docId">

          <div class="rule-details">

            <div class="rule-summary left-side">
                Occurred {{ rule.numberOfTransactions }} times for a total of {{ formatCurrency(rule.total) }}. <br>
                Ranges between {{ formatCurrency(rule.minAmount) }} and {{ formatCurrency(rule.maxAmount) }}. <br>
            </div>
            <div class="active-label" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                <span v-if="rule.isActive">Active</span>
                <span v-else>Inactive</span>
              </div> 
              <div class="toggle-switch" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                  <input 
                    class="recurring-input"
                    type="checkbox" 
                    v-model="rule.isActive" 
                    @click="updateIsActive(rule)"
                    :id="'toggle-' + rule.docId" 
                  >
                  <label :for="'toggle-' + rule.docId" class="slider"></label>
              </div>

            <div class="annual-summary right-side">
              2023: {{ formatCurrency(rule['2023']) }} <br>
              2022: {{ formatCurrency(rule['2022']) }} <br>
              2021: {{ formatCurrency(rule['2021']) }} <br>
            </div>
          </div>

          <table class="recurring-table">
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Date</th>
                <th class="text-center">Name</th>
                <th class="text-right">Amount</th>
                <th class="text-right">Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in rule.transactions" :key="transaction.id">
                <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td>
                <td>{{ formatDate(transaction.date) }}</td>
                <td>{{ transaction.name }}</td>
                <td class="text-center">{{ formatCurrency(transaction.amount) }}</td>
                <td class="text-right">{{ transaction.category }}</td>
              </tr>
            </tbody>
          </table>
        </div>   
      </div>
    </div>
  </div>

<div class="parent-onboarding-recurring" v-if="recurringRules.length > 0">


  <div class="div2-onboarding-recurring">        
    <p class="recurring-summary-onboarding">
      You have <span style="font-weight: bold; font-size: 1.5em;">{{ remainingRecurringCharges }}</span> 
      recurring charges remaining this month totalling 
      <span style="font-size: 2em;">{{ formatCurrency(totalRecurringAmount) }}</span>
    </p>
  </div>

  <div class="monthly-recurring-list div3-onboarding-recurring"> 
    <ul class="current-month-recurring">
      <li v-for="transaction in sortedRecurringTransactions" :key="transaction.name" 
        class="transaction-item" 
        :class="{
          'completed-transaction': transaction.isCompleted,
          'past-due-transaction': transaction.isPastDue,
          'upcoming-transaction': transaction.isUpcoming,
        }">
        <span v-if="transaction.isCompleted" class="status-icon">&#9989;</span>
        <span v-else class="status-icon">&#9634;</span>
        <span v-if="transaction.isPastDue" class="past-due-label">Past Due:</span>
        <span class="transaction-name">{{ transaction.name }}</span>
        <span class="transaction-date">
          {{ formatDate2(transaction.date).main }}<sup class="date-suffix">{{ formatDate2(transaction.date).suffix }}</sup>
        </span>
        <span class="transaction-amount">{{ formatCurrency(transaction.amount) }}</span>
      </li>
      <li class="total-for-month-line">___________________</li>
      <li class="total-for-month">{{ formatCurrency(totalRecurringForMonth) }}</li>
    </ul>
  </div>
  <div class="div1-onboarding-recurring">
    <recurring-stacked-chart :recurring-chart-data="recurringChartData" />
  </div>
</div>
</template>
  
<script>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { auth, db } from '../firebaseInit';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc, query, where, writeBatch, deleteField} from 'firebase/firestore';
import { differenceInCalendarDays, parseISO, addDays, format } from "date-fns";
import RecurringStackedChart from '../components/RecurringStackedChart.vue';


export default {
  name: 'RecurringRules',
  components: {
    RecurringStackedChart,
  },
  data() {
    return {
      recurringRules: [],
      user: null,
      recurringBeingTracked: null,
      newRecurringBeingTracked: null,
      matchedTransactions: [], 
      isLoading: false,
      addingRule: null,
      showSuccessMessage: false,
      showingResults: false,
      buttonLabel: "Find Recurring Transactions",
      remainingRecurringCharges: 0,
      totalRecurringAmount: 0,
      editingRuleId: null, // To track which rule is currently being edited by its ID
      editName: '', // To temporarily store the edited name
      justPressedEnter: false,
      showNoRecurringRulesMessage: false
    };
  },
  computed: {

    noRecurringRulesFound() {
      return this.showNoRecurringRulesMessage && this.matchedTransactions.length === 0 && this.showingResults && !this.isLoading;
    },
    activeAndPendingCharges() {
      return this.sortedRecurringTransactions; // Access as a property, not a function
    },
    remainingRecurringCharges() {
      const now = new Date();
      return this.activeAndPendingCharges.filter(charge => new Date(charge.date) > now).length;
    },
    totalRecurringAmount() {
      const now = new Date();
      return this.activeAndPendingCharges
        .filter(charge => new Date(charge.date) > now)
        .reduce((total, charge) => total + charge.amount, 0);
    },
    totalRecurringForMonth() {
      return this.sortedRecurringTransactions.reduce((total, charge) => total + charge.amount, 0);
    },
    recurringChartData() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const filteredData = this.recurringRules
        .filter(rule => {
          // Check if the rule is active
          if (!rule.isActive) return false;

          // Parse the nextExpectedDate
          const ruleDate = parseISO(rule.nextExpectedDate);
          const isCurrentMonth = ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear;
          
          // Include if the rule is "Monthly" or if the nextExpectedDate is in the current month
          return rule.frequency === "Monthly" || isCurrentMonth;
        })
        .map(rule => {
          return {
            name: rule.name,
            amount: rule.expectedAmount,
            date: rule.nextExpectedDate
          };
        });

      // Sort the data by amount in descending order so that largest bars are at the bottom
      filteredData.sort((a, b) => b.amount - a.amount);

      return filteredData;
    },
    sortedRecurringTransactions() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const currentDayOfMonth = now.getDate();

      // Start and end of the current month
      const endOfMonth = new Date(currentYear, currentMonth + 1, 0); // Last day of current month

      const filteredData = this.recurringRules
        .filter(rule => {
          if (!rule.isActive) return false;
          return ["Monthly", "Weekly", "Every two weeks"].includes(rule.frequency);
        })
        .map(rule => {
          let ruleDate = parseISO(rule.nextExpectedDate);
          let isCompleted = false, isPastDue = false, isUpcoming = false;

          // Conditions check
          if (rule.frequency === "Monthly" || rule.frequency === "Weekly" || rule.frequency === "Every two weeks") {
            if (ruleDate.getMonth() < currentMonth && ruleDate.getDate() < currentDayOfMonth && ruleDate.getFullYear() === currentYear) {
              isPastDue = true;
            } else if (ruleDate.getMonth() < currentMonth && ruleDate.getDate() > currentDayOfMonth && ruleDate.getFullYear() === currentYear) {
              isUpcoming = true;
            } else if (ruleDate.getMonth() === currentMonth && ruleDate.getDate() > currentDayOfMonth && ruleDate.getFullYear() === currentYear) {
              isUpcoming = true;
            } else if (ruleDate.getMonth() === currentMonth && ruleDate.getDate() < currentDayOfMonth) {
              isPastDue = true;
            } else if (ruleDate.getMonth() > currentMonth || ruleDate.getFullYear() > currentYear) {
              isCompleted = true;
            }

            // Adjust date for monthly rules not in the current month
            let adjustedDay = Math.min(ruleDate.getDate(), endOfMonth.getDate());
            ruleDate = new Date(currentYear, currentMonth, adjustedDay);
          }

          return {
            name: rule.name,
            amount: rule.expectedAmount,
            date: ruleDate.toISOString().split('T')[0], // Format the date to YYYY-MM-DD
            isCompleted,
            isPastDue,
            isUpcoming
          };
        });

      // Sort by date in ascending order
      filteredData.sort((a, b) => new Date(a.date) - new Date(b.date));

      return filteredData;
    },
  },
  mounted() {
    document.addEventListener('click', this.outsideClick);

    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.fetchRecurringRules();  
      } else {
        this.user = null;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outsideClick);
  },
  methods: {

    finishOnboarding() {
      this.$router.push({ name: 'Spending' });
    },
    async updateIsActive(rule) {
      // Update the local state immediately for responsive UI
      rule.isActive = !rule.isActive;
      
      // Update the Firestore document
      const userId = this.user.uid;
      const ruleDocRef = doc(db, `users/${userId}/recurringRules`, rule.docId);
      try {
          await updateDoc(ruleDocRef, {
              isActive: rule.isActive
          });
          console.log(`Rule ${rule.name} is now ${rule.isActive ? 'active' : 'inactive'}`);
          this.sortRecurringRules();

      } catch (error) {
          // If there's an error, revert the local change to reflect the accurate database state
          rule.isActive = !rule.isActive;
          console.error('Error updating isActive status:', error);
      }
    },
    handleEnter(rule) {
        this.justPressedEnter = true;
        this.renameRule(rule);
    },
    handleBlur(rule) {
        if (!this.justPressedEnter) {
            this.renameRule(rule);
        }
        this.justPressedEnter = false; // reset the flag
    },
    outsideClick(event) {

        if (this.editingRuleId) {
            // Get the first item from the ref array
            const inputElement = this.$refs.editInput[0];

            // Check if the click was outside the input
            if (inputElement && !inputElement.contains(event.target)) {
                this.editingRuleId = null;  // exit the editing mode
                this.editName = '';  // reset the name (or keep the last edited name as you wish)
            }
        }
    },  
    startEditing(rule) {
        console.log("Starting edit for rule:", rule.name);
        this.editingRuleId = rule.docId;
        this.editName = rule.name;
    },          
    async renameRule(rule) {
        console.log("New rule name:", this.editName, "Old rule name:", rule.name);

        // Guard against empty or whitespace-only names.
        if (!this.editName || !this.editName.trim()) {
            console.error("Attempt to set an empty rule name.");
            return;
        }

        if (this.editName === rule.name) {
            this.editingRuleId = null;
            return; // exit if name hasn't changed
        }

        const userId = this.user.uid;
        const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);

        try {
            // Update rule in Firestore
            await updateDoc(ruleDoc, {
                name: this.editName
            });

            // Update the rule's name in the local array as well
            rule.name = this.editName;

            // Directly update the transactions listed in the transactions array of the rule
            const batch = writeBatch(db);

            // Assuming rule.transactions is an array of transaction objects each having a docId.
            rule.transactions.forEach(transaction => {
                const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
                batch.update(transactionDoc, { recurringName: this.editName });  // Update this line to use 'recurringName' instead of 'recurring'
            });

            await batch.commit();

            console.log(`Renamed rule and updated ${rule.transactions.length} associated transactions.`);
            
            // Reset the editing state
            this.editingRuleId = null;
            this.editName = '';
        } catch (error) {
            console.error("Error renaming rule and updating associated transactions:", error);
        }
    },
    async deleteRecurringRule(rule) {
      const userConfirmation = window.confirm("Are you sure you want to delete this rule?");
      if (!userConfirmation) {
          return; // Exit the method if the user doesn't confirm.
      }
      try {
          const userId = this.user.uid;

          // Check if there are any transactions in the rule before updating them.
          if (rule.transactions && rule.transactions.length > 0) {
              // 1. Update all transactions within the rule to have "recurringName: Not Recurring" and remove the "recurringId".
              const batch = writeBatch(db);
              for (let transaction of rule.transactions) {
                  const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
                  batch.update(transactionDoc, {
                      recurringName: "Not Recurring",
                      recurringId: deleteField()  // Remove the recurringId field
                  });
              }
              await batch.commit();
              console.log(`All transactions for rule ${rule.name} updated to "Not Recurring" and "recurringId" deleted.`);
          }

          // 2. Delete the rule from /users/userid/recurringRules/recurringruleid.
          const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);
          await deleteDoc(ruleDoc);
          console.log(`Rule ${rule.name} has been deleted.`);

          // 3. Remove the rule from the local list.
          this.recurringRules = this.recurringRules.filter(r => r.docId !== rule.docId);

      } catch (error) {
          console.error("Error deleting rule:", error);
      }
    },
    async removeTransactionFromRule(rule, transaction) {
        try {
            // 1. Remove the transaction from the rule's transactions array.
            const updatedTransactions = rule.transactions.filter(t => t.id !== transaction.id);
            rule.transactions = updatedTransactions;

            // 2. Update the rule in your database.
            const userId = this.user.uid;
            const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);
            await updateDoc(ruleDoc, {
                transactions: updatedTransactions
            });

            // 3. Update the transaction's "recurringName" field to "Not Recurring" and delete the "recurringId" field.
            const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
            await updateDoc(transactionDoc, {
                recurringName: "Not Recurring",
                recurringId: deleteField()  // Remove the recurringId field
            });

            // Additional Updates to rule based on removed transaction:
            // 1. Update the total amount
            rule.total -= transaction.amount; 

            // 2. Adjust the minimum and maximum amounts
            if (rule.transactions.length) {
                const amounts = rule.transactions.map(txn => txn.amount);
                rule.minAmount = Math.min(...amounts);
                rule.maxAmount = Math.max(...amounts);
            }

            // 3. Update the number of transactions
            rule.numberOfTransactions = rule.transactions.length;

            // 4. Update yearly totals
            const transactionYear = parseISO(transaction.date).getFullYear().toString();
            if(rule[transactionYear]) {
                rule[transactionYear] -= transaction.amount; 
            }

            console.log(`Transaction ${transaction.id} removed from rule ${rule.name} and updated in the database.`);
        } catch (error) {
            console.error("Error removing transaction from rule:", error);
        }
    },
    daysUntilDue(dateString, isActive) {
        if (!isActive) {
            return "Inactive";
        }
        
        const today = new Date();
        const dueDate = new Date(dateString); 
        const daysDifference = differenceInCalendarDays(dueDate, today);

        if (daysDifference === 1) {
            return "Due tomorrow";
        } else if (daysDifference === 0) {
            return "Due today";
        } else if (daysDifference < 0) {
            const pastDueDays = Math.abs(daysDifference);
            return `Past Due: expected ${pastDueDays} ${pastDueDays === 1 ? 'day' : 'days'} ago`;
        } else {
            return `Due in ${daysDifference} days`;
        }
    },
    toggleRecurringHistory(rule) {
        // If a rule is being edited, don't toggle
        if (this.editingRuleId) {
            return;
        }
        if (this.recurringBeingTracked === rule.docId) {
            this.recurringBeingTracked = null;
        } else {
            this.recurringBeingTracked = rule.docId;
        }
    },
    toggleNewRecurringHistory(item) {
      if (this.newRecurringBeingTracked === item.id) {
        this.newRecurringBeingTracked = null;
      } else {
        this.newRecurringBeingTracked = item.id;
      }
    },
    sortRecurringRules() {
        this.recurringRules.sort((a, b) => {
            // First, prioritize by isActive status
            if (a.isActive && !b.isActive) return -1;
            if (!a.isActive && b.isActive) return 1;

            // If both rules have the same isActive status, then sort by nextExpectedDate
            const aDate = parseISO(a.nextExpectedDate);
            const bDate = parseISO(b.nextExpectedDate);
            return aDate - bDate;
        });
    },
    async fetchRecurringRules() {
      const userId = this.user.uid;
      const rulesCollection = collection(db, `users/${userId}/recurringRules`);

      try {
        const querySnapshot = await getDocs(rulesCollection);
        this.recurringRules = querySnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));

        // Calculate remaining recurring charges and total amount
        this.remainingRecurringCharges = this.recurringRules.length;
        this.totalRecurringAmount = this.recurringRules.reduce(
          (total, rule) => total + rule.expectedAmount,
          0
        );
        
        this.sortRecurringRules();


      } catch (error) {
        console.error("Error fetching recurring rules:", error);
      }
    },
    normalizeName(name) {
      return name.trim().toLowerCase().replace(/['",.]+/g, '').replace(/\s+/g, ' ');
    },
    formatCurrency(amount) {
      if (isNaN(amount) || amount === undefined || amount === null) {
        return "";
      }
      return `$${parseFloat(amount).toFixed(2)}`;
    },
    formatDate(dateString) {
      const date = parseISO(dateString);
      const currentYear = new Date().getFullYear();
      if (date.getFullYear() === currentYear) {
        return format(date, "MMMM do");
      } else {
        return format(date, "MMMM do, yyyy");
      }
    },
    formatDate2(dateString) {
      const date = parseISO(dateString);
      const now = new Date();
      const currentMonth = now.toLocaleString('default', { month: 'short' });
      const day = date.getDate();

      let formattedDate = `${currentMonth} ${day}`;

      if (date.getFullYear() !== now.getFullYear()) {
        // Include the year if it's not the current year
        formattedDate += `, ${date.getFullYear()}`;
      }

      const suffix = this.getDateSuffix(day);
      return {
        main: formattedDate,
        suffix: suffix
      };
    },
    getDateSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async findRecurringTransactions() {
      this.isLoading = true;  

      if (this.showingResults) {
        // If results are currently being shown, hide them and reset the button label
        this.showingResults = false;
        this.isLoading = false;
        this.buttonLabel = "Find Recurring Transactions";
        return; // End the function here
      }

      // If results aren't shown, show them and change the button label
      this.showingResults = true;
      this.buttonLabel = "Cancel";

      // If we've previously fetched transactions, we don't need to do it again.
      if (this.matchedTransactions.length > 0) {
        this.isLoading = false;
        return;
      }

      // Otherwise, start fetching and processing transactions as you have done.

      this.matchedTransactions = [];
      if (!this.user) return;
      // 1. Retrieve transactions from Vuex store
      const allTransactions = this.$store.state.transactions;

      // 2. Filter transactions that don't have a recurring value
      const transactions = allTransactions.filter(txn => txn.recurringId === undefined || txn.recurringId === "" || txn.recurringId === false);

      // 3. Group by name
      const groupedByName = transactions.reduce((acc, txn) => {
        const normalizedName = this.normalizeName(txn.name);
        if (!acc[normalizedName]) {
          acc[normalizedName] = [];
        }
        acc[normalizedName].push(txn);
        return acc;
      }, {});

      function getYearFromTransaction(txn) {
        return parseISO(txn.date).getFullYear();
      }

      // 4. Identify possible recurring frequencies
      for (const [name, txns] of Object.entries(groupedByName)) {
        txns.sort((a, b) => parseISO(a.date) - parseISO(b.date));

        for (let i = 0; i < txns.length - 1; i++) {
          const dateDiff = differenceInCalendarDays(
            parseISO(txns[i + 1].date),
            parseISO(txns[i].date)
          );

          let matchedFrequency;
          let daysToAdd;

          if (dateDiff >= 6 && dateDiff <= 8) {
              matchedFrequency = "Weekly";
              daysToAdd = 7;
          } else if (dateDiff >= 12 && dateDiff <= 16) {
              matchedFrequency = "Every 2 weeks";
              daysToAdd = 14;
          } else if (dateDiff >= 24 && dateDiff <= 35) {
              matchedFrequency = "Monthly";
              daysToAdd = 30;  // Consider using a library for more precise handling
          } else if (dateDiff >= 51 && dateDiff <= 67) {
              matchedFrequency = "Every 2 months";
              daysToAdd = 60;
          } else if (dateDiff >= 81 && dateDiff <= 99) {
              matchedFrequency = "Every 3 months";
              daysToAdd = 90;
          } else if (dateDiff >= 164 && dateDiff <= 196) {
              matchedFrequency = "Every 6 months";
              daysToAdd = 180;
          } else if (dateDiff >= 333 && dateDiff <= 397) {
              matchedFrequency = "Annually";
              daysToAdd = 365; // Not accounting for leap years here
          }


          if (matchedFrequency) {
            const today = new Date();
            const lastTransactionDate = parseISO(txns[txns.length - 1].date);
            const daysSinceLastTransaction = differenceInCalendarDays(today, lastTransactionDate);

            // Determine the maximum allowed days since the last transaction based on frequency
            let maxDaysAllowed;
            switch (matchedFrequency) {
                case "Weekly":
                    maxDaysAllowed = 7 * 3; 
                    break;
                case "Every 2 weeks":
                    maxDaysAllowed = 14 * 3; 
                    break;
                case "Monthly":
                    maxDaysAllowed = 30 * 2; 
                    break;
                case "Every 2 months":
                    maxDaysAllowed = 60 * 3; 
                    break;
                case "Every 3 months":
                    maxDaysAllowed = 90 * 2;  
                    break;
                case "Every 6 months":
                    maxDaysAllowed = 180 * 2; 
                    break;
                case "Annually":
                    maxDaysAllowed = 365 * 2; 
                    break;
                default:
                    maxDaysAllowed = Number.MAX_VALUE; // Default case to handle unexpected frequencies
            }

            // Check if the transaction is still active
            if (daysSinceLastTransaction > maxDaysAllowed) {
                continue; // Skip to the next iteration if inactive
            }
            const nextExpectedDate = addDays(lastTransactionDate, daysToAdd);
            const amounts = txns.map(txn => txn.amount);
            const expectedAmount = (amounts.reduce((acc, amt) => acc + amt, 0)) / txns.length;
            const minAmount = Math.min(...amounts);
            const maxAmount = Math.max(...amounts);
            const itemId = `${name}-${i}`;

            const amountsByYear = txns.reduce((acc, txn) => {
              const year = getYearFromTransaction(txn);
              if (!acc[year]) {
                acc[year] = 0;
              }
              acc[year] += txn.amount;
              acc.total += txn.amount;  // compute the overall total
              return acc;
            }, { total: 0 });



            this.matchedTransactions.push({
              id: itemId, // Assign a unique id
              name: name,
              frequency: matchedFrequency,
              transactions: txns,
              ...amountsByYear,
              minAmount: minAmount,
              maxAmount: maxAmount,
              numberOfTransactions: txns.length,
              nextExpectedDate: format(nextExpectedDate, "yyyy-MM-dd"),
              expectedAmount: expectedAmount
            });
            break;
          }
        }
      }

      if (this.matchedTransactions.length === 0 && this.showingResults) {
        this.showNoRecurringRulesMessage = true;
        this.buttonLabel = "Find Recurring Transactions";

        setTimeout(() => {
          this.showNoRecurringRulesMessage = false;
        }, 3000); // 3 seconds
      }
      this.isLoading = false;  // set loading state back to false when done
    },
    async addRecurringRule(ruleData) {
      this.addingRule = ruleData.name;  // Set the rule name
      const userId = this.user.uid;
      const recurringRulesCollection = collection(db, `users/${userId}/recurringRules`);
      const transactionsToUpdate = ruleData.transactions;  // Keep the transactions to update them later
      // Capitalize the first letter of the name before saving
      ruleData.name = this.capitalizeFirstLetter(ruleData.name);

      ruleData.isActive = true;  // Set the rule to be active by default

      ruleData.customId = ruleData.id;  // set the custom 'itemId' to a field named 'customId'
      delete ruleData.id;  // Remove the id property so it doesn't interfere with Firestore's generated ID

      // Add rule to Firestore
      try {
          const docRef = await addDoc(recurringRulesCollection, ruleData);
          console.log("Recurring rule added with ID:", docRef.id);
          ruleData.docId = docRef.id;  // Store the Firestore document ID in the rule object

          // After adding the rule, update each transaction's "recurringName" and "recurringId" fields
          const transactionsCollection = collection(db, `users/${userId}/transactions`);
          for (let txn of transactionsToUpdate) {
              const txnDoc = doc(transactionsCollection, txn.id);
              await updateDoc(txnDoc, { 
                  recurringName: ruleData.name,
                  recurringId: docRef.id
              });
          }
          console.log(`Updated ${transactionsToUpdate.length} transactions with the recurring rule name and id.`);
          
          // Remove the rule from the matchedTransactions array
          this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);

          // Call the findRecurringTransactions method to refresh the displayed recurring rules
          await this.fetchRecurringRules();

          // After rule has been added and matchedTransaction has been updated
          this.addingRule = null;  // Reset the addingRule property
          this.showSuccessMessage = true;  // Show success message

          // Hide the success message after a short delay
          setTimeout(() => {
              this.showSuccessMessage = false;
          }, 3000);  // 3 seconds delay
          
      } catch (error) {
          console.error("Error processing recurring rule:", error);
      }
      // Remove the rule from the matchedTransactions array
        this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);

      // Check if there are no more matched transactions
      if (this.matchedTransactions.length === 0) {
        this.buttonLabel = "Find Recurring Transactions";
        this.showingResults = false; // Also consider hiding the results if no transactions are left
      }
    }
  }
};

</script>
  
<style>

#import-text-recurring {
  animation: fadeInSlideDown 1s ease-in-out 1s; /* Delayed start */
  text-align: center;
  width: 60%;
  margin: 5% auto;
}

.find-recurring-button-onboarding {
  margin: 0px 5% 40px 5%;
  background: #2c2c2c;
  color: white;
  width: max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: unset;
}

.recurring-summary-onboarding {
  padding: unset;
  font-weight: bold;
  text-align: left;
  justify-content: unset;
}

.monthly-recurring-list { 
  grid-area: 2 / 2 / 4 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll; /* Prevents content from overflowing */
  text-align: center; /* Centers text */
  background-color: #ffffff;
  margin: 0% 15% 0% 0%;
  height: 30vh;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}


.parent-onboarding-recurring {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(2, 1fr) 3fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1-onboarding-recurring { grid-area: 1 / 1 / 4 / 2; }
.div2-onboarding-recurring { grid-area: 2 / 2 / 3 / 3; }
.div3-onboarding-recurring { grid-area: 3 / 2 / 4 / 3; }

.done-onboarding {
  margin: 0px 5% 40px 5%;
  background: #2c2c2c;
  color: white;
  width: max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: unset;
}

.onboarding-style-recurring {
  margin-top: 150px;
}

@media screen and (max-width: 768px) {
  .recurring-next-date {
    font-size: 10px;
  }

  .rule-summary {
    display: none;
  }
  .rule-details {
    justify-content: space-between;
  }
  .annual-summary {
    font-size: 12px;
  }

  .recurring-summary-onboarding {
    margin: 20px;
    font-size: 18px;
    text-align: center;
  }
  .monthly-recurring-list {
    height: unset;
    margin: 0;
    display: contents;
  }
  .transaction-date {
    flex: 0 0 60px;;
  }

  .transaction-amount {
    flex: 0 0 60px;
  }
  .transaction-item {
    padding: unset;
  }

  .transaction-name {
    margin-right: 10px;
  }
  .recurring-frequency {
    font-size: 12px;
  }
  .recurring-table {
    margin: 0;
    padding: 0;
    display: none;
  }

  th, td {
    padding: 0px;
    font-size: 12px;
  }

  .recurring-history tbody > tr > td:nth-child(1) {
    display: none;
  }

  .current-month-recurring {
    padding: 10px;
  }

  #import-text-recurring {
    width: 90%;
    font-weight: 300;
    font-size: 14px;
  }
  .recurring-name, .recurring-next-amount {
    font-size: 14px;
  }
  .parent-onboarding-recurring {
    display: ruby;
  }

  .recurring-row {
    margin: 10px;
  }

  .bar-chart-container {
    width: 90vw;
    margin: auto;
    height: 120vh;
  }


}
</style>
  