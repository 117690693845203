<template>

<div v-if="createModalVisible" class="overlay" @click.self="createModalVisible = false">
  <div class="edit-modal">
    <h3 style="margin-bottom: 20px">Create Recurring Transaction</h3>
    <v-form @submit.prevent="saveRecurringTransaction">
      <v-text-field v-model="newRecurringTransaction.name" label="Name" required></v-text-field>
      <v-text-field v-model.number="newRecurringTransaction.expectedAmount" label="Expected Amount" type="number" required></v-text-field>
      <v-select v-model="newRecurringTransaction.frequency" label="Frequency" :items="['Weekly', 'Every 2 weeks', 'Monthly', 'Every 2 months', 'Every 3 months', 'Every 6 months', 'Annually']" required></v-select>
      <VueDatePicker v-model="newRecurringTransaction.nextExpectedDate" :enable-time-picker="false" popper-class="custom-popper-class" auto-apply />
      <div>
        <v-btn style="margin-right: 10px" type="submit">Save</v-btn>
        <v-btn @click.stop="createModalVisible = false">Cancel</v-btn>
      </div>
    </v-form>
  </div>
</div>

  <div style="display: inline-block;" v-if="recurringRules.length === 0">
    <div class="onboarding-style-recurring">
      <h1 class="fade-slide-down" id="welcome-text-center">Track your regular payments</h1>
      <h3 class="fade-slide-down" id="import-text-recurring-left">Let's take a look at your regular expenses and subscriptions to help you get a better handle on your finances.</h3>
    </div>
    
    <v-btn class="fade-slide-down find-recurring-button-left" @click="findRecurringTransactions" :disabled="isLoading" >
      {{ buttonLabel }}    
    </v-btn>   
  </div>
  <div class="parent">
  
    <div class="div1" id="1st-row-left">
      <!-- <h1>Recurring</h1> -->
    </div>
  
    <div class="div2" id="2nd-row-middle"> 
      <h3></h3>
    </div>
  
    <div class="div3" id="top-right-sidebar2" v-if="recurringRules.length > 0">
      <div>
        <v-btn class="find-recurring-buttons" @click="findRecurringTransactions" :disabled="isLoading">
          {{ buttonLabel }}    
        </v-btn>   
        <v-btn class="find-recurring-buttons" @click="createRecurringTransaction">
          Create Recurring Transaction
        </v-btn>   
      </div>
    </div>
  
    <!-- <div class="div4" id="2nd-row-left">  -->
      <!-- </div> -->
      <div class="innerdiv4" v-if="recurringRules.length > 0">        
        <p class="recurring-summary">
          You have <span style="font-weight: bold; font-size: 1.5em;">{{ remainingRecurringCharges }}</span> 
          recurring charges remaining this month totaling 
          <span style="font-size: 2em;">{{ formatCurrency(totalRecurringAmount) }}</span>
        </p>
      </div>
  
    <div class="combinedgrid" id="2nd-row-middle">

    </div>
  
    <div class="div6" id="2nd-row-right" v-if="recurringRules.length > 0"> 
      <ul class="current-month-recurring">
        <li v-for="transaction in sortedRecurringTransactions" :key="transaction.name" 
          class="transaction-item" 
          :class="{
            'completed-transaction': transaction.isCompleted,
            'past-due-transaction': transaction.isPastDue,
            'upcoming-transaction': transaction.isUpcoming,
          }">
          <span v-if="transaction.isCompleted" class="status-icon">&#9989;</span>
          <span v-else class="status-icon">&#9634;</span>
          <span v-if="transaction.isPastDue" class="past-due-label">Past Due:</span>
          <span class="transaction-name">{{ transaction.name }}</span>
          <span class="transaction-date">
            {{ formatDate2(transaction.date).main }}<sup class="date-suffix">{{ formatDate2(transaction.date).suffix }}</sup>
          </span>
          <span class="transaction-amount">{{ formatCurrency(transaction.amount) }}</span>
          
        </li>
        <li class="total-for-month-line">___________________</li>
        <li class="total-for-month">{{ formatCurrency(totalRecurringForMonth) }}</li>
      </ul>
    </div>
  
    <div class="div7" id="3rd-row-large"> 
    </div>
  
    <div class="div8" id="4th-row-left">

      <span v-if="isLoading" class="rules-message">Loading...</span>
      <div v-if="showSuccessMessage" class="rules-message">Recurring transaction has been added 🎉</div>
      <div v-if="addingRule" class="rules-message">⏳ Adding recurring transaction...</div>
      <div v-if="noRecurringRulesFound" class="rules-message">
        🤷‍♀️ No recurring rules found.
      </div>


      <div class="new-recurrings-list"  v-if="matchedTransactions.length && showingResults">
        <!-- Found Recurring Transactions -->

        <div class="new-recurrings-list" v-if="matchedTransactions.length && showingResults">
            <!-- Found Recurring Transactions -->


            <div class="new-recurring-row" v-for="item in displayedTransactions" :key="item.id" :class="{ 'new-recurring-row-expanded': newRecurringBeingTracked === item.id }">
            <div class="current-recurrings" @click="toggleNewRecurringHistory(item)">

              <div class="left-side">
                <p class="recurring-name">{{ capitalizeFirstLetter(item.name) }}</p>
                <p class="recurring-frequency">{{ item.frequency }}</p>
              </div>

              <div class="right-side">
                <p class="recurring-next-amount">{{ formatCurrency(item.expectedAmount) }}</p>
                <p class="recurring-next-date">{{ daysUntilDue(item.nextExpectedDate) }} on {{ formatDate(item.nextExpectedDate) }}</p>
                <p class="confidence-score">Confidence: {{ item.confidenceScore }}%</p>

              </div>
            </div>

            <div class="recurring-history" v-if="newRecurringBeingTracked === item.id">
              <div class="rule-details">
                <div class="rule-summary left-side">
                Occurred {{ item.numberOfTransactions }} times for a total of {{ formatCurrency(item.total) }}. <br>
                Ranges between {{ formatCurrency(item.minAmount) }} and {{ formatCurrency(item.maxAmount) }}. <br>
              </div>
              <v-btn class="add-recurring-button" @click.stop.prevent="addRecurringRule(item)">➕ADD</v-btn>


                <div class="annual-summary right-side">
                  2024: {{ formatCurrency(item['2024']) }} <br>
                  2023: {{ formatCurrency(item['2023']) }} <br>
                  2022: {{ formatCurrency(item['2022']) }} <br>
                  <!-- 2021: {{ formatCurrency(item['2021']) }} <br> -->
                </div>
              </div>
              <table class="recurring-table">
                <thead>
                  <tr>
                    <!-- <th class="text-left"></th> -->
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-center">Amount</th>
                    <th class="text-right">Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in item.transactions" :key="transaction.id">
                    <!-- <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td> -->
                    <td>{{ formatDate(transaction.date) }}</td>
                    <td>{{ transaction.name }}</td>
                    <td class="text-center">{{ formatCurrency(transaction.amount) }}</td>
                    <td class="text-right">{{ transaction.category }}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>  
        </div>  
        
        <!-- New button for showing all results -->
        <v-btn 
          v-if="showingResults && !showingAllResults && filteredOutTransactions.length > 0" 
          @click="showAllResults" 
          class="show-all-results-button"
        >
          Show results with lower confidence scores ({{ filteredOutTransactions.length }})
        </v-btn>
      </div>

      <div class="recurrings-list">
        <div class="recurring-row" 
          v-for="rule in recurringRules" 
          :key="rule.docId" 
          :class="{ 'recurring-row-expanded': recurringBeingTracked === rule.docId }">

          <div class="current-recurrings" @click="toggleRecurringHistory(rule)">

              <div class="left-side">
                <div class="edit-rule-buttons">
                  <div v-if="editingRuleId !== rule.docId" @click.stop="startEditing(rule)" class="recurring-name">{{ rule.name }}</div>
                  <input class="recurring-input rename-rule-input" 
                      v-if="editingRuleId === rule.docId" 
                      v-model="editName" 
                      ref="editInput"
                      @blur="handleBlur(rule)" 
                      @keydown.enter.prevent="handleEnter(rule)"
                  />

                  <button 
                    class="edit-button" 
                    v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" 
                    @click="deleteRecurringRule(rule)">
                    x
                  </button>

                </div>
                  <p class="recurring-frequency">{{ rule.frequency }}</p>
              </div>

              <div class="right-side">
              <div class="recurring-next-amount" @click="editRecurringAmount(rule)">
                <span v-if="!rule.editingAmount">{{ formatCurrency(rule.expectedAmount) }}</span>
                <input
                  v-else
                  type="number"
                  step="0.01"
                  class="recurring-next-amount-edit"
                  v-model.number="rule.newAmount"
                  @blur="saveRecurringAmount(rule)"
                  @keyup.enter="saveRecurringAmount(rule)"
                >
              </div>
              <p class="recurring-next-date">
                {{ daysUntilDue(rule.nextExpectedDate, rule.isActive) }}
                <template v-if="rule.isActive">on {{ formatDate(rule.nextExpectedDate) }}</template>
              </p>
            </div>
          </div>

          <!-- Recurring History Table -->
          <div class="recurring-history" v-if="recurringBeingTracked === rule.docId">

            <div class="rule-details">

              <div class="rule-summary left-side">
                  Occurred {{ rule.numberOfTransactions }} times for a total of {{ formatCurrency(rule.total) }}. <br>
                  Ranges between {{ formatCurrency(rule.minAmount) }} and {{ formatCurrency(rule.maxAmount) }}. <br>
              </div>
              <div class="active-label" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                  <span v-if="rule.isActive">Active</span>
                  <span v-else>Inactive</span>
                </div> 
                <div class="toggle-switch" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                    <input 
                      class="recurring-input"
                      type="checkbox" 
                      v-model="rule.isActive" 
                      @click="updateIsActive(rule)"
                      :id="'toggle-' + rule.docId" 
                    >
                    <label :for="'toggle-' + rule.docId" class="slider"></label>
                </div>

              <div class="annual-summary right-side">
                2024: {{ formatCurrency(rule['2024']) }} <br>
                2023: {{ formatCurrency(rule['2023']) }} <br>
                2022: {{ formatCurrency(rule['2022']) }} <br>
                <!-- 2021: {{ formatCurrency(rule['2021']) }} <br> -->
              </div>
            </div>

            <table class="recurring-table">
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">Date</th>
                  <th class="text-center">Name</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Category</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in rule.transactions" :key="transaction.id">
                  <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td>
                  <td>{{ formatDate(transaction.date) }}</td>
                  <td>{{ transaction.name }}</td>
                  <td class="text-center">{{ formatCurrency(transaction.amount) }}</td>
                  <td class="text-right">{{ transaction.category }}</td>
                </tr>
              </tbody>
            </table>
          </div>   
        </div>
      </div>
    </div>
  
    <div class="div9" id="bottom-right-sidebar" v-if="recurringRules.length > 0"> 
      <h2 class="template-h2"></h2>
      <recurring-stacked-chart :recurring-chart-data="recurringChartData" />
    </div>
  
    <div class="div10">
    </div>

  </div>
</template>
  
<script>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { auth, db } from '../firebaseInit';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc, query, where, writeBatch, deleteField} from 'firebase/firestore';
import { differenceInCalendarDays, parseISO, addDays, addMonths, addYears, format } from "date-fns";
import RecurringStackedChart from '../components/RecurringStackedChart.vue';
import VueDatePicker from '@vuepic/vue-datepicker';



export default {
  name: 'RecurringRules',
  components: {
    RecurringStackedChart,
    VueDatePicker,
  },
  data() {
    return {
      recurringRules: [],
      user: null,
      showingAllResults: false,
      filteredOutTransactions: [],
      recurringBeingTracked: null,
      newRecurringBeingTracked: null,
      matchedTransactions: [], 
      isLoading: false,
      addingRule: null,
      showSuccessMessage: false,
      showingResults: false,
      buttonLabel: "Find Recurring Transactions",
      remainingRecurringCharges: 0,
      totalRecurringAmount: 0,
      editingRuleId: null, // To track which rule is currently being edited by its ID
      editName: '', // To temporarily store the edited name
      justPressedEnter: false,
      showNoRecurringRulesMessage: false,
      editingRuleId: null,
      createModalVisible: false,
      newRecurringTransaction: {
        name: '',
        expectedAmount: null,
        frequency: '',
        nextExpectedDate: '',
      },
    };
  },
  computed: {
    noRecurringRulesFound() {
      return this.showNoRecurringRulesMessage && this.matchedTransactions.length === 0 && this.showingResults && !this.isLoading;
    },
    displayedTransactions() {
      return this.showingAllResults ? this.matchedTransactions : this.matchedTransactions.filter(t => t.confidenceScore >= 70);
    },
    recurringRules() {
      return this.$store.state.recurringRules;
    },
    activeAndPendingCharges() {
      return this.sortedRecurringTransactions; // Access as a property, not a function
    },
    remainingRecurringCharges() {
      let now = this.getLocalDate(new Date());
      now.setHours(0, 0, 0, 0); // Set time to start of the current day (midnight)
      now.setDate(now.getDate() - 1); // Subtract one day to get the start of the previous day
      return this.activeAndPendingCharges.filter(charge => 
        this.getLocalDate(charge.date) > now).length;
    },
    totalRecurringAmount() {
      let now = this.getLocalDate(new Date());
      now.setHours(0, 0, 0, 0); // Set time to start of the current day (midnight)
      now.setDate(now.getDate() - 1); // Subtract one day to get the start of the previous day
      return this.activeAndPendingCharges
        .filter(charge => this.getLocalDate(charge.date) > now)
        .reduce((total, charge) => total + charge.amount, 0);
    },
    totalRecurringForMonth() {
      return this.sortedRecurringTransactions.reduce((total, charge) => total + charge.amount, 0);
    },
    recurringChartData() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const filteredData = this.recurringRules
        .filter(rule => {
          // Check if the rule is active
          if (!rule.isActive) return false;

          // Parse the nextExpectedDate
          const ruleDate = parseISO(rule.nextExpectedDate);
          const isCurrentMonth = ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear;
          
          // Include if the rule is "Monthly" or if the nextExpectedDate is in the current month
          return rule.frequency === "Monthly" || isCurrentMonth;
        })
        .map(rule => {
          return {
            name: rule.name,
            amount: rule.expectedAmount,
            date: rule.nextExpectedDate
          };
        });

      // Sort the data by amount in descending order so that largest bars are at the bottom
      filteredData.sort((a, b) => b.amount - a.amount);

      return filteredData;
    },
    // this is the previous method i had. may need to revert back to it if theres any issues 
    // sortedRecurringTransactions() {
    //   const now = new Date();
    //   const currentMonth = now.getMonth();
    //   const currentYear = now.getFullYear();
    //   const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Today without time

    //   const filteredData = this.recurringRules
    //     .filter(rule => rule.isActive && ["Monthly", "Weekly", "Every two weeks", "Every 2 weeks", "Every 2 months", "Every 3 months", "Every 6 months", "Annually"].includes(rule.frequency))
    //     .flatMap(rule => {
    //       // Process each transaction in the rule's history
    //       const transactionsThisMonth = rule.transactions.filter(transaction => {
    //         const transactionDate = new Date(transaction.date + 'T00:00:00'); // Keep the date part only, avoid timezone conversion
    //           return (
    //             transactionDate.getMonth() === currentMonth &&
    //             transactionDate.getFullYear() === currentYear
    //           );
    //       });

        
    //       // Map each transaction to a transaction object, ensuring isPastDue is only true for non-completed, past transactions
    //       const transactionObjects = transactionsThisMonth.map(transaction => {
    //         const transactionDate = new Date(transaction.date + 'T00:00:00'); // Keep the date part only, avoid timezone conversion
    //         const isCompleted = transactionDate <= today;
    //         return {
    //           name: rule.name,
    //           amount: transaction.amount,
    //           date: transaction.date,
    //           isCompleted: isCompleted,
    //           // isPastDue: !isCompleted && transactionDate < today, 
    //           // isUpcoming: !isCompleted && transactionDate > today,
    //         };
    //       });

    //       // Check the next expected date, setting isPastDue only if it's past and not completed
    //       let ruleDate = new Date(rule.nextExpectedDate + 'T00:00:00'); // Keep the date part only, avoid timezone conversion
    //       console.log("Rule date: ", ruleDate)
    //       if (ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear && !transactionsThisMonth.some(t => t.date === rule.nextExpectedDate)) {
    //         const isUpcoming = ruleDate >= today;
    //         transactionObjects.push({
    //           name: rule.name,
    //           amount: rule.expectedAmount,
    //           date: rule.nextExpectedDate,
    //           isCompleted: false,
    //           isPastDue: !isUpcoming && ruleDate < today,
    //           isUpcoming: isUpcoming,
    //         });
    //       }
          
    //       return transactionObjects;
    //     });
    //     filteredData.sort((a, b) => new Date(a.date + 'T00:00:00') - new Date(b.date + 'T00:00:00')); // Sort without timezone conversion
    //     console.log("Filtered transactions data: ", filteredData);
    //   return filteredData;

    // },
    sortedRecurringTransactions() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Today without time

      const filteredData = this.recurringRules
        .filter(rule => rule.isActive)
        .flatMap(rule => {
          let dates = [];
          let ruleDate = new Date(rule.nextExpectedDate + 'T00:00:00'); // Adjust for timezone

          // Generate all potential dates within the month based on the frequency
          while (ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear) {
            dates.push(new Date(ruleDate));
            
            // Increment the date based on the frequency
            switch (rule.frequency) {
              case 'Weekly':
                ruleDate.setDate(ruleDate.getDate() + 7);
                break;
              case 'Every 2 weeks':
              case 'Every two weeks':
                ruleDate.setDate(ruleDate.getDate() + 14);
                break;
              case 'Monthly':
                ruleDate.setMonth(ruleDate.getMonth() + 1);
                break;
              case 'Every 2 months':
                ruleDate.setMonth(ruleDate.getMonth() + 2);
                break;
              case 'Every 3 months':
                ruleDate.setMonth(ruleDate.getMonth() + 3);
                break;
              case 'Every 6 months':
                ruleDate.setMonth(ruleDate.getMonth() + 6);
                break;
              case 'Annually':
                ruleDate.setFullYear(ruleDate.getFullYear() + 1);
                break;
              default:
                ruleDate = new Date(currentYear + 1, 0, 1); // Exit loop for unknown frequencies
            }
          }

          // Filter dates to current month and map to transaction objects
          return dates.filter(date => date.getMonth() === currentMonth && date.getFullYear() === currentYear)
            .map(date => {
              const isCompleted = rule.transactions.some(t => t.date === date.toISOString().split('T')[0]);
              const isPastDue = !isCompleted && date < today;
              const isUpcoming = date >= today;
              return {
                name: rule.name,
                amount: rule.expectedAmount,
                date: date.toISOString().split('T')[0], // Convert to YYYY-MM-DD format
                isCompleted: isCompleted,
                isPastDue: isPastDue,
                isUpcoming: isUpcoming
              };
            });
        });

      // Include completed transactions from the rule's history
      const completedTransactions = this.recurringRules
        .filter(rule => rule.isActive)
        .flatMap(rule => {
          return rule.transactions
            .filter(transaction => {
              const transactionDate = new Date(transaction.date + 'T00:00:00');
              return (
                transactionDate.getMonth() === currentMonth &&
                transactionDate.getFullYear() === currentYear &&
                transactionDate <= today
              );
            })
            .map(transaction => {
              return {
                name: rule.name,
                amount: transaction.amount,
                date: transaction.date,
                isCompleted: true,
                isPastDue: false,
                isUpcoming: false
              };
            });
        });

      // Combine filtered data and completed transactions, then sort by date
      return [...filteredData, ...completedTransactions].sort((a, b) => new Date(a.date) - new Date(b.date));
    }
  },
  mounted() {
    const currentUser = auth.currentUser;
    if (currentUser) {
      this.user = currentUser;
    }
    document.addEventListener('click', this.outsideClick);
    this.checkNewTransactionsForRecurringRules();
  

    this.$store.watch(
      (state) => state.recurringRules,
      (newValue) => {
        this.recurringRules = newValue;
      },
      { immediate: true }
    );
    
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outsideClick);
  },
  methods: {
    editRecurringAmount(rule) {
      this.editingRuleId = rule.docId;
      rule.editingAmount = true;
      rule.newAmount = rule.expectedAmount;
    },
    createRecurringTransaction() {
      this.createModalVisible = true;
    },
    async checkNewTransactionsForRecurringRules() {
      console.log("Checking for recurring transactions");
      const allTransactions = this.$store.state.transactions;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      const currentMonthTransactions = allTransactions.filter(txn => {
        const txnDate = parseISO(txn.date);
        return txnDate.getMonth() === currentMonth && txnDate.getFullYear() === currentYear && !txn.manuallyRemoved;
      });

      if (!this.$store.state.recurringRules || this.$store.state.recurringRules.length === 0) {
        return;
      }

      const userId = this.user.uid;

      for (const rule of this.$store.state.recurringRules) {
        console.log("Checking rule:", rule.name);
        const lastTransaction = rule.transactions[rule.transactions.length - 1];
        const lastTransactionDate = lastTransaction ? parseISO(lastTransaction.date) : parseISO(rule.nextExpectedDate);
        console.log("Last transaction date:", lastTransactionDate);

        const transactionsToUpdate = [];

        for (const txn of currentMonthTransactions) {
          console.log("Checking transaction:", txn.name);

          let matchingTransaction;

          if (txn.name.toLowerCase().includes('paypal') || txn.name.toLowerCase().includes('zelle')) {
            // For PayPal and Zelle transactions, check for a match using the normalized name
            const normalizedTxnName = this.normalizeName(txn.name);
            matchingTransaction = rule.transactions.find(t => this.normalizeName(t.name) === normalizedTxnName);
          } else {
            // For non-PayPal and non-Zelle transactions, use the normalized name as before
            const normalizedTxnName = this.normalizeName(txn.name);
            matchingTransaction = rule.transactions.find(t => this.normalizeName(t.name) === normalizedTxnName);
          }

          if (!matchingTransaction) {
            continue;
          }

          const dateDiff = differenceInCalendarDays(parseISO(txn.date), lastTransactionDate);
          console.log("Date difference:", dateDiff);

          let daysAllowed;
          switch (rule.frequency) {
            case "Weekly":
              daysAllowed = [6, 7, 8];
              break;
            case "Every 2 weeks":
              daysAllowed = [13, 14, 15];
              break;
            case "Monthly":
              daysAllowed = [26, 27, 28, 29, 30, 31, 32, 33];
              break;
            case "Every 2 months":
              daysAllowed = [55, 56, 57, 58, 59, 60, 61, 62, 63];
              break;
            case "Every 3 months":
              daysAllowed = [86, 87, 88, 89, 90, 91, 92, 93, 94];
              break;
            case "Every 6 months":
              daysAllowed = [174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186];
              break;
            case "Annually":
              daysAllowed = [350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380];
              break;
          }
          // console.log("Days allowed:", daysAllowed);

          if (daysAllowed.includes(dateDiff)) {
            console.log("Matching transaction found");

            // Add the transaction to the rule's transactions array
            rule.transactions.push(txn);

            // Update the rule's properties (total, min/max amount, etc.)
            rule.total += txn.amount;
            rule.minAmount = Math.min(rule.minAmount, txn.amount);
            rule.maxAmount = Math.max(rule.maxAmount, txn.amount);
            rule.numberOfTransactions = rule.transactions.length;

            const txnYear = parseISO(txn.date).getFullYear().toString();
            if (rule[txnYear]) {
              rule[txnYear] += txn.amount;
            } else {
              rule[txnYear] = txn.amount;
            }
            
            let nextDate = parseISO(rule.nextExpectedDate);
            switch (rule.frequency) {
              case "Weekly":
                nextDate = addDays(nextDate, 7);
                break;
              case "Every 2 weeks":
                nextDate = addDays(nextDate, 14);
                break;
              case "Monthly":
                nextDate = addMonths(nextDate, 1);
                break;
              case "Every 2 months":
                nextDate = addMonths(nextDate, 2);
                break;
              case "Every 3 months":
                nextDate = addMonths(nextDate, 3);
                break;
              case "Every 6 months":
                nextDate = addMonths(nextDate, 6);
                break;
              case "Annually":
                nextDate = addYears(nextDate, 1);
                break;
            }
            rule.nextExpectedDate = format(nextDate, "yyyy-MM-dd");
            console.log("Updated nextExpectedDate:", rule.nextExpectedDate);


            // Update the rule in Firestore
            const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);
            await updateDoc(ruleDoc, {
              transactions: rule.transactions,
              total: rule.total,
              minAmount: rule.minAmount,
              maxAmount: rule.maxAmount,
              numberOfTransactions: rule.numberOfTransactions,
              [txnYear]: rule[txnYear],
              nextExpectedDate: format(nextDate, "yyyy-MM-dd") 
            }); 
            console.log("Updated Firestore document with nextExpectedDate:", format(nextDate, "yyyy-MM-dd"));


            // Add the transaction to the transactionsToUpdate array
            transactionsToUpdate.push(txn);
          } else {
            // console.log("Transaction does not match the rule");
          }
        }

        // Update the recurringId field of the matching transactions
        const transactionsCollection = collection(db, `users/${userId}/transactions`);
        for (let txn of transactionsToUpdate) {
          const txnDoc = doc(transactionsCollection, txn.id);
          await updateDoc(txnDoc, {
            recurringName: rule.name,
            recurringId: rule.docId
          });
        }
      }
    },
    async saveRecurringTransaction() {
      try {
        const userId = this.user.uid;
        const recurringRulesCollection = collection(db, `users/${userId}/recurringRules`);
        
        const newRule = {
          name: this.newRecurringTransaction.name,
          expectedAmount: this.newRecurringTransaction.expectedAmount,
          frequency: this.newRecurringTransaction.frequency,
          nextExpectedDate: format(new Date(this.newRecurringTransaction.nextExpectedDate), 'yyyy-MM-dd'), // Format the date directly
          isActive: true,
          transactions: [],
        };
        
        const docRef = await addDoc(recurringRulesCollection, newRule);
        newRule.docId = docRef.id;
        
        this.recurringRules.push(newRule);
        this.createModalVisible = false;
        this.resetNewRecurringTransaction();
      } catch (error) {
        console.error('Error saving recurring transaction:', error);
      }
    },
    resetNewRecurringTransaction() {
      this.newRecurringTransaction = {
        name: '',
        expectedAmount: null,
        frequency: '',
        nextExpectedDate: '',
      };
    },
    
    async saveRecurringAmount(rule) {
      if (rule.newAmount !== rule.expectedAmount) {
        try {
          const user = auth.currentUser;
          if (user) {
            const ruleRef = doc(db, `users/${user.uid}/recurringRules`, rule.docId);
            await updateDoc(ruleRef, {
              expectedAmount: rule.newAmount,
            });
            
            rule.expectedAmount = rule.newAmount;
          }
        } catch (error) {
          console.error('Error updating recurring rule amount:', error);
        }
      }
      rule.editingAmount = false;
      this.editingRuleId = null;
      // Dispatch an action to update the rule in the Vuex store
      this.$store.dispatch('updateRecurringRule', {
        ...rule,
        expectedAmount: rule.newAmount,
      });
    },
    getLocalDate(dateInput) {
      const date = (dateInput instanceof Date) ? dateInput : new Date(dateInput);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(date.toLocaleString('en-US', { timeZone }));
    },
    
    async updateIsActive(rule) {
      // Update the local state immediately for responsive UI
      rule.isActive = !rule.isActive;
      
      // Update the Firestore document
      const userId = this.user.uid;
      const ruleDocRef = doc(db, `users/${userId}/recurringRules`, rule.docId);
      try {
          await updateDoc(ruleDocRef, {
              isActive: rule.isActive
          });
          console.log(`Rule ${rule.name} is now ${rule.isActive ? 'active' : 'inactive'}`);
          this.sortRecurringRules();

          // Dispatch an action to update the rule in the Vuex store
          this.$store.dispatch('updateRecurringRule', rule);

      } catch (error) {
          // If there's an error, revert the local change to reflect the accurate database state
          rule.isActive = !rule.isActive;
          console.error('Error updating isActive status:', error);
      }
    },
    handleEnter(rule) {
        this.justPressedEnter = true;
        this.renameRule(rule);
    },
    handleBlur(rule) {
        if (!this.justPressedEnter) {
            this.renameRule(rule);
        }
        this.justPressedEnter = false; // reset the flag
    },
    outsideClick(event) {

        if (this.editingRuleId) {
            // Get the first item from the ref array
            const inputElement = this.$refs.editInput[0];

            // Check if the click was outside the input
            if (inputElement && !inputElement.contains(event.target)) {
                this.editingRuleId = null;  // exit the editing mode
                this.editName = '';  // reset the name (or keep the last edited name as you wish)
            }
        }
    },  
    startEditing(rule) {
        console.log("Starting edit for rule:", rule.name);
        this.editingRuleId = rule.docId;
        this.editName = rule.name;
    },          
    async renameRule(rule) {
        console.log("New rule name:", this.editName, "Old rule name:", rule.name);

        // Guard against empty or whitespace-only names.
        if (!this.editName || !this.editName.trim()) {
            console.error("Attempt to set an empty rule name.");
            return;
        }

        if (this.editName === rule.name) {
            this.editingRuleId = null;
            return; // exit if name hasn't changed
        }

        const userId = this.user.uid;
        const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);

        try {
          await updateDoc(ruleDoc, { name: this.editName });
          this.$store.dispatch('updateRecurringRule', { ...rule, name: this.editName });
          rule.name = this.editName;
          // Directly update the transactions listed in the transactions array of the rule
          const batch = writeBatch(db);

          // Assuming rule.transactions is an array of transaction objects each having a docId.
          rule.transactions.forEach(transaction => {
              const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
              batch.update(transactionDoc, { recurringName: this.editName });  // Update this line to use the updated 'recurringName'
          });
          await batch.commit();

          console.log(`Renamed rule and updated ${rule.transactions.length} associated transactions.`);
          // Reset the editing state
          this.editingRuleId = null;
          this.editName = '';
        } catch (error) {
            console.error("Error renaming rule and updating associated transactions:", error);
        }
    },
    async deleteRecurringRule(rule) {
      const userConfirmation = window.confirm("Are you sure you want to delete this rule?");
      if (!userConfirmation) {
          return; // Exit the method if the user doesn't confirm.
      }
      try {
          const userId = this.user.uid;

          // Check if there are any transactions in the rule before updating them.
          if (rule.transactions && rule.transactions.length > 0) {
              // 1. Update all transactions within the rule to have "recurringName: Not Recurring" and remove the "recurringId".
              const batch = writeBatch(db);
              for (let transaction of rule.transactions) {
                  const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
                  batch.update(transactionDoc, {
                      recurringName: "Not Recurring",
                      recurringId: deleteField()  // Remove the recurringId field
                  });
              }
              await batch.commit();
              console.log(`All transactions for rule ${rule.name} updated to "Not Recurring" and "recurringId" deleted.`);
          }

          // 2. Delete the rule from /users/userid/recurringRules/recurringruleid.
          const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);
          await deleteDoc(ruleDoc);
          console.log(`Rule ${rule.name} has been deleted.`);

          // 3. Remove the rule from the local list.
          this.recurringRules = this.recurringRules.filter(r => r.docId !== rule.docId);

          this.$store.dispatch('removeRecurringRule', rule.docId);


      } catch (error) {
          console.error("Error deleting rule:", error);
      }
    },
    async removeTransactionFromRule(rule, transaction) {
        try {
            // 1. Remove the transaction from the rule's transactions array.
            const updatedTransactions = rule.transactions.filter(t => t.id !== transaction.id);
            rule.transactions = updatedTransactions;

            // 2. Update the rule in your database.
            const userId = this.user.uid;
            const ruleDoc = doc(db, `users/${userId}/recurringRules`, rule.docId);
            await updateDoc(ruleDoc, {
                transactions: updatedTransactions
            });

            // 3. Update the transaction's "recurringName" field to "Not Recurring" and delete the "recurringId" field.
            const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
            await updateDoc(transactionDoc, {
              recurringName: "Not Recurring",
              recurringId: deleteField(),
              manuallyRemoved: true // Add this line
            });

            // Additional Updates to rule based on removed transaction:
            // 1. Update the total amount
            rule.total -= transaction.amount; 

            // 2. Adjust the minimum and maximum amounts
            if (rule.transactions.length) {
                const amounts = rule.transactions.map(txn => txn.amount);
                rule.minAmount = Math.min(...amounts);
                rule.maxAmount = Math.max(...amounts);
            }

            // 3. Update the number of transactions
            rule.numberOfTransactions = rule.transactions.length;

            // 4. Update yearly totals
            const transactionYear = parseISO(transaction.date).getFullYear().toString();
            if(rule[transactionYear]) {
                rule[transactionYear] -= transaction.amount; 
            }

            console.log(`Transaction ${transaction.id} removed from rule ${rule.name} and updated in the database.`);
        } catch (error) {
            console.error("Error removing transaction from rule:", error);
        }
    },
    daysUntilDue(dateString, isActive) {
        if (!isActive) {
            return "Inactive";
        }
        
        const today = this.getLocalDate(new Date());
        const dueDate = this.getLocalDate(dateString);
        const daysDifference = differenceInCalendarDays(dueDate, today);

        if (daysDifference === 1) {
            return "Due tomorrow";
        } else if (daysDifference === 0) {
            return "Due today";
        } else if (daysDifference < 0) {
            const pastDueDays = Math.abs(daysDifference);
            return `Past Due: expected ${pastDueDays} ${pastDueDays === 1 ? 'day' : 'days'} ago`;
        } else {
            return `Due in ${daysDifference} days`;
        }
    },
    toggleRecurringHistory(rule) {
        // If a rule is being edited, don't toggle
        if (this.editingRuleId) {
            return;
        }
        if (this.recurringBeingTracked === rule.docId) {
            this.recurringBeingTracked = null;
        } else {
            this.recurringBeingTracked = rule.docId;
        }
    },
    toggleNewRecurringHistory(item) {
      if (this.newRecurringBeingTracked === item.id) {
        this.newRecurringBeingTracked = null;
      } else {
        this.newRecurringBeingTracked = item.id;
      }
    },
    sortRecurringRules() {
        this.recurringRules.sort((a, b) => {
            // First, prioritize by isActive status
            if (a.isActive && !b.isActive) return -1;
            if (!a.isActive && b.isActive) return 1;

            // If both rules have the same isActive status, then sort by nextExpectedDate
            const aDate = parseISO(a.nextExpectedDate);
            const bDate = parseISO(b.nextExpectedDate);
            return aDate - bDate;
        });
    },
    async fetchRecurringRules() {
      const userId = this.user.uid;
      const rulesCollection = collection(db, `users/${userId}/recurringRules`);

      try {
        const querySnapshot = await getDocs(rulesCollection);
        this.recurringRules = querySnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));

        // Calculate remaining recurring charges and total amount
        this.remainingRecurringCharges = this.recurringRules.length;
        this.totalRecurringAmount = this.recurringRules.reduce(
          (total, rule) => total + rule.expectedAmount,
          0
        );
        
        this.sortRecurringRules();


      } catch (error) {
        console.error("Error fetching recurring rules:", error);
      }
    },
    normalizeName(name) {
      const nameparts = name.trim().toLowerCase().replace(/['",.]+/g, '').split(/\s+/);
      if (nameparts.includes('paypal') || nameparts.includes('zelle')) {
        // For Zelle, keep everything up to and including the sender's name
        if (nameparts.includes('zelle')) {
          const zelleIndex = nameparts.indexOf('zelle');
          const fromIndex = nameparts.indexOf('from');
          if (fromIndex !== -1 && fromIndex < nameparts.length - 1) {
            return nameparts.slice(zelleIndex, fromIndex + 3).join(' '); // Include 'zelle', 'payment', 'from', and two words after
          }
        }
        return nameparts.slice(0, 1).join(' ');
      } else {
        return nameparts[0];
      }
    },
    formatCurrency(amount) {
      if (isNaN(amount) || amount === undefined || amount === null) {
        return "$0.00";
      }
      // Format the number with the US locale to get commas for thousands
      return `$${parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },

    formatDate(dateString) {
      const date = parseISO(dateString);
      const currentYear = new Date().getFullYear();
      if (date.getFullYear() === currentYear) {
        return format(date, "MMMM do");
      } else {
        return format(date, "MMMM do, yyyy");
      }
    },
    formatDate2(dateString) {
      const date = parseISO(dateString);
      const now = new Date();
      const currentMonth = now.toLocaleString('default', { month: 'short' });
      const day = date.getDate();

      let formattedDate = `${currentMonth} ${day}`;

      if (date.getFullYear() !== now.getFullYear()) {
        // Include the year if it's not the current year
        formattedDate += `, ${date.getFullYear()}`;
      }

      const suffix = this.getDateSuffix(day);
      return {
        main: formattedDate,
        suffix: suffix
      };
    },
    getDateSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // 1. Fetch all transactions
    async findRecurringTransactions() {
      this.isLoading = true;

      if (this.showingResults) {
        // If results are currently being shown, hide them and reset the button label
        this.showingResults = false;
        this.isLoading = false;
        this.buttonLabel = "Find Recurring Transactions";
        return; // End the function here
      }

      // If results aren't shown, show them and change the button label
      this.showingResults = true;
      this.buttonLabel = "Done";

      // If we've previously fetched transactions, we don't need to do it again.
      if (this.matchedTransactions.length > 0) {
        this.isLoading = false;
        return;
      }

      // Otherwise, start fetching and processing transactions as you have done.
      this.matchedTransactions = [];

      // 1. Retrieve transactions from Vuex store
      const allTransactions = this.$store.state.transactions;
      console.log('1');

      // 2. Filter transactions that don't have a recurring value and are not part of an existing recurring rule
      const transactions = allTransactions.filter(txn =>
        (txn.recurringId === undefined || txn.recurringId === "" || txn.recurringId === false) &&
        txn.amount > 0 &&
        !this.recurringRules.some(rule => rule.transactions.some(t => t.id === txn.id))
      );

      // 3. Group by name
      const groupedByName = transactions.reduce((acc, txn) => {
        const normalizedName = this.normalizeName(txn.name);
        if (!acc[normalizedName]) {
          acc[normalizedName] = [];
        }
        acc[normalizedName].push(txn);
        return acc;
      }, {});

      function getYearFromTransaction(txn) {
        return parseISO(txn.date).getFullYear();
      }

      // 4. Identify possible recurring frequencies
      for (const [name, txns] of Object.entries(groupedByName)) {
        txns.sort((a, b) => parseISO(a.date) - parseISO(b.date));

        if (name.toLowerCase().includes('paypal') || name.toLowerCase().includes('zelle')) {
          // If the name includes "paypal" or "zelle", group transactions by normalized name and amount
          const groupedByNameAndAmount = txns.reduce((acc, txn) => {
            const normalizedName = this.normalizeName(txn.name);
            const key = `${normalizedName}-${txn.amount}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(txn);
            return acc;
          }, {});

          // Process each group of transactions with the same normalized name and amount
          for (const [key, groupedTxns] of Object.entries(groupedByNameAndAmount)) {
            processTransactionGroup(groupedTxns, this.matchedTransactions);
          }
        } else {
          // For non-paypal and non-zelle transactions, process them as before
          processTransactionGroup(txns, this.matchedTransactions);
        }
      }

      function calculateConfidenceScore(txns, matchedFrequency) {
        let score = 100; // Start with 100%

        // Adjust score based on the variance of amounts
        const amounts = txns.map(txn => txn.amount);
        const maxAmount = Math.max(...amounts);
        const minAmount = Math.min(...amounts);
        const amountVariance = (maxAmount - minAmount) / maxAmount;
        
        // New non-linear penalty calculation for amount variance
        const variancePenalty = Math.min(50, Math.round(100 * Math.pow(amountVariance, 2)));
        score -= variancePenalty;

        // Rest of the function remains the same
        // Adjust score based on the number of transactions
        if (txns.length < 3) {
          score -= Math.min(30, (3 - txns.length) * 15); // Linear scaling, max 30 point reduction
        }

        // Adjust score if there are irregular gaps between transactions
        let irregularGapPenalty = 0;
        for (let i = 0; i < txns.length - 1; i++) {
          const dateDiff = differenceInCalendarDays(parseISO(txns[i + 1].date), parseISO(txns[i].date));
          if (dateDiff > 90) {
            irregularGapPenalty += Math.min(5, Math.floor((dateDiff - 90) / 30)); // 5 points per month over 90 days, max 5 per gap
          }
        }
        score -= Math.min(20, irregularGapPenalty); // Cap total irregular gap penalty at 20 points

        // Adjust score based on the days since the last transaction
        const today = new Date();
        const lastTransactionDate = parseISO(txns[txns.length - 1].date);
        const daysSinceLastTransaction = differenceInCalendarDays(today, lastTransactionDate);

        const frequencyMap = {
          "Weekly": 7,
          "Every 2 weeks": 14,
          "Monthly": 30,
          "Every 2 months": 60,
          "Every 3 months": 90,
          "Every 6 months": 180,
          "Annually": 365,
        };

        const expectedInterval = frequencyMap[matchedFrequency] || Number.MAX_VALUE;
              const overdueFactor = Math.max(0, daysSinceLastTransaction - expectedInterval) / expectedInterval;
              score -= Math.min(40, Math.round(overdueFactor * 40)); // Linear scaling, max 40 point reduction

              return Math.max(score, 0); // Ensure the score doesn't go below 0
            }

            function processTransactionGroup(txns, matchedTransactions) {
              for (let i = 0; i < txns.length - 1; i++) {
                const dateDiff = differenceInCalendarDays(
                  parseISO(txns[i + 1].date),
                  parseISO(txns[i].date)
          );

          let matchedFrequency = null;
          let daysToAdd = null;

          for (let i = txns.length - 2; i >= 0; i--) {
            const dateDiff = differenceInCalendarDays(
              parseISO(txns[i + 1].date),
              parseISO(txns[i].date)
            );

            if (dateDiff >= 6 && dateDiff <= 8) {
              matchedFrequency = "Weekly";
              daysToAdd = 7;
              break;
            } else if (dateDiff >= 13 && dateDiff <= 15) {
              matchedFrequency = "Every 2 weeks";
              daysToAdd = 14;
              break;
            } else if (dateDiff >= 26 && dateDiff <= 33) {
              matchedFrequency = "Monthly";
              daysToAdd = 30;
              break;
            } else if (dateDiff >= 55 && dateDiff <= 63) {
              matchedFrequency = "Every 2 months";
              daysToAdd = 60;
              break;
            } else if (dateDiff >= 86 && dateDiff <= 94) {
              matchedFrequency = "Every 3 months";
              daysToAdd = 90;
              break;
            } else if (dateDiff >= 174 && dateDiff <= 186) {
              matchedFrequency = "Every 6 months";
              daysToAdd = 180;
              break;
            } else if (dateDiff >= 350 && dateDiff <= 380) {
              matchedFrequency = "Annually";
              daysToAdd = 365;
              break;
            }
          }

          if (matchedFrequency) {
            const today = new Date();
            const lastTransactionDate = parseISO(txns[txns.length - 1].date);
            const daysSinceLastTransaction = differenceInCalendarDays(today, lastTransactionDate);

            // Determine the maximum allowed days since the last transaction based on frequency
            let maxDaysAllowed;
            switch (matchedFrequency) {
              case "Weekly":
                maxDaysAllowed = 7 * 3;
                break;
              case "Every 2 weeks":
                maxDaysAllowed = 14 * 3;
                break;
              case "Monthly":
                maxDaysAllowed = 30 * 2;
                break;
              case "Every 2 months":
                maxDaysAllowed = 60 * 3;
                break;
              case "Every 3 months":
                maxDaysAllowed = 90 * 2;
                break;
              case "Every 6 months":
                maxDaysAllowed = 180 * 2;
                break;
              case "Annually":
                maxDaysAllowed = 365 * 2;
                break;
              default:
                maxDaysAllowed = Number.MAX_VALUE; // Default case to handle unexpected frequencies
            }

            // Check if the transaction is still active
            if (daysSinceLastTransaction > maxDaysAllowed) {
              continue; // Skip to the next iteration if inactive
            }
            const nextExpectedDate = addDays(lastTransactionDate, daysToAdd);
            const amounts = txns.map(txn => txn.amount);
            const expectedAmount = (amounts.reduce((acc, amt) => acc + amt, 0)) / txns.length;
            const minAmount = Math.min(...amounts);
            const maxAmount = Math.max(...amounts);
            const itemId = `${txns[0].name}-${i}`;

            const amountsByYear = txns.reduce((acc, txn) => {
              const year = getYearFromTransaction(txn);
              if (!acc[year]) {
                acc[year] = 0;
              }
              acc[year] += txn.amount;
              acc.total += txn.amount; // compute the overall total
              return acc;
            }, { total: 0 });

            // Calculate confidence score based on matching frequency and other criteria
            const confidenceScore = calculateConfidenceScore(txns);

            matchedTransactions.push({
              id: itemId, // Assign a unique id
              name: txns[0].name,
              frequency: matchedFrequency,
              transactions: txns,
              ...amountsByYear,
              minAmount: minAmount,
              maxAmount: maxAmount,
              numberOfTransactions: txns.length,
              nextExpectedDate: format(nextExpectedDate, "yyyy-MM-dd"),
              expectedAmount: expectedAmount,
              confidenceScore: confidenceScore.toFixed(0) // Add the confidence score here
            });
            break;
          }
        }
      }


      const frequencyOrder = {
        'Weekly': 1,
        'Every 2 weeks': 2,
        'Monthly': 3,
        'Every 2 months': 4,
        'Every 3 months': 5,
        'Every 6 months': 6,
        'Annually': 7
      };
      // Filter matchedTransactions based on confidence score and sort them
      const allMatchedTransactions = this.matchedTransactions;
      this.filteredOutTransactions = allMatchedTransactions.filter(transaction => transaction.confidenceScore < 70);
      this.matchedTransactions = allMatchedTransactions
        .filter(transaction => transaction.confidenceScore >= 70)
        .sort((a, b) => this.frequencyOrder[a.frequency] - this.frequencyOrder[b.frequency]);

      this.showingAllResults = false; // Reset this flag when finding new transactions

      if (this.matchedTransactions.length === 0 && this.showingResults) {
        this.showNoRecurringRulesMessage = true;
        this.buttonLabel = "Find Recurring Transactions";

        setTimeout(() => {
          this.showNoRecurringRulesMessage = false;
        }, 3000); // 3 seconds
      }
      this.isLoading = false; // set loading state back to false when done
    },
    showAllResults() {
      this.showingAllResults = true;
      this.matchedTransactions = [...this.matchedTransactions, ...this.filteredOutTransactions]
        .sort((a, b) => this.frequencyOrder[a.frequency] - this.frequencyOrder[b.frequency]);
    },
    async addRecurringRule(ruleData) {
      this.addingRule = ruleData.name;  // Set the rule name
      const userId = this.user.uid;
      const recurringRulesCollection = collection(db, `users/${userId}/recurringRules`);
      const transactionsToUpdate = ruleData.transactions;  // Keep the transactions to update them later
      // Capitalize the first letter of the name before saving
      ruleData.name = this.capitalizeFirstLetter(ruleData.name);

      ruleData.isActive = true;  // Set the rule to be active by default

      ruleData.customId = ruleData.id;  // set the custom 'itemId' to a field named 'customId'
      delete ruleData.id;  // Remove the id property so it doesn't interfere with Firestore's generated ID

      // Add rule to Firestore
      try {
          const docRef = await addDoc(recurringRulesCollection, ruleData);
          console.log("Recurring rule added with ID:", docRef.id);
          ruleData.docId = docRef.id;  // Store the Firestore document ID in the rule object

          // After adding the rule, update each transaction's "recurringName" and "recurringId" fields
          const transactionsCollection = collection(db, `users/${userId}/transactions`);
          for (let txn of transactionsToUpdate) {
              const txnDoc = doc(transactionsCollection, txn.id);
              await updateDoc(txnDoc, { 
                  recurringName: ruleData.name,
                  recurringId: docRef.id
              });
          }
          console.log(`Updated ${transactionsToUpdate.length} transactions with the recurring rule name and id.`);

          this.$store.dispatch('fetchRecurringRules');
          
          // Remove only the specific rule from the matchedTransactions array
          if (ruleData.name.toLowerCase().includes('paypal') || ruleData.name.toLowerCase().includes('zelle')) {
            this.matchedTransactions = this.matchedTransactions.filter(item => 
              item.name !== ruleData.name || item.expectedAmount !== ruleData.expectedAmount
            );
          } else {
            this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);
          }

          // Call the findRecurringTransactions method to refresh the displayed recurring rules
          // await this.fetchRecurringRules();

          // After rule has been added and matchedTransaction has been updated
          this.addingRule = null;  // Reset the addingRule property
          this.showSuccessMessage = true;  // Show success message

          // Hide the success message after a short delay
          setTimeout(() => {
              this.showSuccessMessage = false;
          }, 3000);  // 3 seconds delay

          
      } catch (error) {
          console.error("Error processing recurring rule:", error);
      }
      // Remove the rule from the matchedTransactions array
        this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);

      // Check if there are no more matched transactions
      if (this.matchedTransactions.length === 0) {
        this.buttonLabel = "Find Recurring Transactions";
        this.showingResults = false; // Also consider hiding the results if no transactions are left
      }
    }
  }
};

</script>
  
<style>





.recurring-finder-button {
  margin: 0px auto !important;
  background-color: #d8d8d8;
  color: black;
  width: 25%;
  padding: 20px;
  border-radius: 13px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: #fff;
  outline-style: solid;
}

.recurring-finder-button:hover {
  background-color: white;
}

.add-recurring-button {
  margin: 0 auto !important;
  background-color: #eeeeee;
  color: black;
  width: max-content;
  padding: 8px 15px !important;
  border-radius: 6px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  outline-color: #fff;
  outline-style: solid;
}

.add-recurring-button:hover {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 7px 4px rgb(29 29 29 / 5%);
  transition: 0.3s ease-in-out;
}

.recurring-input {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  margin: 1%;
  font-family: sans-serif;
}

.rename-rule-input {
  padding: 0px 5px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: black;
  font-weight: 900;
  margin: 0;
  font-size: 18px;
  font-family: sans-serif;
}




.recurring-textarea {
  width: 80%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  appearance: none;
  margin: 1%;
  font-family: sans-serif;
}

.recurring-select {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  margin: 1%;
  appearance: none;
}

.add-recurrings {
  align-items: baseline;
  padding: 3%;
  margin-bottom: 5%;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 10px;
  margin: 1% auto;
  text-align: left;
}

.current-recurrings, 
.rule-details {
  align-items: center;
  margin-bottom: 3%;
  padding: 0 2%;
  border-radius: 10px;
  margin: 0;
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.left-side,
.right-side {
    display: flex;
    flex-direction: column; /* Stack the p tags vertically */
    width: 50%; /* Each side takes up half the width */
    margin: 1%;
}

.rule-summary {
  text-align: left;
}

.annual-summary {
  text-align: right;
}

.recurring-name,
.recurring-next-amount {
    font-weight: 900;
    margin: 0;
    font-size: 18px;
}

.recurring-next-amount:hover {
  outline: #e3e3e3 solid thin;
  border-radius: 5px;
  cursor: pointer;
}

.recurring-name {
  text-align: left;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recurring-name:hover {
  outline: #e3e3e3 solid thin;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: -10px;
  cursor: pointer;
  background-color: white;
}

.recurring-next-amount {
    text-align: right;
}

/* If you have specific styles for frequency and date, you can add them here */
.recurring-frequency {
    text-align: left;
    font-size: 1em;
    margin: 0;
}

.recurring-next-date {
    text-align: right;
    font-size: 1em;
    margin: 0;
}






.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  height: 100%;
  z-index: 1;
}

.edit-modal {
  position: fixed;
  top: 50%;
  /* left: var(--sidebar-width); */
  background-color: white;
  padding: 1em;
  z-index: 1000; /* to make sure the modal appears above the overlay */
  width: 80%; /* adjust to desired size */
  max-width: 500px; /* adjust to desired size */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center horizontally */
  transform: translate(-50%, -50%);
  left: 50%;
  position: fixed;
  top: 50%;
}


.recurring-history {
  display: table;
  width:-webkit-fill-available;
  clear: both;
}

.recurring-row,
.new-recurring-row {
  align-items: center;
  margin-bottom: 3%;
  width: 93.5%;
  justify-content: space-evenly;
  border-radius: 10px;
  margin: 1% auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.new-recurring-row {
  background-color: #ffffff;
    border: #a09aff thin dashed;
    color: #959595;
}


.recurring-row:hover,
.new-recurring-row:hover {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.recurring-row-expanded, 
.new-recurring-row-expanded {
  background-color: white;
}


.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto .75fr auto auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 { 
  grid-area: 1 / 1 / 2 / 2; 
}
.div2 { 
  grid-area: 1 / 2 / 2 / 4; 
}
.div3 { 
  grid-area: 1 / 4 / 3 / 5; 
  background-color: white;
  display: flex;
  align-items: center;
  height: 45vh;
}
.div4 { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 20px;
  background-color: #c6d0bc;
  margin-left: 5%;
  margin-top: 5%;
  height: 30vh;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
}

.innerdiv1 { 
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: center;
    text-align: right;
    float: right;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 600;
}

.innerdiv2 { 
  grid-area: 1 / 2 / 2 / 3; 
}

.innerdiv3 { 
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 2.5vw;
  font-weight: 800;
}

.innerdiv4 { 
  grid-area: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any content that exceeds the container's bounds */
  max-height: 30vh; /* Set the maximum height */
}



/* 
.div5 { 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 20px;
  background-color: #afd0bf;
  margin: 5%;
  height: 30vh;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
}

*/

.div6 { 
  grid-area: 2 / 2 / 4 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll; 
  text-align: center;
  background-color: #ffffff;
  margin: 5% 5% 2% 0%;
  min-height: 30vh;
  height: max-content;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}



.growthdiv1 { 
  grid-area: 1 / 1 / 2 / 3; 
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  margin: 25px;
  margin-bottom: 50px;
}
.growthdiv2 { grid-area: 1 / 3 / 2 / 4; }
.growthdiv3 { 
  grid-area: 2 / 1 / 3 / 2; 
  font-weight: 600;
  text-align: right;
}
.growthdiv4 { 
  grid-area: 2 / 2 / 3 / 3; 
  font-weight: 600;
}
.growthdiv5 { 
  grid-area: 2 / 3 / 3 / 4; 
  font-weight: 600;
  text-align: left;
}
.growthdiv6 { 
  grid-area: 3 / 1 / 4 / 2; 
  text-align: right;
}
.growthdiv7 { grid-area: 3 / 2 / 4 / 3; }
.growthdiv8 { 
  grid-area: 3 / 3 / 4 / 4; 
  text-align: left;
}
.growthdiv9 { 
  grid-area: 4 / 1 / 5 / 2; 
  text-align: right;
}
.growthdiv10 { grid-area: 4 / 2 / 5 / 3; }
.growthdiv11 { 
  grid-area: 4 / 3 / 5 / 4; 
  text-align: left;
}


.innerdiv9 { 
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: flex-end;
    text-align: right;
    float: right;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 600;
}

.innerdiv10 { 
  grid-area: 1 / 2 / 2 / 3; 
  padding: 3%;
}

.innerdiv11 { 
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 3em;
  font-weight: 800;
}

.innerdiv12 { 
  grid-area: 3 / 1 / 4 / 3; 
}






.div7 { 
  grid-area: 3 / 1 / 4 / 4; 
  padding: 0%;
}
.div8 { 
  grid-area: 4 / 1 / 5 / 4; 
}
.div9 { 
  grid-area: 3 / 4 / 6 / 5; 

}

.div10 {
  grid-area: 5 / 1 / 5 / 4;
  padding: 3%;
}

.template-h2 {
  text-align: left;
  margin-left: 3%;
}



th,
td {
  padding: 8px;
  text-align: left;
  font-size: .9em;
  font-family: unset;
}

td:not(.date-td,.checkbox-td,.delete-td):hover {
  outline: #e3e3e3;
  outline-style: solid;
  border-radius: 5px;
  outline-width: thin;
  background-color: white;
  cursor: pointer;
}

th {
  border-bottom: #e3e3e3;
}

th.text-left, td.text-left {
  text-align: left;
}

tr:not(.date-row):hover {
  background-color: #fbfafa;
}

tr {
  height: 14px;
}

.recurring-table {
  width:-webkit-fill-available;
  margin: 3%;
}

.track-recurrings-save {
  margin: 10px 10px;
  padding: 0px 30px;
  background-color: white;
  outline-style: solid;
  outline-color: #fff;
  color: black;
  transition: 0.3s ease-in-out;
}


.update-recurrings-button {
  margin: 10px 10px;
  padding: 10px 30px;
  background-color: white;
  outline-style: solid;
  outline-color: #fff;
  color: black;
  transition: 0.3s ease-in-out;
}

.track-recurrings-cancel {
  margin: 10px 10px;
  padding: 0px 30px;
  background-color: white;
  outline-style: solid;
  color: black;
  outline-color: #fff;
  transition: 0.3s ease-in-out;
}

.edit-recurrings-button-group {
  display: flex;
  transition: 0.3s ease-in-out;
}

.edit-recurrings-button-group input, .edit-recurrings-button-group textarea, .edit-recurrings-button-group select {
  background-color: #f7f7f7;
  color: gray;
  font-family: sans-serif;
  font-size: 14px;
}



.add-recurrings-button2 {
  margin: 10px 10px;
  background-color: #5d5d5d;
  transition: 0.3s ease-in-out;
}

.find-recurring-buttons {
  margin-bottom: 10px;
  /* margin: 20px 50px !important; */
    /* background-color: #79c1c1; */
    /* max-width: 300px !important; */
    /* color: white; */
    /* width: -webkit-fill-available; */
    /* padding: 20px; */
    /* border-radius: 7px; */
    /* font-weight: bold; */
    /* transition: 0.3s ease-in-out; */
    /* box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%); */
    /* outline-color: unset; */
    /* outline-style: solid; */
}

.beige-buttons-edit-recurrings {
  margin: 10px 10px;
  padding: 10px 20px;
  width: 80px;
  background-color: white;
  outline-style: solid;
  outline-color: #fff;
  color: black;
  transition: 0.3s ease-in-out;
}

.find-recurring-buttons:hover {
  background-color: #2c2c2c;
}


input:focus-visible {
  /* background-color: white; */
  outline: none;
}

textarea:focus-visible {
  background-color: #f2f3f5;
}

p {
  margin-bottom: 1.5rem;
}



.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Apply the same text alignment to each column in the table body */
.recurring-history tbody > tr > td:nth-child(1) {
  text-align: left;
}

.recurring-history tbody > tr > td:nth-child(2) {
  text-align: left;
}

.recurring-history tbody > tr > td:nth-child(3) {
  text-align: center;
}
.recurring-history tbody > tr > td:nth-child(4) {
  text-align: right;
}

.edit-inline-input {
  background-color: unset;
  text-align: inherit;
  color: gray;
  padding: 0;
  border: none;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}


td:focus-visible {
    outline: none;
}

.recurrings-list {
  margin-bottom: 8%;
}

.positive {
  color: green;
  font-weight: 500;
}

.negative {
  color: red;
}

.canvas-styling {
  padding: 3%;
}

.rule-summary {
  text-align: left;
}

.annual-summary {
  text-align: right;
}

.recurring-summary {
  text-align: right;
  font-size: 1vw;
  font-weight: 600;
  padding: 10% 10% 0% 10%;
}

.edit-button {
  transition: color 0.3s ease, background-color 0.3s ease;
  display: inline-block;
  padding: 5px 10px;
  margin: 0px 0px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff00;
  color: #b1b1b1;
  font-size: 12px;
  min-width: 36px;
  cursor: pointer;
  font-weight: bolder;
}


.edit-button:hover {
    background-color: red !important;
    color: white;
}

.delete-edit-button {
  transition: color 0.3s ease, background-color 0.3s ease;
  display: inline-block;
  padding: 5px 10px;
  margin: 0px 0px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff00;
  color: #b1b1b1;
  font-size: 12px;
  cursor: pointer;
  font-weight: bolder;
}


.delete-edit-button:hover {
    background-color: red !important;
    color: white;
}

.edit-rule-buttons {
  display: inline-flex;
  float: left;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 76px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 20px;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.active-label {
  padding-right: 8px;
}

.current-month-recurring {
  list-style-type: none;
  text-align: left;
  padding-left: 0px;
  padding: 20px;
  font-size: 14px;
  height: 80%;
  margin: 0; 
  width: 100%; 
}

.transaction-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will space out the children */
  padding: 2px 3%;
}

.status-icon {
  flex: 0 0 auto; /* No grow, no shrink, auto basis */
  margin-right: 10px;
}

.transaction-name {
  flex: 1; /* Allows this element to grow and take available space */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transaction-date {
  flex: 0 0 100px; /* Fixed width */
  text-align: left;
}

.transaction-amount {
  flex: 0 0 90px; /* No grow, no shrink, auto basis */
  text-align: right;
  margin-left: auto; /* This pushes the amount to the far right */
  align-self: end;
}

.completed-transaction {
  color: #adadad; 
}

.past-due-transaction {
  color: #adadad; 
  /* text-decoration: line-through; */
}

.rules-message {
  font-size: 18px;
  font-weight: 600;
  margin: 40px 0px;
}

.total-for-month {
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 26px;
  font-weight: 600;
}

.total-for-month-line {
  text-align: right;
  color: #cccccc;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
}

.date-suffix {
  font-size: smaller; /* Adjust the font size as needed */
  vertical-align: super;
}

.past-due-label {
  margin-right: 4px; /* Adjust as needed */
  font-weight: 600;
}

#import-text-recurring-left {
  text-align: left;
  margin: 2.5rem;
  animation: fadeInSlideDown .5s ease-in-out .5s; /* Delayed start */

}

#welcome-text-center {
  text-align: center;
  margin: 2.5rem;
  animation: fadeInSlideDown .5s ease-in-out .25s;
}

.fade-slide-down {
  opacity: 0;
  transform: translateY(-20px);
  animation-fill-mode: forwards !important; 
}

.find-recurring-button-left {
  animation: fadeInSlideDown .5s ease-in-out .75s; /* Delayed start */
  /* margin-left: 2.5rem;
  background-color: #ffffff; */
  /* color: white; */
  /* width: max-content; */
  /* padding: 20px; */
  /* border-radius: 13px; */
  /* font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: unset; */
}

#top-right-sidebar {
  position: sticky;
  background-color: white;
  /* height: 100%; */
  margin-right: 5%;
  margin-top: 40px;
  height: 25vh;
  top: 0;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}

#top-right-sidebar2 {
  position: sticky;
  background-color: white;
  margin-top: 40px;
  height: 25vh;
  top: 0;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes dropIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}


.recurring-row {
  animation: dropIn 0.5s ease-out forwards;
}

.current-month-recurring {
    animation: fadeIn 1s ease-in forwards;
}

.recurring-summary {
    animation: fadeIn 1s ease-in forwards;

}

#top-right-sidebar {
    animation: dropIn 0.5s ease-out forwards;
}

#bottom-right-sidebar {
  position: fixed;
  width: auto;
  bottom: 20px;
  right: 0px;
}

.custom-popper-class {
  background-color: #f6f6f6;
  height: 50px;
}

.recurring-next-amount-edit {
  text-align: right;
  width: 100%;
  border-radius: 5px;
  padding: 0px 5px;
  background-color: #f5f5f5;
}


.show-all-results-button {
  margin: 20px auto;
  background-color: #f0f0f0;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  display: block;
}

.show-all-results-button:hover {
  background-color: #e0e0e0;
}

.confidence-score {
  text-align: right;
  font-size: 1em;
  margin: 0;
}

@media screen and (max-width: 768px) {
  #bottom-right-sidebar {
    position: relative;
  }
  .current-month-recurring {
    width: 100vw;
    padding: 10px;
  }

  .date-suffix {
    font-size: xx-small;
  }

  sup {
    position: initial;
  }

  .transaction-item {
    font-size: 12px;
    margin: 2px 0px;
  }

  .total-for-month, .total-for-month-line {
    margin-right: 0px;
  }
  .past-due-label {
    font-size: 12px;
  }

  .recurring-summary {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  #top-right-sidebar {
    box-shadow: unset;
    height: 25vh;
    margin-top: 40px;
  }

  #top-right-sidebar2 {
    box-shadow: unset;
    height: 25vh;
    margin-top: 40px;
  }
  
  .div6 {
    box-shadow: unset;
    margin: unset;
  }
  .left-side, .right-side {
    width: 48vw;
  }



  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 20px;
    transition: .4s;
  }

  .toggle-switch {
    width: 32px;
    height: 17px;
    margin-left: -50px;
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }

  .current-recurrings {
    min-height: 60px;
  }

}
</style>
  