<template>

  <div class="Settings">
    <div class="settings-manager">
      <label-manager></label-manager>
      <!-- <asset-class-manager></asset-class-manager> -->
      <categories-manager></categories-manager>
      <budget-template-manager></budget-template-manager>
      <transaction-rules-manager></transaction-rules-manager>
      <v-btn class="manage-billing-button" :href="'https://billing.stripe.com/p/login/dR60111cGbRo3VC288'" target="_blank">
        Billing
      </v-btn>
    </div>
  </div>
  <div class="Account Details">
    <div class="settings-manager">
      <v-form class="settings-form" @submit.prevent="updateUserProfile">
        <v-text-field
          label="Name"
          id="name"
          v-model="userProfile.name"
          required
          outlined
          dense
        ></v-text-field>

        <v-text-field
          label="Email"
          id="email"
          v-model="userProfile.email"
          required
          type="email"
          outlined
          dense
        ></v-text-field>

        <v-text-field
          label="Birthdate"
          id="birthdate"
          v-model="userProfile.birthdate"
          required
          type="date"
          outlined
          dense
        ></v-text-field>

        <v-btn type="submit" class="settings-button">
          Update Profile
        </v-btn>

        <v-btn @click="toggleDeleteConfirmation" class="settings-delete-user-button settings-button">
          Delete Account
        </v-btn>
        <div v-if="showDeleteConfirmation" class="delete-confirmation">
          <p style="font-size: 16px;">Are you sure you want to delete your account? This action cannot be undone.</p>
          <v-btn @click="confirmDelete" color="error">Confirm</v-btn>
          <v-btn @click="toggleDeleteConfirmation">Cancel</v-btn>
        </div>
      </v-form>
    </div>
  </div>
  <div class="Support">
   <!-- <div class="settings-manager">
     <h3>Support</h3>
     <p class="support-text">
       If you have any questions, concerns, or need assistance with your account, please reach out to us at <a href="mailto:support@fivebags.co">support@fivebags.co</a>. 
     </p>
   </div> -->
 </div>

</template>

<script>
import { deleteDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebaseInit';
import { deleteUser } from 'firebase/auth';
import LabelManager from '../components/LabelManager.vue';
// import AssetClassManager from '../components/AssetClassManager.vue';
import CategoriesManager from '../components/CategoriesManager.vue';
import BudgetTemplateManager from '../components/BudgetTemplateManager.vue';
import TransactionRulesManager from '../components/TransactionRulesManager.vue';


export default {
  name: 'SettingsView',
  components: {
    LabelManager,
    // AssetClassManager,
    CategoriesManager,
    BudgetTemplateManager,
    TransactionRulesManager,

  },
  data() {
    return {
      authenticated: false, // Initialize the authenticated state
      showDeleteConfirmation: false,
      userProfile: {
        name: '',
        email: '',
        birthdate: ''
      },
      stripeLoaded: false
    };
  },
  created() {
    // Listen for changes in authentication state
    auth.onAuthStateChanged((user) => {
      this.authenticated = user !== null; // Update the authenticated state
    });
  },
  async mounted() {
    this.fetchUserProfile();
    this.loadStripeScript(); 
  },
  methods: {
    loadStripeScript() {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.onload = () => {
          this.stripeLoaded = true; // Set stripeLoaded to true once the script is loaded
        };
        document.head.appendChild(script);
      },
    deleteAccount() {
      // Modify this method to only show the confirmation modal
      this.showDeleteConfirmation = true;
    },
    closeModal() {
      this.showDeleteConfirmation = false;
    },
    async fetchUserProfile() {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, `users/${user.uid}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            this.userProfile = docSnap.data(); // Assuming the document data matches the userProfile structure
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    },
    async updateUserProfile() {
      try {
        const user = auth.currentUser;
        if (user) {
          await updateDoc(doc(db, `users/${user.uid}`), {
            ...this.userProfile // Spread operator to update all fields
          });
          alert('Profile updated successfully.');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    },
    toggleDeleteConfirmation() {
      this.showDeleteConfirmation = !this.showDeleteConfirmation;
    },
    
    async confirmDelete() {
      try {
        const user = auth.currentUser;
        if (user) {
          await deleteDoc(doc(db, `users/${user.uid}`));
          await deleteUser(user);
          alert('Account deleted successfully.');
          
          // Force a page refresh and navigate to login
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please try again.');
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}

.settings-manager {
  padding: 30px 0px;
}

.asset-class-manager {
  padding: 5%;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.categories-manager, .budget-template-manager {
  padding: 2%;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.delete-account-button {
  background-color: #ff4d4d; /* Red color for delete button */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.delete-account-button:hover {
  background-color: #ff0000; /* Darker shade of red on hover */
}

.user-data {
  padding: 10px;
  width: 200px;
  display: inline-flex;
}

label.user-data {
  font-weight: 700;
}

.manage-billing-button {
  width: 300px;
  height: 60px;
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 10px;
}

.manage-billing-button:hover {
    background-color: #e6e6e6;
}

.settings-header {
  font-size: 35px;
  padding: 8px 20px;
  color: black;
  margin: 0px auto;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  display: inline-block;
}

.settings-delete-user-button:hover {
  color: white;
  background-color: red;
}

.Support {
 padding: 40px;
 text-align: center;
}

.support-text {
 font-size: 18px;
 line-height: 1.6;
 max-width: 600px;
 margin: 0 auto;
}

.delete-confirmation {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

.delete-confirmation p {
  margin-bottom: 15px;
}

.delete-confirmation button {
  margin-right: 10px;
}

.settings-form {
  padding: 50px; 
  width: 500px; 
  margin: 0 auto;
}

.settings-button {
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important;
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .settings-form {
    padding: 0;
    width: 250px;
  }
  .support-text {
    font-size: 14px;
  }
  .Support {
    padding: 20px;;
  }

  .settings-manager {
    padding: 10px 0px;
    margin-top: 60px;
  }

  .manage-billing-button {
    width: 200px;
  }

}
</style>
