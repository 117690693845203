<template>
  <div :class="{ 'light-mode': lightMode }">
    <TheHeader @toggle-light-mode="toggleLightMode" />
  
    <div class="pricing-parent">
      <div class="pricing-container">

    
  </div>
</div>
  <TheFooter class="footer-container" :lightMode="lightMode" />
</div>
</template>

<script>
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'

export default {
  name: "PricingView",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      lightMode: false,
    };
  },
  methods: {
    toggleLightMode() {
      this.lightMode = !this.lightMode;
    },
  },
};
</script>

<style scoped>
.pricing-parent {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: left;
  min-height: 80vh;
  background-color: white;
  margin: 0 auto;
  padding-top: 200px;

  color: black;
}


p {
  font-size: .95rem;
  margin: 1rem;
}

h1 {
  font-size: 2rem;
  margin: none;
}

.blog-pricing {

  padding-top: 200px;
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.pricing-parent a {
  color: white;
}

.light-mode .pricing-parent a {
  color: black;
}

.pricing-container {
  margin: 0 auto;
  width: 80%;
  padding-bottom: 200px;
}

ul{
  padding: 0px 30px;
  font-size: .85rem;
}

.light-mode .pricing-parent {
  background-color: white;
  color: black;
}


/* .pricing h1, .pricing h2 {
  color: #2c3e50;
} */

/* .pricing ul {
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 20px;
}

.pricing p {
  margin: 0.5em 0;
} */
</style>

