<template>
  <div class="sidebar" :class="{ 'inactive-subscription': !hasActiveSubscription }">

  <div class="loader-container hidden">
    <span class="loader"></span>
  </div>

  <div v-if="$store.state.syncingTransactions" class="progress-banner">
    <span class="loader2"></span> Syncing transactions... Please wait.
  </div>

  <!-- Quick Nav Modal -->
  <div v-if="showQuickNav" class="modal">
    <div class="quick-nav-content">
      <input v-model="searchQuery" type="text" placeholder="Type to search..." class="quick-nav-search" @keyup="filterNavItems">
      <div v-for="category in filteredCategories" :key="category.id" @click="goToCategory(category.name)">
        {{ category.name }}
      </div>
    </div>
  </div>

  <div v-if="showPlaidModal" class="modal-backdrop">
    <div class="modal-content">
      <!-- Loading bar and transaction count -->
      <div v-if="importingPlaid">
        <div class="progress-bar-container">
          <div class="progress-bar" :style="{ width: plaidProgressBarWidth }"></div>
        </div>
        <p>Importing Transactions: {{ plaidTransactionsImported }}/{{ totalPlaidTransactions }} ({{ plaidProgressBarPercentage }}%)</p>
      </div>
      <!-- <button @click="showPlaidModal = false">Cancel</button> -->
    </div>
  </div>


  <!-- Add Account Dialog -->
  <v-dialog style="padding: 20px 40px;" v-model="showAddAccountModal" max-width="600px">
    <v-card>

      <v-card-text class="account-grid">

        <button v-if="!showAddAssetForm && !showAddLiabilityForm" @click="linkNewAccount" @click.self="showAddAccountModal = false"> Bank Accounts</button>
        <button v-if="!showAddAssetForm && !showAddLiabilityForm" @click="linkNewAccount" @click.self="showAddAccountModal = false">Credit Cards & Loans</button>
        <button id="coming-soon" v-if="!showAddAssetForm && !showAddLiabilityForm">Real Estate <br> <span class="coming-soon">Coming Soon</span></button>
        <button id="coming-soon" v-if="!showAddAssetForm && !showAddLiabilityForm">Crypto Wallet <br> <span class="coming-soon">Coming Soon</span></button>

        <form v-if="showAddAssetForm" @submit.prevent="addAsset" class="form-display-style">
          <h2 class="accountType-sidebar">Add a Custom Asset</h2><br>
          <v-select
            v-model="newAsset.assetClass"
            :items="assetClasses"
            item-text="name"
            item-value="name"
            label="Select Asset Class"
            clearable
            required
          ></v-select>
          <div v-if="newAsset.assetClass === '💎 Precious Metals'"> 
            <v-select 
              style="width: 100%; text-align: left;" 
              v-model="newAsset.metalType" 
              :items="['Gold', 'Silver', 'Platinum', 'Palladium', 'Custom']" 
              label="Select Metal Type" 
              class="w-full md:w-14rem">
            </v-select>
            <v-text-field
              class="assets-input-sidebar"
              v-model="newAsset.quantity"
              type="number"
              label="Quantity in ounces"
              required
            ></v-text-field>
          </div>
          <div v-if="['🏡 Real Estate', '🏎️ Vehicles'].includes(newAsset.assetClass)">
            <v-select
              class="assets-input-sidebar"
              :items="['Financed', 'Fully Owned']" 
              v-model="newAsset.status"
              label="Financed Status"
              required
              style="width: 100%; text-align: left;"
            ></v-select>
            <v-text-field v-if="newAsset.status === 'Financed'" class="assets-input-sidebar" v-model.number="newAsset.currentLoanBalance" type="number" label="Current Loan Balance" required @input="newAsset.currentLoanBalance = validatePositiveNumber(newAsset.currentLoanBalance)"></v-text-field>
            <v-text-field v-if="newAsset.status === 'Financed'" class="assets-input-sidebar" v-model.number="newAsset.interestRate" type="number" step="0.01" label="Interest Percentage Rate" required @input="newAsset.interestRate = validatePositiveNumber(newAsset.interestRate)"></v-text-field>
            <v-text-field v-if="newAsset.status === 'Financed'" class="assets-input-sidebar" v-model.number="newAsset.monthlyPayment" type="number" label="Monthly Payment" required @input="newAsset.monthlyPayment = validatePositiveNumber(newAsset.monthlyPayment)"></v-text-field>
            <v-text-field v-if="newAsset.status === 'Financed'" class="assets-input-sidebar" v-model.number="newAsset.loanTerm" type="number" label="Loan in Terms (months)" required @input="newAsset.loanTerm = validatePositiveNumber(newAsset.loanTerm)"></v-text-field>

          </div>
          <div v-if="newAsset.assetClass === '🏎️ Vehicles'">
            <v-text-field class="assets-input-sidebar" v-model="newAsset.year" type="number" label="Year" required></v-text-field>
            <v-text-field class="assets-input-sidebar" v-model="newAsset.make" type="text" label="Make" required></v-text-field>
            <v-text-field class="assets-input-sidebar" v-model="newAsset.model" type="text" label="Model" required></v-text-field>
            <v-select
              class="assets-input-sidebar"
              :items="['Excellent', 'Great', 'Good', 'Fair', 'Poor']" 
              v-model="newAsset.condition"
              label="Vehicle Condition"
              required
              style="width: 100%; text-align: left;"
            ></v-select>
          </div>

          <v-text-field class="assets-input-sidebar" v-model="newAsset.name" label="Name" required  ></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model="newAsset.purchaseDate" type="date" label="Purchase Date" required></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model.number="newAsset.currentValue" type="number" label="Current Value" required @input="newAsset.currentValue = validatePositiveNumber(newAsset.currentValue)"></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model.number="newAsset.purchaseValue" type="number" label="Starting Value" required @input="newAsset.purchaseValue = validatePositiveNumber(newAsset.purchaseValue)"></v-text-field>
          <v-textarea class="assets-textarea-sidebar" v-model="newAsset.description" label="Description"></v-textarea>
          
          <div class="addAccountButtons">
            <v-btn color="primary" type="submit">Add Asset</v-btn>
            <v-btn type="button" @click="showAddAssetForm = false">Close</v-btn>
          </div>
        </form>

        <button v-if="!showAddLiabilityForm && !showAddAssetForm" @click="showAddAssetForm = true" >
          Add Asset
        </button>


        <!-- ADD LIABILITY FORM -->
        <form v-if="showAddLiabilityForm" @submit.prevent="addLiability" class="form-display-style">
          <h2 class="accountType-sidebar">Add a Custom Liability</h2><br>
          <v-select
              class="assets-input-sidebar"
              :items="liabilityClasses" 
              v-model="newLiability.liabilityClass"
              label="Select Liability Class"
              required
              style="width: 100%; text-align: left;"
            ></v-select>
          <v-text-field class="assets-input-sidebar" v-model="newLiability.name" label="Name" required></v-text-field>
          <div v-if="newLiability.liabilityClass === '🚗 Vehicle Loans'">
            <v-text-field class="assets-input-sidebar" v-model="newLiability.year" type="number" label="Year" required></v-text-field>
            <v-text-field class="assets-input-sidebar" v-model="newLiability.make" type="text" label="Make" required></v-text-field>
            <v-text-field class="assets-input-sidebar" v-model="newLiability.model" type="text" label="Model" required></v-text-field>
            <v-select
              class="assets-input-sidebar"
              :items="['Excellent', 'Great', 'Good', 'Fair', 'Poor']" 
              v-model="newLiability.condition"
              label="Vehicle Condition"
              required
              style="width: 100%; text-align: left;"
            ></v-select>

          </div>
          <v-text-field class="assets-input-sidebar" v-model.number="newLiability.interestRate" type="number" step="0.01" label="Interest Percentage Rate" required></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model.number="newLiability.monthlyPayment" type="number" label="Monthly Payment" required></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model.number="newLiability.loanTerm" type="number" label="Loan Term (months)"></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model="newLiability.purchaseDate" type="date" label="Purchase Date" required></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model.number="newLiability.currentLoanBalance" type="number" label="Current Loan Balance" required></v-text-field>
          <v-text-field class="assets-input-sidebar" v-model="newLiability.purchaseValue" type="number" label="Starting Balance" required></v-text-field>
          <v-textarea class="assets-textarea-sidebar" v-model="newLiability.description" label="Description"></v-textarea>
          <div class="addAccountButtons">
            <v-btn color="primary" type="submit">Add Liability</v-btn>
            <v-btn type="button" @click="showAddLiabilityForm = false">Close</v-btn>
          </div>
        </form>
        
        <button v-if="!showAddAssetForm && !showAddLiabilityForm" @click="showAddLiabilityForm = true">
          Add Liability
        </button>

      </v-card-text>
    </v-card>
  </v-dialog>


  <div class="sidebar" :class="{ collapsed: collapsed }" @click="toggleCollapse">
    <!-- Sidebar content -->
    <div class="sidebar-content">
      <!-- Your navigation links can go here -->
      
      <!-- <router-link to="/networth" exact-active-class="active-link" @click.stop> -->
        <!-- <svg exact-active-class="active-link" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 288 512"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"/></svg> -->
         <!-- Net Worth</router-link> -->

        <span class="logo-sidebar">
          <img :src="image" alt="five1" class="fade-in" style="width:10%; margin-right: 10px;" />
          <span class="logo-text">fiyr</span>
        </span>
         <span class="sidebar-line-break">______________________________</span>

      <router-link to="/assets" active-class="active-link" class="router-link" id="first-link"  @click.stop>
        <svg exact-active-class="active-link" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 288 512">
          <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"/>
        </svg>
        <span>Networth</span>
      </router-link>
      
      <!-- <router-link to="/liabilities" active-class="active-link" class="router-link" @click.stop>
      
        Liabilities</router-link> -->

      <router-link to="/transactions" active-class="active-link" class="router-link" @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"/>
        </svg>
        <span>Transactions</span>
      </router-link>

      <router-link to="/recurring" active-class="active-link" class="router-link"  @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"/>
          </svg>
          <span>Recurring</span>
        </router-link>

      <router-link to="/budget" active-class="active-link" class="router-link" @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 544 512">
          <path d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"/>
        </svg>
        <span>Budget</span>
      </router-link>

      <router-link to="/summary" active-class="active-link" class="router-link"  @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
          <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-17.4-6.9-33.1-18.1-44.6L366 161.7c5.3-12.1-.2-26.3-12.3-31.6s-26.3 .2-31.6 12.3L257.9 288c-.6 0-1.3 0-1.9 0c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm352-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
        </svg>
        <span>Summary</span>
      </router-link>

      <router-link to="/fire" active-class="active-link" class="router-link"  @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z"/>
          </svg>
          <span>FIRE</span>
        </router-link>
  

      <router-link to="/goals" active-class="active-link" class="router-link" @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
          <path d="M346.3 271.8l-60.1-21.9L214 448H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H282.1l64.1-176.2zm121.1-.2l-3.3 9.1 67.7 24.6c18.1 6.6 38-4.2 39.6-23.4c6.5-78.5-23.9-155.5-80.8-208.5c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM107.2 112.9c-11.1 15.7-2.8 36.8 15.3 43.4l71 25.8 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2c-79.6 2.5-154.7 42.2-201.2 108z"/>
        </svg>
        <span>Goals</span>
      </router-link>
        
      
        <router-link to="/income" active-class="active-link" class="router-link" @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
          <path d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"/>
        </svg>
        <span>Income</span>
      </router-link>

        <!-- <router-link to="/debt-to-income" active-class="active-link" class="router-link" @click.stop> -->
        <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.<path d="M528 448H352V153.25c20.42-8.94 36.1-26.22 43.38-47.47l132-44.26c8.38-2.81 12.89-11.88 10.08-20.26l-10.17-30.34C524.48 2.54 515.41-1.97 507.03.84L389.11 40.37C375.3 16.36 349.69 0 320 0c-44.18 0-80 35.82-80 80 0 3.43.59 6.71 1.01 10.03l-128.39 43.05c-8.38 2.81-12.89 11.88-10.08 20.26l10.17 30.34c2.81 8.38 11.88 12.89 20.26 10.08l142.05-47.63c4.07 2.77 8.43 5.12 12.99 7.12V496c0 8.84 7.16 16 16 16h224c8.84 0 16-7.16 16-16v-32c-.01-8.84-7.17-16-16.01-16zm111.98-144c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zM440 288l72-144 72 144H440zm-269.07-37.51c-17.65-35.29-68.19-35.36-85.87 0C-2.06 424.75.02 416.33.02 432H0c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02c0-16.18 1.34-8.73-85.05-181.51zM56 416l72-144 72 144H56z"/></svg> -->
        <!-- <span>Debt-to-Income</span></router-link> -->

        
      <!-- <router-link to="/dashboard" active-class="active-link" class="router-link" @click.stop> -->
        <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"> -->
          
          <!-- <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-17.4-6.9-33.1-18.1-44.6L366 161.7c5.3-12.1-.2-26.3-12.3-31.6s-26.3 .2-31.6 12.3L257.9 288c-.6 0-1.3 0-1.9 0c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm352-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
        <span>Dashboard</span></router-link> -->

      <router-link to="/accounts" active-class="active-link" class="router-link"  @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
          
          <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"/>
        </svg>
        <span>Accounts</span>
      </router-link>

      <router-link to="/settings" active-class="active-link" class="router-link"  @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
        </svg>
        <span>Settings</span>
    </router-link>

    </div>
    

    <!-- User info section -->
    <div class="sidebar-footer">
      <div class="user-info">

        <!-- Chaport widget container -->
        <!-- <div class="add-account help-button" @click.stop="toggleChaport">
          <span class="add-account-text">Help</span>
        </div> -->

        <!-- <div class="add-account-plus" @click.stop="toggleChaport">
          <span class="plus-icon">
            <svg style="width: 30px; height: 30px; vertical-align: sub;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M504 256c0 137-111 248-248 248S8 393 8 256C8 119.1 119 8 256 8s248 111.1 248 248zM262.7 90c-54.5 0-89.3 23-116.5 63.8-3.5 5.3-2.4 12.4 2.7 16.3l34.7 26.3c5.2 3.9 12.6 3 16.7-2.1 17.9-22.7 30.1-35.8 57.3-35.8 20.4 0 45.7 13.1 45.7 33 0 15-12.4 22.7-32.5 34C247.1 238.5 216 254.9 216 296v4c0 6.6 5.4 12 12 12h56c6.6 0 12-5.4 12-12v-1.3c0-28.5 83.2-29.6 83.2-106.7 0-58-60.2-102-116.5-102zM256 338c-25.4 0-46 20.6-46 46 0 25.4 20.6 46 46 46s46-20.6 46-46c0-25.4-20.6-46-46-46z"/></svg>
          </span>
        </div> -->

        <div class="add-account" 
            @click="$store.state.subscription && $store.state.subscription.status === 'active' ? openAddAccountModal() : null" 
            :class="{ 'disabled': !$store.state.subscription || $store.state.subscription.status !== 'active' }" @click.stop>
          <span class="add-account-text">Add Account</span>
        </div>
        
        <!-- Display user name from Vuex store -->
        <p>{{ formattedUsername }}</p>
        
        <!-- Sign out button -->
        <button class="signoutbutton" @click="signOut">Sign Out</button>
      </div>
      <!-- Add Account Button for Collapsed Sidebar (always rendered, visibility controlled by CSS) -->
      <div class="add-account-plus" 
      @click="$store.state.subscription && $store.state.subscription.status === 'active' ? openAddAccountModal() : null" 
      :class="{ 'disabled': !$store.state.subscription || $store.state.subscription.status !== 'active' }" @click.stop>
        <span class="plus-icon">
          <svg style="width: 30px; height: 30px; vertical-align: sub;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div v-if="errorMessage" class="error-overlay">
    <div class="error-message">
      <p>{{ errorMessage }}</p>
      <button @click="errorMessage = null">Close</button>
    </div>
  </div>

</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { query, where, addDoc, getDocs, doc, getDoc, collection, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { mapState, mapGetters } from 'vuex';
import { auth, db } from "../firebaseInit";
import mitt from 'mitt';
import five1 from '../assets/logo-white.png';


const eventBus = mitt();


export default {
  name: 'SideBar',
  props: ['collapsed'], 
  emits: ['sidebarCollapseStateChanged'],
  components: {
    five1
  },
  data() {
    return {
      showAddAccountModal: false,
      errorMessage: null,
      showAddAssetForm: false,
      image: five1,
      showAddLiabilityForm: false,
      showChaport: false,
      chaportInitialized: false,
      progressBannerTimeout: 2000000, // 20 minute
      newAsset: {
        name: '',
        description: '',
        purchaseDate: '',
        purchaseValue: '',
        currentValue: '',
        assetClass: '', 
        accountType: 'Asset',
        metalType: '',
        quantity: '',
        status: '', // Initialize it empty
        currentLoanBalance: null,
        interestRate: null,
        monthlyPayment: null,
        loanTerm: '',
      },
      newLiability: {
        name: '',
        description: '',
        purchaseDate: '',
        purchaseValue: '',
        currentValue: '',
        liabilityClass: '', 
        assetClass: '', 
        accountType: 'Liability',
        status: '', // New field
        currentLoanBalance: null, // New field
        interestRate: null, // New field
        monthlyPayment: null, // New field
      },
      lastTabChangeTime: 0,
      selectedTab: 'both',
      assetClasses: [],
      liabilityClasses: [],
      assets: [],  
      liabilities: [],  
      linkToken: null,
      accessTokens: {}, // Store multiple tokens
      transactions: [],
      // Plaid import
      showPlaidModal: false,
      importingPlaid: false,
      plaidTransactionsImported: 0,
      totalPlaidTransactions: 0,
      categoryMap: {},
      // Quick Nav
      showQuickNav: false,
      searchQuery: '',
      navItems: [
        { name: 'Transactions', route: '/transactions' },
        { name: 'Budget', route: '/budget' },
        // Add other nav items here...
      ],
      filteredNavItems: [],
    };
  },
  computed: {
    ...mapState(['username', 'syncingTransactions', 'subscription', 'categories']),
    ...mapGetters(['isSubscriptionActive']),
    hasActiveSubscription() {
      return this.isSubscriptionActive;
    },
    filteredCategories() {
      if (this.searchQuery) {
        return this.categories.filter(category => category.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      return this.categories;
    },
    isAssetsPage() {
      return this.$route.path === '/assets';
    },
    progressBannerLeft() {
      return this.collapsed ? '80px' : '280px';
    },
    formattedUsername() {
      if (this.username) {
        return this.username.charAt(0).toUpperCase() + this.username.slice(1);
      } else {
        return '';
      }
    },
    plaidProgressBarWidth() {
      return `${(this.plaidTransactionsImported / this.totalPlaidTransactions) * 100}%`;
    },
    plaidProgressBarPercentage() {
      return Math.round((this.plaidTransactionsImported / this.totalPlaidTransactions) * 100);
    },
    subscriptionStatus() {
      console.log('Subscription status:', this.$store.state.subscription);
      return this.$store.state.subscription && this.$store.state.subscription.status === 'active';
    },
  },
  created() {
    this.auth = this.$auth;
  },
  mounted() {
    this.$store.dispatch('initializeSyncingTransactions');

    // Check the sync status on page load
    const itemIds = Object.keys(localStorage).filter(key => key.startsWith('syncItem_'));
    
    if (itemIds.length > 0) {
      // Only set syncingTransactions to true if there are items to sync
      this.$store.dispatch('updateSyncingTransactions', true);
      itemIds.forEach(itemId => {
        const syncStartTime = localStorage.getItem(itemId);
        const elapsedTime = Date.now() - syncStartTime;
        if (elapsedTime > this.progressBannerTimeout) {
          localStorage.removeItem(itemId);
          this.$store.dispatch('updateSyncingTransactions', false);
        } else {
          this.checkTransactionSyncStatus(itemId.replace('syncItem_', ''));
        }
      });
    } else {
      // Explicitly set syncingTransactions to false if there are no items
      this.$store.dispatch('updateSyncingTransactions', false);
    }

    // Listen for sync events
    eventBus.on('syncStarted', () => {
      this.$store.dispatch('updateSyncingTransactions', true);
    });

    eventBus.on('syncComplete', async () => {
      this.$store.dispatch('updateSyncingTransactions', false);
      // Fetch transactions and categories when sync is complete
      await this.$store.dispatch('fetchTransactions');
      await this.$store.dispatch('fetchCategories');
    });

    eventBus.on('syncTimeout', async () => {
      this.$store.dispatch('updateSyncingTransactions', false);
      // Fetch transactions and categories when sync times out
      await this.$store.dispatch('fetchTransactions');
      await this.$store.dispatch('fetchCategories');
    });

    this.fetchClassCollections();
    window.addEventListener('keydown', this.handleKeyDown);
    // Initialize filteredNavItems
    this.filteredNavItems = this.navItems;
  },
  methods: {
    async linkNewAccount() {
      await this.createLinkToken();;
      // this.$store.dispatch('updateSyncingTransactions', false)
      this.openPlaidLink();
    },
    toggleChaport() {
      this.showChaport = !this.showChaport;
      if (this.showChaport && !this.chaportInitialized) {
        this.initChaport();
      }
    },
    initChaport() {
      if (this.chaportInitialized) return;

      window.chaportConfig = {
        appId: '6707fe01c5ae7aa1141101dd'
      };

      const v3 = window.chaport = {};
      v3._q = [];
      v3._l = {};
      v3.q = function() { v3._q.push(arguments) };
      v3.on = function(e, fn) {
        if (!v3._l[e]) v3._l[e] = [];
        v3._l[e].push(fn)
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://app.chaport.com/javascripts/insert.js';
      
      document.head.appendChild(script);

      this.chaportInitialized = true;
    },
    async fetchClassCollections() {
      try {
        const user = auth.currentUser;
        if (user) {
          const assetClassesCollection = collection(db, `users/${user.uid}/assetclasses`);
          const assetClassesSnapshot = await getDocs(assetClassesCollection);
          this.assetClasses = assetClassesSnapshot.docs.map(doc => doc.data().name);

          const liabilityClassesCollection = collection(db, `users/${user.uid}/liabilityclasses`);
          const liabilityClassesSnapshot = await getDocs(liabilityClassesCollection);
          this.liabilityClasses = liabilityClassesSnapshot.docs.map(doc => doc.data().name);
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error fetching class collections:', error);
      }
    },
    
    async onTransactionSyncComplete() {
      this.$store.dispatch('updateSyncingTransactions', false);
      await this.$store.dispatch('fetchTransactions');
      await this.$store.dispatch('fetchCategories');
    },
    async checkTransactionSyncStatus(item_id) {
      const userId = auth.currentUser.uid; // Ensure you have the current user's ID
      // Store the sync start time in local storage
      localStorage.setItem(`syncItem_${item_id}`, Date.now());

      // Emit the 'syncStarted' event
      eventBus.emit('syncStarted');
      console.log(`Starting to check sync status for user: ${userId} and item: ${item_id}`);
      
      this.$store.dispatch('updateSyncingTransactions', true); // Initially set to true

      let syncComplete = false;
      let timeoutId = null; // Declare a variable to store the timeout ID

      // Emit the 'syncComplete' event when the sync is complete
      eventBus.emit('syncComplete');


      timeoutId = setTimeout(() => {
        console.log(`Sync timeout reached for item ${item_id}. Hiding progress banner.`);
        this.$store.dispatch('updateSyncingTransactions', false);
      }, this.progressBannerTimeout);

      while (!syncComplete) {
        const response = await axios.get(`https://fivebags-server.nn.r.appspot.com/check_sync_status`, {
          params: { userId, item_id }
        });
        syncComplete = response.data.syncComplete;

        console.log(`Received sync status for item ${item_id}: ${syncComplete}`);

        // Update the Vuex store only if the status changes
        if (!syncComplete) {
          console.log(`Sync is not complete for item ${item_id}. Waiting before next check...`);
          this.$store.dispatch('updateSyncingTransactions', true);
          await new Promise(resolve => setTimeout(resolve, 30000)); // Wait before next check
        } else {
          console.log(`Sync is complete for item ${item_id}. Updating UI...`);
          this.$store.dispatch('updateSyncingTransactions', false);
          clearTimeout(timeoutId); // Clear the timeout when sync completes successfully
        }
      }
      // Once the loop exits, it means sync is complete
      console.log(`Sync is complete for item ${item_id}. Updating UI...`);
      this.$store.dispatch('updateSyncingTransactions', false);
      await this.onTransactionSyncComplete();
      
    },
    // async checkTransactionSyncStatus(item_id) {
    //   const userId = auth.currentUser.uid;
    //   this.$store.dispatch('updateSyncingTransactions', true);

    //   let syncComplete = false;

    //   while (!syncComplete) {
    //     const response = await axios.get(`https://fivebags-server.nn.r.appspot.com/check_sync_status`, {
    //       params: { userId, item_id }
    //     });
    //     syncComplete = response.data.syncComplete;

    //     if (!syncComplete) {
    //       await new Promise(resolve => setTimeout(resolve, 5000));
    //     } else {
    //       this.$store.dispatch('updateSyncingTransactions', false);
    //     }
    //   }

    //   await this.onTransactionSyncComplete();
    // },


    async openPlaidLink() {
      console.log('Creating Plaid Link handler...');
      const linkHandler = window.Plaid.create({
        token: this.linkToken,
        onSuccess: async (public_token, metadata) => {
            console.log('Public token:', public_token);
            const response = await axios.post('https://fivebags-server.nn.r.appspot.com/exchange_public_token', { public_token });

            // Extract access_token and item_id from the response
            const access_token = response.data.access_token;
            const item_id = response.data.item_id; 
            console.log('Access token:', access_token);
            console.log('Item ID:', item_id);

            // Get user_id from Firebase Authentication
            const user = auth.currentUser;
            const user_id = user ? user.uid : null;

            // Send access_token, item_id, and user_id to your server for storage
            await axios.post('https://fivebags-server.nn.r.appspot.com/store_access_token', {
                access_token,
                item_id,
                user_id,
                institution_id: metadata.institution.institution_id,
                accounts: metadata.accounts.map(account => ({
                    id: account.id,
                    name: account.name,
                    mask: account.mask,
                    type: account.type,
                    subtype: account.subtype,
                })),
            });

            console.log("Access token and account details have been saved.")

            // Trigger backend process to fetch and handle transactions
            await axios.post('https://fivebags-server.nn.r.appspot.com/get_transactions', {
            access_token,
            item_id,
            user_id,
            institution_id: metadata.institution.institution_id,
            accounts: metadata.accounts.map(account => ({
              id: account.id,
              name: account.name,
              mask: account.mask,
              type: account.type,
              subtype: account.subtype,
            })),
            products: ['transactions'], // Specify the products here
            days_requested: 730 // Add this line to request 2 years of transaction history
          });

            // Fetch account data from Plaid
            const accounts = await this.fetchAccounts(access_token);

            this.checkTransactionSyncStatus(item_id);

            // Process each account
            for (const account of accounts) {
              await this.processAccount(account, user_id);
            }

            // Process accounts
            await this.processAccounts(access_token, user_id, metadata);

            // // Fetch and process investments
            // await this.fetchInvestments(access_token, user_id);

          },
          onExit: (error, metadata) => {
            if (error) {
              console.log('Plaid error:', error);
            }
            console.log('Plaid metadata:', metadata);
          },
      });

      console.log('Opening Plaid Link...');
      linkHandler.open();
    },
    // async openPlaidLink() {
    //   console.log('Creating Plaid Link handler...');
    //   const linkHandler = window.Plaid.create({
    //     token: this.linkToken,
    //     onSuccess: async (public_token) => {
    //       console.log('Public token:', public_token);
    //       const response = await axios.post('https://fivebags-server.nn.r.appspot.com/exchange_public_token', { public_token });

    //       // Extract access_token and item_id from the response
    //       const access_token = response.data.access_token;
    //       const item_id = response.data.item_id; 
    //       console.log('Access token:', access_token);
    //       console.log('Item ID:', item_id);

    //       // Get user_id from Firebase Authentication
    //       const user = auth.currentUser;
    //       const user_id = user ? user.uid : null;

    //       try {
    //         // Fetch account data from Plaid
    //         const accounts = await this.fetchAccounts(access_token);

    //         // Send access_token, item_id, user_id, and accounts to your server for storage
    //         await axios.post('https://fivebags-server.nn.r.appspot.com/store_access_token', {
    //           access_token,
    //           item_id,
    //           user_id,
    //           accounts, // Include the accounts array
    //         });

    //         // Show the loader
    //         document.querySelector('.loader-container').classList.remove('hidden');

    //         // Process each account
    //         for (const account of accounts) {
    //           await this.processAccount(account, user_id);
    //         }

    //         // Fetch and process investments
    //         await this.fetchInvestments(access_token, user_id);

    //         const transactions = await this.fetchTransactions(access_token);
    //         this.onTransactionsFetched(transactions);

    //         // Hide the loader
    //         document.querySelector('.loader-container').classList.add('hidden');
    //       } catch (error) {
    //         if (error.response && error.response.status === 409) {
    //           const { accountName, accountMask } = error.response.data;
    //           this.errorMessage = `The account "${accountName}" (${accountMask}) is already linked. Please unselect this account in the Plaid modal and try again with only new accounts.`;
    //           console.log('Error message:', this.errorMessage); // Add this line
    //         } else {
    //           console.error('Error sending access token to server:', error);
    //           this.errorMessage = 'An error occurred while processing your request. Please try again later.';
    //           console.log('Error message:', this.errorMessage); // Add this line
    //         }
    //       }
    //     },
    //     onExit: (error, metadata) => {
    //       if (error) {
    //         console.log('Plaid error:', error);
    //       }
    //       console.log('Plaid metadata:', metadata);
    //     },
    //   });

    //   console.log('Opening Plaid Link...');
    //   linkHandler.open();
    // },
    async createLinkToken() {
      console.log('Starting to create link token...');
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/create_link_token');
        this.linkToken = response.data.link_token;
        console.log('Fetched link token:', this.linkToken);
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    },
    async fetchTransactions(access_token, item_id, user_id) {
      let cursor = "";
      let transactions = [];
      let hasMore = false;

      do {
        try {
          console.log(`Fetching transactions with cursor: ${cursor}`);
          const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_transactions', {
            access_token,
            cursor,
            item_id, 
            user_id, 
          });

          // Update the cursor and hasMore flag
          cursor = response.data.next_cursor;
          hasMore = response.data.has_more;

          console.log(`Next cursor: ${cursor}`);
          console.log(`Has more: ${hasMore}`);

          // Add the returned transactions to the list
          transactions = transactions.concat(response.data.added);
        } catch (error) {
          console.error('Error fetching transactions:', error);
          
          // If a request fails, wait a bit and then try again
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } while (hasMore);

      console.log(`Total transactions fetched: ${transactions.length}`);
      return transactions;
    },
    // async fetchInvestments(access_token, user_id) {
    //   try {
    //     const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_investments', {
    //       access_token,
    //     });

    //     console.log('Investments Response:', response.data);

    //     const { accounts, holdings } = response.data.holdings;

    //     if (!Array.isArray(accounts) || !Array.isArray(holdings)) {
    //       throw new Error("Invalid data structure for accounts or holdings");
    //     }

    //     const assetsLiabilitiesMapRef = doc(db, `users/${user_id}/mappings`, 'assetsLiabilitiesMap');
    //     const mapSnapshot = await getDoc(assetsLiabilitiesMapRef);
    //     const assetsLiabilitiesMap = mapSnapshot.exists() ? mapSnapshot.data() : {};

    //     const currentDate = new Date().toISOString().split('T')[0];

    //     for (const account of accounts) {
    //       const accountHoldings = holdings.filter(holding => holding.account_id === account.account_id);
    //       let valueHistory = accountHoldings.map(holding => ({
    //         date: holding.institution_price_as_of,
    //         value: Number(holding.institution_value.toFixed(2))
    //       }));

    //       // Sort valueHistory by date to ensure correct order
    //       valueHistory.sort((a, b) => new Date(a.date) - new Date(b.date));

    //       let earliestDate = valueHistory.length > 0 ? valueHistory[0].date : currentDate;
    //       const startValue = valueHistory.length > 0 ? valueHistory[0].value : account.balances.current;
    //       const endValue = account.balances.current;

    //       // Interpolate missing values between earliestDate and currentDate
    //       const interpolatedValues = this.interpolateValues(earliestDate, currentDate, startValue, endValue);

    //       let assetClass = "🧩 Other Assets"; // Default class
    //       let accountType = "Other"; // Default type
    //       const accountSubtypeLower = account.subtype.toLowerCase();
    //       const accountTypeLower = account.type.toLowerCase();
    //       for (const [classKey, classDetails] of Object.entries(assetsLiabilitiesMap)) {
    //         if (classDetails.types.map(value => value.toLowerCase()).includes(accountSubtypeLower) ||
    //             classDetails.types.map(value => value.toLowerCase()).includes(accountTypeLower)) {
    //           assetClass = classKey;
    //           accountType = classDetails.accountType || "Other";
    //           break;
    //         }
    //       }

    //       const accountData = {
    //         ...account,
    //         purchaseDate: earliestDate,
    //         currentValue: account.balances.current,
    //         holdings: accountHoldings,
    //         valueHistory: interpolatedValues,
    //         assetClass: assetClass,
    //         accountType: accountType,
    //       };

    //       let collectionRef;
    //       if (accountType === 'Asset') {
    //         collectionRef = collection(db, `users/${user_id}/assets`);
    //       } else if (accountType === 'Liability') {
    //         collectionRef = collection(db, `users/${user_id}/liabilities`);
    //         accountData.currentValue = -Math.abs(accountData.currentValue);
    //         accountData.valueHistory = accountData.valueHistory.map(vh => ({
    //           ...vh,
    //           value: -Math.abs(vh.value)
    //         }));
    //       } else {
    //         console.error('Unknown account type:', accountType);
    //         continue;
    //       }

    //       const matchingAccountsQuery = query(collectionRef, where("mask", "==", account.mask), where("name", "==", account.name));
    //       const querySnapshot = await getDocs(matchingAccountsQuery);
    //       if (querySnapshot.empty) {
    //         await addDoc(collectionRef, accountData);
    //       } else {
    //         const docRef = querySnapshot.docs[0].ref;
    //         await setDoc(docRef, accountData, { merge: true });
    //       }
    //     }

    //   } catch (error) {
    //     console.error('Error fetching investments:', error);
    //   }
    // },  
    async fetchAccounts(access_token) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/accounts_get', {
          access_token,
        });
        return response.data.accounts; // Return the accounts array
      } catch (error) {
        console.error('Error fetching accounts:', error);
        throw error;
      }
    },
    // Adds account to my linked accounts page
    async processAccount(account, user_id) {
      const userAccountsRef = collection(db, `users/${user_id}/accounts`);

      // Query Firestore for an existing account by account_id, mask, and official_name
      const querySnapshot = await getDocs(query(userAccountsRef, where("mask", "==", account.mask), where("official_name", "==", account.official_name)));
      
      let existingAccountRef = null;

      // Check if any document matches either account_id or both mask and official_name
      querySnapshot.forEach((doc) => {
        if (doc.id === account.account_id || (doc.data().mask === account.mask && doc.data().official_name === account.official_name)) {
          existingAccountRef = doc.ref;
        }
      });

      if (existingAccountRef) {
        // Update the existing account if it exists and fields are different
        const existingAccountData = (await getDoc(existingAccountRef)).data();
        if (JSON.stringify(account) !== JSON.stringify(existingAccountData)) {
          await setDoc(existingAccountRef, account, { merge: true });
        }
      } else {
        // Add the account if it does not exist
        const newAccountRef = doc(userAccountsRef, account.account_id);
        await setDoc(newAccountRef, account);
      }
    },
    // Adds account to my networth page as an asset or liability
    async processAccounts(access_token, user_id, metadata) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/accounts_get', {
          access_token,
        });
        console.log('Accounts Response:', response.data);
        const { accounts } = response.data;

        const assetsLiabilitiesMapRef = doc(db, `users/${user_id}/mappings`, 'assetsLiabilitiesMap');
        const mapSnapshot = await getDoc(assetsLiabilitiesMapRef);
        const assetsLiabilitiesMap = mapSnapshot.exists() ? mapSnapshot.data() : {};

        const currentDate = new Date().toISOString().split('T')[0];

        for (const account of accounts) {
          let assetClass = "🧩 Other Assets"; // Default class
          let accountType = "Other"; // Default type
          const accountSubtypeLower = account.subtype.toLowerCase();
          const accountTypeLower = account.type.toLowerCase();

          for (const [classKey, classDetails] of Object.entries(assetsLiabilitiesMap)) {
            if (classDetails.types.map(value => value.toLowerCase()).includes(accountSubtypeLower) ||
                classDetails.types.map(value => value.toLowerCase()).includes(accountTypeLower)) {
              assetClass = classKey;
              accountType = classDetails.accountType || "Other";
              break;
            }
          }

          const accountData = {
            ...account,
            purchaseDate: currentDate,
            currentValue: account.balances.current,
            valueHistory: [{
              date: currentDate,
              value: account.balances.current
            }],
            assetClass: assetClass,
            accountType: accountType,
          };

          let collectionRef;
          if (accountType === 'Asset') {
            collectionRef = collection(db, `users/${user_id}/assets`);
          } else if (accountType === 'Liability') {
            collectionRef = collection(db, `users/${user_id}/liabilities`);
            accountData.currentValue = -Math.abs(accountData.currentValue);
            accountData.valueHistory = accountData.valueHistory.map(vh => ({
              ...vh,
              value: -Math.abs(vh.value)
            }));
          } else {
            console.error('Unknown account type:', accountType);
            continue;
          }

          const matchingAccountsQuery = query(collectionRef, where("mask", "==", account.mask), where("name", "==", account.name));
          const querySnapshot = await getDocs(matchingAccountsQuery);
          if (querySnapshot.empty) {
            await addDoc(collectionRef, accountData);
          } else {
            const docRef = querySnapshot.docs[0].ref;
            await setDoc(docRef, accountData, { merge: true });
          }
        }
      } catch (error) {
        console.error('Error processing accounts:', error);
      }
    },
    goToCategory(label) {
      this.$router.push(`/category/${label}`);
      this.showQuickNav = false; // Assuming you want to close the modal after navigation
    },
    validatePositiveNumber(value) {
      return value < 0 ? 0 : value;
    },  
    handleKeyDown(event) {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        this.showQuickNav = true;
        this.$nextTick(() => {
          this.$refs.quickNavSearch.focus();
        });
      }
    },
    filterNavItems() {
      if (!this.searchQuery) {
        this.filteredNavItems = this.navItems;
      } else {
        this.filteredNavItems = this.navItems.filter(item => 
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    goToNavItem(item) {
      this.$router.push(item.route);
      this.showQuickNav = false;
    },
    async signOut() {
      try {
        await auth.signOut(); // Sign out from Firebase
        this.$store.dispatch('clearUserData'); // Assuming there's an action to clear user data
        this.$router.push('/login'); // Redirect to login
        // Set the margin-left of the content-wrapper to 0
        const elements = document.getElementsByClassName('content-wrapper');
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.marginLeft = '0';
        }
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },
    formatDate(date) {
      return date.toISOString().split('T')[0]; // Simple YYYY-MM-DD format
    },
    toggleCollapse() {
      this.$emit('sidebar-collapse-state-changed', !this.collapsed); // Emit the new state of the sidebar
    },
    openAddAccountModal() {
      this.showAddAccountModal = true;
    },
    interpolateValues(startDate, endDate, startValue, endValue) {
      if (isNaN(startValue) || isNaN(endValue)) {
        // If start or end values are not numbers, return only the current value and date
        return [{
          date: endDate,
          value: isNaN(endValue) ? 0 : Math.round(endValue)
        }];
      }
      
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffYear = end.getFullYear() - start.getFullYear();
        const diffMonth = end.getMonth() - start.getMonth();
        const monthDiff = diffYear * 12 + diffMonth;
        let interpolatedValues = [];
        
        for (let i = 0; i <= monthDiff; i++) {
          const monthOffset = start.getMonth() + i;
          const year = start.getFullYear() + Math.floor(monthOffset / 12);
          const month = monthOffset % 12;
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          const day = start.getDate() > daysInMonth ? daysInMonth : start.getDate();
          const date = new Date(year, month, day);
          const fraction = monthDiff === 0 ? 1 : i / monthDiff;
          const interpolatedValue = startValue + (endValue - startValue) * fraction;
          interpolatedValues.push({
            date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
            value: Math.round(interpolatedValue)
          });
        }
        return interpolatedValues;
      },

    async addAsset() {
      try {

        const user = auth.currentUser;
        // Calculate the interpolated value history for the asset
        const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
        const interpolatedValues = this.interpolateValues(
          this.newAsset.purchaseDate,
          currentDate,
          parseFloat(this.newAsset.purchaseValue),
          parseFloat(this.newAsset.currentValue)
        );

        const assetToAdd = {
          name: this.newAsset.name,
          description: this.newAsset.description,
          purchaseDate: this.newAsset.purchaseDate,
          purchaseValue: parseFloat(this.newAsset.purchaseValue),
          currentValue: parseFloat(this.newAsset.currentValue),
          assetClass: this.newAsset.assetClass.name || this.newAsset.assetClass,
          accountType: 'Asset',
          valueHistory: interpolatedValues, // Add the interpolated value history
          loanTerm: this.newAsset.loanTerm,
        };

        // Add specific fields based on asset class
        if (this.newAsset.assetClass === '💎 Precious Metals') {
          assetToAdd.metalType = this.newAsset.metalType; // Ensure metal type is saved correctly
          assetToAdd.quantity = parseFloat(this.newAsset.quantity);
        }

        if (['🏡 Real Estate', '🏎️ Vehicles'].includes(this.newAsset.assetClass)) {
          assetToAdd.status = this.newAsset.status; // Ensure status is saved correctly
          if (this.newAsset.status === 'Financed') {
            assetToAdd.currentLoanBalance = parseFloat(this.newAsset.currentLoanBalance);
            assetToAdd.interestRate = parseFloat(this.newAsset.interestRate);
            assetToAdd.monthlyPayment = parseFloat(this.newAsset.monthlyPayment);
            assetToAdd.loanTerm = parseFloat(this.newAsset.loanTerm);
          }
        }
        // After successfully adding the asset, check if it is financed and has a current loan balance
        if (['🏡 Real Estate', '🏎️ Vehicles'].includes(this.newAsset.assetClass) && this.newAsset.status === 'Financed' && parseFloat(this.newAsset.currentLoanBalance) > 0) {
          // Prepare liability details
          const liabilityToAdd = {
            name: `${this.newAsset.name} Loan`, // Append 'Loan' to the asset's name
            purchaseDate: this.newAsset.purchaseDate,
            purchaseValue: -parseFloat(this.newAsset.purchaseValue), // Negative purchase value
            currentValue: -parseFloat(this.newAsset.currentLoanBalance), // Negative current loan balance
            accountType: 'Liability',
            monthlyPayment: parseFloat(this.newAsset.monthlyPayment),
            interestRate: parseFloat(this.newAsset.interestRate),
            loanTerm: parseFloat(this.newAsset.loanTerm),
            // Generate interpolated value history for the liability
            valueHistory: this.interpolateValues(
              this.newAsset.purchaseDate,
              currentDate, // Assume this is already defined as the current date in YYYY-MM-DD format
              -parseFloat(this.newAsset.purchaseValue),
              -parseFloat(this.newAsset.currentLoanBalance)
            ),
          };

          // Set the liabilityClass based on the assetClass of the new asset
          if (this.newAsset.assetClass === '🏡 Real Estate') {
            liabilityToAdd.assetClass = '🏠 Mortgage';
          } else if (this.newAsset.assetClass === '🏎️ Vehicles') {
            liabilityToAdd.assetClass = '🚗 Vehicle Loans';
          }

          // Add vehicle-specific properties
          if (this.newAsset.assetClass === '🏎️ Vehicles') {
            assetToAdd.year = this.newAsset.year;
            assetToAdd.make = this.newAsset.make;
            assetToAdd.model = this.newAsset.model;
            assetToAdd.condition = this.newAsset.condition; // Assuming Dropdown is bound to an object with a 'name' property
          }

          // Add the liability to Firestore under the user's liabilities collection
          const liabilitiesCollection = collection(db, `users/${user.uid}/liabilities`);
          await addDoc(liabilitiesCollection, liabilityToAdd);
          console.log('Corresponding liability added for the financed asset.');
        }
        if (user) {
          const assetsCollection = collection(db, `users/${user.uid}/assets`);
          const docRef = await addDoc(assetsCollection, assetToAdd);
          console.log(`Asset added with ID: ${docRef.id}`);
          this.showAddAccountModal = false;
          // Reset form
          this.newAsset = {
            name: '',
            description: '',
            purchaseDate: '',
            purchaseValue: '',
            currentValue: '',
            assetClass: '',
            metalType: '',
            quantity: '',
            status: '',
            currentLoanBalance: '',
            interestRate: '',
            monthlyPayment: '',
            loanTerm: '',
            year: '',
            make: '',
            model: '',
            condition: '',
          };
          // Check if on assets page and refresh

          if (this.isAssetsPage) {
            this.$router.go();
          }
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },

    async addLiability() {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.log('No user is logged in.');
          return;
        }

        const currentDate = new Date();
        const interpolatedValues = this.interpolateValues(
          this.newLiability.purchaseDate, 
          currentDate, // This should be the current date in YYYY-MM-DD format
          -Math.abs(parseFloat(this.newLiability.purchaseValue)),
          -Math.abs(parseFloat(this.newLiability.currentLoanBalance))
        );
        const liabilityToAdd = {
          name: this.newLiability.name,
          description: this.newLiability.description,
          purchaseDate: this.newLiability.purchaseDate,
          purchaseValue: -Math.abs(parseFloat(this.newLiability.purchaseValue)),
          currentValue: -Math.abs(parseFloat(this.newLiability.currentLoanBalance)),
          assetClass: this.newLiability.liabilityClass,
          liabilityClass: this.newLiability.liabilityClass,
          accountType: 'Liability',
          valueHistory: this.interpolateValues(
              this.newLiability.purchaseDate,
              currentDate, // Assume this is already defined as the current date in YYYY-MM-DD format
              -parseFloat(this.newLiability.purchaseValue),
              -parseFloat(this.newLiability.currentLoanBalance)
            ),
          interestRate: parseFloat(this.newLiability.interestRate),
          loanTerm: parseFloat(this.newLiability.loanTerm),
          monthlyPayment: -Math.abs(parseFloat(this.newLiability.monthlyPayment)), // Ensure this is negative
        };


          // Add vehicle-specific properties
          if (this.newLiability.liabilityClass === '🚗 Vehicle Loans') {
            liabilityToAdd.year = this.newLiability.year;
            liabilityToAdd.make = this.newLiability.make;
            liabilityToAdd.model = this.newLiability.model;
            liabilityToAdd.condition = this.newLiability.condition; // Assuming Dropdown is bound to an object with a 'name' property
          }

        const liabilitiesCollection = collection(db, `users/${user.uid}/liabilities`);
        const docRef = await addDoc(liabilitiesCollection, liabilityToAdd);
        console.log(`Liability added with ID: ${docRef.id}`);
        this.showAddAccountModal = false;

        // Reset the liability form
        this.newLiability = {
          name: '',
          description: '',
          purchaseDate: '',
          purchaseValue: '',
          currentValue: '',
          liabilityClass: '',
          status: '',
          loanTerm: '',
          currentLoanBalance: null,
          interestRate: null,
          monthlyPayment: null,
          year: '',
          make: '',
          model: '',
          condition: '',
        }
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },
    // Asynchronously check and update the value history for all items
    async checkAndUpdateValueHistoryForAllItems() {
      // Get the current date and extract the year and month
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based, so add 1 for human-readable format

      // Combine both assets and liabilities into a single array for processing
      const itemsToUpdate = [...this.assets, ...this.liabilities];

      // Iterate over each item in the combined array
      for (const item of itemsToUpdate) {
        // Ensure that each item has a valueHistory array; if not, initialize it as an empty array
        item.valueHistory = item.valueHistory || [];
        // Sort the valueHistory array by date in descending order to make sure the latest entry is first
        item.valueHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Retrieve the most recent (latest) entry from the valueHistory array
        const lastValueEntry = item.valueHistory[0];
        // If there's no entry in valueHistory, skip the rest of the loop for this item
        if (!lastValueEntry) {
          console.log(`No value history for item ${item.name}, skipping...`);
          continue;
        }

        // Convert the date string of the latest entry into a Date object
        const lastEntryDate = new Date(lastValueEntry.date);
        const lastEntryYear = lastEntryDate.getFullYear();
        const lastEntryMonth = lastEntryDate.getMonth() + 1; // Adjust month to be human-readable

        // Calculate the difference in months between the current date and the last entry date
        const monthsDiff = (currentYear - lastEntryYear) * 12 + (currentMonth - lastEntryMonth);

        // Track if any new entries are added
        let entriesAdded = false;

        // If there are months without entries (monthsDiff > 0), add new entries for those months
        for (let i = 1; i <= monthsDiff; i++) {
          // Calculate the year and month for the new entry
          const newEntryYear = lastEntryMonth + i > 12 ? lastEntryYear + 1 : lastEntryYear;
          const newEntryMonth = (lastEntryMonth + i - 1) % 12 + 1;
          // Format the date for the new entry as YYYY-MM-DD, defaulting to the first day of the month
          const newEntryDate = `${newEntryYear}-${newEntryMonth.toString().padStart(2, '0')}-01`;

          // Add a new entry for the missing month with the same value as the last entry
          item.valueHistory.push({
            date: newEntryDate,
            value: lastValueEntry.value
          });
          entriesAdded = true; // Mark that new entries have been added
        }

        if (entriesAdded) {
          // Log the item name and the number of new entries added
          console.log(`Added ${monthsDiff} new value history entries for item ${item.name}.`);
        } else {
          // Log that no new entries were needed for this item
          console.log(`No missing month values for item ${item.name}, no update necessary.`);
        }

        // If any new entries were added (monthsDiff > 0), update the item in Firestore
        if (entriesAdded) {
          // Check for a logged-in user before attempting to update Firestore
          const user = auth.currentUser;
          if (!user) continue; // If no user is logged in, skip Firestore update

          // Determine the Firestore collection name based on the item's accountType
          const collectionName = item.accountType === 'Liability' ? 'liabilities' : 'assets';
          // Reference the specific Firestore document for this item
          const itemRef = doc(db, `users/${user.uid}/${collectionName}`, item.id);
          // Update the document in Firestore with the new valueHistory array, merging with existing data
          await setDoc(itemRef, { valueHistory: item.valueHistory }, { merge: true });
        }
      }
    },
    
  },
  
  watch: {
    '$store.state.transactions': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // Check if selectedDateOption is set before recalculating metrics
        if (this.selectedDateOption) {
          this.calculateAndStoreMetrics();
        }
      },
      errorMessage(newValue) {
        console.log('Error message changed:', newValue);
      }
    },
  }
};
</script>

<style scoped>
.sidebar {
  position: relative;
  height: 95%;
  background-color: #2c2c2c;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.sidebar-content a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px 40px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  margin: 5px auto;
  position: relative; /* Add this line */
}

.sidebar.collapsed .sidebar-content a:hover span {
  display: block;
  position: absolute;
  bottom: -10px; /* Adjust this value to position the text below the SVG */
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  white-space: nowrap;
  background-color: #323232;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.sidebar.collapsed .sidebar-content a:hover span {
  opacity: 1;
}

.sidebar-content a span {
  margin-left: 10px;
}

.sidebar.collapsed .sidebar-content a span {
  display: none;
}

.sidebar.collapsed .sidebar-content a {
  justify-content: center;
  padding: 20px 0;
}

.sidebar.collapsed .sidebar-content a svg {
  margin: 0;
}

.sidebar-content a:hover {
  /* background-color: #80ffff; */
  /* padding: 2%; */
  color: #80ffff;
  width: 100%;
  background-color: #323232;
  border-radius: 5px; 
  font-weight: 800;
  transition: .1s ease-in-out;
  cursor: pointer;
}

.sidebar-content a:hover svg {
  fill: #80ffff;
  background-color: #323232;
}



.user-info {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 280px;
  background: #2c2c2c;
  text-align: center;
  transition: all 0.4s ease;

}

.user-info button {
  background-color: black;
  padding: 10px 30px;
  text-wrap: nowrap;
  display: block;
  margin: 20px auto;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  left: 0px;
}


.sidebar-content {
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #2c2c2c;
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the sidebar height */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}

.sidebar.collapsed {
  /* width: 80px; */
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}




.sidebar.collapsed:hover {
  background-color: #2c2c2c;
    width: 40px;
    cursor: pointer;
    /* box-shadow: 0px 0px 20px 0px rgb(209 209 209); */
    transition: width .5s ease; /* Add transition property for smooth effect */
}


.sidebar.collapsed .sidebar-content {
  transition: .4s ease;
  width: 80px;
}

.sidebar.collapsed .user-info {
  width: 80px; /* Match the collapsed sidebar width */
  opacity: 0;
  pointer-events: none;
}

.toggle-button {
  background-color: #fff;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 265px;
  margin: 0px auto;
  padding: 0px;
  border-radius: 26px;
}

.toggle-button.collapsed {
  background-color: #fff;
  left: 0px; /* Adjust the left position */
  bottom: 5%;
  display: none;
}

.toggle-button:hover {
  background-color: #80ffff;
}

.toggle-button .icon {
  color: #3f3636;
  font-size: 18px;
  transition: color 0.3s ease;
}

.toggle-button.collapsed .icon {
  color: #3f3636;
}

.toggle-button.collapsed:hover .icon {
  color: #fff;
}

.signoutbutton {
  background-color: black;
  padding: 10px 30px;
  text-wrap: nowrap;
  display: block;
  margin: 20px auto;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  left: 0px;
  transition: all 0.4s ease; /* Add this line */
}

.signoutbutton:hover {
  background-color: #f10000;
  transition: 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

svg {
  min-width: 30px;
  fill: white;
}

.active-link {
  color: #80ffff !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  /* cursor: cell; */
}

.active-link svg {
  fill: #80ffff !important; 
  scale: 1.3;
}

.add-account {
  color: white;
  background-color: #303030;
  font-weight: 600;
  padding: 10px 0px;
  width: 90%;
  margin: 0px auto;
  box-shadow: 0px 2px 3px 0px rgb(29 29 29 / 30%);
  border-radius: 5px;
  transition: all 0.4s ease;
}

.add-account:hover {
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 15%);
  transition: 0.3s ease-in-out;
  background-color: #505050;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0 0 0 / 50%);
}


.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 50px;
  border: 1px solid #888;
  width: 50%;
  height: auto;
  overflow-y: auto;
  max-width: 60%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex; /* Enable Flexbox */
  gap: 10px;
  flex-wrap: wrap; /* Allow items to wrap as needed */
  justify-content: center; /* Space out the columns */
}


.quick-nav-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 50px;
  border: 1px solid #888;
  width: 80%;
  height: 70%;
  overflow-y: auto;
  max-width: 60%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex; /* Enable Flexbox */
  gap: 10px;
  flex-wrap: wrap; /* Allow items to wrap as needed */
  justify-content: center; /* Space out the columns */
}

.quick-nav-content > button {
  width: 48%;
  height: 120px;
  padding: 15px 20px;
  color: black;
  font-size: 20px;
  margin: 0px auto;
  font-weight: 600;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 3px 0px rgb(29 29 29 / 8%);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.quick-nav-content > button:not(#coming-soon):hover {
  background-color: #b7b7b7;
}

.modal-content > button {
  width: 48%;
  height: 120px;
  padding: 15px 20px;
  color: black;
  font-size: 20px;
  margin: 0px auto;
  font-weight: 600;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 3px 0px rgb(29 29 29 / 8%);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.modal-content > button:not(#coming-soon):hover {
  background-color: #b7b7b7;
}

/* Optional: Adjust close button style if needed */

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.coming-soon {
  color: grey;
  margin-top: 20px;
  font-size: 16px;
}

#coming-soon:hover {
  cursor: default;
}

.assets-input-sidebar {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  font-size: 14px;
  color: #64748b;
}

.assets-textarea-sidebar {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  font-size: 14px;
  color: #64748b;
}

.accountType-sidebar {
  margin: 0px;
  font-size: 30px;
}

.button-close {
  background-color: red; /* Example style for the Close button */
  color: white;
}

.button-add {
  background-color: green; /* Example style for the Add buttons */
  color: white;
}


.addAccountButtons > button {
  display: unset;
}

.addAccountButtons {
  display: flex;
  margin-bottom: 26px;
  justify-content: space-evenly;
}

.addAccountButtons button {
  height: 46px !important;
  width: 30% !important;
}

.loader-container {
    display: flex;
    background-color: #000000;
    opacity: 40%;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    position: fixed; /* So that it doesn't affect other elements */
    top: 0;
    left: 0;
}

.loader {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border-top: 6px solid #9ec9ff;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader2 {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  border-top: 6px solid #9ec9ff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.hidden {
    display: none;
}

.router-link {
  display: flex !important; /* Use flexbox for alignment */
  align-items: center; /* Align items vertically in the center */
  text-decoration: none; /* Optional: remove underline from link */
}

.router-link svg {
  margin-right: 8px; /* Add some space between the icon and the text */
}

.account-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-grid button {

  background-color: #fcfcfc;
  color: black;
  width: 48%; /* Adjust width for two columns */
  margin-bottom: 10px; /* Space between rows */
  height: 100px;
  transition: all .3s ease-in-out;

}

/* Adjust styles for your coming-soon id or class */
#coming-soon, .coming-soon {
  text-align: center;
}

/* You might want to adjust the hover effect as well */
.account-grid button:hover {
  background-color: #f5f5f5; /* Slight background change on hover */
  color: black;
  font-weight: 600;
  transition: all .3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-display-style {
  display: block; /* Use block layout instead of flex */
  width: 100%; /* Optional: ensure the form takes the full width */
  /* Add any other styles you need for the form here */
}


/* Backdrop styles */
.modal-backdrop {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Black with 70% opacity */
  display: flex;
  justify-content: center; /* Center modal horizontally */
  align-items: center;     /* Center modal vertically */
  z-index: 999;            /* Make sure it sits on top of everything else */
  transition: 0.3s ease-in-out;
}

/* Modal styles */
.modal-content {
  width: 50%;           /* Example width, you can adjust as needed */
  max-width: 500px;     /* Maximum width */
  background-color: #fff;
  border-radius: 15px;   /* Rounded corners */
  padding: 20px 50px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  position: relative;
  transition: 0.3s ease-in-out;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.progress-bar {
  height: 20px;
  background-color: green;
  border-radius: 4px;
}

.sidebar.collapsed .add-account-plus {
  position: fixed;
  bottom: 20px;
  width: 40px;
  background: #2c2c2c;
  text-align: center;
  margin: 0 auto;
  transition: all 0.4s ease;
}



.sidebar.collapsed .add-account-plus svg:hover {
  fill: #80ffff !important; 
  scale: 1.2;
  /* transition: all .3s ease-in-out; */
}

.sidebar.collapsed .add-account-plus svg {
  scale: 1;
  /* transition: all .3s ease-in-out; */
}

.sidebar.collapsed .add-account-plus svg:hover {
  fill: #80ffff !important; 
  scale: 1.2;
}

/* Quick Nav Modal styles */
.modal-content .quick-nav-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content div {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.modal-content div:hover {
  background-color: #f8f8f8;
}

.progress-banner {
  position: fixed;
  bottom: 0;
  left: v-bind(progressBannerLeft); /* Use the computed property */
  right: 0;
  background-color: #f0f0f0;
  color: #333;
  border: #dbdbdb thin solid;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 10px;
  box-sizing: border-box;
}

#first-link {
  margin-top: 100px;
}

.sidebar.collapsed .logo-text {
  display: none;
}

.logo-text {
  color: white;
}

.sidebar.collapsed .sidebar-line-break {
  display: none;
}



.sidebar.collapsed img {
  display: none;
}
.logo-sidebar {
  color: #c7c7c7;
  font-weight: 900;
  font-size: 30px;
  /* letter-spacing: 2px; */
  float: left;
  margin-left: 18px;
  margin-top: 18px;
  text-align: left;
  display: flex;
  align-items: center;
}

.sidebar-line-break {
  color: #5c5c5c; 
  float: left; 
  margin-left: 18px; 
  margin-top: -10px;
}

.sidebar-content a.active-link {
  color: #80ffff !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  background-color: #323232;
}

.sidebar-content a.active-link svg {
  fill: #80ffff !important; 
  scale: 1.3;
}

.sidebar.collapsed .sidebar-content a.active-link span {
  display: block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  white-space: nowrap;
  background-color: #323232;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  color: #80ffff;
  font-weight: 900;
}


.error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.error-message {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-width: 400px;
}

.error-message p {
  margin-bottom: 10px;
}

.error-message button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}


.sidebar.inactive-subscription a:not([href="/subscription"]) {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.sidebar.inactive-subscription a:not([href="/subscription"]):hover::after {
  content: "Subscription required";
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}

.add-account-plus.disabled {
  /* opacity: 0.5; */
  cursor: not-allowed;
}


.add-account.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#chaport-container {
  margin-bottom: 20px;
  width: 100%;
}

/* Ensure the widget doesn't overflow the sidebar */
#chaport-container iframe {
  max-width: 100%;
}

.add-account.disabled:hover::after {
  content: "Subscription required";
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.add-account-plus.disabled:hover::after {
  content: "Subscription required";
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  bottom: 10%;
  left: 250%;
  transform: translateX(-50%);
}


.sidebar.collapsed .add-account-plus.disabled svg {
    fill: #969696 !important;
}

.help-button {
  margin-bottom: 10px;
}


.sidebar {
  transition: width 0.4s ease;
}

.mobile-toggle {
  display: none;
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    width: 200px;
    transform: translateX(-100%);
  }

  .sidebar.collapsed {
    transform: translateX(0);
  }

  .mobile-toggle {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001;
  }
}

</style>
