/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence, indexedDBLocalPersistence, initializeAuth, GoogleAuthProvider, signInWithPopup, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';


const firebaseConfig = {
  apiKey: "AIzaSyCs_YRJTO-VFDUNT9j6EIMpUnMD5WhMIwo",
  authDomain: "fivebags-vue.firebaseapp.com",
  databaseURL: "https://fivebags-vue-default-rtdb.firebaseio.com",
  projectId: "fivebags-vue",
  storageBucket: "fivebags-vue.appspot.com",
  messagingSenderId: "1019145987336",
  appId: "1:1019145987336:web:1beeb3ae05709f8333f8bd",
  measurementId: "G-83L3EEYG4H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let auth;

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence
  });
} else {
  auth = getAuth(app);
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      console.log('Persistence set to browserLocalPersistence');
    })
    .catch((error) => {
      console.error('Error setting persistence:', error);
    });
}

const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);

// Function to save auth token
async function saveAuthToken(token) {
  await Preferences.set({
    key: 'authToken',
    value: token
  });
}

// Function to get auth token
async function getAuthToken() {
  const { value } = await Preferences.get({ key: 'authToken' });
  return value;
}

// Function to check and restore auth state
async function checkAndRestoreAuthState() {
  const token = await getAuthToken();
  if (token) {
    try {
      await signInWithCustomToken(auth, token);
      console.log('User authenticated with stored token');
    } catch (error) {
      console.error('Error authenticating with stored token:', error);
      // Clear the stored token if it's invalid
      await Preferences.remove({ key: 'authToken' });
    }
  }
}

// Listen for auth state changes
auth.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in, save the token
    user.getIdToken().then(saveAuthToken);
  } else {
    // User is signed out, remove the token
    Preferences.remove({ key: 'authToken' });
  }
});

// Export the checkAndRestoreAuthState function so it can be called when the app starts
export { app, auth, db, googleProvider, signInWithPopup, checkAndRestoreAuthState };

// Call checkAndRestoreAuthState immediately in case the app is already running
checkAndRestoreAuthState();