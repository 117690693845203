<template>

<div v-if="!isCollapsed" class="drawer-handle"></div>
  <button v-else @click="openDrawer" class="open-drawer-button">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="1.5em">
      <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
    </svg>
  </button>
  <div 
    v-if="isVisible"
    class="bottom-drawer"
    :class="{ 'collapsed': isCollapsed, 'expanded': isExpanded }"
    :style="{ height: drawerHeight + 'px' }"
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
    @wheel="handleWheel"
  >
    
    <div class="drawer-content" ref="drawerContent">
      <router-link to="/transactions" active-class="active-link" class="drawer-link">
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
          <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"/>
        </svg>
        <span>Transactions</span>
      </router-link>
      <router-link to="/budget" active-class="active-link" class="drawer-link">
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 544 512">
          <path d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"/>
        </svg>
        <span>Budget</span>
      </router-link>
      <router-link to="/recurring" active-class="active-link" class="drawer-link">
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
          <path d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"/>
        </svg>
        <span>Recurring</span>
      </router-link>
      <router-link to="/fire" active-class="active-link" class="drawer-link"  @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
            <path d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z"/>
          </svg>
          <span>FIRE</span>
        </router-link>
      <router-link to="/assets" active-class="active-link" class="drawer-link">
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 288 512">
          <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"/>
        </svg>
        <span>Assets</span>
      </router-link>

      <!-- Placeholder for additional links -->
      <template v-if="isExpanded">
        <!-- Additional links will go here -->
        <router-link to="/summary" active-class="active-link" class="drawer-link"  @click.stop>
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
          <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM256 416c35.3 0 64-28.7 64-64c0-17.4-6.9-33.1-18.1-44.6L366 161.7c5.3-12.1-.2-26.3-12.3-31.6s-26.3 .2-31.6 12.3L257.9 288c-.6 0-1.3 0-1.9 0c-35.3 0-64 28.7-64 64s28.7 64 64 64zM176 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM96 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm352-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
        </svg>
        <span>Summary</span>
        </router-link>

        <router-link to="/goals" active-class="active-link" class="drawer-link" @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
            <path d="M346.3 271.8l-60.1-21.9L214 448H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H282.1l64.1-176.2zm121.1-.2l-3.3 9.1 67.7 24.6c18.1 6.6 38-4.2 39.6-23.4c6.5-78.5-23.9-155.5-80.8-208.5c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM107.2 112.9c-11.1 15.7-2.8 36.8 15.3 43.4l71 25.8 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2c-79.6 2.5-154.7 42.2-201.2 108z"/>
          </svg>
          <span>Goals</span>
        </router-link>
        <router-link to="/income" active-class="active-link" class="drawer-link">
          <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512">
            <path d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"/>
          </svg>
          <span>Income</span>
        </router-link>

        <router-link to="/accounts" active-class="active-link" class="drawer-link"  @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
            
            <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"/>
          </svg>
          <span>Accounts</span>
        </router-link>

        <router-link to="/settings" active-class="active-link" class="drawer-link"  @click.stop>
          <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
            <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
          </svg>
          <span>Settings</span>
        </router-link>
        <!-- Sign out button -->
        <v-btn class="signoutbutton" @click="signOut">Sign Out</v-btn>
      </template>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-undef */

import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from "../firebaseInit";
import { useStore } from 'vuex';

const emit = defineEmits(['drawer-height-changed']);


const router = useRouter();
const store = useStore();

const drawerHeight = ref(90);
const startY = ref(0);
const isCollapsed = ref(false);
const collapseThreshold = 40;
const isVisible = ref(true);
const drawerContent = ref(null);

const isExpanded = computed(() => drawerHeight.value > 90);

watch(drawerHeight, (newHeight) => {
  emit('drawer-height-changed', newHeight);
});

function touchStart(event) {
  startY.value = event.touches[0].clientY;
}

function touchMove(event) {
  event.preventDefault();
  const currentY = event.touches[0].clientY;
  const diff = startY.value - currentY;
  
  if (diff > 0) {
    drawerHeight.value = Math.min(drawerHeight.value + diff, 220);
    isCollapsed.value = false;
  } else {
    drawerHeight.value = Math.max(drawerHeight.value + diff, 20);
    isCollapsed.value = drawerHeight.value <= collapseThreshold;
  }
  
  startY.value = currentY;
}

function touchEnd() {
  if (drawerHeight.value <= collapseThreshold) {
    drawerHeight.value = 20;
    isCollapsed.value = true;
  } else if (drawerHeight.value < 90) {
    drawerHeight.value = 90;
    isCollapsed.value = false;
  } else if (drawerHeight.value > 100) {
    drawerHeight.value = 220;
    isCollapsed.value = false;
  } else {
    drawerHeight.value = 90;
    isCollapsed.value = false;
  }
}

function handleWheel(event) {
  event.preventDefault();
}

function preventScroll(e) {
  e.preventDefault();
}

function openDrawer() {
  drawerHeight.value = 90;
  isCollapsed.value = false;
}

onMounted(() => {
  if (drawerContent.value) {
    drawerContent.value.addEventListener('touchmove', preventScroll, { passive: false });
  }
});

onUnmounted(() => {
  if (drawerContent.value) {
    drawerContent.value.removeEventListener('touchmove', preventScroll);
  }
});

async function signOut() {
  try {
    await auth.signOut();
    store.dispatch('clearUserData');
    isVisible.value = false;
    router.push('/login');
    const elements = document.getElementsByClassName('content-wrapper');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.marginLeft = '0';
    }
  } catch (error) {
    console.error("Error signing out: ", error);
  }
}
</script>

<style scoped>
.bottom-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: v-bind(drawerHeight + 'px');
  transition: height 0.3s ease;
  background-color: white;
  border-radius: 14px 14px 0 0;
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 9%);
  z-index: 1000;
}

.drawer-handle {
  width: 40px;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  margin: 10px auto;
}

.open-drawer-button {
  position: fixed;
  bottom: -10px;
  right: -10px;
  background-color: white !important;
  transform: translate(-50%, -50%);
  background: none;
  border-radius: 30px;
  box-shadow: 0px 6px 15px 6px rgb(29 29 29 / 12%);
  cursor: pointer;
  padding: 10px 13px 5px;
}

.open-drawer-button svg {
  fill: #2c2c2c;
}

.drawer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 10px;
  overflow-y: auto;
  height: calc(100% - 24px);
  -webkit-overflow-scrolling: touch;
}

.drawer-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c2c2c;
  text-decoration: none;
  width: 20%;
  margin-bottom: 10px;
}

.drawer-link svg {
  fill: #2c2c2c;
  margin-bottom: 5px;
}

.drawer-link span {
  font-size: 12px;
  text-align: center;
}

.collapsed {
  height: 10px !important;
  box-shadow: 0px -1px 2px 0px rgb(0 0 0 / 5%)
}

.expanded .drawer-content {
  flex-wrap: wrap;
}

.active-link {
  color: #3aefff !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  /* cursor: cell; */
}

.active-link svg {
  fill: #3aefff !important; 
}

@media (min-width: 768px) {
  .bottom-drawer {
    display: none;
  }
}
</style>