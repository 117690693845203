<template>

<div v-if="showNotification" class="notification-overlay">
  <div class="notification-message">Goal Updated 🎉</div>
</div>

<div v-if="showDeleteConfirmation" class="overlay">
  <div class="modal-backdrop" @click="showDeleteConfirmation = false"></div>
  <div class="new-goal-form" @click.stop>
    <p>Are you sure you want to delete this goal?</p>
    <p style="color: red; font-size: 14px;">This action is permanent and can not be undone.</p>
    <div style="display: flex">
      <button class="delete-confirm-button" @click="confirmDeleteGoal">Delete</button>
      <button class="cancel-button" @click="showDeleteConfirmation = false">Cancel</button>
    </div>
  </div>
</div>


<div v-show="showAddGoalForm" class="overlay">
  <div class="modal-backdrop" @click="toggleAddGoalForm"></div>
    <!-- New Goal Form -->
    <div class="new-goal-form" @click.stop>
    <div class="form-group">
      <label for="goal-name">Goal Name:</label>
      <input type="text" v-model="newGoal.name" placeholder="Goal Name">
    </div>

    <div class="form-group">
      <label for="goal-amount">Goal Amount:</label>
      <input type="number" v-model="newGoal.amount" placeholder="Amount">
    </div>

    <div class="form-group">
      <label for="goal-start-date">Goal Date Range:</label>
      <VueDatePicker v-model="newGoal.date" range :enable-time-picker="false" />
    </div>

    <div class="form-group">
      <label for="goal-emoji">Emoji:</label>
      <input
        ref="emojiInput"
        class="new-category-input"
        v-model="newEmoji"
        type="text"
        placeholder="Emoji"
        maxlength="2"
        required
        @focus="handleEmojiPickerPosition"
      />
    </div>

    <v-btn @click="addGoal">Add Goal</v-btn>
  </div>
</div>




<div v-if="showGoalDetailsModal" class="overlay">
  <div class="modal-backdrop" @click="toggleGoalDetailsModal()"></div>
  <div class="new-goal-form" @click.stop>
    <div class="form-group">
      <label for="goal-name">Goal Name:</label>
      <input id="goal-name" type="text" v-model="selectedGoal.name" placeholder="Goal Name">
    </div>

    <div class="form-group">
      <label for="goal-amount">Goal Amount:</label>
      <input id="goal-amount" type="number" v-model="selectedGoal.amount" placeholder="Amount">
    </div>

    <div class="form-group">
      <label for="goal-current-balance">Current Balance:</label>
      <input id="goal-current-balance" type="number" v-model="selectedGoal.currentBalance" placeholder="Current Balance">
    </div>

    <div class="form-group">
      <label for="goal-start-date">Start Date:</label>
      <VueDatePicker id="goal-start-date" v-model="selectedGoal.startDate" :enable-time-picker="false" placeholder="Start Date"></VueDatePicker>
    </div>

    <div class="form-group">
      <label for="goal-end-date">End Date:</label>
      <VueDatePicker id="goal-end-date" v-model="selectedGoal.date" :enable-time-picker="false" placeholder="End Date"></VueDatePicker>
    </div>

    <div class="form-group">
      <label for="goal-emoji">Emoji:</label>
      <input id="goal-emoji" ref="emojiInput" class="new-category-input" v-model="selectedGoal.emoji" type="text" placeholder="Emoji" maxlength="2" required @focus="handleEmojiPickerPosition">
    </div>

    <v-btn color="primary" @click="updateGoal">Update Goal</v-btn>
  </div>
</div>




<div v-if="showEmojiPicker"
     class="emoji-picker-wrapper"
     :style="{ top: emojiPickerPosition.top, left: emojiPickerPosition.left }">
  <emoji-picker @emoji-click="onEmojiSelected"></emoji-picker>
</div>

<div v-if="showFundDragTip"
     id="fund-drag-tip"
     class="showing"
     :style="{ top: fundDragTipPosition.top + 'px', left: fundDragTipPosition.left + 'px' }">
  <a class="close-btn" @click="showFundDragTip = false">x</a>
  <form @submit.prevent="submitFundToGoal">
    <input type="number" v-model="enteredAmount" name="amount" autocomplete="off">
    <div class="spend-from-goal"><em class="fancy" style="color: grey; ">of</em> <span style="color:black; font-weight: 600;">{{ formatCurrency(safeToSpend) }}</span></div>
  </form>
  <div v-if="errorMessage" class="error-message" style="color: red;">{{ errorMessage }}</div>
</div>

<div class="goals-header">
  <div class="balances-parent">

<!-- Display Safe to Spend -->
<div class="safe-to-spend-container" 
  @click="handleSafeToSpendClick">
  <span class="total-balance"
  :class="{ 'is-dragging': isDragging }"
  draggable="true"
  @dragstart="handleDragStart"
  @dragend="handleDragEnd">{{ formatCurrency(safeToSpend) }}</span>
  <div class="safe-to-spend-parent">
      <h2 class="safe-to-spend-label">Safe to Spend</h2>
      <p class="safe-to-spend-p">
        {{ formatCurrency(totalBalance) }} Total Balance - 
        {{ formatCurrency(totalGoalsBalance) }} Goals Balance
        <span v-if="includeRemainingInSafeToSpend && currentMonthRemaining >= 0"> - {{ formatCurrency(currentMonthRemaining) }} Remaining Monthly Budget</span>
      </p>
  </div>
    <!-- Assign a ref to the monthly-budget container -->
    <div class="monthly-budget" v-if="showBudgetAndSpending" ref="monthlyBudgetContainer">
      <!-- Display Total Budget for the Current Month -->
      <div class="current-month-budget-container">
        <h2 style="text-align: left">{{ currentMonthAndYear }} Budget: <span style="float:right">{{ formatCurrency(currentMonthTotalBudget) }} </span></h2>
      </div>

      <!-- Display Total Spending for the Current Month -->
      <div class="current-month-spending-container">
        <h2 style="text-align: left">{{ currentMonthAndYear }} Spending: <span style="float:right">{{ formatCurrency(currentMonthTotalSpending) }}</span></h2>
      </div>

      <div class="current-month-remaining-container">
        <h2 style="text-align: left">Remaining Spend: <span style="float:right">{{ formatCurrency(currentMonthRemaining) }}</span></h2>
      </div>

      <!-- Monthly Budget Toggle -->
      <div class="monthly-budget-toggle">
        <label class="account-switch">
          <input type="checkbox" v-model="includeRemainingInSafeToSpend" :disabled="currentMonthRemaining < 0" @change="updateIncludeRemainingInSafeToSpend">
          <span class="account-slider round"></span>
        </label>
        <span style="margin-left:10px; display: block;">Include Remaining Budget in Safe to Spend</span>
      </div>
      <div v-if="currentMonthRemaining < 0" style="margin-left:10px; color: red;">Over budget - Remaining budget automatically excluded</div>
    </div>
  </div>
  


<!-- Display Total Balance -->
<div class="total-balance-container" 
  @click="handleParentClick">
  <span class="total-balance2">{{ formatCurrency(totalBalance) }}</span><h2 class="total-balance-label">Total Balance</h2>
  <!-- Assign a ref to the assets container -->
  <div class="cash-assets-container" v-if="showCashAssets" ref="assetsContainer">
    <div v-for="asset in cashAssets" :key="asset.id" class="cash-asset-card">
      <p class="asset-name">{{ asset.name }}</p>
      <p class="asset-balance">{{ formatCurrency(asset.currentValue) }}</p>
      <label class="account-switch">
        <input type="checkbox" :checked="asset.included" @change="toggleAssetInclude(asset.id, $event)">
        <span class="account-slider round"></span>
      </label>
    </div>
  </div>
</div>

</div>
  <div class="calendar-toggle-parent">
    <div class="add-goal-button-parent">
      <v-btn  @click="toggleAddGoalForm" class="add-goal-button">Add Goal</v-btn>
    </div>
    <v-btn-toggle
        v-model="viewMode"
        mandatory
        class="calendar-toggle"
      >
        <v-btn class="calendar-toggle-option" value="calendar">Calendar</v-btn>
        <v-btn class="calendar-toggle-option" value="list">List</v-btn>
      </v-btn-toggle>
  </div>
  

</div>



<div v-show="viewMode === 'calendar'" class="year-container">
  <div class="year-container" ref="yearContainer"
    @mousedown="startDragging"
    @mousemove="onDrag"
    @mouseup="stopDragging"
    @mouseleave="stopDragging">
    <div class="months-container" @wheel="handleScrollZoom" ref="monthsContainer">
      <div v-for="(month, index) in allMonths" :key="index" :id="`month-${month.year}-${month.month}`" class="month" :style="{ minWidth: monthWidth + 'px' }">
        <div class="month-name-container">
          {{ month.name }} <br><span class="year-tag" v-if="month.isJanuary">{{ month.year }}</span>
        </div>
      </div>
      <!-- Position Goal Cards Here -->
      <div class="goals-container">
        <li class="goal-card" 
        :class="{ 'drag-over': dragging, 'hovered': hoveredGoalId === goal.id }"
        v-for="goal in sortedGoals" 
        :style="goal.style"
        :key="goal.id" 
        :id="`goal-${goal.id}`"
        @click="toggleGoalDetailsModal(goal)" 
        @dragover.prevent
        @dragenter="handleDragEnter(goal)"
        @dragleave="handleDragLeave"
        @drop="handleDrop(goal, $event)">
          <div class="goal-sticky-container">
            <span class="goal-emoji">{{ goal.emoji }}</span>
            <span class="goal-name">{{ goal.name }}
              <span class="goal-percentage">{{ calculateGoalPercentage(goal) }}%</span>
            </span>
            <div class="goal-div6">
              <span v-if="calculateGoalPercentage(goal) < 100">
                ${{ dailySavings.find(d => d.id === goal.id).dailySave.toFixed(2) }} per day
              </span>
              <span v-else>Goal Reached 🎉</span>
            </div>
          </div>
          <span class="goal-total">
            {{ formatCurrency(parseFloat(goal.currentBalance)) }}
            <span class="goalamount" v-if="calculateGoalPercentage(goal) < 100">
              / {{ formatCurrency(parseFloat(goal.amount)) }}
            </span>
            <span v-else>
              Total Balance
            </span>
          </span>

          <div class="goal-fill" :style="getGoalBackgroundStyle(goal)">
            <div class="spent-portion" :style="getSpentPortionStyle(goal)"></div>

            <div class="goal-marker" :style="{left: `var(--currentDateMarker)`}"></div>
            <div v-if="calculateAmountBehind(goal) > 0"
                class="amount-behind"
                :style="{left: `var(--goalFill)`}">
              Behind: {{ formatCurrency(calculateAmountBehind(goal).toFixed(2)) }}
            </div>
          </div>

          <div class="goal-div7">
          </div>
          <div class="goal-div8">
            {{ formatDate(goal.date) }} | {{ calculateDurationInEnglish(goal.startDate, goal.date) }}
          </div>
          

          <button class="delete-goal" @click.stop="deleteGoal(goal.id)">X</button>

        </li> 
      </div>
    </div>
    <div class="current-day-indicator" :style="{ left: currentDayPositionPx + 'px' }"></div>
  </div>
</div>

<div v-if="viewMode === 'list'">
  <div class="goals-container2" @click="toggleGoalsContainer">
    <div>
      <li class="goal-card2" 
      :class="{ 'drag-over': dragging, 'hovered': hoveredGoalId === goal.id }"
      v-for="goal in sortedGoals" 
      :key="goal.id" 
      :id="`goal-${goal.id}`"
      @click="toggleGoalDetailsModal(goal)" 
      @dragover.prevent
      @dragenter="handleDragEnter(goal)"
      @dragleave="handleDragLeave"
      @drop="handleDrop(goal, $event)">
      <div class="goal-fill2" :style="getGoalBackgroundStyle(goal)">
          <div class="goal-marker" :style="{left: `var(--currentDateMarker)`}"></div>
          <div v-if="calculateAmountBehind(goal) > 0"
              class="amount-behind"
              :style="{left: `var(--goalFill)`}">
            Behind: {{ formatCurrency(calculateAmountBehind(goal).toFixed(2)) }}
          </div>
        </div>
        <div class="goal-sticky-container">
          <span class="goal-emoji">{{ goal.emoji }}</span>
          <span class="goal-name">{{ goal.name }}
            <span class="goal-percentage">{{ calculateGoalPercentage(goal) }}%</span>
          </span>
          <div class="goal-div6">
            <span v-if="calculateGoalPercentage(goal) < 100">
              ${{ dailySavings.find(d => d.id === goal.id).dailySave.toFixed(2) }} per day
            </span>
            <span v-else>Goal Reached 🎉</span>
          </div>
        </div>
        <span class="goal-total2">
          {{ formatCurrency(parseFloat(goal.currentBalance)) }} /
          <span class="goalamount">{{ formatCurrency(parseFloat(goal.amount)) }}</span>
        </span>


        <div class="goal-div7">
        </div>
        <div class="goal-div8">
          {{ formatDate(goal.date) }} | {{ calculateDurationInEnglish(goal.startDate, goal.date) }}
        </div>
        <div v-if="showCategoryInsights[goal.id]" class="category-insights-1"></div>
        <div v-if="showCategoryInsights[goal.id]" class="category-insights-2">
          <div></div>
          <div>
            <span class="bold-currency"></span>
          </div>
          <div>
            <span class="bold-currency"></span>
          </div>
          <div>
            <span class="bold-currency"></span>
          </div>
        </div>
        <div v-if="showCategoryInsights[goal.id]" class="category-insights-3"></div>
        <div v-if="showCategoryInsights[goal.id]" class="category-insights-4">
          <div>
            <span class="bold-currency"></span>
          </div>
          <div></div>
          <div>
            <span class="bold-currency">
            </span>
          </div>
        </div>
      </li> 
    </div>
    </div>
</div>


</template>


<script>
/* eslint-disable */
import { collection, getDocs, getDoc, addDoc, deleteDoc, writeBatch, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';
import 'emoji-picker-element';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';



export default {
  name: 'BalancesView',
  components: { VueDatePicker },
  data() {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"

    return {
      newGoal: {
        name: '',
        amount: 0,
        date: '',
        startDate: '',
        currentBalance: 0,
        emoji: '',
      },
      goals: [],
      initialPinchDistance: null,
      initialMonthWidth: null,
      viewMode: 'calendar', 
      cashAssets: [],
      showCashAssets: false,
      showBudgetAndSpending: false,
      includeRemainingInSafeToSpend: null,
      newEmoji: '',
      showCategoryInsights: {},
      dragging: false,
      hoveredGoalId: null,
      dragEnterLeaveBalance: 0,
      showFundDragTip: false,
      fundDragTipPosition: { top: 0, left: 0 },
      selectedGoalId: null,
      enteredAmount: null,
      errorMessage: '',
      emojiPickerPosition: { top: '0px', left: '0px' },
      showEmojiPicker: false,
      allMonths: [],
      currentDayPosition: 0,
      isGoalsContainerOpen: false,
      monthWidth: 200, // Initial width in pixels
      viewMode: 'calendar',
      showAddGoalForm: false,
      showGoalDetailsModal: false,
      selectedGoal: null,
      showNotification: false,
      showDeleteConfirmation: false,
      goalToDelete: null, // To keep track of which goal is being deleted
      isDragging: false,
      dragStartX: 0,
    };
  },
  computed: {
    currentMonthAndYear() {
      const now = new Date();
      const monthName = now.toLocaleString('default', { month: 'short' }); // Abbreviated month name
      const year = now.getFullYear();
      return `${monthName} ${year}`; // Formats to "Feb 2024", for example
    },
    sortedGoals() {
      return this.goals.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    sortedGoals2() {
      return this.goals.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    dailySavings() {
      return this.goals.map(goal => {
        const daysRemaining = this.calculateDaysRemaining(goal.date);
        if (daysRemaining === 0) {
          return { id: goal.id, dailySave: 0 };
        }
        const amountRemaining = goal.amount - goal.currentBalance;
        const dailySave = amountRemaining / daysRemaining;
        return { id: goal.id, dailySave: dailySave > 0 ? dailySave : 0 }; // Ensure negative values are not returned
      });
    },
    currentMonthRemaining() {
      return this.currentMonthTotalBudget - this.currentMonthTotalSpending;
    },
    totalBalance() {
      return this.cashAssets.reduce((sum, asset) => {
        if (asset.included && asset.valueHistory && asset.valueHistory.length > 0) {
          return sum + asset.currentValue;
        }
        return sum;
      }, 0);
    },
    safeToSpend() {
      const totalCurrentBalances = this.totalBalance;
      const totalCommittedToGoals = this.goals.reduce((sum, goal) => sum + (goal.currentBalance || 0), 0);
      let deduction = this.currentMonthTotalBudget - this.currentMonthTotalSpending;

      // If the deduction is negative, set it to 0
      deduction = Math.max(deduction, 0);

      if (!this.includeRemainingInSafeToSpend) {
        deduction = 0; // Do not deduct remaining amount if toggle is off
      }

      return totalCurrentBalances - totalCommittedToGoals - deduction;
    },

    currentMonthTotalBudget() {
      const currentYear = new Date().getFullYear();
      const currentMonthIndex = new Date().getMonth(); // January is 0, December is 11
      const monthKey = `${currentYear}-${currentMonthIndex}`;
      const budgetData = this.$store.state.budgetTotals[monthKey];
      return budgetData ? budgetData.total : 0;
    },
    totalGoalsBalance() {
      return this.goals.reduce((sum, goal) => sum + (goal.currentBalance || 0), 0);
    },
    currentMonthTotalSpending() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      return this.$store.state.transactions.reduce((sum, transaction) => {
        // Extract the date part and manually set to midnight UTC to avoid timezone issues
        const dateParts = transaction.date.split('-');
        const transactionDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0));

        if (
          transactionDate.getUTCFullYear() === currentYear &&
          transactionDate.getUTCMonth() === currentMonth &&
          transaction.amount >= 0
        ) {
          return sum + transaction.amount;
        }
        return sum;
      }, 0);
    },

  },
  methods: {
    async updateGoal() {
      if (!this.selectedGoal.id) {
        alert("Goal ID is missing. Cannot update the goal.");
        return;
      }

      // Construct the goal update payload
      const goalUpdatePayload = {
        name: this.selectedGoal.name,
        amount: this.selectedGoal.amount,
        currentBalance: this.selectedGoal.currentBalance,
        // Ensure the date is in the correct format (assuming VueDatePicker returns a Date object)
        date: this.selectedGoal.date instanceof Date ? this.selectedGoal.date.toISOString().split('T')[0] : this.selectedGoal.date,
        emoji: this.selectedGoal.emoji,
      };

      try {
        // Update the goal in Firestore
        const goalRef = doc(db, `/users/${auth.currentUser.uid}/goals`, this.selectedGoal.id);
        await updateDoc(goalRef, goalUpdatePayload);

        // Optionally, refresh the local goals list to reflect the update
        this.fetchGoals();

        // Close the modal and clear the selected goal
        this.showGoalDetailsModal = false;
        this.selectedGoal = null;
        
        // After successfully updating the goal
        this.showNotification = true; // Show the notification

      setTimeout(() => {
        this.showNotification = false; // Hide the notification after 2 seconds
      }, 2000);
      } catch (error) {
        console.error("Error updating goal: ", error);
        alert("Failed to update the goal. Please try again.");
      }
    },
    async updateIncludeRemainingInSafeToSpend() {
      const userRef = doc(db, `/users/${auth.currentUser.uid}`);

      try {
        await updateDoc(userRef, {
          includeRemainingInSafeToSpend: this.includeRemainingInSafeToSpend
        });
        console.log('includeRemainingInSafeToSpend updated successfully in the database.');
      } catch (error) {
        console.error('Error updating includeRemainingInSafeToSpend in the database:', error);
      }
    },

    toggleGoalDetailsModal(goal) {
      if (this.showGoalDetailsModal) {
        this.selectedGoal = null;
      } else {
        this.selectedGoal = { ...goal };
      }
      this.showGoalDetailsModal = !this.showGoalDetailsModal;
    },
    async fetchIncludeRemainingInSafeToSpend() {
      try {
        const userRef = doc(db, `/users/${auth.currentUser.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          this.includeRemainingInSafeToSpend = userDoc.data().includeRemainingInSafeToSpend ?? false;
        } else {
          this.includeRemainingInSafeToSpend = false;
        }
      } catch (error) {
        console.error('Error fetching includeRemainingInSafeToSpend from the database:', error);
        this.includeRemainingInSafeToSpend = false;
      }
    },
    deleteGoal(goalId) {
      this.goalToDelete = goalId;
      this.showDeleteConfirmation = true;
    },
    startDragging(event) {
      this.isDragging = true;
      this.startScrollLeft = this.$refs.yearContainer.scrollLeft;
      this.startScrollTop = this.$refs.yearContainer.scrollTop;
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.$refs.yearContainer.style.cursor = 'grabbing';
      this.$refs.yearContainer.style.userSelect = 'none';
    },
    onDrag(event) {
      if (!this.isDragging) return;

      const deltaX = event.clientX - this.startX;
      const deltaY = event.clientY - this.startY;

      this.$refs.yearContainer.scrollLeft = this.startScrollLeft - deltaX;
      this.$refs.yearContainer.scrollTop = this.startScrollTop - deltaY;
    },
    stopDragging() {
      this.isDragging = false;
      this.$refs.yearContainer.style.cursor = 'grab';
      this.$refs.yearContainer.style.removeProperty('user-select');
    },
    async confirmDeleteGoal() {
      if (this.goalToDelete) {
        try {
          const goalRef = doc(db, `/users/${auth.currentUser.uid}/goals`, this.goalToDelete);
          await deleteDoc(goalRef);

          // Refresh goals or remove the goal from the local list
          this.goals = this.goals.filter(goal => goal.id !== this.goalToDelete);
          
          // Optionally, display a success message
          console.log('Goal deleted successfully.');
        } catch (error) {
          console.error("Error deleting goal:", error);
          // Optionally, display an error message
          alert('Failed to delete the goal. Please try again.');
        }

        // Hide the confirmation modal and reset `goalToDelete`
        this.showDeleteConfirmation = false;
        this.goalToDelete = null;
      }
    },
    toggleAddGoalForm() {
      this.showAddGoalForm = !this.showAddGoalForm;
    },
    // mobile zoom
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        // Prevent default to avoid page zooming
        event.preventDefault();
        this.initialPinchDistance = this.getPinchDistance(event.touches);
        this.initialMonthWidth = this.monthWidth;
      }
    },

    handleTouchMove(event) {
      if (event.touches.length === 2) {
        event.preventDefault();
        const currentDistance = this.getPinchDistance(event.touches);
        const scaleFactor = currentDistance / this.initialPinchDistance;
        
        // Adjust zoom sensitivity
        const sensitivity = 0.2; // Decrease this value to reduce sensitivity
        const zoomFactor = 1 + (scaleFactor - 1) * sensitivity;
        
        const newMonthWidth = this.initialMonthWidth * zoomFactor;
        
        // Apply bounds to the new width
        this.monthWidth = Math.max(Math.min(newMonthWidth, 600), 100);

        // Recalculate positions after zoom
        this.$nextTick(() => {
          this.calculateCurrentDayPosition();
          this.setGoalsPositions();
        });
      }
    },

    handleTouchEnd() {
      this.initialPinchDistance = null;
      this.initialMonthWidth = null;
    },

    getPinchDistance(touches) {
      return Math.hypot(
        touches[0].clientX - touches[1].clientX,
        touches[0].clientY - touches[1].clientY
      );
    },
    handleScrollZoom(event) {
      // Ignore horizontal scrolling
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        return;
      }

      const container = this.$refs.yearContainer;
      const sensitivity = 0.05; // Adjust this value as needed
      const zoomFactor = event.deltaY * sensitivity;

      // Step 1: Calculate the current scroll percentage
      const currentScrollPercentage = container.scrollLeft / (container.scrollWidth - container.clientWidth);

      // Apply the zoom factor
      this.monthWidth += zoomFactor;
      this.monthWidth = Math.max(Math.min(this.monthWidth, 600), 100); // Enforce min/max widths

      // Update the scroll position based on the previous percentage
      this.$nextTick(() => {
        container.scrollLeft = currentScrollPercentage * (container.scrollWidth - container.clientWidth);
      });

      // Recalculate positions after zoom
      this.calculateCurrentDayPosition();
      this.setGoalsPositions();


    },


    toggleGoalsContainer() {
      this.isGoalsContainerOpen = !this.isGoalsContainerOpen;
    },
    // this function is meant to allow the goal name to be displayed regardles of position however it has issues with scrolling / zooming
    // handleScroll() {
    //   const container = this.$refs.yearContainer;
    //   const scrollLeft = container.scrollLeft;

    //   this.sortedGoals.forEach(goal => {
    //     const goalElement = document.getElementById(`goal-${goal.id}`);
    //     const stickyElement = goalElement.querySelector('.goal-sticky-container');

    //     // Calculate the stopping position at 300px before the end of the parent
    //     const stoppingPosition = goalElement.offsetWidth - 300;

    //     // Calculate the current left position of the sticky element relative to the parent
    //     const currentLeft = scrollLeft - goalElement.offsetLeft;

    //     if (currentLeft > 0 && currentLeft < stoppingPosition) {
    //       stickyElement.style.left = `${currentLeft}px`;
    //     } else if (currentLeft >= stoppingPosition) {
    //       // Stop the sticky element 300px before the end of the parent
    //       stickyElement.style.left = `${stoppingPosition}px`;
    //     } else {
    //       stickyElement.style.left = '0px';
    //     }
    //   });
    // },
    setGoalsPositions() {
      // Initialize an array to keep track of rows and their occupied horizontal positions
      let rows = [];

      this.sortedGoals.forEach(goal => {
        const start = this.calculateDatePosition(goal.startDate);
        const end = this.calculateDatePosition(goal.date, true);

        // Try to find a row where the goal does not overlap with existing goals
        let placed = false;
        for (let i = 0; i < rows.length && !placed; i++) {
          let overlaps = false;
          for (let j = 0; j < rows[i].length && !overlaps; j++) {
            overlaps = start.left < rows[i][j].end && end.left > rows[i][j].start;
          }

          // If no overlap in this row, place the goal here
          if (!overlaps) {
            rows[i].push({start: start.left, end: end.left});
            goal.style = this.calculateGoalStyle(start.left, end.left, i);
            placed = true;
          }
        }

        // If the goal did not fit in any existing row, create a new row
        if (!placed) {
          rows.push([{start: start.left, end: end.left}]);
          goal.style = this.calculateGoalStyle(start.left, end.left, rows.length - 1);
        }
      });

      // Sort each row by start position (this step might be optional based on your needs)
      rows.forEach(row => row.sort((a, b) => a.start - b.start));
    },

    calculateGoalStyle(startLeft, endLeft, rowIndex) {
      const verticalSpacing = 130; // Vertical spacing between rows
      return {
        left: `${startLeft}px`,
        width: `${endLeft - startLeft}px`,
        top: `${rowIndex * verticalSpacing}px`,
        position: 'absolute'
      };
    },
    getSpentPortionStyle(goal) {
        const spentPercentage = this.calculateSpentPercentage(goal); // Calculate the spent percentage
        return {
          width: `${spentPercentage}%`,
          backgroundImage: `repeating-linear-gradient(
            45deg,
            rgba(255, 0, 0, 0.7),
            rgba(255, 0, 0, 0.7) 10px,
            rgba(255, 102, 102, 0.7) 10px,
            rgba(255, 102, 102, 0.7) 20px
          )`, // Creates a repeating stripe pattern
        };
      },

      calculateSpentPercentage(goal) {
        if (!goal.totalSpentFromGoal || !goal.amount) return 0; // Handle division by zero or undefined values
        const spentPercentage = (goal.totalSpentFromGoal / goal.amount) * 100;
        return Math.min(spentPercentage, 100); // Ensure the percentage does not exceed 100%
      },


    calculateDatePosition(dateStr, isEnd = false) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();

      // Find the month element and calculate the day's position within it
      const monthElement = document.getElementById(`month-${year}-${month}`);
      if (!monthElement) {
        return { left: 0, width: 0 }; // Fallback in case the element isn't found
      }

      const monthLeft = monthElement.offsetLeft;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const dayWidth = this.monthWidth / daysInMonth;

      // Calculate the exact position of the date within the month
      let datePosition = monthLeft + (day - 1) * dayWidth; // Position at the start of the day
      if (isEnd) {
        datePosition += dayWidth; // Adjust to the end of the day for the endDate
      }

      return { left: datePosition, width: dayWidth };
    },

    // calculateGoalWidth(goal) {
    //   const startDate = new Date(goal.startDate);
    //   const endDate = new Date(goal.date);

    //   // Calculate the total duration in days
    //   const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    //   const durationInDays = Math.round(Math.abs((endDate - startDate) / oneDay));

    //   // Assume monthWidth represents the width for a typical month (e.g., 30 days)
    //   // Adjust the width of the goal proportionally based on its duration in days
    //   const widthPerDay = this.monthWidth / 30; // Width of one day, assuming 30 days in a month
    //   const goalWidth = durationInDays * widthPerDay;

    //   return goalWidth;
    // },


    // getGoalCardStyle(goal) {
    //   const start = new Date(goal.startDate);
    //   const end = new Date(goal.date);

    //   const startYearBase = new Date().getFullYear() - 2; // Timeline starts 2 years back
    //   const startMonths = (start.getFullYear() - startYearBase) * 12 + start.getMonth();
    //   const endMonths = (end.getFullYear() - startYearBase) * 12 + end.getMonth();

    //   const leftPosition = startMonths * 200; // Each month is 200px
    //   const width = (endMonths - startMonths + 1) * 200; // Width spanning the duration of the goal

    //   console.log(`Goal: ${goal.name}, Left: ${leftPosition}px, Width: ${width}px, Start Date: ${goal.startDate}, End Date: ${goal.date}`);
    //   return {
    //     left: leftPosition + 'px',
    //     width: width + 'px',
    //   };
    // },
    generateMonths() {
      const baseWidthPerDay = 200 / 30; // Assuming 200px is the width for a 30-day month
      const startYear = new Date().getFullYear() - 2;

      for (let year = startYear; year <= startYear + 4; year++) {
        for (let month = 0; month < 12; month++) {
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          const monthWidth = daysInMonth * baseWidthPerDay; // Adjust width based on days in month
          this.allMonths.push({
            name: new Date(year, month).toLocaleString('default', { month: 'long' }),
            year: year,
            month: month,
            isJanuary: month === 0,
            days: daysInMonth,
            width: monthWidth,
          });
        }
      }
    },

    calculateCurrentDayPosition() {
      const now = new Date();
      let cumulativeWidth = 0; // Tracks the cumulative width of months up to the current day

      this.allMonths.forEach(month => {
        const daysInMonth = new Date(month.year, month.month + 1, 0).getDate();
        const monthWidthForDays = (this.monthWidth / daysInMonth) * daysInMonth; // Adjust width based on days in month

        if (month.year < now.getFullYear() || (month.year === now.getFullYear() && month.month < now.getMonth())) {
          cumulativeWidth += monthWidthForDays; // Add entire month width for past months
        } else if (month.year === now.getFullYear() && month.month === now.getMonth()) {
          // For the current month, add width proportional to the day of the month
          const proportionalWidth = (now.getDate() / daysInMonth) * monthWidthForDays;
          cumulativeWidth += proportionalWidth;
          return; // Exit the loop once the current month is processed
        }
      });

      this.currentDayPositionPx = cumulativeWidth; // Update the indicator's position
    },

    setInitialScrollPosition() {
      const container = this.$refs.yearContainer;
      // Adjust the scroll position so that the current day indicator is about 30% into the container
      container.scrollLeft = this.currentDayPositionPx - container.offsetWidth * 0.2;
    },
    handleEmojiPickerPosition(event) {
      
        this.showEmojiPicker = true;
        const inputRect = event.target.getBoundingClientRect();
        this.emojiPickerPosition = {
          top: inputRect.bottom + window.scrollY + 'px',
          left: inputRect.left + window.scrollX + 'px'
        };

    },
    calculateAmountBehind(goal) {
      // Parse the start and end dates as local dates
      const startDate = new Date(goal.startDate + 'T00:00'); // Add time part to ensure local date
      const endDate = new Date(goal.date + 'T00:00'); // Add time part to ensure local date
      const today = new Date(); // Current local date

      // Calculate total duration and duration until today
      const totalDuration = endDate - startDate;
      const durationUntilToday = today - startDate;

      // Calculate expected progress and amount behind
      const expectedProgress = (durationUntilToday / totalDuration) * goal.amount;
      const amountBehind = expectedProgress - goal.currentBalance;

      return amountBehind > 0 ? amountBehind : 0; // Return 0 if not behind
    },

    handleDragStart(event) {
      this.dragging = true;
      // Optionally, set a data transfer object
      event.dataTransfer.setData('text', 'balance');
    },
    handleDragEnd() {
      this.dragging = false;
    },
    handleFormSubmit() {
      if (this.enteredAmount > 0 && this.enteredAmount <= this.totalBalance) {
        const goal = this.goals.find(g => g.id === this.selectedGoalId);
        if (goal) {
          goal.currentBalance += this.enteredAmount;
          // Optionally, update the goal in the database here
        }
        this.enteredAmount = null;
        this.showFundDragTip = false;
      } else {
        alert("Invalid amount entered.");
      }
    },
    async submitFundToGoal() {
      if (!this.selectedGoalId || this.enteredAmount <= 0 || this.enteredAmount > this.totalBalance) {
        this.errorMessage = 'Amount exceeds your total balance.';
        return;
      }

      try {
        const goalToUpdate = this.goals.find(goal => goal.id === this.selectedGoalId);
        if (goalToUpdate) {
          goalToUpdate.currentBalance += this.enteredAmount;

          const goalRef = doc(db, `/users/${auth.currentUser.uid}/goals`, this.selectedGoalId);
          await updateDoc(goalRef, {
            currentBalance: goalToUpdate.currentBalance
          });

          console.log('Goal updated successfully');
        }
      } catch (error) {
        console.error('Error updating goal:', error);
        this.errorMessage = 'Error updating goal. Please try again.';
      }

      // Reset states
      this.enteredAmount = null;
      this.showFundDragTip = false;
    },


    handleDrop(goal, event) {
      if (this.dragging) {
        // Set the selected goal ID
        this.selectedGoalId = goal.id;

        // Logic to handle dropping on a goal
        console.log('Dropped on goal:', goal.name);

        // Calculate the position for the fund drag tip
        const rect = event.target.getBoundingClientRect();
        this.fundDragTipPosition = {
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX
        };

        // Show the fund drag tip
        this.showFundDragTip = true;

        // Reset hover and dragging states
        this.hoveredGoalId = null;
        this.dragEnterLeaveBalance = 0;
      }

      // Reset the dragging state
      this.dragging = false;
      this.enteredAmount = null;
    },
    handleDragEnter(goal) {
      if (this.dragging) {
        this.hoveredGoalId = goal.id;
        this.dragEnterLeaveBalance++;
      }
    },
    handleDragLeave() {
      this.dragEnterLeaveBalance--;
      if (this.dragEnterLeaveBalance <= 0) {
        this.hoveredGoalId = null;
        this.dragEnterLeaveBalance = 0; // Reset the counter
      }
    },
    calculateDaysRemaining(goalDate) {
      const currentDate = new Date();
      const endDate = new Date(goalDate);
      const timeDiff = endDate - currentDate;
      const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
      return daysRemaining > 0 ? daysRemaining : 0; // Return 0 if the end date is in the past
    },
    calculateDurationInEnglish(startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
      const oneWeek = 7; // days in a week
      const oneMonth = 30.44; // average days in a month
      const oneYear = 365; // days in a year

      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffDays = Math.round(Math.abs((end - start) / oneDay));

      if (diffDays < oneWeek) {
        return diffDays + " day" + (diffDays !== 1 ? "s" : "");
      } else if (diffDays < oneMonth) {
        const weeks = Math.round(diffDays / oneWeek);
        return weeks + " week" + (weeks !== 1 ? "s" : "");
      } else if (diffDays < oneYear) {
        const months = Math.round(diffDays / oneMonth);
        return months + " month" + (months !== 1 ? "s" : "");
      } else {
        const years = Math.round(diffDays / oneYear * 10) / 10; // Round to one decimal place
        return years + " year" + (years !== 1 ? "s" : "");
      }
    },
    calculateCurrentDatePosition(goal) {
      const startDate = new Date(goal.startDate);
      const finalDate = new Date(goal.date);
      const currentDate = new Date();

      // Calculate total duration and elapsed duration
      const totalDuration = finalDate - startDate;
      const elapsedDuration = currentDate - startDate;

      // Calculate the position as a percentage
      let position = (elapsedDuration / totalDuration) * 100;
      
      // Clamp the value between 0 and 100
      position = Math.max(0, Math.min(position, 100));

      return position.toFixed(2); // Rounded to two decimal places
    },
    calculateGoalProgress(goal) {
      const currentBalance = parseFloat(goal.currentBalance);
      const totalAmount = parseFloat(goal.amount);

      // Prevent division by zero
      if (totalAmount === 0) return 0;

      const percentage = (currentBalance / totalAmount) * 100;
      return Math.round(percentage); // Round to the nearest whole number
    },
    calculateGoalPercentage(goal) {
      let percentage = (goal.currentBalance / goal.amount) * 100;
      return percentage.toFixed(0); // Allows percentages over 100%
    },
    getGoalBackgroundStyle(goal) {
      const progressPercentage = this.calculateProgressPercentage(goal);
      const currentDatePercentage = this.calculateCurrentDatePercentage(goal.startDate, goal.date);
      const amountBehind = this.calculateAmountBehind(goal);

      let backgroundStyle, markerPosition;

      // If the progress is behind the current date
      if (progressPercentage < currentDatePercentage) {
        backgroundStyle = `linear-gradient(90deg, rgb(148, 228, 255) ${progressPercentage}%, #ebebeb ${progressPercentage}%, #ebebeb ${currentDatePercentage}%, transparent ${currentDatePercentage}%)`;
        markerPosition = currentDatePercentage;
      } else {
        backgroundStyle = `linear-gradient(90deg, rgb(148, 228, 255) ${progressPercentage}%, transparent ${progressPercentage}%)`;
        markerPosition = progressPercentage;
      }

      return {
        background: backgroundStyle,
        "--goalFill": `${markerPosition}%`
      };
    },
    calculateProgressPercentage(goal) {
      const totalAmount = goal.amount || 1; // Prevent division by zero
      return (goal.currentBalance / totalAmount) * 100;
    },
    calculateCurrentDatePercentage(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const today = new Date();

      const totalDuration = end - start;
      const durationUntilToday = today - start;

      return (durationUntilToday / totalDuration) * 100;
    },
    handleSafeToSpendClick(event) {
      // Check if the click is inside the monthly-budget container
      if (!this.$refs.monthlyBudgetContainer || !this.$refs.monthlyBudgetContainer.contains(event.target)) {
        this.toggleBudgetAndSpending();
      }
    },
    onEmojiSelected(event) {
      this.newEmoji = event.detail.unicode;
      this.showEmojiPicker = false;
      // Update the newGoal emoji as well
      this.newGoal.emoji = this.newEmoji;

      // If there is a selected goal, update its emoji as well
      if (this.selectedGoal) {
        this.selectedGoal.emoji = this.newEmoji;
      }
    },
    onEmojiEditSelected(event, category) {
      category.emoji = event.detail.unicode;
      category.showEmojiPicker = false; // Hide the emoji picker
    },
    getCategoryBackgroundStyle(category) {
        const percentage = this.calculateCategoryUsagePercentage(category);
        let fill, circlePosition;

        if (percentage <= 100) {
            fill = percentage.toFixed(2);
            circlePosition = fill;
        } else {
            fill = (100 / percentage * 100).toFixed(2);
            circlePosition = 100;
        }

        return {
            background: percentage <= 100 ? `linear-gradient(90deg, rgb(148, 228, 255) ${fill}%, transparent 0)` : `linear-gradient(90deg, rgb(148, 228, 255) ${fill}%, #ebebeb 100%)`,
            "--blueFill": `${circlePosition}%`
        };
    },
    toggleBudgetAndSpending() {
      this.showBudgetAndSpending = !this.showBudgetAndSpending;
    },
    handleParentClick(event) {
      // Check if the click is inside the assets container
      if (!this.$refs.assetsContainer || !this.$refs.assetsContainer.contains(event.target)) {
        this.toggleCashAssets();
      }
    },
    toggleCashAssets() {
      this.showCashAssets = !this.showCashAssets; // Toggle the visibility
    },

    async toggleAssetInclude(assetId, event) {
      if (event) {
        event.stopPropagation();
      }

      const assetIndex = this.cashAssets.findIndex(asset => asset.id === assetId);
      if (assetIndex !== -1) {
        // Directly toggle the 'included' state and Vue 3 will reactively update the UI
        this.cashAssets[assetIndex].included = !this.cashAssets[assetIndex].included;

        try {
          const assetRef = doc(db, `/users/${auth.currentUser.uid}/assets`, assetId);
          await updateDoc(assetRef, {
            included: this.cashAssets[assetIndex].included
          });
          console.log('Asset inclusion updated successfully in the database.');
        } catch (error) {
          console.error('Error updating asset inclusion in the database:', error);
        }
      }
    },


      

    async fetchCashAssets() {
      try {
        const assetsRef = collection(db, `/users/${auth.currentUser.uid}/assets`);
        const querySnapshot = await getDocs(assetsRef);
        
        // Transform each asset document into an object with an additional 'included' flag
        // and filter to only include assets with a specific class
        this.cashAssets = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        included: doc.data().included !== undefined ? doc.data().included : true
      })).filter(asset => asset.assetClass === '💵 Cash');

      } catch (error) {
        console.error("Error fetching cash assets:", error);
      }
    },

    formatCurrency(amount) {
      if (amount != null) {
        // Check if the amount is negative and store the flag
        const isNegative = amount < 0;

        // Work with the absolute value for formatting
        const absoluteAmount = Math.abs(amount);

        // Round to the nearest integer and format with commas
        const roundedAmount = Math.round(absoluteAmount);
        const formattedAmount = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Return the formatted amount with the correct sign
        return isNegative ? `-$${formattedAmount}` : `$${formattedAmount}`;
      }
      return '$0';
    },

    formatDate(dateStr) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString('en-US', options);
    },
    async addGoal() {
      if (!this.newGoal.name || this.newGoal.amount <= 0 || !this.newGoal.date) {
        alert("Please fill in all fields for the goal.");
        return;
      }

      // Check if `this.newGoal.date` is an array and has two elements (start and end dates)
      if (Array.isArray(this.newGoal.date) && this.newGoal.date.length === 2) {
        const [startDate, endDate] = this.newGoal.date;

        // Format dates to YYYY-MM-DD
        const formattedStartDate = this.formatDateToISO(startDate);
        const formattedEndDate = this.formatDateToISO(endDate);

        try {
          const userGoalsRef = collection(db, `/users/${auth.currentUser.uid}/goals`);
          await addDoc(userGoalsRef, {
            ...this.newGoal,
            startDate: formattedStartDate,
            date: formattedEndDate, // Previously `endDate` to match your data model
            emoji: this.newEmoji,
            currentBalance: 0
          });
          // Reset form after successful addition
          this.newGoal = { name: '', amount: 0, date: '', startDate: '', currentBalance: 0, emoji: '' };
          this.toggleAddGoalForm(); // Hide form
        } catch (error) {
          console.error("Error adding goal:", error);
          alert("Failed to add the goal. Please try again.");
        }
      } else {
        alert("Invalid date range.");
      }
      this.fetchGoals();
      this.setGoalsPositions();
    },

    formatDateToISO(date) {
      return date.toISOString().split('T')[0];
    },

    // async deleteGoal(goalId) {
    //   try {
    //     const goalRef = doc(db, `/users/${auth.currentUser.uid}/goals`, goalId);
    //     await deleteDoc(goalRef);

    //     // Remove the goal from the local array
    //     this.goals = this.goals.filter(goal => goal.id !== goalId);
    //   } catch (error) {
    //     console.error("Error deleting goal:", error);
    //   }
    //   this.fetchGoals();
    //   this.setGoalsPositions();
    // },

  async fetchGoals() {
    try {
      const userGoalsRef = collection(db, `/users/${auth.currentUser.uid}/goals`);
      const querySnapshot = await getDocs(userGoalsRef);
      this.goals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
    this.setGoalsPositions(); // Call this after goals data is loaded
  },
},

mounted() {
    this.fetchCashAssets();
    this.fetchGoals();
    this.fetchIncludeRemainingInSafeToSpend();
    this.generateMonths();
    this.calculateCurrentDayPosition();
    this.$nextTick(() => {
      this.setInitialScrollPosition();
      this.setGoalsPositions();
    });
    const container = this.$refs.yearContainer;
    container.addEventListener('scroll', this.handleScroll);
    container.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    container.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    container.addEventListener('touchend', this.handleTouchEnd);
  },
  beforeUnmount() {

    if (this.$refs.yearContainer) {
      this.$refs.yearContainer.removeEventListener('scroll', this.handleScroll);
    }

    if (this.$refs.yearContainer) {
      const container = this.$refs.yearContainer;
      container.removeEventListener('touchstart', this.handleTouchStart);
      container.removeEventListener('touchmove', this.handleTouchMove);
      container.removeEventListener('touchend', this.handleTouchEnd);
    }
  }
};
</script>

<style scoped>

h2 {
  text-align: center;
}

.goals-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: auto;
}

.goals-container2 {
  font-size: 16px;
  display: inline-block;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 700;
  color: white;
  background-color: white;
  /* margin-top: 20px; */
  padding: 30px;
  top: 180px;
  left: 0;
  width: 100%;
  position: relative;
  height: 100vh;
}

.goals-container2:hover {
  cursor: pointer;
}
.goal-card2 {
  display: grid;
  grid-template-columns: 10px repeat(3, 1fr);
  grid-template-rows: 30px 25px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-top: 20px;
  margin-top: 30px;
  padding-right: 10px;
  margin: 20px auto;
  width: 50%;
  padding-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: box-shadow 0.3s ease, border-radius 0.3s ease, padding 0.3s ease;
  transition: border-color 0.3s ease;
  z-index: 100;
}

.goal-card {
  display: grid;
  grid-template-columns: 10px repeat(3, 1fr);
  grid-template-rows: 30px 30px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-top: 20px;
  padding-right: 10px;
  margin: 35px 0px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  transition: box-shadow 0.3s ease, border-radius 0.3s ease, padding 0.3s ease;
  transition: border-color 0.3s ease;
  z-index: 100;
}

.amount-behind {
  position: absolute;
  top: 15px; /* Adjust as needed */
  left: 50%; /* Starting position */
  transform: translateX(-50%);
  display: none;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
  z-index: 211;
}

.goal-card:hover .amount-behind {
  display: block;
}

.goal-card.drag-over {
  border-width: medium;
  border: dashed;
}

.goal-card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}

.goal-content {
  margin-bottom: 20px;
}

.goal-name {
  color: #333;
  margin: 10px;
  font-weight: 900;
  grid-area: 1 / 2 / 2 / 4;
  text-align: left;
  font-size: 22px;
  white-space: nowrap; /* Keeps the text in a single line */
  text-overflow: ellipsis; /* Adds an ellipsis to indicate text clipping */
  overflow: hidden; /* Ensures that the overflowed text is hidden */
  max-width: 90%;
}

.goal-percentage {
  font-weight: bold;
  color: #c0c0c0;
  margin-left: 10px;
  min-width: 60px;
  text-align: right;
  font-size: smaller;
}

.goal-percentage p {
  font-size: 18px;
}

.goal-amount {
  grid-area: 1 / 4 / 2 / 5;
}

.goal-amount, .goal-date {
  color: #666;
  margin: 0;
}

.delete-goal {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease-in-out;
  opacity: 0; /* Show on hover or when expanded */
  grid-area: 2 / 2 / 3 / 5;
}

.goal-card:hover .delete-goal {
  opacity: 1; /* Show on hover or when expanded */
  z-index: 300;
}

.cash-assets-container {
  margin: 120px 25px;
  position: absolute;
  background-color: white;
  padding: 20px;
  z-index: 10;
  right: 0;
  overflow: auto;
  width: max-content;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 17px 20px 1px rgb(10 10 10 / 10%);
}

.cash-asset-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 0 auto 10px;
  width: 400px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.asset-name, .asset-balance {
  margin: 0;
  color: #333;
  text-align: left;
}

.asset-name {
  grid-area: 1 / 1 / 2 / 2;
}


.asset-balance {
  grid-area: 2 / 1 / 3 / 2;
}

.account-switch {
  grid-area: 1 / 2 / 3 / 3;

}

.total-balance-container {
  color: white;
  opacity: 1;
  display: flex;
  transition: opacity 0.3s ease;
}


.total-balance-container:hover {

  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 15%);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.total-balance-container.dragging {
  opacity: 0.5; /* Faded effect when dragging */
  background-color: #f8f7f1;
  border-width: medium;
  border: dashed;
}

.safe-to-spend-container {
  color: white;
  display: flex;
  opacity: 1;
  transition: opacity 0.3s ease;
  
}

.safe-to-spend-container:hover {
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 15%);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.total-balance:hover {
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 34%);
  transition: 0.3s ease-in-out;
  cursor: all-scroll;
}

.account-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: auto auto;
}

/* Hide default HTML checkbox */
.account-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The account-slider */
.account-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.account-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .account-slider {
  background-color: #2196F3;
}

input:focus + .account-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .account-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded account-sliders */
.account-slider.round {
  border-radius: 34px;
}

.account-slider.round:before {
  border-radius: 50%;
}

.balances-parent {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  background-color: #393945;
}



.add-goal-button {
  margin: 20px;
  font-size: large;
}

.goal-emoji {
  grid-area: 1 / 1 / 4 / 2;
  font-size: 30px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.goal-total {
  grid-area: 1 / 4 / 2 / 5;
  font-weight: bold;
  color: #a5a5a5;
  min-width: 60px;
  align-self: flex-end;
  text-align: right;
  z-index: 300;
  background-color: white;
}

.goal-total2 {
  grid-area: 1 / 4 / 2 / 5;
  font-weight: bold;
  color: #a5a5a5;
  min-width: 60px;
  align-self: flex-end;
  text-align: right;
  z-index: 300;
}
.goalamount {
  color: #333;
}

.emoji-input {
  text-align: center; 
  width: 20px;
  margin-right: 10px;
  position: relative;
}


.emoji-picker-wrapper {
  position: absolute;
  z-index: 1002;
}

.input-wrapper {
  position: relative; /* This makes it a positioning context for absolute children */
}

emoji-picker:host {
  width: 400px !important;
}

emoji-picker::part(picker) {
  width: 400px !important;
}

.goal-fill {
  grid-area: 2 / 2 / 3 / 5;
  position: relative;
  /* width: 100%; */
  height: 8px;
  border-radius: 10.5px;
  margin-top: 10px;
  background-color: #f1f1f1 !important;
  /* box-shadow:  0px 4px 7px 0px rgb(29 29 29 / 5%); */
}

.goal-fill2 {
  background-color: #ffffff !important;
  grid-area: 1 / 2 / 1 / 5;
  position: relative;
  /* width: 100%; */
  height: 106px;
  margin-left: -5px;
  border-radius: 6px;
  margin-top: -13px;
}

/* .goal-fill::after {
  content: "";
  position: absolute;
  left: calc(var(--goalFill));
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  background-color: white;
  outline-style: solid;
  outline-color: #757575;
  z-index: 1;
} */

.category-insights-1 {
  height: 100px;
  grid-area: 3 / 1 / 3 / 2;
  text-align: right;
  font-size: 12px;
}

.category-insights-2 {
  height: 100px;
  grid-area: 3 / 2 / 3 / 3;
  text-align: left;
  font-size: 12px;
}

.category-insights-3 {
  height: 100px;
  grid-area: 3 / 3 / 3 / 4;
  text-align: right;
  font-size: 12px;
}

.category-insights-4 {
  height: 100px;
  grid-area: 3 / 4 / 3 / 5;
  text-align: right;
  font-size: 12px;
  margin-right: 10px;
}

.goal-div6 { 
  grid-area: 3 / 2 / 4 / 3; 
  color: #a5a5a5;
  margin-right: 10px;
  margin-top: 30px;
  font-size: small;
  text-align: left;
}
.goal-div7 { grid-area: 3 / 3 / 4 / 4; }
.goal-div8 { 
  grid-area: 3 / 3 / 4 / 5;
  color: #a5a5a5;
  font-size: small;
  text-align: right;
  overflow: hidden;
  text-wrap: nowrap;
  margin-top: 5px;
  z-index: 210;
  background-color: white;
  
}

.is-dragging .goal-card {
  border: 3px dashed #151515; /* Dashed outline for drag effect */
}

.goal-card.hovered {
  border: 3px solid #64aaff; /* Solid outline for hovered goal */
  background-color: white;
}

#fund-drag-tip {
  position: absolute;
  top: 0;
  left: -1000px;
  background: white;
  padding: 25px;
  margin-top: -45px;
  border: 1px solid #dadfe1;
  -webkit-box-shadow: 0 5px 15px rgba(62,71,76,0.1);
  -moz-box-shadow: 0 5px 15px rgba(62,71,76,0.1);
  box-shadow: 0 5px 15px rgba(62,71,76,0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  z-index: 202;
  width: 240px;
}

.spend-from-goal {
  display: inline-block;
  margin: auto 10px;
}
#fund-drag-tip .close-btn {
    font-family: 'icons';
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #b0babf;
}

#fund-drag-tip .close-btn:hover {
cursor: pointer;
color: black;
}

#fund-drag-tip input {
  background: #f6f8f8;
  border: 1px solid #dadfe1;
  width: 80px;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  font-weight: 500;
  color: #3e474c;
  line-height: 1em;
  padding: 1em 1.1em;
  -moz-border-radius: 0.25em;
  -ms-border-radius: 0.25em;
  -o-border-radius: 0.25em;
  border-radius: 0.25em;
}

#fund-drag-tip form {
  display: inline-flex;
  margin-top: 0em;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-size: 0.9em;
}


.year-container {
    width: 100%;
    overflow-x: auto;
    position: relative;
    height: -webkit-fill-available;
    background-color: #f9f9f9;
    padding-top: 110px;
  }

  .months-container {
    display: flex;
    position: relative; /* Ensures goal cards are positioned relative to this container */
    min-width: 1200px;
    height: -webkit-fill-available;
  }

  .month {
    flex: 1;
    text-align: center;
    border-right: 1px solid #dfdfdf;
    /* min-width: 200px; */
    height: -webkit-fill-available;
    font-weight: 800;
  }

.current-day-indicator {
  position: absolute;
  background: red;
  top: 120px;
  width: 1px;
  height: -webkit-fill-available;
  border-radius: 10px;
  z-index: 10; /* Ensure it's above other elements */
}

.goal-sticky-container {
  position: absolute;
  top: 0;
  padding: 10px;
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 80%;
  z-index: 200; /* Ensure it's above other content */
}
.year-tag {
  color: #adadff;
  font-size: 20px;
}

.goal-percentage,
.goal-div8 {
  opacity: 0;
}

.goal-card:hover .goal-percentage,
.goal-card:hover .goal-div8 {
  opacity: 1;
}

.goals-header {
  position: fixed;
  width: -webkit-fill-available;
  z-index: 900; /* Ensure it's above other content */
  height: max-content;
  background-color: #f9f9f9;;
}

.monthly-budget {
  margin: 120px 25px;
  z-index: 910;
  position: absolute;
  background-color: white;
  padding: 20px;
  color: black;
  overflow: auto;
  width: max-content;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 17px 20px 1px rgb(10 10 10 / 10%);
}


.view-toggle button {
  background-color: #ffffff00;
  color: gray;
  border: 1px solid #e9e9e9;
  font-size: 16px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
.view-toggle button.active {
  background-color: #7aa6ff;
  color: white;
}

.view-toggle {
  padding: 20px;
}

.total-balance, .total-balance2 {
  font-size: 50px;
  font-weight: 700;
  margin: auto 10px;
  background-color: #4c4c5d;
  padding: 0px 20px;
  border-radius: 5px;
}

.total-balance2:hover {
  cursor: pointer;
}

.overlay {
  position: fixed; /* Use fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1001; /* Ensure it's above other content */
}

.modal-backdrop {
  position: absolute; /* Cover the .overlay area */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black */
  z-index: -1; /* Behind the form */
}

.new-goal-form {
  z-index: 2; /* Ensure the form is above the backdrop */
  background-color: white; /* Or any color you prefer */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  /* Adjust the width as necessary */
  width: max-content;
  
}
.new-goal-form {
  display: inline-flex;
  margin: 0 auto;
}

.new-goal-form input {
  height: 40px;
  padding: 0px 10px;
  min-width: 58%;
  border-width: thin;
  border-style: solid;
  color: black;
  height: 32px;
  border-radius: 5px;
  border-color: lightgrey;
}

.monthly-budget-toggle {
  display: flex;
  align-items: center;
  padding: 14px 0px;
}

.new-category-input {
  width: 45px;
}

.delete-goal {
  position: absolute;
  top: -65px;
  right: -20px;
  z-index: 10;
  font-weight: 900;
  color: red;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-radius: 50%;
  padding: 5px 10px;
  border-color: #e1e1e1;
  font-size: 14px;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.delete-goal:hover {
  color: white;
  background-color: #4a4a4a;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 15%);
}

.form-group {
  margin-bottom: 15px;
  text-wrap: nowrap;
  text-align: left;
  display: flex; /* Changed from inline-flex to flex */
  align-items: center; /* Vertically center the contents */
  gap: 5px;
  display: inline-flex;
}

.form-group label {
  display: block; /* Make the label take the full width */
  margin-bottom: 0px; /* Space between label and input */
  min-width: 40%;
}

.new-goal-form {
  display: flex;
  flex-direction: column; /* Stack form groups vertically */
  padding: 50px;
  background-color: white; /* Background color for the form */
  border-radius: 5px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for some depth */
  width: fit-content; /* Adjust width based on content */
  margin: auto; /* Center the form in the overlay */
}

.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top */
}

.notification-message {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}

.spent-portion {
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5); /* Red color with some opacity */
  border-radius: inherit; /* Match the parent's border-radius if any */
}

.year-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px; /* Adjust the width of the fade effect as needed */
  background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  z-index: 1; /* Ensure it's above the content but below any floating elements */
}


.month, .goal-card, .goal-sticky-container, .current-day-indicator, .goal-name, .goal-emoji, .goal-amount {
  transition: all ease-in-out; /* Smooth out the resizing */
}

.calendar-toggle-option {
    background-color: #f6f6f6;
  }
.month-name-container {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: -25px; /* Adjust this value as needed */
  height: 30px;
  background: #f9f9f9; /* Ensure the text is readable when scrolling */
  z-index: 850; /* Ensure it's above other content */
  font-weight: 900;
}

.calendar-toggle-parent {
  display:flex; 
  justify-content: space-between;
}

.calendar-toggle {
  height: 40px; 
  margin: 20px 20px; 
  border: thin solid lightgrey; 
  box-shadow: rgba(29, 29, 29, 0.05) 0px 6px 15px 0px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes dropIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}


.goal-card {
    animation: dropIn .3s ease-in forwards;
}

.year-container {
  cursor: grab;
}

.year-container:active {
  cursor: grabbing;
}

.total-balance-label {
  padding: 40px 0px;
}

.safe-to-spend-parent {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  height: 120px;
}

.safe-to-spend-label {
  padding: 0px 10px; 
  margin: 0px; 
  text-align: left;
}

.safe-to-spend-p {
  padding: 0px 10px; 
  font-weight: 600; 
  font-size: 14px; 
  margin: 0; 
  text-align: left;
}

@media screen and (max-width: 768px) {

  .year-container {
    height: 100vh;
  
  }

  .new-goal-form {
    width: 90vw;
    padding: 50px 10px;
    font-size: 12px;
  }

  .year-container::before {
    background: unset;
  }
  .total-balance-container, .safe-to-spend-container {
    display: unset;
    padding: 10px 10px 0px 10px;
    text-align: left;
  }

  .safe-to-spend-container:hover {
    box-shadow: unset;
  }

  .total-balance, .total-balance2 {
    padding: 4px 20px;
    margin: 10px 0px;
    font-size: 26px;
  }
  .total-balance-label {
    padding: 0px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 4px;
  }
  .safe-to-spend-parent {
    height: 70px;
  }

  .safe-to-spend-label {
    font-size: 14px;
    padding: unset;
  }

  .cash-asset-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: unset;
    width: 80vw;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: unset;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }


  .cash-assets-container{
    margin: 36px 20px;
    padding: unset;
    background-color: transparent;
  }

  .monthly-budget {
    margin: 0px 25px;
    z-index: 910;
    position: absolute;
    background-color: white;
    padding: 10px;
    color: black;
    overflow: auto;
    width: -moz-max-content;
    width: unset;
    border-radius: 0px 0px 10px 10px;
    font-size: 10px;
  }

  .account-switch {
    width: 30px;
    height: 17px;
  }

  .account-slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  input:checked + .account-slider:before {
    transform: translateX(13px);
  }
  .safe-to-spend-p {
    padding: 0px; 
    font-weight: 600; 
    font-size: 10px; 
    margin: 0; 
    text-align: left;
  }

  .add-goal-button-parent {
    height: 40px;
  }

  .add-goal-button {
    height: 30px !important;
    font-size: 10px;
    margin: 10px 20px;

  }


  .calendar-toggle {
    height: unset !important;
    margin: 10px 20px;
    border: thin solid lightgrey; 
    box-shadow: rgba(29, 29, 29, 0.05) 0px 6px 15px 0px;
  }

  .calendar-toggle-option {
    background-color: #f6f6f6;
    font-size: 10px;
    height: 30px !important;
  }

  .balances-parent {
    padding-top: 40px;
  }


}

</style>
