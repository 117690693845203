<template>
  <div class="password-reset-container">
    <form @submit.prevent="resetPassword" class="password-reset-form">
      <div class="form-group">
        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          required
          @input="clearErrors"
        ></v-text-field>
      </div>
      <div v-if="resetError" class="error-message">
        {{ resetError }}
      </div>
      <div v-if="resetSuccess" class="success-message">
        {{ resetSuccess }}
      </div>
      <div class="form-group password-reset-button">
        <v-btn style="color: black;" type="submit" :disabled="loading || isRateLimited">Reset Password</v-btn>
      </div>
    </form>
    <div class="back-to-login">
      <a href="#" @click.prevent="$emit('back-to-login')">Back to Login</a>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebaseInit';
import { sendPasswordResetEmail } from 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      resetError: '',
      resetSuccess: '',
      loading: false,
      lastResetAttempt: 0,
      resetAttempts: 0,
      noAccountFound: false,
    };
  },
  computed: {
    isRateLimited() {
      if (this.noAccountFound) return false;
      const currentTime = Date.now();
      const timeSinceLastAttempt = currentTime - this.lastResetAttempt;
      return timeSinceLastAttempt < 60000 || this.resetAttempts >= 5;
    },
  },
  methods: {
    async resetPassword() {
      if (this.isRateLimited) {
        this.resetError = 'Too many attempts. Please try again later.';
        return;
      }

      this.loading = true;
      this.resetError = '';
      this.resetSuccess = '';
      this.lastResetAttempt = Date.now();
      this.resetAttempts++;
      this.noAccountFound = false;

      try {
        await sendPasswordResetEmail(auth, this.email);
        this.resetSuccess = 'Password reset email sent. Please check your inbox.';
        this.resetAttempts = 0; // Reset the counter on success
      } catch (error) {
        console.error(error);
        this.handleResetError(error);
      } finally {
        this.loading = false;
      }

      // Reset attempts after 5 minutes
      setTimeout(() => {
        this.resetAttempts = 0;
      }, 300000);
    },
    handleResetError(error) {
      switch (error.code) {
        case 'auth/user-not-found':
          this.resetError = 'No account found with this email address.';
          this.noAccountFound = true;
          this.resetAttempts = 0; // Reset attempts for this case
          break;
        case 'auth/invalid-email':
          this.resetError = 'Invalid email address. Please enter a valid email.';
          break;
        case 'auth/missing-email':
          this.resetError = 'Please enter an email address.';
          break;
        case 'auth/too-many-requests':
          this.resetError = 'Too many password reset attempts. Please try again later.';
          break;
        default:
          this.resetError = 'An error occurred. Please try again later.';
      }
    },
    clearErrors() {
      this.resetError = '';
      this.resetSuccess = '';
      this.noAccountFound = false;
    },
  },
};
</script>

<style scoped>
.password-reset-container {
  width: 600px;
  margin-top: 10px;
}

.password-reset-form {
  padding: 20px;
  border-radius: 5px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-bottom: 10px;
}

.back-to-login {
  margin-top: 10px;
  text-align: center;
}

.back-to-login a {
  color: #59a7ff;
  text-decoration: none;
}

.back-to-login a:hover {
  text-decoration: underline;
}


@media screen and (max-width: 768px) {

  .password-reset-container {
    width: 300px !important;
    margin: 0 auto;
  }
  .password-reset-button {
    margin: 0 auto;
    width: 200px;
  }

}
</style>