<template>
  <div :class="{ 'light-mode': lightMode }">
    <div v-if="isCheckingAuth" class="loading-overlay">
      <p>Loading...</p>
    </div>
    <template v-else>
      <TheHeader @toggle-light-mode="toggleLightMode" :class="{ 'hidden': isLoading }" />
      <div class="container">
        <v-btn-toggle class="login-option" v-model="selectedForm" mandatory style="border: solid thin lightgrey;">
          <v-btn value="register">Register</v-btn>
          <v-btn value="login">Login</v-btn>
        </v-btn-toggle>
        <RegistrationForm style="margin-bottom: 50px;" v-if="selectedForm === 'register'" @loading-state-changed="updateLoadingState" />
        <LoginForm style="margin-bottom: 50px;" v-if="selectedForm === 'login'" @loading-state-changed="updateLoadingState" @show-reset-password="showResetPassword" />
        <PasswordReset style="margin-bottom: 50px;" v-if="selectedForm === 'reset-password'" @back-to-login="backToLogin" />
        <TheFooter class="footer-container" :lightMode="lightMode" :class="{ 'hidden': isLoading }"></TheFooter>
      </div>
    </template>
  </div>
</template>

<script>
import RegistrationForm from '../components/RegistrationForm.vue';
import LoginForm from '../components/LoginForm.vue';
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'
import PasswordReset from '../components/PasswordReset.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseInit'; 
import { useRouter } from 'vue-router';


export default {
  components: {
    RegistrationForm,
    LoginForm,
    PasswordReset,
    TheHeader,
    TheFooter,
  },
  setup() {
    const router = useRouter();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        router.push('/transactions');
      }
      this.isCheckingAuth = false; // Update loading state
    });

    return {
      // ... any other setup return values ...
    }
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$router.push('/transactions');
      }
      this.isCheckingAuth = false;
    });
  },
  data() {
    return {
      selectedForm: 'login', // Default selected form
      lightMode: false,
      isLoading: false,
      isCheckingAuth: true,
    };
  },
  methods: {
    toggleLightMode() {
      this.lightMode = !this.lightMode;
    },
    updateLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
    showResetPassword() {
      this.selectedForm = 'reset-password';
    },
    backToLogin() {
      this.selectedForm = 'login';
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.container {
  background-color: white;
  color: black;
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.hidden {
  display: none !important;
}

.container a {
  color: white;
}

.light-mode .container {
  background-color: white;
  color: black;
}


.light-mode .container a {
  color: black;
}

.light-mode button  {
  color: black;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 9999;
}

@media screen and (max-width: 768px) {

  .container {
    display: unset;
  }

  .login-option {
    margin: 40px;
    margin-left: 8px;
  }
}


</style>