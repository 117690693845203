<template>
<div v-if="recurringIncome && recurringIncome.length === 0">
    <div class="onboarding-style-recurring">
      <h1 class="fade-slide-down" id="welcome-text-left">Track your regular income</h1>
      <h3 class="fade-slide-down" id="import-text-recurring-left">Let's take a look at your regular income and payments to get a clear picture of your incoming deposits.</h3>
    </div>
    <v-btn class="fade-slide-down find-recurring-button-left" @click="findRecurringTransactions" :disabled="isLoading" >
      {{ buttonLabel }}    
    </v-btn>   
  </div>
  <div class="parent">
    <div class="div3" id="top-right-sidebar" v-if="recurringIncome && recurringIncome.length > 0">
        <div class="total-income-label" v-if="totalOfAllIncomeForLastYear">
          <h2><strong>Total Income ({{ lastFullYear }}) </strong></h2>
          <h1 style="color: forestgreen; font-size: 2rem;">{{ formatCurrency(totalOfAllIncomeForLastYear) }}</h1>
        </div>
    </div>
    
    <div class="innerdiv4-income" v-if="recurringIncome.length > 0">        
      <p class="recurring-summary">
        You have <span style="font-weight: bold; font-size: 1.5em;">{{ remainingRecurringCharges }}</span> 
        recurring deposits coming up this month totaling 
        <span style="font-size: 2em;">{{ formatCurrency(-totalRecurringAmount) }}</span>
      </p>
      <v-btn class="find-recurring-button2" @click="findRecurringTransactions" :disabled="isLoading">
        {{ buttonLabel }}    
      </v-btn>   
    </div>
    <div class="div6-income" id="2nd-row-right" v-if="recurringIncome.length > 0"> 
      <ul class="current-month-recurring">
        <li v-for="transaction in sortedRecurringTransactions" :key="transaction.name" 
          class="transaction-item" 
          :class="{
            'completed-transaction': transaction.isCompleted,
            'past-due-transaction': transaction.isPastDue,
            'upcoming-transaction': transaction.isUpcoming,
          }">
          <span v-if="transaction.isCompleted" class="status-icon">&#9989;</span>
          <span v-else class="status-icon">&#9634;</span>
          
          <span class="transaction-name"><span v-if="transaction.isPastDue" class="past-due-label">Past Due:</span>{{ transaction.name }}</span>
          <span class="transaction-date">
            {{ formatDate2(transaction.date).main }}<sup class="date-suffix">{{ formatDate2(transaction.date).suffix }}</sup>
          </span>
          <span class="transaction-amount">{{ formatCurrency(-transaction.amount) }}</span>
        </li>
        <li class="total-for-month-line">___________________</li>
        <li class="total-for-month">{{ formatCurrency(-totalRecurringForMonth) }}</li>
      </ul>
    </div>
  
    <div class="div7" id="3rd-row-large"> 
    </div>
  
    <div class="div8" id="4th-row-left">

      <span v-if="isLoading" class="rules-message">Loading...</span>
      <div v-if="showSuccessMessage" class="rules-message">Recurring transaction has been added 🎉</div>
      <div v-if="addingRule" class="rules-message">⏳ Adding recurring transaction...</div>
      <div v-if="norecurringIncomeFound" class="rules-message">
        🤷‍♀️ No recurring income found.
      </div>


      <div class="new-recurrings-list"  v-if="matchedTransactions.length && showingResults">
        <!-- Found Recurring Transactions -->

        <div class="new-recurrings-list" v-if="matchedTransactions.length && showingResults">
            <!-- Found Recurring Transactions -->


          <div class="new-recurring-row" v-for="item in matchedTransactions" :key="item.id" :class="{ 'new-recurring-row-expanded': newRecurringBeingTracked === item.id }"> 
            <div class="current-recurrings" @click="toggleNewRecurringHistory(item)">

              <div class="left-side">
                <p class="recurring-name">{{ capitalizeFirstLetter(item.name) }}</p>
                <p class="recurring-frequency">{{ item.frequency }}</p>
              </div>

              <div class="right-side">
                <p class="recurring-next-amount">{{ formatCurrency(-item.expectedAmount) }}</p>
                <p class="recurring-next-date">{{ daysUntilDue(item.nextExpectedDate) }} on {{ formatDate(item.nextExpectedDate) }}</p>
              </div>
            </div>

            <div class="recurring-history" v-if="newRecurringBeingTracked === item.id">
              <div class="rule-details">
                <div class="rule-summary left-side">
                Occurred {{ item.numberOfTransactions }} times for a total of {{ formatCurrency(-item.total) }}. <br>
                Ranges between {{ formatCurrency(-item.minAmount) }} and {{ formatCurrency(-item.maxAmount) }}. <br>
              </div>
              <button class="add-recurring-button" @click.stop.prevent="addRecurringIncome(item)">➕ADD</button>


                <div class="annual-summary right-side">
                  2024: {{ formatCurrency(-item['2024']) }} <br>
                  2023: {{ formatCurrency(-item['2023']) }} <br>
                  2022: {{ formatCurrency(-item['2022']) }} <br>
                  <!-- 2021: {{ formatCurrency(item['2021']) }} <br> -->
                </div>
              </div>
              <table class="recurring-table">
                <thead>
                  <tr>
                    <!-- <th class="text-left"></th> -->
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-center">Amount</th>
                    <th class="text-right">Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in item.transactions" :key="transaction.id">
                    <!-- <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td> -->
                    <td>{{ formatDate(transaction.date) }}</td>
                    <td>{{ transaction.name }}</td>
                    <td class="text-center">{{ formatCurrency(-transaction.amount) }}</td>
                    <td class="text-right">{{ transaction.category }}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>  
        </div>  
        
      </div>

      <div class="recurrings-list">
        <div class="recurring-row" 
          v-for="rule in recurringIncome" 
          :key="rule.docId" 
          :class="{ 'recurring-row-expanded': recurringBeingTracked === rule.docId }">

          <div class="current-recurrings" @click="toggleRecurringHistory(rule)">

              <div class="left-side">
                <div class="edit-rule-buttons">
                  <div v-if="editingRuleId !== rule.docId" @click.stop="startEditing(rule)" class="recurring-name">{{ rule.name }}</div>
                  <input class="recurring-input rename-rule-input" 
                      v-if="editingRuleId === rule.docId" 
                      v-model="editName" 
                      ref="editInput"
                      @blur="handleBlur(rule)" 
                      @keydown.enter.prevent="handleEnter(rule)"
                  />

                  <button 
                    class="edit-button" 
                    v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" 
                    @click="deleteRecurringIncome(rule)">
                    x
                  </button>

                </div>
                  <p class="recurring-frequency">{{ rule.frequency }}</p>
              </div>

              <div class="right-side">
              <div class="recurring-next-amount" @click="editRecurringAmount(rule)">
                <span v-if="!rule.editingAmount">{{ formatCurrency(-rule.expectedAmount) }}</span>
                <input
                  v-else
                  type="number"
                  step="0.01"
                  class="recurring-next-amount-edit"
                  v-model.number="rule.newAmount"
                  @blur="saveRecurringAmount(rule)"
                  @keyup.enter="saveRecurringAmount(rule)"
                >
              </div>
              <p class="recurring-next-date">
                {{ daysUntilDue(rule.nextExpectedDate, rule.isActive) }}
                <template v-if="rule.isActive">on {{ formatDate(rule.nextExpectedDate) }}</template>
              </p>
            </div>
          </div>

          <!-- Recurring History Table -->
          <div class="recurring-history" v-if="recurringBeingTracked === rule.docId">

            <div class="rule-details">

              <div class="rule-summary left-side">
                  Occurred {{ rule.numberOfTransactions }} times for a total of {{ formatCurrency(-rule.total) }}. <br>
                  Ranges between {{ formatCurrency(-rule.minAmount) }} and {{ formatCurrency(-rule.maxAmount) }}. <br>
              </div>
              <div class="active-label" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                  <span v-if="rule.isActive">Active</span>
                  <span v-else>Inactive</span>
                </div> 
                <div class="toggle-switch" v-if="recurringBeingTracked === rule.docId && editingRuleId !== rule.docId" >
                    <input 
                      class="recurring-input"
                      type="checkbox" 
                      v-model="rule.isActive" 
                      @click="updateIsActive(rule)"
                      :id="'toggle-' + rule.docId" 
                    >
                    <label :for="'toggle-' + rule.docId" class="slider"></label>
                </div>

              <div class="annual-summary right-side">
                2024: {{ formatCurrency(-rule['2024']) }} <br>
                2023: {{ formatCurrency(-rule['2023']) }} <br>
                2022: {{ formatCurrency(-rule['2022']) }} <br>
                <!-- 2021: {{ formatCurrency(rule['2021']) }} <br> -->
              </div>
            </div>

            <table class="recurring-table">
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">Date</th>
                  <th class="text-center">Name</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Category</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in rule.transactions" :key="transaction.id">
                  <td class="delete-edit-button" @click="removeTransactionFromRule(rule, transaction)">x</td>
                  <td>{{ formatDate(transaction.date) }}</td>
                  <td>{{ transaction.name }}</td>
                  <td class="text-center">{{ formatCurrency(-transaction.amount) }}</td>
                  <td class="text-right">{{ transaction.category }}</td>
                </tr>
              </tbody>
            </table>
          </div>   
        </div>
      </div>
    </div>
  
    <div class="div9" id="bottom-right-sidebar" v-if="recurringIncome && recurringIncome.length > 0">
      <h2 class="template-h2"></h2>
      <recurring-stacked-chart :recurring-chart-data="recurringChartData" />
    </div>
    <div class="div10">
      <div class="income">
        <div class="income-second-row">

          <div class="additional-income-sources">
            <div class="tracked-income-header" @click="toggleAllIncomeTransactions">
            <h2 style="margin: 0px auto; cursor: pointer;">
              <strong class="income-transactions-title" >All Income Transactions</strong>
              <span class="income-transactions-icon" v-if="!showAllIncomeTransactions">▼</span>
              <span class="income-transactions-icon" v-else>▲</span>
            </h2>
            <v-icon class="info-icon" @mouseover="showTrackedIncomeTooltip = true" @mouseleave="showTrackedIncomeTooltip = false">mdi-information-outline</v-icon>
            <div class="tooltip" v-show="showTrackedIncomeTooltip">
              Tracked income is automatically gathered from transactions that have a category belonging to the "Income" group. The transactions are grouped by name for easier viewing. You can manage your category groups in the settings page.
            </div>
          </div>
          <div v-if="showAllIncomeTransactions">
            <div class="income-source-name" v-for="(transactions, source) in incomeBySource" :key="source">
              <div @click="toggleIncome(source)" class="source-row">
                <h3>{{ source }}</h3>
                <div class="source-totals">
                  <span v-for="year in Object.keys(totalsBySource[source]).sort().reverse()" :key="year">
                    {{ year }}: {{ formatCurrency(-totalsBySource[source][year]) }}
                    <template v-if="year !== Object.keys(totalsBySource[source]).sort().reverse()[0]"> | </template>
                  </span>
                </div>
              </div>
              <div v-if="expandedIncome === source">
                <ul class="transaction-list">
                  <li v-for="transaction in transactions" :key="transaction.id" class="transaction-item">
                    <span class="income-date">{{ formatDate(transaction.date) }}</span>
                    <span style="width: 375px; overflow: hidden; height: 20px;" class="income-name">{{ transaction.name }}</span>
                    <span class="income-amount">{{ formatCurrency(-transaction.amount) }}</span>
                    <span style="width: 115px;" class="income-category">{{ transaction.category }}</span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
          <div class="additional-income-totals">
            <div style="margin: 0 20px;">
            <div class="add-income-container">
              <v-btn class="add-custom-button" @click="showIncomeFields = !showIncomeFields">+ADD CUSTOM INCOME</v-btn>
              <v-icon  style="float: left; justify-content: space-around;" class="info-icon" @mouseover="showTrackedIncomeTooltip = true" @mouseleave="showTrackedIncomeTooltip = false">mdi-information-outline</v-icon>
              <div class="tooltip" v-show="showTrackedIncomeTooltip">
                You can add additional custom income below. This income will not be included in your recurring income above, but it will count towards your annual totals. If you wish to have additional recurring income that is tracked, please add those income transactions on the spending page.
              </div>
            </div>
          </div>
            <v-row class="additional-income-headers">
              <v-col cols="3">Total</v-col>
              <v-col cols="4">Frequency</v-col>
              <v-col cols="2">Start Date</v-col>
              <v-col cols="2">End Date</v-col>
              <v-col cols="1"></v-col>
            </v-row>
            
            <div v-for="(incomes, source) in additionalIncomes" :key="source">
              <v-row>
                <v-col style="border-top: 1px solid #ccc; margin-top: 10px; margin-bottom: -10px;" cols="12">
                  <h3 style="text-align: left;">{{ source }}</h3>
                </v-col>
              </v-row>
              <v-row 
                v-for="(income, index) in incomes" 
                :key="index"
                @mouseover="hoverIncome = `${source}-${index}`"
                @mouseleave="hoverIncome = ''"
                class="income-row"
              >
                <v-col style="text-align: left;" cols="3">
                  {{ formatCurrency(income.amount) }}
                </v-col>
                <v-col style="text-align: left;" cols="4">
                  {{ income.frequency }}
                </v-col>
                <v-col style="text-align: left;" cols="2">
                  {{ income.startDate }}
                </v-col>
                <v-col style="text-align: left;" cols="2">
                  {{ income.endDate }}
                </v-col>
                <v-col cols="1">
                  <v-icon
                    v-if="hoverIncome === `${source}-${index}`"
                    @click="editIncome(source, income, index)"
                  >mdi-pencil</v-icon>
                </v-col>
              </v-row>
            </div>

              <div v-if="showIncomeFields">
                <v-text-field
                  label="Additional Annual Income"
                  v-model="additionalIncome.amount"
                  prefix="$"
                  type="number"
                ></v-text-field>

                <v-select
                  label="Income Source"
                  :items="incomeSources"
                  v-model="additionalIncome.source"
                  @change="handleSourceChange"
                ></v-select>

                <v-text-field
                  label="Custom Income Source"
                  v-if="additionalIncome.source === 'Custom'"
                  v-model="additionalIncome.customSource"
                ></v-text-field>

                <v-select label="Frequency" :items="frequencyOptions" v-model="additionalIncome.frequency"></v-select>
                <v-text-field label="Start Date" v-model="additionalIncome.startDate" type="date"></v-text-field>
                <v-text-field label="End Date (leave blank if ongoing)" v-model="additionalIncome.endDate" type="date"></v-text-field>

                <v-btn @click="saveIncome">Save</v-btn>
                <v-btn @click="showIncomeFields = !showIncomeFields">Cancel</v-btn>
              </div>
          
          </div>

        </div>

        </div>
    </div>

  </div>

      <!-- Modal for editing income -->
      <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Edit Income
        </v-card-title>
        <v-card-text>
          <v-select
            label="Income Source"
            :items="incomeSources"
            v-model="editedIncome.source"
            @change="handleEditSourceChange"
          ></v-select>
          <v-text-field
            v-if="editedIncome.source === 'Custom'"
            label="Custom Income Source"
            v-model="editedIncome.customSource"
          ></v-text-field>
          <v-text-field
            label="Amount"
            v-model="editedIncome.amount"
            prefix="$"
            type="number"
          ></v-text-field>

          <!-- Additional fields for editing frequency, start date, and end date -->
          <v-select
            label="Frequency"
            :items="frequencyOptions"
            v-model="editedIncome.frequency"
          ></v-select>
          <v-text-field
            label="Start Date"
            v-model="editedIncome.startDate"
            type="date"
          ></v-text-field>
          <v-text-field
            label="End Date (leave blank if ongoing)"
            v-model="editedIncome.endDate"
            type="date"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteCurrentIncome">Delete</v-btn>
          <v-btn color="blue darken-1" text @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveEditedIncome">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


</template>

<script>
/* eslint-disable */
import { collection, getDocs, getDoc, addDoc, writeBatch, deleteDoc, query, orderBy, updateDoc, doc, setDoc, deleteField } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';
import { differenceInCalendarDays, parseISO, addDays, addMonths, addYears, format } from "date-fns";
import RecurringStackedChart from '../components/RecurringStackedChart.vue';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  name: 'IncomeView',
  components: {
    RecurringStackedChart,
    VueDatePicker,
  },
  data() {
    return {
      recurringIncome: [],
      showAllIncomeTransactions: false,
      user: null,
      recurringBeingTracked: null,
      newRecurringBeingTracked: null,
      matchedTransactions: [], 
      isLoading: false,
      addingRule: null,
      showSuccessMessage: false,
      showingResults: false,
      buttonLabel: "Find Recurring Income",
      remainingRecurringCharges: 0,
      totalRecurringAmount: 0,
      editingRuleId: null, // To track which rule is currently being edited by its ID
      editName: '', // To temporarily store the edited name
      justPressedEnter: false,
      showNorecurringIncomeMessage: false,
      editingRuleId: null,
      newRecurringTransaction: {
        name: '',
        expectedAmount: null,
        frequency: '',
        nextExpectedDate: '',
      },
      showIncomeFields: false,
      additionalIncomes: {},
      editDialog: false,
      expandedIncome: null,
      frequencyOptions: ['Weekly', 'Biweekly', 'Monthly', 'Semi-Annually', 'Annually'],
      menu: false,
      endMenu: false,
      categories: {},
      showTooltip: false,
      hoverIncome: '',
      editedIncome: {
        source: '',
        amount: 0,
      },
      incomeToEdit: '',
      hoverIncome: '',
      showTrackedIncomeTooltip: false,

      additionalIncome: {
        amount: null,
        source: '',
        customSource: '',
      },
      incomeSources: [
        'Business',
        'Employment',
        'Side-hustle',
        'Part-time Job',
        'Investments',
        'Rental Income',
        'Freelancing',
        'Custom'
      ],
    };
  },
  computed: {
    lastFullYear() {
      return new Date().getFullYear() - 1;
    },
    incomeTransactions() {
      return this.$store.state.transactions.filter(transaction => 
        transaction.amount < 0 && 
        (!transaction.labels || !transaction.labels.includes("ignore"))
      );
    },
    incomeBySource() {
      console.log('Categories:', this.categories); // Verify the categories object

      return this.incomeTransactions.reduce((acc, transaction) => {
        // console.log(`Transaction: ${transaction.id}, Category: ${transaction.category}, Group: ${this.categories[transaction.category]}`);

        if (this.categories[transaction.category]?.toLowerCase() === 'income') {
          // console.log(`Included transaction: ${transaction.id}`); // Log included transaction ID

          const normalizedSource = this.normalizeIncomeSource(transaction.name || 'Unknown');
          if (!acc[normalizedSource]) {
            acc[normalizedSource] = [];
          }
          acc[normalizedSource].push(transaction);
        } else {
          // console.log(`Excluded transaction: ${transaction.id}`); // Log excluded transaction ID
        }

        // After populating the acc object with transactions, sort them by date
        for (const source in acc) {
          acc[source].sort((a, b) => new Date(a.date) - new Date(b.date));
        }
        return acc;
      }, {});
    },

    totalsBySource() {
      const totals = {};
      for (const source in this.incomeBySource) {
        totals[source] = this.incomeBySource[source].reduce((acc, transaction) => {
          const year = new Date(transaction.date).getFullYear();
          if (!acc[year]) {
            acc[year] = 0;
          }
          acc[year] += transaction.amount;
          return acc;
        }, {});
      }
      return totals;
    },
    totalOfAllIncomeForLastYear() {
      const lastYear = this.lastFullYear;
      let total = 0;

      // Log transaction totals by source
      for (const source in this.incomeBySource) {
        let sourceTotal = 0;
        this.incomeBySource[source].forEach(transaction => {
          const transactionYear = new Date(transaction.date).getFullYear();
          // Check if the transaction is not ignored and is from last year
          if (transactionYear === lastYear && (!transaction.labels || !transaction.labels.includes("ignore"))) {
            sourceTotal -= transaction.amount; // Remove the negation here
          }
        });
        console.log(`Transaction Total for ${source} in ${lastYear}: ${sourceTotal}`);
        total += sourceTotal;
      }

      // Log additional incomes (this part remains unchanged as it likely doesn't involve "ignore" labels)
      for (const source in this.additionalIncomes) {
        let additionalIncomeTotal = 0;
        this.additionalIncomes[source].forEach(income => {
          const startDate = new Date(income.startDate);
          const endDate = income.endDate && income.endDate.toLowerCase() !== 'ongoing' ? new Date(income.endDate) : new Date();
          if (startDate.getFullYear() <= lastYear && (endDate.getFullYear() >= lastYear || endDate.getFullYear() === 0)) {
            const months = this.getMonthsCount(startDate, endDate, lastYear);
            const incomeAmount = this.calculateIncomeForYear(income.amount, income.frequency, months);
            additionalIncomeTotal += incomeAmount;
          }
        });
        console.log(`Additional Income Total for ${source} in ${lastYear}: ${additionalIncomeTotal}`);
        total += additionalIncomeTotal;
      }

      console.log(`Overall Total Income for ${lastYear}: ${total}`);
      return total;
    },
    norecurringIncomeFound() {
      return this.showNorecurringIncomeMessage && this.matchedTransactions.length === 0 && this.showingResults && !this.isLoading;
    },
    recurringIncome() {
      return this.$store.state.recurringIncome || []; // Return an empty array if recurringIncome is undefined
    },
    activeAndPendingCharges() {
      return this.sortedRecurringTransactions; // Access as a property, not a function
    },
    remainingRecurringCharges() {
      let now = this.getLocalDate(new Date());
      now.setHours(0, 0, 0, 0); // Set time to start of the current day (midnight)
      now.setDate(now.getDate() - 1); // Subtract one day to get the start of the previous day
      return this.activeAndPendingCharges.filter(charge => 
        this.getLocalDate(charge.date) > now).length;
    },
    totalRecurringAmount() {
      let now = this.getLocalDate(new Date());
      now.setHours(0, 0, 0, 0); // Set time to start of the current day (midnight)
      now.setDate(now.getDate() - 1); // Subtract one day to get the start of the previous day
      return this.activeAndPendingCharges
        .filter(charge => this.getLocalDate(charge.date) > now)
        .reduce((total, charge) => total + charge.amount, 0);
    },
    totalRecurringForMonth() {
      return this.sortedRecurringTransactions.reduce((total, charge) => total + charge.amount, 0);
    },
    recurringChartData() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const filteredData = this.recurringIncome
        .filter(rule => {
          // Check if the rule is active
          if (!rule.isActive) return false;

          // Parse the nextExpectedDate
          const ruleDate = parseISO(rule.nextExpectedDate);
          const isCurrentMonth = ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear;
          
          // Include if the rule is "Monthly" or if the nextExpectedDate is in the current month
          return rule.frequency === "Monthly" || isCurrentMonth;
        })
        .map(rule => {
          return {
            name: rule.name,
            amount: rule.expectedAmount,
            date: rule.nextExpectedDate
          };
        });

      // Sort the data by amount in descending order so that largest bars are at the bottom
      filteredData.sort((a, b) => b.amount - a.amount);

      return filteredData;
    },
    sortedRecurringTransactions() {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Today without time

      const filteredData = this.recurringIncome
        .filter(rule => rule.isActive)
        .flatMap(rule => {
          let dates = [];
          let ruleDate = new Date(rule.nextExpectedDate + 'T00:00:00'); // Adjust for timezone

          // Generate all potential dates within the month based on the frequency
          while (ruleDate.getMonth() === currentMonth && ruleDate.getFullYear() === currentYear) {
            dates.push(new Date(ruleDate));
            
            // Increment the date based on the frequency
            switch (rule.frequency) {
              case 'Weekly':
                ruleDate.setDate(ruleDate.getDate() + 7);
                break;
              case 'Every 2 weeks':
              case 'Every two weeks':
                ruleDate.setDate(ruleDate.getDate() + 14);
                break;
              case 'Monthly':
                ruleDate.setMonth(ruleDate.getMonth() + 1);
                break;
              case 'Every 2 months':
                ruleDate.setMonth(ruleDate.getMonth() + 2);
                break;
              case 'Every 3 months':
                ruleDate.setMonth(ruleDate.getMonth() + 3);
                break;
              case 'Every 6 months':
                ruleDate.setMonth(ruleDate.getMonth() + 6);
                break;
              case 'Annually':
                ruleDate.setFullYear(ruleDate.getFullYear() + 1);
                break;
              default:
                ruleDate = new Date(currentYear + 1, 0, 1); // Exit loop for unknown frequencies
            }
          }

          // Filter dates to current month and map to transaction objects
          return dates.filter(date => date.getMonth() === currentMonth && date.getFullYear() === currentYear)
            .map(date => {
              const isCompleted = rule.transactions.some(t => t.date === date.toISOString().split('T')[0]);
              const isPastDue = !isCompleted && date < today;
              const isUpcoming = date >= today;
              return {
                name: rule.name,
                amount: rule.expectedAmount,
                date: date.toISOString().split('T')[0], // Convert to YYYY-MM-DD format
                isCompleted: isCompleted,
                isPastDue: isPastDue,
                isUpcoming: isUpcoming
              };
            });
        });

      // Include completed transactions from the rule's history
      const completedTransactions = this.recurringIncome
        .filter(rule => rule.isActive)
        .flatMap(rule => {
          return rule.transactions
            .filter(transaction => {
              const transactionDate = new Date(transaction.date + 'T00:00:00');
              return (
                transactionDate.getMonth() === currentMonth &&
                transactionDate.getFullYear() === currentYear &&
                transactionDate <= today
              );
            })
            .map(transaction => {
              return {
                name: rule.name,
                amount: transaction.amount,
                date: transaction.date,
                isCompleted: true,
                isPastDue: false,
                isUpcoming: false
              };
            });
        });

      // Combine filtered data and completed transactions, then sort by date
      return [...filteredData, ...completedTransactions].sort((a, b) => new Date(a.date) - new Date(b.date));
    }


  },
  mounted() {
    const currentUser = auth.currentUser;
    if (currentUser) {
        this.user = currentUser;
    }
    document.addEventListener('click', this.outsideClick);
    this.fetchCategories();
    this.checkNewTransactionsForrecurringIncome();
    this.$store.watch(
        (state) => state.recurringIncome,
        (newValue) => {
            this.recurringIncome = newValue;
        },
        { immediate: true }
    );
    this.$store.dispatch('fetchRecurringIncome'); // Ensure it is only here
},
  beforeDestroy() {
    document.removeEventListener('click', this.outsideClick);
  },
  methods: {
    async fetchCategories() {
      const categoriesRef = collection(db, `users/${auth.currentUser.uid}/categories`);
      const snapshot = await getDocs(categoriesRef);
      snapshot.forEach(doc => {
        // Assuming the category ID is the key to access its group
        this.categories[doc.id] = doc.data().group;
      });
    },
    toggleAllIncomeTransactions() {
      this.showAllIncomeTransactions = !this.showAllIncomeTransactions;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    },
    normalizeIncomeSource(sourceName) {
      // Convert to lowercase
      sourceName = sourceName.replace(/\*/g, ' ').toLowerCase();

      // Remove any numbers, extra spaces, and trailing commas
      sourceName = sourceName.replace(/[0-9]/g, '').replace(/,\s*$/, '').replace(/\s+/g, ' ').trim();

      // Define a list of words to exclude from the source name
      const excludeWords = ['inc', 'llc', 'group', 'the', 'and', 'offpglobal', 'ppd', 'id'];

      // Split the source name into words and remove trailing commas from each word
      let words = sourceName.split(' ').map(word => word.replace(/,$/, ''));

      // Filter out excluded words
      words = words.filter(word => !excludeWords.includes(word));

      // Take the first word as the normalized source name
      let normalizedWord = words.length > 0 ? words[0] : 'unknown';

      // Capitalize the first letter of the word
      normalizedWord = normalizedWord.charAt(0).toUpperCase() + normalizedWord.slice(1);

      // Return the normalized source name
      return normalizedWord;
    },
    toggleIncome(source) {
      this.expandedIncome = this.expandedIncome === source ? null : source;
    },
    async deleteCurrentIncome() {
      if (!this.$store.state.user) return;

      const { source, index } = this.incomeToEdit;
      if (source === undefined || index === undefined) {
        console.error("Invalid source or index for deleting income");
        return;
      }

      // Remove the income from the array
      this.additionalIncomes[source].splice(index, 1);
      if (this.additionalIncomes[source].length === 0) {
        delete this.additionalIncomes[source];  // Remove the source if it's empty
      }

      // Update Firestore
      const incomeRef = doc(db, `users/${this.$store.state.user.uid}/totals`, 'income');
      await setDoc(incomeRef, this.additionalIncomes);

      // Close the dialog and refresh the data
      this.editDialog = false;
      this.fetchAdditionalIncomes();
    },
    calculateIncomeForYear(amount, frequency, months) {
      let multiplier = 0;
      switch (frequency) {
        case 'Monthly':
          multiplier = months;
          break;
        case 'Weekly':
          multiplier = months * 4; // Approximation
          break;
        case 'Biweekly':
          multiplier = months * 2;
          break;
        case 'Semi-Annually':
          multiplier = months / 6;
          break;
        case 'Annually':
          multiplier = months / 12;
          break;
      }
      return amount * multiplier;
    },
    getMonthsCount(startDate, endDate, year) {
      let startMonth = startDate.getFullYear() === year ? startDate.getMonth() : 0;
      let endMonth = endDate.getFullYear() === year ? endDate.getMonth() : 11; // December
      return endMonth - startMonth + 1;
    },
    editIncome(source, income, index) {
      this.editedIncome = JSON.parse(JSON.stringify(income));
      this.editedIncome.originalSource = source;
      this.incomeToEdit = { source, index }; // Ensure index is set correctly
      this.editDialog = true;
    },



    resetIncomeForm() {
      this.additionalIncome = { amount: null, source: '', customSource: '', frequency: '', startDate: '', endDate: '' };
      this.incomeToEdit = {};
      this.editDialog = false;
      this.showIncomeFields = false;
      this.fetchAdditionalIncomes();
    },
      

    handleEditSourceChange() {
      if (this.editedIncome.source !== 'Custom') {
        this.editedIncome.customSource = '';
      }
    },

    closeEdit() {
      this.editDialog = false;
      
    },

    async saveEditedIncome() {
      if (!this.$store.state.user || this.incomeToEdit.source === undefined || this.incomeToEdit.index === undefined) {
        console.error("User not logged in or missing source/index for editing income");
        return;
      }

      console.log("Saving edited income", JSON.parse(JSON.stringify(this.editedIncome)));
      console.log("Current income to edit", JSON.parse(JSON.stringify(this.incomeToEdit)));

      const incomeRef = doc(db, `users/${this.$store.state.user.uid}/totals`, 'income');
      const { source, index } = this.incomeToEdit;

      // Ensure we have a valid index
      if (source !== undefined && index !== undefined) {
        const newSource = this.editedIncome.source === 'Custom' ? this.editedIncome.customSource : this.editedIncome.source;
        const originalSource = this.editedIncome.originalSource || source;

        if (originalSource !== newSource) {
          // If the source has changed
          if (!this.additionalIncomes[newSource]) {
            this.additionalIncomes[newSource] = [];
          }
          this.additionalIncomes[newSource].push(this.editedIncome);

          // Remove from the old source
          this.additionalIncomes[originalSource].splice(index, 1);
          if (this.additionalIncomes[originalSource].length === 0) {
            delete this.additionalIncomes[originalSource];
          }
        } else {
          // Update the income in the same source
          this.additionalIncomes[source][index] = this.editedIncome;
        }

        await setDoc(incomeRef, this.additionalIncomes);
      } else {
        console.error("Invalid index or source for editing income");
      }

      this.resetIncomeForm();
      this.fetchAdditionalIncomes();
      this.editDialog = false;
    },





    async deleteIncome(source) {
      if (!this.$store.state.user) return;

      const incomeRef = doc(db, `users/${this.$store.state.user.uid}/totals`, 'income');
      await updateDoc(incomeRef, { [source]: deleteField() });

      this.fetchAdditionalIncomes();
    },
    async fetchAdditionalIncomes() {
      if (!this.$store.state.user) return;

      const incomeRef = doc(db, `users/${this.$store.state.user.uid}/totals`, 'income');
      const docSnap = await getDoc(incomeRef);

      if (docSnap.exists()) {
        let data = docSnap.data();
        // Ensure all data entries are arrays
        for (const key in data) {
          if (!Array.isArray(data[key])) {
            data[key] = [data[key]]; // Convert non-array entries into arrays
          }
        }
        this.additionalIncomes = data;
      } else {
        console.log("No additional income found");
      }
    },
    handleSourceChange() {
    if (this.additionalIncome.source !== 'Custom') {
        this.additionalIncome.customSource = '';
      }
    },

    async saveIncome() {
      if (!this.$store.state.user) {
        alert("You must be logged in to save income.");
        return;
      }

      const incomeSource = this.additionalIncome.source === 'Custom' ? this.additionalIncome.customSource : this.additionalIncome.source;
      if (!this.additionalIncome.endDate) {
        this.additionalIncome.endDate = "ongoing";
      }
      const incomeRef = doc(db, `users/${this.$store.state.user.uid}/totals`, 'income');
      const docSnap = await getDoc(incomeRef);
      let incomes = docSnap.exists() ? docSnap.data() : {};

      if (this.incomeToEdit.source !== undefined) {
        // Editing an existing income
        let editingSource = this.incomeToEdit.originalSource || incomeSource;
        incomes[editingSource][this.incomeToEdit.index] = { ...this.additionalIncome, source: incomeSource };
        if (editingSource !== incomeSource) {
          // Move to a new source if changed
          incomes[incomeSource] = incomes[incomeSource] || [];
          incomes[incomeSource].push(incomes[editingSource][this.incomeToEdit.index]);
          incomes[editingSource].splice(this.incomeToEdit.index, 1);
          if (incomes[editingSource].length === 0) {
            delete incomes[editingSource];
          }
        }
      } else {
        // Adding a new income
        incomes[incomeSource] = incomes[incomeSource] || [];
        incomes[incomeSource].push(this.additionalIncome);
      }

      await setDoc(incomeRef, incomes);
      this.resetIncomeForm();
    },
    editRecurringAmount(rule) {
      this.editingRuleId = rule.docId;
      rule.editingAmount = true;
      rule.newAmount = rule.expectedAmount;
    },
    createRecurringTransaction() {
      this.createModalVisible = true;
    },
    async checkNewTransactionsForrecurringIncome() {
      console.log("Checking for recurring transactions");
      const allTransactions = this.$store.state.transactions;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      // Filter transactions for the current month and year
      const currentMonthTransactions = allTransactions.filter(txn => {
        const txnDate = parseISO(txn.date);
        return txnDate.getMonth() === currentMonth && txnDate.getFullYear() === currentYear && !txn.manuallyRemoved && txn.amount < 0;
      });

      if (!this.$store.state.recurringIncome || this.$store.state.recurringIncome.length === 0) {
        return;
      }

      const userId = this.user.uid;

      for (const rule of this.$store.state.recurringIncome) {
        console.log("Checking rule:", rule.name);
        const lastTransaction = rule.transactions[rule.transactions.length - 1];
        const lastTransactionDate = lastTransaction ? parseISO(lastTransaction.date) : parseISO(rule.nextExpectedDate);
        console.log("Last transaction date:", lastTransactionDate);

        const transactionsToUpdate = [];

        for (const txn of currentMonthTransactions) {
          console.log("Checking transaction:", txn.name);

          let matchingTransaction;

          if (txn.name.toLowerCase().includes('paypal') || txn.name.toLowerCase().includes('zelle')) {
            // For PayPal and Zelle transactions, check for a match using the normalized name
            const normalizedTxnName = this.normalizeName(txn.name);
            matchingTransaction = rule.transactions.find(t => this.normalizeName(t.name) === normalizedTxnName);
          } else {
            // For non-PayPal and non-Zelle transactions, use the normalized name as before
            const normalizedTxnName = this.normalizeName(txn.name);
            matchingTransaction = rule.transactions.find(t => this.normalizeName(t.name) === normalizedTxnName);
          }

          if (!matchingTransaction) {
            continue;
          }

          const dateDiff = differenceInCalendarDays(parseISO(txn.date), lastTransactionDate);
          console.log("Date difference:", dateDiff);

          let daysAllowed;
          switch (rule.frequency) {
            case "Weekly":
              daysAllowed = [6, 7, 8];
              break;
            case "Every 2 weeks":
              daysAllowed = [13, 14, 15];
              break;
            case "Monthly":
              daysAllowed = [26, 27, 28, 29, 30, 31, 32, 33];
              break;
            case "Every 2 months":
              daysAllowed = [55, 56, 57, 58, 59, 60, 61, 62, 63];
              break;
            case "Every 3 months":
              daysAllowed = [86, 87, 88, 89, 90, 91, 92, 93, 94];
              break;
            case "Every 6 months":
              daysAllowed = [174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186];
              break;
            case "Annually":
              daysAllowed = [350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380];
              break;
          }

          if (daysAllowed.includes(dateDiff)) {
            console.log("Matching transaction found");

            // Add the transaction to the rule's transactions array
            rule.transactions.push(txn);

            // Update the rule's properties (total, min/max amount, etc.)
            rule.total += txn.amount;
            rule.minAmount = Math.min(rule.minAmount, txn.amount);
            rule.maxAmount = Math.max(rule.maxAmount, txn.amount);
            rule.numberOfTransactions = rule.transactions.length;

            const txnYear = parseISO(txn.date).getFullYear().toString();
            if (rule[txnYear]) {
              rule[txnYear] += txn.amount;
            } else {
              rule[txnYear] = txn.amount;
            }
            
            let nextDate = parseISO(rule.nextExpectedDate);
            switch (rule.frequency) {
              case "Weekly":
                nextDate = addDays(nextDate, 7);
                break;
              case "Every 2 weeks":
                nextDate = addDays(nextDate, 14);
                break;
              case "Monthly":
                nextDate = addMonths(nextDate, 1);
                break;
              case "Every 2 months":
                nextDate = addMonths(nextDate, 2);
                break;
              case "Every 3 months":
                nextDate = addMonths(nextDate, 3);
                break;
              case "Every 6 months":
                nextDate = addMonths(nextDate, 6);
                break;
              case "Annually":
                nextDate = addYears(nextDate, 1);
                break;
            }
            rule.nextExpectedDate = format(nextDate, "yyyy-MM-dd");
            console.log("Updated nextExpectedDate:", rule.nextExpectedDate);


            // Update the rule in Firestore
            const ruleDoc = doc(db, `users/${userId}/recurringIncome`, rule.docId);
            await updateDoc(ruleDoc, {
              transactions: rule.transactions,
              total: rule.total,
              minAmount: rule.minAmount,
              maxAmount: rule.maxAmount,
              numberOfTransactions: rule.numberOfTransactions,
              [txnYear]: rule[txnYear],
              nextExpectedDate: format(nextDate, "yyyy-MM-dd") 
            }); 
            console.log("Updated Firestore document with nextExpectedDate:", format(nextDate, "yyyy-MM-dd"));


            // Add the transaction to the transactionsToUpdate array
            transactionsToUpdate.push(txn);
          } else {
            // console.log("Transaction does not match the rule");
          }
        }

        // Update the recurringId field of the matching transactions
        const transactionsCollection = collection(db, `users/${userId}/transactions`);
        for (let txn of transactionsToUpdate) {
          const txnDoc = doc(transactionsCollection, txn.id);
          await updateDoc(txnDoc, {
            recurringName: rule.name,
            recurringId: rule.docId
          });
        }
      }
    },
    async saveRecurringTransaction() {
      try {
        const userId = this.user.uid;
        const recurringIncomeCollection = collection(db, `users/${userId}/recurringIncome`);
        
        const newRule = {
          name: this.newRecurringTransaction.name,
          expectedAmount: this.newRecurringTransaction.expectedAmount,
          frequency: this.newRecurringTransaction.frequency,
          nextExpectedDate: format(new Date(this.newRecurringTransaction.nextExpectedDate), 'yyyy-MM-dd'), // Format the date directly
          isActive: true,
          transactions: [],
        };
        
        const docRef = await addDoc(recurringIncomeCollection, newRule);
        newRule.docId = docRef.id;
        
        this.recurringIncome.push(newRule);
        this.createModalVisible = false;
        this.resetNewRecurringTransaction();
      } catch (error) {
        console.error('Error saving recurring transaction:', error);
      }
    },
    resetNewRecurringTransaction() {
      this.newRecurringTransaction = {
        name: '',
        expectedAmount: null,
        frequency: '',
        nextExpectedDate: '',
      };
    },
    
    async saveRecurringAmount(rule) {
      if (rule.newAmount !== rule.expectedAmount) {
        try {
          const user = auth.currentUser;
          if (user) {
            const ruleRef = doc(db, `users/${user.uid}/recurringIncome`, rule.docId);
            await updateDoc(ruleRef, {
              expectedAmount: rule.newAmount,
            });
            
            rule.expectedAmount = rule.newAmount;
          }
        } catch (error) {
          console.error('Error updating recurring rule amount:', error);
        }
      }
      rule.editingAmount = false;
      this.editingRuleId = null;
      // Dispatch an action to update the rule in the Vuex store
      this.$store.dispatch('updateRecurringIncome', {
        ...rule,
        expectedAmount: rule.newAmount,
      });
    },
    getLocalDate(dateInput) {
      const date = (dateInput instanceof Date) ? dateInput : new Date(dateInput);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(date.toLocaleString('en-US', { timeZone }));
    },
    
    async updateIsActive(rule) {
      // Update the local state immediately for responsive UI
      rule.isActive = !rule.isActive;
      
      // Update the Firestore document
      const userId = this.user.uid;
      const ruleDocRef = doc(db, `users/${userId}/recurringIncome`, rule.docId);
      try {
          await updateDoc(ruleDocRef, {
              isActive: rule.isActive
          });
          console.log(`Rule ${rule.name} is now ${rule.isActive ? 'active' : 'inactive'}`);
          this.sortrecurringIncome();

          // Dispatch an action to update the rule in the Vuex store
          this.$store.dispatch('updateRecurringIncome', rule);

      } catch (error) {
          // If there's an error, revert the local change to reflect the accurate database state
          rule.isActive = !rule.isActive;
          console.error('Error updating isActive status:', error);
      }
    },
    handleEnter(rule) {
        this.justPressedEnter = true;
        this.renameRule(rule);
    },
    handleBlur(rule) {
        if (!this.justPressedEnter) {
            this.renameRule(rule);
        }
        this.justPressedEnter = false; // reset the flag
    },
    outsideClick(event) {

        if (this.editingRuleId) {
            // Get the first item from the ref array
            const inputElement = this.$refs.editInput[0];

            // Check if the click was outside the input
            if (inputElement && !inputElement.contains(event.target)) {
                this.editingRuleId = null;  // exit the editing mode
                this.editName = '';  // reset the name (or keep the last edited name as you wish)
            }
        }
    },  
    startEditing(rule) {
        console.log("Starting edit for rule:", rule.name);
        this.editingRuleId = rule.docId;
        this.editName = rule.name;
    },          
    async renameRule(rule) {
        console.log("New rule name:", this.editName, "Old rule name:", rule.name);

        // Guard against empty or whitespace-only names.
        if (!this.editName || !this.editName.trim()) {
            console.error("Attempt to set an empty rule name.");
            return;
        }

        if (this.editName === rule.name) {
            this.editingRuleId = null;
            return; // exit if name hasn't changed
        }

        const userId = this.user.uid;
        const ruleDoc = doc(db, `users/${userId}/recurringIncome`, rule.docId);

        try {
          await updateDoc(ruleDoc, { name: this.editName });
          this.$store.dispatch('updateRecurringIncome', { ...rule, name: this.editName });
          rule.name = this.editName;
          // Directly update the transactions listed in the transactions array of the rule
          const batch = writeBatch(db);

          // Assuming rule.transactions is an array of transaction objects each having a docId.
          rule.transactions.forEach(transaction => {
              const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
              batch.update(transactionDoc, { recurringName: this.editName });  // Update this line to use the updated 'recurringName'
          });
          await batch.commit();

          console.log(`Renamed rule and updated ${rule.transactions.length} associated transactions.`);
          // Reset the editing state
          this.editingRuleId = null;
          this.editName = '';
        } catch (error) {
            console.error("Error renaming rule and updating associated transactions:", error);
        }
    },
    async deleteRecurringIncome(rule) {
      const userConfirmation = window.confirm("Are you sure you want to delete this rule?");
      if (!userConfirmation) {
          return; // Exit the method if the user doesn't confirm.
      }
      try {
          const userId = this.user.uid;

          // Check if there are any transactions in the rule before updating them.
          if (rule.transactions && rule.transactions.length > 0) {
              // 1. Update all transactions within the rule to have "recurringName: Not Recurring" and remove the "recurringId".
              const batch = writeBatch(db);
              for (let transaction of rule.transactions) {
                  const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
                  batch.update(transactionDoc, {
                      recurringName: "Not Recurring",
                      recurringId: deleteField()  // Remove the recurringId field
                  });
              }
              await batch.commit();
              console.log(`All transactions for rule ${rule.name} updated to "Not Recurring" and "recurringId" deleted.`);
          }

          // 2. Delete the rule from /users/userid/recurringIncome/recurringruleid.
          const ruleDoc = doc(db, `users/${userId}/recurringIncome`, rule.docId);
          await deleteDoc(ruleDoc);
          console.log(`Rule ${rule.name} has been deleted.`);

          // 3. Remove the rule from the local list.
          this.recurringIncome = this.recurringIncome.filter(r => r.docId !== rule.docId);

          this.$store.dispatch('removeRecurringIncome', rule.docId);


      } catch (error) {
          console.error("Error deleting rule:", error);
      }
    },
    async removeTransactionFromRule(rule, transaction) {
        try {
            // 1. Remove the transaction from the rule's transactions array.
            const updatedTransactions = rule.transactions.filter(t => t.id !== transaction.id);
            rule.transactions = updatedTransactions;

            // 2. Update the rule in your database.
            const userId = this.user.uid;
            const ruleDoc = doc(db, `users/${userId}/recurringIncome`, rule.docId);
            await updateDoc(ruleDoc, {
                transactions: updatedTransactions
            });

            // 3. Update the transaction's "recurringName" field to "Not Recurring" and delete the "recurringId" field.
            const transactionDoc = doc(db, `users/${userId}/transactions`, transaction.id);
            await updateDoc(transactionDoc, {
              recurringName: "Not Recurring",
              recurringId: deleteField(),
              manuallyRemoved: true // Add this line
            });

            // Additional Updates to rule based on removed transaction:
            // 1. Update the total amount
            rule.total -= transaction.amount; 

            // 2. Adjust the minimum and maximum amounts
            if (rule.transactions.length) {
                const amounts = rule.transactions.map(txn => txn.amount);
                rule.minAmount = Math.min(...amounts);
                rule.maxAmount = Math.max(...amounts);
            }

            // 3. Update the number of transactions
            rule.numberOfTransactions = rule.transactions.length;

            // 4. Update yearly totals
            const transactionYear = parseISO(transaction.date).getFullYear().toString();
            if(rule[transactionYear]) {
                rule[transactionYear] -= transaction.amount; 
            }

            console.log(`Transaction ${transaction.id} removed from rule ${rule.name} and updated in the database.`);
        } catch (error) {
            console.error("Error removing transaction from rule:", error);
        }
    },
    daysUntilDue(dateString, isActive) {
        if (!isActive) {
            return "Inactive";
        }
        
        const today = this.getLocalDate(new Date());
        const dueDate = this.getLocalDate(dateString);
        const daysDifference = differenceInCalendarDays(dueDate, today);

        if (daysDifference === 1) {
            return "Due tomorrow";
        } else if (daysDifference === 0) {
            return "Due today";
        } else if (daysDifference < 0) {
            const pastDueDays = Math.abs(daysDifference);
            return `Past Due: expected ${pastDueDays} ${pastDueDays === 1 ? 'day' : 'days'} ago`;
        } else {
            return `Due in ${daysDifference} days`;
        }
    },
    toggleRecurringHistory(rule) {
        // If a rule is being edited, don't toggle
        if (this.editingRuleId) {
            return;
        }
        if (this.recurringBeingTracked === rule.docId) {
            this.recurringBeingTracked = null;
        } else {
            this.recurringBeingTracked = rule.docId;
        }
    },
    toggleNewRecurringHistory(item) {
      if (this.newRecurringBeingTracked === item.id) {
        this.newRecurringBeingTracked = null;
      } else {
        this.newRecurringBeingTracked = item.id;
      }
    },
    sortrecurringIncome() {
        this.recurringIncome.sort((a, b) => {
            // First, prioritize by isActive status
            if (a.isActive && !b.isActive) return -1;
            if (!a.isActive && b.isActive) return 1;

            // If both rules have the same isActive status, then sort by nextExpectedDate
            const aDate = parseISO(a.nextExpectedDate);
            const bDate = parseISO(b.nextExpectedDate);
            return aDate - bDate;
        });
    },
    async fetchRecurringIncome() {
      const userId = this.user.uid;
      const incomeCollection = collection(db, `users/${userId}/recurringIncome`);

      try {
        const querySnapshot = await getDocs(incomeCollection);
        this.recurringIncome = querySnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));

        // Calculate remaining recurring deposits and total amount
        this.remainingRecurringCharges = this.recurringIncome.length;
        this.totalRecurringAmount = this.recurringIncome.reduce(
          (total, rule) => total + rule.expectedAmount,
          0
        );
        
        this.sortrecurringIncome();


      } catch (error) {
        console.error("Error fetching recurring income:", error);
      }
    },
    normalizeName(name) {
      const nameparts = name.trim().toLowerCase().replace(/['",.]+/g, '').split(/\s+/);
      if (nameparts.includes('paypal')) {
        return nameparts.slice(0, 1).join(' ');
      } else if (nameparts.includes('zelle')) {
        // For Zelle, keep everything up to and including the sender's name
        const zelleIndex = nameparts.indexOf('zelle');
        const fromIndex = nameparts.indexOf('from');
        if (fromIndex !== -1 && fromIndex < nameparts.length - 1) {
          return nameparts.slice(zelleIndex, fromIndex + 3).join(' '); // Include 'zelle', 'payment', 'from', and two words after
        }
      }
      return nameparts[0];
    },
    formatCurrency(amount) {
      if (isNaN(amount) || amount === undefined || amount === null) {
        return "$0.00";
      }
      // Format the number with the US locale to get commas for thousands
      return `$${parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },

    formatDate(dateString) {
      const date = parseISO(dateString);
      const currentYear = new Date().getFullYear();
      if (date.getFullYear() === currentYear) {
        return format(date, "MMMM do");
      } else {
        return format(date, "MMMM do, yyyy");
      }
    },
    formatDate2(dateString) {
      const date = parseISO(dateString);
      const now = new Date();
      const currentMonth = now.toLocaleString('default', { month: 'short' });
      const day = date.getDate();

      let formattedDate = `${currentMonth} ${day}`;

      if (date.getFullYear() !== now.getFullYear()) {
        // Include the year if it's not the current year
        formattedDate += `, ${date.getFullYear()}`;
      }

      const suffix = this.getDateSuffix(day);
      return {
        main: formattedDate,
        suffix: suffix
      };
    },
    getDateSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // 1. Fetch all transactions
    async findRecurringTransactions() {
      this.isLoading = true;

      if (this.showingResults) {
        // If results are currently being shown, hide them and reset the button label
        this.showingResults = false;
        this.isLoading = false;
        this.buttonLabel = "Find Recurring Income";
        return; // End the function here
      }

      // If results aren't shown, show them and change the button label
      this.showingResults = true;
      this.buttonLabel = "Done";

      // If we've previously fetched transactions, we don't need to do it again.
      if (this.matchedTransactions.length > 0) {
        this.isLoading = false;
        return;
      }

      // Otherwise, start fetching and processing transactions as you have done.
      this.matchedTransactions = [];

      // 1. Retrieve transactions from Vuex store
      const allTransactions = this.$store.state.transactions;
      console.log('1');

      // 2. Filter transactions that don't have a recurring value and are not part of an existing recurring rule
      const transactions = allTransactions.filter(txn =>
        (txn.recurringId === undefined || txn.recurringId === "" || txn.recurringId === false) &&
        txn.amount < 0 &&
        !this.recurringIncome.some(rule => rule.transactions.some(t => t.id === txn.id))
      );

      // 3. Group by name
      const groupedByName = transactions.reduce((acc, txn) => {
        const normalizedName = this.normalizeName(txn.name);
        if (!acc[normalizedName]) {
          acc[normalizedName] = [];
        }
        acc[normalizedName].push(txn);
        return acc;
      }, {});

      function getYearFromTransaction(txn) {
        return parseISO(txn.date).getFullYear();
      }

      // 4. Identify possible recurring frequencies
      for (const [name, txns] of Object.entries(groupedByName)) {
        txns.sort((a, b) => parseISO(a.date) - parseISO(b.date));

        if (name.toLowerCase().includes('paypal') || name.toLowerCase().includes('zelle')) {
          // If the name includes "paypal" or "zelle", group transactions by normalized name and amount
          const groupedByNameAndAmount = txns.reduce((acc, txn) => {
            const normalizedName = this.normalizeName(txn.name);
            const key = `${normalizedName}-${txn.amount}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(txn);
            return acc;
          }, {});

          // Process each group of transactions with the same normalized name and amount
          for (const [key, groupedTxns] of Object.entries(groupedByNameAndAmount)) {
            processTransactionGroup(groupedTxns, this.matchedTransactions);
          }
        } else {
          // For non-paypal and non-zelle transactions, process them as before
          processTransactionGroup(txns, this.matchedTransactions);
        }
      }

      function processTransactionGroup(txns, matchedTransactions) {
        for (let i = 0; i < txns.length - 1; i++) {
          const dateDiff = differenceInCalendarDays(
            parseISO(txns[i + 1].date),
            parseISO(txns[i].date)
          );

          let matchedFrequency = null;
          let daysToAdd = null;

          for (let i = txns.length - 2; i >= 0; i--) {
            const dateDiff = differenceInCalendarDays(
              parseISO(txns[i + 1].date),
              parseISO(txns[i].date)
            );

            if (dateDiff >= 6 && dateDiff <= 8) {
              matchedFrequency = "Weekly";
              daysToAdd = 7;
              break;
            } else if (dateDiff >= 13 && dateDiff <= 15) {
              matchedFrequency = "Every 2 weeks";
              daysToAdd = 14;
              break;
            } else if (dateDiff >= 26 && dateDiff <= 33) {
              matchedFrequency = "Monthly";
              daysToAdd = 30;
              break;
            } else if (dateDiff >= 55 && dateDiff <= 63) {
              matchedFrequency = "Every 2 months";
              daysToAdd = 60;
              break;
            } else if (dateDiff >= 86 && dateDiff <= 94) {
              matchedFrequency = "Every 3 months";
              daysToAdd = 90;
              break;
            } else if (dateDiff >= 174 && dateDiff <= 186) {
              matchedFrequency = "Every 6 months";
              daysToAdd = 180;
              break;
            } else if (dateDiff >= 350 && dateDiff <= 380) {
              matchedFrequency = "Annually";
              daysToAdd = 365;
              break;
            }
          }

          if (matchedFrequency) {
            const today = new Date();
            const lastTransactionDate = parseISO(txns[txns.length - 1].date);
            const daysSinceLastTransaction = differenceInCalendarDays(today, lastTransactionDate);

            // Determine the maximum allowed days since the last transaction based on frequency
            let maxDaysAllowed;
            switch (matchedFrequency) {
              case "Weekly":
                maxDaysAllowed = 7 * 3;
                break;
              case "Every 2 weeks":
                maxDaysAllowed = 14 * 3;
                break;
              case "Monthly":
                maxDaysAllowed = 30 * 2;
                break;
              case "Every 2 months":
                maxDaysAllowed = 60 * 3;
                break;
              case "Every 3 months":
                maxDaysAllowed = 90 * 2;
                break;
              case "Every 6 months":
                maxDaysAllowed = 180 * 2;
                break;
              case "Annually":
                maxDaysAllowed = 365 * 2;
                break;
              default:
                maxDaysAllowed = Number.MAX_VALUE; // Default case to handle unexpected frequencies
            }

            // Check if the transaction is still active
            if (daysSinceLastTransaction > maxDaysAllowed) {
              continue; // Skip to the next iteration if inactive
            }
            const nextExpectedDate = addDays(lastTransactionDate, daysToAdd);
            const amounts = txns.map(txn => txn.amount);
            const expectedAmount = (amounts.reduce((acc, amt) => acc + amt, 0)) / txns.length;
            const minAmount = Math.min(...amounts);
            const maxAmount = Math.max(...amounts);
            const itemId = `${txns[0].name}-${i}`;

            const amountsByYear = txns.reduce((acc, txn) => {
              const year = getYearFromTransaction(txn);
              if (!acc[year]) {
                acc[year] = 0;
              }
              acc[year] += txn.amount;
              acc.total += txn.amount; // compute the overall total
              return acc;
            }, { total: 0 });

            matchedTransactions.push({
              id: itemId, // Assign a unique id
              name: txns[0].name,
              frequency: matchedFrequency,
              transactions: txns,
              ...amountsByYear,
              minAmount: minAmount,
              maxAmount: maxAmount,
              numberOfTransactions: txns.length,
              nextExpectedDate: format(nextExpectedDate, "yyyy-MM-dd"),
              expectedAmount: expectedAmount
            });
            break;
          }
        }
      }

      const frequencyOrder = {
        'Weekly': 1,
        'Every 2 weeks': 2,
        'Monthly': 3,
        'Every 2 months': 4,
        'Every 3 months': 5,
        'Every 6 months': 6,
        'Annually': 7
      };
      // Sort the matchedTransactions array based on frequency order
      this.matchedTransactions.sort((a, b) => {
        return frequencyOrder[a.frequency] - frequencyOrder[b.frequency];
      });

      if (this.matchedTransactions.length === 0 && this.showingResults) {
        this.showNorecurringIncomeMessage = true;
        this.buttonLabel = "Find Recurring Income";

        setTimeout(() => {
          this.showNorecurringIncomeMessage = false;
        }, 3000); // 3 seconds
      }
      this.isLoading = false; // set loading state back to false when done
    },
    async addRecurringIncome(ruleData) {
      this.addingRule = ruleData.name;  // Set the rule name
      const userId = this.user.uid;
      const recurringIncomeCollection = collection(db, `users/${userId}/recurringIncome`);
      const transactionsToUpdate = ruleData.transactions;  // Keep the transactions to update them later
      // Capitalize the first letter of the name before saving
      ruleData.name = this.capitalizeFirstLetter(ruleData.name);

      ruleData.isActive = true;  // Set the rule to be active by default

      ruleData.customId = ruleData.id;  // set the custom 'itemId' to a field named 'customId'
      delete ruleData.id;  // Remove the id property so it doesn't interfere with Firestore's generated ID

      // Add rule to Firestore
      try {
          const docRef = await addDoc(recurringIncomeCollection, ruleData);
          console.log("Recurring rule added with ID:", docRef.id);
          ruleData.docId = docRef.id;  // Store the Firestore document ID in the rule object

          // After adding the rule, update each transaction's "recurringName" and "recurringId" fields
          const transactionsCollection = collection(db, `users/${userId}/transactions`);
          for (let txn of transactionsToUpdate) {
              const txnDoc = doc(transactionsCollection, txn.id);
              await updateDoc(txnDoc, { 
                  recurringName: ruleData.name,
                  recurringId: docRef.id
              });
          }
          console.log(`Updated ${transactionsToUpdate.length} transactions with the recurring rule name and id.`);

          this.$store.dispatch('fetchRecurringIncome');
          
          // Remove only the specific rule from the matchedTransactions array
          if (ruleData.name.toLowerCase().includes('paypal') || ruleData.name.toLowerCase().includes('zelle')) {
            this.matchedTransactions = this.matchedTransactions.filter(item => 
              item.name !== ruleData.name || item.expectedAmount !== ruleData.expectedAmount
            );
          } else {
            this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);
          }

          // Call the findRecurringTransactions method to refresh the displayed recurring income
          // await this.fetchRecurringIncome();

          // After rule has been added and matchedTransaction has been updated
          this.addingRule = null;  // Reset the addingRule property
          this.showSuccessMessage = true;  // Show success message

          // Hide the success message after a short delay
          setTimeout(() => {
              this.showSuccessMessage = false;
          }, 3000);  // 3 seconds delay

          
      } catch (error) {
          console.error("Error processing recurring rule:", error);
      }
      // Remove the rule from the matchedTransactions array
        this.matchedTransactions = this.matchedTransactions.filter(item => item.name !== ruleData.name);

      // Check if there are no more matched transactions
      if (this.matchedTransactions.length === 0) {
        this.buttonLabel = "Find Recurring Income";
        this.showingResults = false; // Also consider hiding the results if no transactions are left
      }
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}

.income-table {
  width: 75vw;
  margin: 0 auto;
}

.income-source-name:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  transition: .2s ease-in-out;
}

.income-source-name {
  padding: 10px;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid rgb(204, 204, 204);
  
}

.total-income {
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  width: 35%;
  margin: 40px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.additional-income-sources {
  background-color: white;
  border-radius: 15px;
  padding: 20px !important;
  flex: 1;
  margin-bottom: 30px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.additional-income-totals {
  background-color: white;
  border-radius: 15px;
  padding: 0px !important;
  flex: 1;
  margin-bottom: 30px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.income-second-row {
  justify-content: space-around; /* This will distribute space around the items evenly */
  align-items: flex-start; /* This aligns the items at the start of the cross axis */
}

.income-row:hover {
  background-color: #f5f5f5; /* Light grey background on hover */
}

.additional-income-headers {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0px;
  align-items: center;
}

.transaction-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.transaction-item {
  grid-template-columns: repeat(4, 1fr); /* Divide into four columns */
  gap: 10px; /* Adjust the space between columns */
}

.transaction-item span {
  display: flex;
}


.date, .name, .amount, .category {
  text-align: left; /* Align text to the left */
}

.total-row {
  display: grid;
  grid-template-columns: 1fr; /* Take up full width */
  margin-top: 10px; /* Space between rows */
  text-align: center;
  margin-bottom: 20px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes dropIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}


.income {
    animation: fadeIn 1s ease-in forwards;
}

.income-date {
  text-align: left;
  min-width: 200px;
}


.add-income-container {
  position: relative;
  width: 100%;
  display: inline-block;
}

.info-icon {
  margin-left: 5px;
  cursor: pointer;
  color: #969696;
}

.tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  width: 400px;
  z-index: 1;
  opacity: 0.8;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 5px);
}

.source-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.source-totals {
  text-align: right;
  white-space: nowrap;
}


.tracked-income-header {
  position: relative;
  display: inline-block;
}

.info-icon {
  margin-left: 5px;
  cursor: pointer;
  color: #969696;
}

.tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  width: 500px;
  z-index: 1;
  opacity: 0.8;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% - 105px);
  display: none;
}



.info-icon:hover + .tooltip {
  display: block;
}

.div8 {
  z-index: 1;
}

.div6-income { 
  grid-area: 1 / 2 / 2 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll; 
  text-align: center;
  background-color: #ffffff;
  margin: 5% 5% 2% 0%;
  min-height: 30vh;
  height: max-content;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}

.innerdiv4 { 
  grid-area: unset;
  display: inline-table;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any content that exceeds the container's bounds */
  max-height: 30vh; /* Set the maximum height */
}

.div9 {
  background-color: unset;
}

.add-custom-button {
  margin: 10px;
  justify-content: space-around;
}

#top-right-sidebar {
  margin-top: 40px;
}

.tracked-income-header {
  cursor: pointer;
}

.tracked-income-header h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-income-label {
  width: 100%; 
  overflow: hidden;
}

.find-recurring-button2 {
  margin-bottom: 20px;
}

sup {
  top: 0.5em;
}

@media screen and (max-width: 768px) {
  .parent {
    display: unset !important;
  }

  sup {
    top: unset;
  }

  .bar-chart-container{
    display: none;
  }

  .recurring-summary {
    font-size: 16px;
  }

  .recurring-row {
    border-radius: 8px !important;
  }

  #top-right-sidebar {
    padding: 20px;
    margin-top: 20px;
  }

  .transaction-item {
    display: grid;
    padding: 2px 3%;
    gap: 0;
    grid-template-columns: 14px 2fr .5fr .5fr;
  }

  .transaction-item .status-icon {
    width: 14px;
    overflow: hidden;
  }

  .transaction-item .transaction-name,
  .transaction-item .transaction-date,
  .transaction-item .transaction-amount {
    width: 100%;
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .date-suffix {
    font-size: xx-small;
  }

  sup {
    line-height: 2;
  }

  .transaction-name {
    margin-left: 4px;
  }

  .current-month-recurring {
    padding: 0px;
    font-size: 12px;
  }
  li .status-icon {
    max-width: 14px;
  }
  .current-recurrings {
    font-size: small;
  }
  
  td {
    padding: 2px;
    font-size: .6em;
  }
  
  .recurring-history tbody > tr > td:nth-child(3) {
      text-align: left;
  }
  .recurring-table {
    margin: 0px;
  }
  .annual-summary {
    font-size: 12px;
  }
  .additional-income-sources, .additional-income-totals {
    padding: 0;
    border-radius: 8px;
  }
  .rule-summary {
    font-size: 12px;
  }

  .income-transactions-title {
    font-size: 12px;
  }
  .income-transactions-icon {
    font-size: 10px;
  }

  .div6-income {
    box-shadow: unset;
    margin: unset;
  }

  .info-icon {
    display: none;
  }

  .source-row {
    display: inline-table;
    margin: 10px 0px ;
  }
  .transaction-list {
    display: none;
  }
  .additional-income-headers {
    font-weight: 300;
    font-size: 12px;
  }
  .total-income-label {
    margin-top: 40px;
  }

  .transaction-item {
    grid-template-columns: 14px 1.5fr .5fr .5fr;
  }
  .transaction-date {
    overflow: unset !important;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 20px;
    transition: .4s;
  }

  .toggle-switch {
    width: 32px;
    height: 17px;
    margin-left: -50px;
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }
  .total-for-month, .total-for-month-line {
    margin-right: 10px !important;
  }

  .current-recurrings {
    min-height: 60px;
  }

  .income-source-name {
    padding: 0px !important;
    overflow: scroll;
  }
}
</style>
