<template>
  <div class="labels-manager-parent">
    <!-- <h2 class="settings-title">Label Manager</h2> -->
    <v-btn class="manage-labels-button" @click="expandContent">
      {{ isExpanded ? 'Hide Labels' : 'Labels' }}
    </v-btn>
    <div v-if="isExpanded" class="info-text-labels">
      ℹ️ Assigning the <span class="label-name">ignore</span> label to a transaction will exclude it from all spending and budget calculations, as well as charts. <br> <br> Transactions tagged with the <span class="label-name">review</span> label will be distinctly marked with a red border on the spending page table. These labels are system defaults and are immutable.
    </div>
    <transition name="slide">
      <div v-if="isExpanded" class="label-manager"> <!-- add back to div if you want to hide this behind the button again-->
        <!-- Add label form -->
        <form @submit.prevent="addLabel" class="add-label">
          <input class="newlabelinput" v-model="newLabel" type="text" placeholder="New Label" required>
          <br>
          <v-btn class="add-labels-button" type="submit">Add Label</v-btn>
        </form>

        <!-- Display existing labels -->
        <ul>
          <li class="inline-label-li" v-for="label in labels" :key="label.id">
            <template v-if="label.editing">
              <input
                v-model="label.name"
                type="text"
                required
                @keyup.enter="saveLabel(label)"
              />
              <button class="labelbuttons-save" @click="saveLabel(label)">Save</button>
            </template>
            <template v-else>
              <span class="labelname">{{ label.name }}</span>
              <button class="labelbuttons" v-if="label.name !== 'ignore' && label.name !== 'review'" @click="toggleEditing(label)">✏️</button>
              <button class="labelbuttons" v-if="label.name !== 'ignore' && label.name !== 'review'" @click="deleteLabel(label.id)">❌</button>
            </template>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { collection, addDoc, doc, deleteDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  name: 'LabelManager',
  data() {
    return {
      newLabel: '',
      labels: [],
      isExpanded: false, // Added data property
    };
  },
  mounted() {
    this.retrieveLabels();
  },
  methods: {
    
    async retrieveLabels() {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const querySnapshot = await getDocs(labelsCollection);
          this.labels = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }));
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving labels:', error);
      }
    },
    async addLabel() {
      try {
        const user = auth.currentUser;
        if (user) {
          const label = {
            name: this.newLabel,
          };
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const docRef = await addDoc(labelsCollection, label);
          console.log('Label added with ID:', docRef.id);
          this.newLabel = '';
          this.retrieveLabels();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error adding label:', error);
      }
    },
    async editLabel(label) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const labelDocRef = doc(labelsCollection, label.id);
          const newName = prompt('Enter the new label name', label.name);

          if (newName) {
            await updateDoc(labelDocRef, { name: newName });
            console.log('Label edited:', label.id);
            this.retrieveLabels();
          }
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error editing label:', error);
      }
    },
    async deleteLabel(labelId) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          await deleteDoc(doc(labelsCollection, labelId));
          console.log('Label deleted:', labelId);
          this.retrieveLabels();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error deleting label:', error);
      }
    },
    toggleEditing(label) {
      label.editing = !label.editing;
    },
    async saveLabel(label) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const labelDocRef = doc(labelsCollection, label.id);

          await updateDoc(labelDocRef, { name: label.name });
          console.log('Label saved:', label.id);

          this.toggleEditing(label);
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error saving label:', error);
      }
    },
    expandContent() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  display: inline-grid;
}

li {
  display: inline-flex;
  height: 20px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

input{
  width: auto;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 14px;
  color: gray;
}

.labelbuttons {
  margin-left: 10px;
  padding: 5px;
  background-color: unset;
  opacity: 0;
}

.labelbuttons:hover {
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
  padding: 5px;
}

.inline-label-li:hover .labelbuttons {
  opacity: 1;
}

.inline-label-li {
  width: -webkit-fill-available;
  height: 36px;
}

.labelname {
  width: 140px;
  text-align: left;
}


.slide-enter-active,
.slide-leave-active {
  transition: height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  height: 0;
  overflow: hidden;
}

.manage-labels-button {
  width: 300px;
  height: 60px;
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 10px;
}

.settings-title {
  text-align: center;
}

.newlabelinput {
  display: inline-flex;
  height: 36px;
  width: 190px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.labelbuttons-save {
  margin-left: -20px;
  color: black;
  padding: 5px;
  background-color: unset;
}

.labelbuttons-save:hover {
  background: #e9e9e9;
}

.info-text-labels {
  background: #f9f9f9;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 40px;
  font-size: 0.9em;
  width: 500px;
}

.label-name {
  background-color: #e8e8e8;
  padding: 2px 4px;
  border-radius: 4px;
}

.label-manager {
  margin: 20px;
}

.labels-manager-parent {
  margin: 0 auto;
  width: fit-content;
}

.add-label {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 40px;
}

.add-labels-button {
  margin: 0 0 0 15px; 
  width: 190px; 
  box-shadow: 0px 2px 5px 0px rgb(29 29 29 / 2%);
}

@media screen and (max-width: 768px) {
  .labels-manager-parent {
    /* width: 200px; */
  }
  .manage-labels-button {
    width: 200px;
  }
  .info-text-labels {
    background: #f9f9f9;
    padding: 10px ;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    font-size: 0.9em;
    width: 90%;
  }
  .add-label {
    display: block;
    padding-bottom: 20px;
  }
  .add-labels-button {
    box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
    margin: 0px 0px 20px 0px;
  }
}
</style>
