<template>
  <div style="margin: 0 auto;">
    <v-btn class="manage-templates-button" @click="expandContent">
      {{ isExpanded ? 'Hide Templates' : 'Budget Templates' }}
    </v-btn>
    <transition name="slide">
      <div v-if="isExpanded" class="template-manager">
        <!-- Display this message if there are no budget templates -->
        <div v-if="budgetTemplates.length === 0" class="no-templates-message">
          No budget templates have been found. Templates can be created from the budget page by setting the desired budget for a month and saving it as a new template.
        </div>
        <!-- Otherwise, display the table for budget templates -->
        <div v-else>
          <table class="category-budget-table">
          <thead>
            <tr>
              <th>Category</th>
              <!-- Headers for each template name with delete button -->
              <th v-for="template in budgetTemplates" :key="template.id" class="template-header">
                <template v-if="template.editing">
                  <input
                    v-model="template.name"
                    type="text"
                    required
                    @blur="saveTemplate(template)"
                    @keyup.enter="saveTemplate(template)"
                  />
                </template>
                <template v-else>
                  <span @click="startEditing(template)">{{ template.name }}</span>
                  <button class="delete-button" @click="deleteTemplate(template.id)">Delete</button>
                </template>
              </th>
            </tr>
            <!-- Secondary header row for default templates -->
            <tr>
              <th>Default</th>
              <th v-for="template in budgetTemplates" :key="template.id">
                <input
                  type="checkbox"
                  :checked="template.isDefault"
                  @click="toggleDefault($event, template)"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop over each category -->
            <tr v-for="category in uniqueCategories" :key="category">
              <td>{{ category }}</td>
              <!-- Display amounts for each template -->
              <td v-for="template in budgetTemplates" :key="template.id">
                {{ getAmountForCategory(template, category) }}
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { collection, doc, deleteDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  name: 'BudgetTemplateManager',
  data() {
    return {
      budgetTemplates: [],
      isExpanded: false,
    };
  },
  mounted() {
    this.retrieveTemplates();
  },
  computed: {
    uniqueCategories() {
      const categories = new Set();
      this.budgetTemplates.forEach(template => {
        template.categoriesBudget.forEach(item => {
          categories.add(item.categoryName);
        });
      });
      return Array.from(categories);
    }
  },
  methods: {

    async toggleDefault(event, selectedTemplate) {
  event.preventDefault(); // Prevent the default checkbox behavior
  
  try {
    const user = auth.currentUser;
    if (user) {
      // Check if the selected template is not already the default
      if (!selectedTemplate.isDefault) {
        // Ask the user to confirm setting the new default
        const confirm = window.confirm("Are you sure you want to set this template as the new default?");
        if (confirm) {
          const templatesCollection = collection(db, `users/${user.uid}/budgetTemplates`);
          const querySnapshot = await getDocs(templatesCollection);
          
          // Reset the isDefault field for all templates
          for (const documentSnapshot of querySnapshot.docs) {
            const templateDocRef = doc(db, `users/${user.uid}/budgetTemplates/${documentSnapshot.id}`);
            await updateDoc(templateDocRef, { isDefault: false });
            console.log('Set isDefault to false for template:', documentSnapshot.id); // Added log
          }

          // Set the selected template as the default
          const selectedTemplateDocRef = doc(db, `users/${user.uid}/budgetTemplates/${selectedTemplate.id}`);
          await updateDoc(selectedTemplateDocRef, { isDefault: true });
          console.log('Set isDefault to true for template:', selectedTemplate.id); // Added log
          
          // Refresh the templates to reflect the updated default status
          this.retrieveTemplates();
        }
      }
    } else {
      console.log('No user is logged in.');
    }
  } catch (error) {
    console.error('Error toggling default status:', error);
  }
},


    startEditing(template) {
      template.editing = true;
      this.$nextTick(() => {
        // Focus the input field after it is rendered
        const input = this.$el.querySelector(`input[data-id="${template.id}"]`);
        input && input.focus();
      });
    },
    getAmountForCategory(template, category) {
      const item = template.categoriesBudget.find(item => item.categoryName === category);
      return item ? item.amount : '-';
    },
    async retrieveTemplates() {
      try {
        const user = auth.currentUser;
        if (user) {
          const templatesCollection = collection(db, `users/${user.uid}/budgetTemplates`);
          const querySnapshot = await getDocs(templatesCollection);
          this.budgetTemplates = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            categoriesBudget: doc.data().categoriesBudget,
            isDefault: doc.data().isDefault, // Make sure to fetch the isDefault field
            editing: false
          }));
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving budget templates:', error);
      }
    },
    async saveTemplate(template) {
      try {
        // Check if the user is logged in
        const user = auth.currentUser;
        if (user) {
          // Reference to the specific template document in the database
          const templateDocRef = doc(db, `users/${user.uid}/budgetTemplates/${template.id}`);

          // Update the template name in the database
          await updateDoc(templateDocRef, { name: template.name });

          // Log the result and reset the editing state
          console.log('Template name updated:', template.id);
          template.editing = false;

          // Optionally, you can refresh the list of templates if needed
          this.retrieveTemplates(); // Assuming you have a method to fetch templates
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error saving template:', error);
      }
    },
    async deleteTemplate(templateId) {
      try {
        const user = auth.currentUser;
        if (user) {
          const templateDocRef = doc(db, `users/${user.uid}/budgetTemplates/${templateId}`);
          await deleteDoc(templateDocRef);
          console.log('Budget Template deleted:', templateId);
          this.retrieveTemplates();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error deleting budget template:', error);
      }
    },
    toggleEditing(template) {
      template.editing = !template.editing;
    },

    cancelEditing(template) {
      // Revert any changes made to the template's name
      template.name = template.originalName;

      // Reset the editing state
      template.editing = false;

      // Optionally, refresh the list of templates if needed
      this.retrieveTemplates(); // Assuming you have a method to fetch templates
    },
    expandContent() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  display: inline-grid;
}

li {
  display: grid;
    grid-template-columns: 1fr auto auto;
    background-color: #f1f1f1;
    padding: 0 16px;
    border-radius: 3px;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    cursor: grab;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

input{
    width: auto;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 14px;
    color: gray;
}

.classbuttons {
  margin-left: 10px;
}

.classname {
  width: auto;
  text-align: left;
}


.slide-enter-active,
.slide-leave-active {
  transition: height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  height: 0;
  overflow: hidden;
}

.manage-classs-button {
  margin-bottom: 10px;
}

.settings-title {
  text-align: center;
}

.newclassinput {
  display: inline-flex;
  height: 30px;
  width: 300px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.class-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.template-header {
  position: relative;
  width: 150px;
}

.delete-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: none;
}

.manage-templates-button {
  width: 300px;
  height: 60px;
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 10px;
}

.template-header:hover .delete-button {
  display: block;
    margin: 0;
    padding: 5px;
    background-color: white;
    font-weight: bold;
    color: black;
    border: none;
    border-radius: 0px;
    cursor: pointer;
}

.no-templates-message {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  text-align: center;
  margin: 20px;
}

@media screen and (max-width: 768px) {
  .manage-templates-button {
    width: 200px;
  }
}

</style>
