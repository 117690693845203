<template>
  <div class="uniformcharts">
    <div>
      <canvas ref="chartCanvas" class="linechart-canvas"></canvas>
    </div>
    <div class="options-row">
      <!-- Chart Type Dropdown -->
      <div style="display: grid;">
        <span class="compare-with">Display</span>
        <select v-model="chartType" @change="renderChart" class="select-dropdown">
          <option value="line">Line</option>
          <option value="bar">Bar</option>
        </select>
      </div>

      <!-- Format Dropdown -->
      <div style="display: grid;">
        <span class="compare-with">Type</span>
        <select v-model="dataType" @change="renderChart" class="select-dropdown">
          <option value="daily">Daily</option>
          <option value="cumulative">Cumulative</option>
        </select>
      </div>

      <!-- Compare With Dropdown -->
      <div style="display: grid;">
        <span class="compare-with" v-if="dateOption === 'month'">Compare</span>
        <select class="month-select" v-if="dateOption === 'month'" v-model="selectedComparisonMonth" @change="renderChart">
          <option v-for="month in previousMonths" :key="month.value" :value="month.value">
            {{ month.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const SpendingLineTextPlugin = {
  id: 'SpendingLineTextPlugin',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    if (!ctx) return;  // Check if context exists

    ctx.save();
    ctx.textBaseline = 'middle';
    const edgeDistance = 50;

    if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      if (!activePoint || !activePoint.element) return;  // Check if activePoint and its element exist

      const spendingAmount = chart.data.datasets[0].data[activePoint.index];
      const dateLabel = chart.data.labels[activePoint.index];
      if (spendingAmount === undefined || dateLabel === undefined) return;  // Check if data exists

      let centerX = activePoint.element.x;
      const textY = chart.chartArea.top - 20;

      const formatCurrency = (value) => `$${Math.abs(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

      if (activePoint.element.x < chart.chartArea.left + edgeDistance) {
        ctx.textAlign = 'left';
        centerX += 10;
      } else if (activePoint.element.x > chart.chartArea.right - edgeDistance) {
        ctx.textAlign = 'right';
        centerX -= 10;
      } else {
        ctx.textAlign = 'center';
      }

      ctx.fillStyle = '#000000';
      ctx.font = 'bold 28px Arial';
      ctx.fillText(formatCurrency(spendingAmount), centerX, textY);

      ctx.font = '16px Arial';
      ctx.fillText(dateLabel, centerX, textY + 30);

      // Add comparison month label and amount if dateOption is 'month'
      if (chart.config.options.plugins.dateOption === 'month' && chart.data.datasets.length > 1) {
        const comparisonAmount = chart.data.datasets[1].data[activePoint.index];
        const comparisonMonthLabel = chart.data.datasets[1].label;
        if (comparisonAmount !== undefined && comparisonMonthLabel) {
          ctx.font = 'bold 28px Arial';
          ctx.fillStyle = 'grey';
          const comparisonText = `${formatCurrency(comparisonAmount)}`;
          ctx.fillText(comparisonText, centerX, textY + 65);
          ctx.font = '16px Arial';
          const comparisonMonth = `${comparisonMonthLabel}`;
          ctx.fillText(comparisonMonth, centerX, textY + 90);
        }
      }

      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo(activePoint.element.x, chart.chartArea.top + 30);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'lightgrey';
      ctx.stroke();
      ctx.setLineDash([]);
    }

    ctx.restore();
  }
};

export default {
  name: 'LineChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    dateOption: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartType: 'line', // Default chart type
      dataType: 'cumulative', // Default set to cumulative
      selectedComparisonMonth: 1, // Default to previous month (1 month back)
      previousMonths: this.getPreviousMonths(),
    };
  },
  computed: {
    
    filteredData() {
      const today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
      let startDate;

      switch (this.dateOption) {
        case 'week':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 7));
          break;
        case '1year':
          startDate = new Date(Date.UTC(today.getUTCFullYear() - 1, today.getUTCMonth(), today.getUTCDate()));
          break;
        case '2year':
          startDate = new Date(Date.UTC(today.getUTCFullYear() - 2, today.getUTCMonth(), today.getUTCDate()));
          break;
        case 'month':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
          break;
        case '30':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 30));
          break;
        case '60':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 60));
          break;
        case '90':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 90));
          break;
        case 'year':
          startDate = new Date(Date.UTC(today.getUTCFullYear(), 0, 1));
          break;
        case 'all':
          startDate = new Date(Date.UTC(1970, 0, 1)); // The start of the UNIX timestamp, effectively "all time"
          break;

        default:
        startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 30));
          break;
      }

      return this.data.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate >= startDate && entryDate <= today;
      });
    },
    comparisonMonthLabel() {
      const today = new Date();
      const comparisonMonthDate = new Date(today.getFullYear(), today.getMonth() - this.selectedComparisonMonth, 1);
      return comparisonMonthDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    },

  },
  mounted() {
    this.$nextTick(() => {
      this.renderChart();
    });
  },
  watch: {
    data() {
      this.renderChart();
    },
    dateOption() {
      this.renderChart();
      this.spendingLineChart.options.plugins.dateOption = this.dateOption;
      this.spendingLineChart.update();
    },
    dataType() {
      this.renderChart();
    },
  },
  methods: {
    setChartType(type) {
      this.chartType = type;
      this.renderChart();
    },
    setDataType(type) {
      this.dataType = type;
      this.renderChart();
    },
    setOption(value) {
      this.dateOption = value;
      this.renderChart();
    },
    getPreviousMonths() {
      const today = new Date();
      const months = [];
      for (let i = 1; i <= 12; i++) {
        const monthDate = new Date(today.getFullYear(), today.getMonth() - i, 1);
        const label = monthDate.toLocaleString('default', { month: 'long', year: 'numeric' });
        months.push({ value: i, label });
      }
      return months;
    },
    getDatesBetween(startDate, endDate, includeFullMonth = false) {
      let datesArray = [];
      let currentDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()));

      if (includeFullMonth) {
        endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, 0));
      }

      while (currentDate <= endDate) {
        datesArray.push(new Date(currentDate));
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      return datesArray.map(date => date.toISOString().split('T')[0]);
    },

    formatDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(Date.UTC(year, month - 1, day));
      const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    },
    // New method to get the total spending for a specific date
    getTotalSpendingForDate(date) {
      const totalAmountsByDate = this.filteredData.reduce((total, entry) => {
        if (entry.date === date) {
          total += entry.amount;
        }
        return total;
      }, 0);

      return totalAmountsByDate;
    },
    
    calculateCumulativeTotal(amounts) {
      let cumulative = 0;
      return amounts.map(amount => {
        cumulative += amount;
        return cumulative;
      });
    },

    renderChart() {
      const chartCanvas = this.$refs.chartCanvas;

      if (!chartCanvas) {
        console.error('Canvas element is not available.');
        return;
      }

      const today = new Date();
      const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

      // Filter data to only include entries on or before today's date
      const filteredData = this.filteredData;

      // Create an object to hold the total transaction amount for each date
      let totalAmountsByDate = filteredData.reduce((total, entry) => {
        const entryDate = new Date(entry.date);
        const dateKey = new Date(Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth(), entryDate.getUTCDate())).toISOString().split('T')[0];
        
        if (total[dateKey]) {
          total[dateKey] += entry.amount;
        } else {
          total[dateKey] = entry.amount;
        }
        return total;
      }, {});


      let labels = Object.keys(totalAmountsByDate);

      let allDates;
      if (this.dateOption === 'month') {
        allDates = this.getDatesBetween(new Date(labels[0]), new Date(), true); // Include full month
      } else {
        allDates = this.getDatesBetween(new Date(labels[0]), new Date());
      }

      // Ensure every date in allDates has an entry in totalAmountsByDate
      allDates.forEach(date => {
        const currentDate = new Date(date);
        if (currentDate > today) {
          totalAmountsByDate[date] = null;  // Set future dates to null to stop the line
        } else if (!totalAmountsByDate[date]) {
          totalAmountsByDate[date] = 0;  // Set past dates with no data to 0
        }
      });

      // Sort the dates and generate the corresponding amounts
      labels = Object.keys(totalAmountsByDate).sort();
      let amounts = labels.map(label => totalAmountsByDate[label]);

      // Calculate cumulative total if needed
      if (this.dataType === 'cumulative') {
        let cumulative = 0;
        amounts = amounts.map((amount, index) => {
          if (labels[index] <= today.toISOString().split('T')[0]) {
            cumulative += amount;
            return cumulative;
          }
          return null; // Prevent cumulative totals after today
        });
      }

      // Now format the labels (which are the date strings)
      let formattedLabels = labels.map(label => this.formatDate(label));

      // Handle the comparison month dataset
      let comparisonMonthAmounts = [];
      if (this.dateOption === 'month') {
        // Calculate the comparison month based on the user's selection
        const comparisonMonthStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - this.selectedComparisonMonth, 1));
        const comparisonMonthEnd = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - this.selectedComparisonMonth + 1, 0));

        const comparisonMonthDates = this.getDatesBetween(comparisonMonthStart, comparisonMonthEnd, true);

        // Get comparison month transactions
        let comparisonMonthTotalAmountsByDate = this.data.reduce((total, entry) => {
          const entryDate = new Date(entry.date);
          const utcDate = new Date(Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth(), entryDate.getUTCDate()));
          if (utcDate >= comparisonMonthStart && utcDate <= comparisonMonthEnd) {
            const dateKey = utcDate.toISOString().split('T')[0];
            if (total[dateKey]) {
              total[dateKey] += entry.amount;
            } else {
              total[dateKey] = entry.amount;
            }
          }
          return total;
        }, {});

        // Fill comparison month data
        comparisonMonthAmounts = comparisonMonthDates.map(date => comparisonMonthTotalAmountsByDate[date] || 0);

        // Optionally handle cumulative calculation for comparison month
        if (this.dataType === 'cumulative') {
          let cumulative = 0;
          comparisonMonthAmounts = comparisonMonthAmounts.map(amount => {
            cumulative += amount;
            return cumulative;
          });
        }
      }

      // Destroy the existing chart instance if it exists
      if (this.spendingLineChart) {
        this.spendingLineChart.destroy();
      }

      this.spendingLineChart = new Chart(chartCanvas, {
        type: this.chartType,
        data: {
          labels: formattedLabels,
          datasets: [
            {
              // Current month data
              label: this.dataType === 'daily' ? 'Daily Total' : 'Cumulative Total',
              data: amounts,
              fill: 'origin',
              borderColor: '#78edff',
              backgroundColor: this.gradientFill,  // Maintain the gradient fill
              tension: 0.4,
              spanGaps: true,  // Ensure gaps aren't connected after today
              pointStyle: 'circle',
              radius: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#78edff',
              pointHoverBorderWidth: 2,
              pointHoverBorderColor: '#ffffff',
            },
            {
              // Previous month data
              label: `${this.comparisonMonthLabel}`,
              data: comparisonMonthAmounts,
              borderColor: 'lightgrey',
              borderDash: [5, 5],  // Dashed line
              fill: false,  // No fill for the comparison month
              tension: 0.4,
              spanGaps: true,
              pointStyle: 'circle',
              radius: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#cccccc',
              pointHoverBorderWidth: 2,
              pointHoverBorderColor: '#ffffff',
              hoverBackgroundColor: 'grey',  // Display the amount on hover
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
            hoverRadius: 5,
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
              ticks: {
                callback: function(value) {
                  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
                }
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: false,  // Enable the tooltip for hover display
              mode: 'index',
              intersect: false,
              axis: 'x',  // Tooltip follows the x-axis
            },
            legend: {
              display: false, 
            },
            datalabels: {
              color: '#000000',
              display: false,
            },
            dateOption: this.dateOption,
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          layout: {
            padding: {
              top: 40,
              right: 0,
              bottom: 20,
              left: 0,
            }
          },
        },
        plugins: [SpendingLineTextPlugin]
      });
      // After chart is created, use its height for the gradient
      const chartHeight = this.spendingLineChart.height;

      if (this.chartType === 'line') {
        this.gradientFill = chartCanvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, chartHeight);
        this.gradientFill.addColorStop(0, "#78edff"); // Start color
        this.gradientFill.addColorStop(1, "rgba(120, 237, 255, 0)"); // End color with 0 opacity
      } else {
        this.gradientFill = '#78edff'; // Solid color for bar chart
      }

      // Now update the chart data and re-render it
      this.spendingLineChart.data = {
        labels: formattedLabels,
        datasets: [
          {
            label: 'Total Spending',
            data: amounts,
            fill: this.chartType === 'line' ? 'origin' : false,
            borderColor: '#78edff',
            backgroundColor: this.gradientFill,
            tension: 0.4,
            spanGaps: true,
            datalabels: {
              align: 'end',
              anchor: 'end',
            },
            pointStyle: 'circle', // Set this to 'circle' to display round dots
            radius: 0, // Set this to 0 to hide the dots
            pointHoverRadius: 5, // Set this to 0 to prevent points from showing on hover
            pointHoverBackgroundColor: '#78edff', // Set this to the background color to match your line color
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: '#000000',
          },
          {
            // Previous month data
            label: `${this.comparisonMonthLabel}`,
            data: this.dateOption === 'month' ? comparisonMonthAmounts : [],
            borderColor: 'lightgrey',
            borderDash: [5, 5],  // Dashed line
            fill: false,  // No fill for the comparison month
            tension: 0.4,
            spanGaps: true,
            pointStyle: 'circle',
            radius: 0,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#cccccc',
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: '#ffffff',
            hoverBackgroundColor: 'grey',  // Display the amount on hover
          },
        ],
      };

      this.spendingLineChart.update();
    },

  },
};
</script>



<style scoped>

.select-button {
  background-color: #ffffff00;
  color: lightgrey;

  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}

.select-button:hover {
  background-color: #eeeeee;
  color: black;
  cursor: pointer;
}

.linechart-canvas {
  height: 300px;
  padding: 2%;
}


.options-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  float: left;
  margin-left: 2%;
}

.time-select-button {
  background-color: #ffffff00;
  color: #aaaaaa;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
}

.time-select-button:hover {
  background-color: #bdccd9;
  color: black;
  cursor: pointer;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.time-select-button.active {
  background-color: #c7e6ff;
  color: black;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.month-select {
  background-color: unset;
  width: min-content;
  border: none;
  float: right;
  text-align: left;
  border-radius: 15px;
  border: none;
  font-size: 14px;
  color: gray;
  margin-right: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

select {
  border: none;
}

select:focus {
  outline: none;
}


.active {
  color: #94c8ffcc !important;
}

.compare-with {
  font-size: 10px;
  line-height: 10px;
  color: lightgrey;
  text-align: left;
  margin-right: 15px;
}

.options-row button {
  margin: 0px auto;
}

.select-dropdown {
  background-color: unset;
    width: -moz-min-content;
    width: min-content;
    border: none;
    float: right;
    text-align: left;
    border-radius: 15px;
    border: none;
    font-size: 14px;
    color: gray;
    margin-right: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.select-dropdown:hover {
  background-color: #eeeeee;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .select-dropdown {
    color: lightgrey;
    text-align: center;
    width: 100%;
  }

  .month-select {
    color: lightgrey;
    text-align: center;
    width: 100%;
  }

  .options-row {
    display: flex;
    justify-content: space-around;
    float: unset;
  }

  .compare-with {
    text-align: center;
    width: 100%;
  }
}
</style>