<template>
  <div style="width: 100%; margin: 0 auto">
    <div class="cashflow-parent">
      <h3 class="chart-title">Cashflow</h3>
      <select class="year-select" v-model="selectedYear">
        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>
    <canvas ref="chartRef"></canvas>
    <div class="cashflow-metrics">
      <p>Avg Income <span>{{ formatAverageAmount(averageIncome) }}</span></p>
      <p>Avg Expenses <span>{{ formatAverageAmount(averageExpenses) }}</span></p>
      <p>Monthly Avg <span :class="getAmountClass(averageNetCashFlow)">{{ formatAmount(averageNetCashFlow) }}</span></p>
      <p>Total Net <span :class="getAmountClass(netCashFlow)">{{ formatAmount(netCashFlow) }}</span></p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'CashFlowChart',
  setup() {
    const store = useStore();
    const chartRef = ref(null);
    let chart = null; // Use a regular variable instead of a ref
    const selectedYear = ref(new Date().getFullYear());

    // Transactions are assumed to be non-reactive or you can clone them when using
    const transactions = store.state.transactions;

    const years = computed(() => {
      const yearsSet = new Set();
      transactions.forEach(transaction => {
        const date = new Date(transaction.date + 'T00:00:00Z');
        const year = date.getUTCFullYear();
        yearsSet.add(year);
      });
      return Array.from(yearsSet).sort((a, b) => b - a);
    });

    // Reactive reference to store cash flow data
    const cashFlowData = ref({
      labels: [],
      incomeData: [],
      spendingData: [],
      netData: [],
      totalIncome: 0,
      totalExpenses: 0,
      averageIncome: 0,
      averageExpenses: 0,
      netCashFlow: 0,
      averageNetCashFlow: 0
    });

    // Function to get cash flow data for the selected year
    const getCashFlowData = () => {
      const data = {
        labels: [],
        incomeData: [],
        spendingData: [],
        netData: [],
        totalIncome: 0,
        totalExpenses: 0,
        averageIncome: 0,
        averageExpenses: 0,
        netCashFlow: 0,
        averageNetCashFlow: 0
      };

      const monthlyData = {};

      // Filter transactions based on selectedYear
      const filteredTransactions = transactions.filter(transaction => {
        if (transaction.label && transaction.label.includes('ignore')) {
          return false;
        }
        const date = new Date(transaction.date + 'T00:00:00Z');
        const year = date.getUTCFullYear();
        return year === selectedYear.value;
      });

      filteredTransactions.forEach(transaction => {
        const date = new Date(transaction.date + 'T00:00:00Z');
        const month = date.getUTCMonth();
        const monthYear = `${selectedYear.value}-${String(month + 1).padStart(2, '0')}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = { income: 0, spending: 0 };
        }

        if (transaction.amount < 0) {
          monthlyData[monthYear].income += Math.abs(transaction.amount);
        } else {
          monthlyData[monthYear].spending += transaction.amount;
        }
      });

      Object.entries(monthlyData)
        .sort()
        .forEach(([monthYear, { income, spending }]) => {
          const [year, month] = monthYear.split('-');
          const monthName = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ][parseInt(month) - 1];
          data.labels.push(monthName); // Only month name
          data.incomeData.push(income);
          data.spendingData.push(-spending); // Make spending negative
          data.netData.push(income - spending);

          // Accumulate total income and expenses
          data.totalIncome += income;
          data.totalExpenses += spending;
        });

      // Number of months with data
      const numMonths = data.labels.length;

      // Compute averages
      data.averageIncome = numMonths > 0 ? data.totalIncome / numMonths : 0;
      data.averageExpenses = numMonths > 0 ? data.totalExpenses / numMonths : 0;
      data.netCashFlow = data.totalIncome - data.totalExpenses;
      data.averageNetCashFlow = numMonths > 0 ? data.netCashFlow / numMonths : 0;


      return data;
    };

    const createChart = () => {
      const ctx = chartRef.value.getContext('2d');
      cashFlowData.value = getCashFlowData(); // Get fresh data

      const chartData = {
        labels: cashFlowData.value.labels.slice(),
        datasets: [
          {
            label: 'Income',
            data: cashFlowData.value.incomeData.slice(),
            backgroundColor: 'white',
            borderColor: '#2c2c2c',
            borderWidth: 1,
            order: 1,
            borderRadius: {
              topLeft: 10,
              topRight: 10,
              bottomLeft: 0,
              bottomRight: 0
            },
            borderSkipped: false,
            stack: 'stack0',
            barPercentage: 0.5,
            categoryPercentage: 0.8,
          },
          {
            label: 'Spending',
            data: cashFlowData.value.spendingData.slice(),
            backgroundColor: '#2c2c2c',
            borderColor: '#2c2c2c',
            borderWidth: 1,
            order: 2,
            borderRadius: {
              topLeft: 0,
              topRight: 0,
              bottomLeft: 10,
              bottomRight: 10
            },
            borderSkipped: false,
            stack: 'stack0',
            barPercentage: 0.5,
            categoryPercentage: 0.8,
          },
          {
            label: 'Net',
            data: cashFlowData.value.netData.slice(),
            type: 'line',
            borderColor: 'lightgrey',
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            pointBackgroundColor: '#2c2c2c',
            pointBorderColor: 'white',
            pointBorderWidth: 2,
            pointRadius: 4,
            pointHoverRadius: 6,
            order: 0
          }
        ]
      };

      chart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: false
              },
              border: {
                display: false
              },
              ticks: {
                font: {
                  size: 12
                }
              }
            },
            y: {
              stacked: true,
              display: true,
              grid: {
                display: true,
                drawBorder: false,
                color: (context) => {
                  if (context.tick.value === 0) {
                    return 'rgba(0, 0, 0, 0.5)'; // Color of the zero line
                  }
                  return 'rgba(0, 0, 0, 0)'; // Color of other grid lines
                },
                lineWidth: (context) => {
                  if (context.tick.value === 0) {
                    return 2; // Width of the zero line
                  }
                  return 1; // Width of other grid lines
                },
              },
              border: {
                display: false
              },
              ticks: {
                display: false
              },
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                title: (tooltipItems) => {
                  const monthName = tooltipItems[0].label;
                  const year = selectedYear.value;
                  return `${monthName} ${year}`; // Show month and year
                },
                label: (context) => {
                  let value = context.raw;
                  if (typeof value !== 'number') {
                    return null; // Skip this label if the value is not a number
                  }
                  
                  const formattedValue = Math.abs(value).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  });
                  
                  if (context.dataset.label === 'Net') {
                    const sign = value >= 0 ? '+' : '-';
                    return `Net: ${sign}$${formattedValue}`;
                  } else {
                    return `${context.dataset.label}: $${formattedValue}`;
                  }
                }
              }
            },
            datalabels: {
              display: false
            }
          },
          hover: {
            mode: 'index',
            intersect: false
          }
        }
      });
    };

    const updateChart = () => {
      if (chart) {
        chart.destroy(); // Destroy the old chart
      }
      createChart(); // Create a new chart with updated data
    };

    watch(selectedYear, () => {
      updateChart();
    });

    onMounted(() => {
      createChart();
    });

    // Computed properties to expose averages and net cash flow
    const averageIncome = computed(() => cashFlowData.value.averageIncome);
    const averageExpenses = computed(() => cashFlowData.value.averageExpenses);
    const netCashFlow = computed(() => cashFlowData.value.netCashFlow);
    const averageNetCashFlow = computed(() => cashFlowData.value.averageNetCashFlow);

    const formatAmount = (amount) => {
      const absAmount = Math.abs(amount);
      const formattedAbsAmount = absAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      if (amount < 0) {
        return `-$${formattedAbsAmount}`;
      } else {
        return `+$${formattedAbsAmount}`;
      }
    };

    const formatAverageAmount = (amount) => {
      const absAmount = Math.abs(amount);
      return `$${absAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    };

    // New function to determine CSS class based on amount
    const getAmountClass = (amount) => {
      return amount < 0 ? 'negative-amount' : 'positive-amount';
    };


    return {
      chartRef,
      selectedYear,
      years,
      averageIncome,
      averageExpenses,
      netCashFlow,
      averageNetCashFlow,
      formatAmount,
      formatAverageAmount,
      getAmountClass
    };
  },
};
</script>

<style scoped>
.year-select {
  padding: 4px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: center !important;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 18px;
  color: black;
  width: 150px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.6s ease;
}

.year-select:hover {
  background-color: #b4b4b4;
  transform: scale(1.01);
}

.negative-amount {
  color: red;
}

.positive-amount {
  color: green;
}

.chart-title {
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: -30px;
  text-align: left;
}

.cashflow-metrics p {
  margin-bottom: 0;
  display: grid;
  font-size: 1rem;
}

.cashflow-metrics span {
  margin-bottom: 0;
  display: grid;
  font-size: 1.5rem;
}


.cashflow-metrics {
  display: flex;
  padding: 30px;
  justify-content: space-evenly;
}

.cashflow-parent {
  display: flex; 
  justify-content: space-between;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {

  .cashflow-metrics p {
    font-size: 10px;
  }

  .cashflow-metrics {
    padding: 20px 0px;
  }

  .cashflow-parent {
    margin-left: 0px;
  }

  .cashflow-metrics span {
    font-size: .9rem;
  }

  .year-select {
    width: 75px;
    font-size: 16px;
    text-align: center !important;
    text-align-last: center;
  }

  .chart-title {
    margin-left: 10px;
  }
}
</style>