<template>
  <div v-if="isOpen" class="modal" @click="closeOnBackdropClick">
    <div class="modal-content" @click.stop>

        <p class="modal-section-title">Add to an existing rule:</p>
        <div class="select-rule-div">
          <select class="select-rule" v-model="selectedRule">
            <option :value="null" disabled>Select a Rule</option>
            <option v-for="rule in rules" :key="rule.id" :value="rule.id">{{ rule.name }}</option>
          </select>
          <button class="confirm-button" @click="confirmSelection"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></button>
        </div>

        <p class="modal-section-title">Create a new rule:</p>
        <div class="create-new-rule">
          <input class="input-rule" type="text" v-model="ruleName" :placeholder="placeholderName" />
          
          <select class="select-rule" v-model="selectedFrequency">
            <option value="" disabled>Frequency</option>
            <option value="Weekly">Weekly</option>
            <option value="Every 2 weeks">Every 2 weeks</option>
            <option value="Monthly">Monthly</option>
            <option value="Every 2 months">Every 2 months</option>
            <option value="Every 3 months">Every 3 months</option>
            <option value="Every 6 months">Every 6 months</option>
            <option value="Annually">Annually</option>
          </select>

          <button class="confirm-button" @click="createRule">➕</button>
        </div>

        
        <div v-if="transaction.recurringId" class="remove-rule-div">
          <p class="modal-section-title">Current Rule: <span style="color: darkblue;"> {{ this.transaction.recurringName }}</span></p>
          <v-btn @click="removeRecurringRule" class="remove-button">Remove From Rule</v-btn>
        </div>

      

      <div class="close-modal-button" @click="close">x</div>
    </div>
  </div>
</template>

<script>

import { parseISO, addDays } from 'date-fns';
import { db } from '../firebaseInit';
import { collection, addDoc, updateDoc, doc, getDoc, arrayUnion } from 'firebase/firestore';



export default {
  props: {
    isOpen: Boolean,
    rules: Array,
    transaction: Object,
    userId: {
      type: String,
      default: "NO_USER"
    },
  },
  data() {
    return {
      selectedRule: null,
      isCreatingNewRule: false,
      ruleName: '',
      selectedFrequency: '',
      daysToAddMap: {
        'Weekly': 7,
        'Every 2 weeks': 14,
        'Monthly': 30, // A rough average
        'Every 2 months': 60,
        'Every 3 months': 90,
        'Every 6 months': 180,
        'Annually': 365
      }
    };
  },
  computed: {
    daysToAdd() {
      return this.daysToAddMap[this.selectedFrequency];
    },
    nextExpectedDate() {
      const transactionDate = parseISO(this.transaction.date);
      return addDays(transactionDate, this.daysToAdd);
    },
    placeholderName() {
      return this.transaction.name;
    }
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
    confirmSelection() {
      this.$emit('confirm-selection', this.selectedRule);
      this.selectRule(this.selectedRule); // Call selectRule after emitting the event
      this.close();
      this.$store.dispatch('fetchRecurringRules');
    },
    closeOnBackdropClick(event) {
      if (event.target === event.currentTarget) {
        this.close();
      }
    },
    async removeRecurringRule() {
      try {
        const confirmed = window.confirm("Do you want to remove this transaction from the recurring rule?");
        if (!confirmed) return;

        const transactionDocRef = doc(db, `users/${this.userId}/transactions`, this.transaction.id);
        await updateDoc(transactionDocRef, {
          recurringId: '',
          recurringName: 'Not Recurring'
        });

        const ruleDocRef = doc(db, `users/${this.userId}/recurringRules`, this.transaction.recurringId);
        const ruleDocSnap = await getDoc(ruleDocRef);
        const ruleData = ruleDocSnap.data();

        if (ruleData && ruleData.transactions) {
          const updatedTransactions = ruleData.transactions.filter(t => t.id !== this.transaction.id);
          const updatedNumberOfTransactions = ruleData.numberOfTransactions - 1;
          const updatedTotal = ruleData.total - this.transaction.amount;
          const updatedMinAmount = updatedTransactions.length > 0 ? Math.min(...updatedTransactions.map(t => t.amount)) : 0;
          const updatedMaxAmount = updatedTransactions.length > 0 ? Math.max(...updatedTransactions.map(t => t.amount)) : 0;

          const updatedRule = {
            numberOfTransactions: updatedNumberOfTransactions,
            transactions: updatedTransactions,
            total: updatedTotal,
            minAmount: updatedMinAmount,
            maxAmount: updatedMaxAmount
          };

          // Update the annual totals
          const transactionYear = new Date(this.transaction.date).getFullYear().toString();
          if (ruleData[transactionYear]) {
            updatedRule[transactionYear] = ruleData[transactionYear] - this.transaction.amount;
          }

          await updateDoc(ruleDocRef, updatedRule);

          // Update Vuex store
          this.$store.dispatch('updateRecurringRule', { id: this.transaction.recurringId, ...ruleData, ...updatedRule });
          this.$store.commit('updateTransaction', { ...this.transaction, recurringId: '', recurringName: 'Not Recurring' });

          // Fetch updated recurring rules to ensure store is in sync
          await this.$store.dispatch('fetchRecurringRules');
        }

        this.close();
      } catch (error) {
        console.error("Error removing transaction from recurring rule:", error);
      }
    },
    async createRule() {
      try {
        // If the ruleName is not set by user, use transaction's name
        if (!this.ruleName) {
          this.ruleName = this.transaction.name;
        }

        const newRule = {
          name: this.ruleName,
          frequency: this.selectedFrequency,
          expectedAmount: this.transaction.amount,
          minAmount: this.transaction.amount,
          maxAmount: this.transaction.amount,
          total: this.transaction.amount,
          numberOfTransactions: 1,
          ...this.computeAnnualTotals([this.transaction]),
          nextExpectedDate: this.nextExpectedDate.toISOString().split('T')[0], // Format the date to 'YYYY-MM-DD'
          transactions: [this.transaction]
        };

        const rulesRef = collection(db, `users/${this.userId}/recurringRules`);
        const newDocRef = await addDoc(rulesRef, newRule); // Adds new rule to the collection

        // Update the transaction's "recurringName" and "recurringId" fields
        const transactionDocRef = doc(db, `users/${this.userId}/transactions`, this.transaction.id);
        await updateDoc(transactionDocRef, {
          recurringName: this.ruleName,
          recurringId: newDocRef.id
        });
        
        this.close(); // <--- Close the modal after successfully creating the rule
      } catch (error) {
      console.error("Error creating rule:", error);
      }
    },
    async selectRule(selectedRuleId) {
      const rulesRef = collection(db, `users/${this.userId}/recurringRules`);
      const transactionDocRef = doc(db, `users/${this.userId}/transactions`, this.transaction.id);

      const selectedRuleSnap = await getDoc(doc(rulesRef, selectedRuleId));

      if (selectedRuleSnap.exists()) {
        const selectedRuleData = selectedRuleSnap.data();

        // Update the transaction with selected rule details
        await updateDoc(transactionDocRef, {
          recurringId: selectedRuleSnap.id,
          recurringName: selectedRuleData.name
        });

        // Add transaction to the selected recurringRule's transactions array
        const selectedRuleDocRef = doc(rulesRef, selectedRuleSnap.id);
        const updatedTotal = selectedRuleData.total + this.transaction.amount;
        const updatedMinAmount = Math.min(selectedRuleData.minAmount, this.transaction.amount);
        const updatedMaxAmount = Math.max(selectedRuleData.maxAmount, this.transaction.amount);
        const updatedNumberOfTransactions = selectedRuleData.numberOfTransactions + 1;
        const annualTotals = this.computeAnnualTotals([...selectedRuleData.transactions, this.transaction]);

        const updatedRule = {
          total: updatedTotal,
          minAmount: updatedMinAmount,
          maxAmount: updatedMaxAmount,
          transactions: arrayUnion(this.transaction),
          numberOfTransactions: updatedNumberOfTransactions,
          ...annualTotals,
        };

        await updateDoc(selectedRuleDocRef, updatedRule);

        // Dispatch action to update the Vuex store
        this.$store.dispatch('updateRecurringRule', { id: selectedRuleSnap.id, ...selectedRuleData, ...updatedRule });

        // Update the transaction in the Vuex store
        this.$store.commit('updateTransaction', {
          ...this.transaction,
          recurringId: selectedRuleSnap.id,
          recurringName: selectedRuleData.name
        });

        // Fetch updated recurring rules to ensure store is in sync
        await this.$store.dispatch('fetchRecurringRules');
      }

      this.close(); // Close the modal after processing
    },
    getYearFromTransaction(txn) {
        return parseISO(txn.date).getFullYear();
    },
    computeAnnualTotals(transactions) {
      return transactions.reduce((acc, txn) => {
        const year = this.getYearFromTransaction(txn);
        if (!acc[year]) {
          acc[year] = 0;
        }
        acc[year] += txn.amount;
        acc.total += txn.amount;  // compute the overall total
        return acc;
      }, { total: 0 });
    }
  }
};
</script>

<style>
.select-rule-div, .create-new-rule {
  display: flex;
  height: 50px;
  align-items: center;
}

.confirm-button {
  transition: font-size 0.3s ease, background-color 0.3s ease, fill 0.3s ease;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin: 20px 0px 20px 5px;
}

.confirm-button:hover {
  background-color: white;
  font-size: 125%;
  fill: #4caf50;
}

.remove-button {
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #f1f1f1;
  border-radius: 5px;
  color: grey;
  margin: 20px 0px 20px 5px;
}

.remove-button:hover {
  background-color: rgb(246 246 246);
  color: red;
}

.close-modal-button {
  transition: font-weight 0.3s ease, color 0.3s ease;
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 500;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-modal-button:hover {
  color: red;  /* You can style the hover state if you like. */
  font-weight: 800;
}

.select-rule, .input-rule {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: grey;
  margin: 1%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.modal-section-title {
  text-align: left;
  margin: 40px 5px 0px;
  font-size: 1em;
  font-weight: 600;
  color: darkslategrey;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 10px;
    font-size: small;
    padding: 20px 20px 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: 0.3s ease-in-out;
  }
}
</style>