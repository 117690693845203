import { createRouter, createWebHistory } from 'vue-router';
import DashboardPage from './views/DashboardPage.vue';
import LoginView from './views/LoginView.vue';
import RegisterView from './views/RegisterView.vue';
import HomePage from './views/HomePage.vue';
import NotFound from './views/NotFound.vue';
import SpendingView from './views/SpendingView.vue';
import NetWorth from './views/NetWorth.vue'; 
import Assets from './views/AssetsView.vue'; 
import Liabilities from './views/LiabilitiesView.vue';
import Income from './views/IncomeView.vue';
import Budget from './views/BudgetView.vue';
import Goals from './views/GoalsView.vue';
import Fire from './views/FireView.vue';
import AnnualSummary from './views/AnnualSummary.vue';
import DebtToIncome from './views/DebtToIncome.vue';
import Settings from './views/SettingsView.vue';
import Recurring from './views/RecurringView.vue';
import Onboarding from './views/UserOnboarding.vue';
import Onboarding2 from './views/UserOnboarding2.vue';
import Onboarding3 from './views/UserOnboarding3.vue';
import Subscription from './views/SubscriptionView.vue';
import Success from './views/SubscriptionSuccess.vue';
import LinkedAccounts from './views/LinkedAccounts.vue';
import CategoryView from './views/CategoryView.vue';
import AnnualBudget from './views/AnnualBudget.vue';
import CashFlow from './views/CashFlow.vue';
import AI from './views/AI.vue';
import LifeGrid from './views/LifeGrid.vue';
import TermsofService from './views/TermsofService.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import ManifestoView from './components/ManifestoView.vue';
import PricingView from './components/PricingView.vue';
import BlogHome from './components/blog/BlogHome.vue';
import UnlockingFire from './components/blog/UnlockingFire.vue';
import FourPercent from './components/blog/FourPercent.vue';
import EmergencyFund from './components/blog/EmergencyFund.vue';
import FireFrugality from './components/blog/FireFrugality.vue';
import SavingsRate from './components/blog/SavingsRate.vue';
import StepbyStep from './components/blog/StepbyStep.vue';
import MaximizeRetirement from './components/blog/MaximizeRetirement.vue';
import LoanRepayment from './components/blog/LoanRepayment.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import store from './store';
import { auth, db } from './firebaseInit'; // Import the Firebase auth instance
import { Capacitor } from '@capacitor/core';



function isMobile() {
  return Capacitor.isNativePlatform();
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription, 
  },
  {
    path: '/subscription-success',
    name: 'Success',
    component: Success, 
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: TermsofService,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: SpendingView,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/networth',
    name: 'Networth',
    component: NetWorth,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/income',
    name: 'Income',
    component: Income,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true},
  },
  {
    path: '/fire',
    name: 'Fire',
    component: Fire,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/goals',
    name: 'Goals',
    component: Goals,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/cashflow',
    name: 'Cashflow',
    component: CashFlow,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/liabilities',
    name: 'Liabilities',
    component: Liabilities,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/budget',
    name: 'Budget',
    component: Budget,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/summary',
    name: 'Summary',
    component: AnnualSummary,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/debt-to-income',
    name: 'Debt-to-Income',
    component: DebtToIncome,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    meta: { requiresAuth: true },
  },
  {
    path: '/onboarding2',
    name: 'Onboarding2',
    component: Onboarding2,
    meta: { requiresAuth: true },
  },
  {
    path: '/onboarding3',
    name: 'Onboarding3',
    component: Onboarding3,
    meta: { requiresAuth: true },
  },
  {
    path: '/recurring',
    name: 'Recurring',
    component: Recurring,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/accounts',
    name: 'Linked Accounts',
    component: LinkedAccounts,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/annual-budget',
    name: 'Annual Budget',
    component: AnnualBudget,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/life-grid',
    name: 'Life Grid',
    component: LifeGrid,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/ai',
    name: 'AI',
    component: AI,
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/manifesto',
    name: 'Manifesto',
    component: ManifestoView,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingView,
  },
  // Dynamic route for categories/labels
  {
    path: '/category/:label',
    name: 'Category',
    component: CategoryView,
    meta: { requiresAuth: true, requiresSubscription: true },
    props: true // This passes the dynamic segment of the URL to the component as a prop
  },
  {
    path: '/blog',
    name: 'BlogHome',
    component: BlogHome,
  },
  {
    path: '/blog/unlocking-fire',
    name: 'UnlockingFire',
    component: UnlockingFire,
  },
  {
    path: '/blog/emergency-fund',
    name: 'EmergencyFund',
    component: EmergencyFund,
  },
  {
    path: '/blog/four-percent',
    name: 'FourPercent',
    component: FourPercent,
  },
  {
    path: '/blog/savings-rate',
    name: 'SavingsRate',
    component: SavingsRate,
  },
  {
    path: '/blog/step-by-step',
    name: 'StepbyStep',
    component: StepbyStep,
  },
  {
    path: '/blog/maximize-retirement',
    name: 'MaximizeRetirement',
    component: MaximizeRetirement,
  },
  {
    path: '/blog/loan-repayment-strategies',
    name: 'LoanRepayment',
    component: LoanRepayment,
  },
  {
    path: '/blog/fire-frugality',
    name: 'FireFrugality',
    component: FireFrugality,
  },
  { path: '/:pathMatch(.*)', name: 'NotFound', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }
    }
  }
});


router.beforeEach(async (to, from, next) => {
  if (isMobile()) {
    // If trying to access the home page, redirect to login
    if (to.path === '/') {
      next({ name: 'Login' });
      return;
    }
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresSubscription = to.matched.some(record => record.meta.requiresSubscription);

  // Check if the user is already authenticated
  const isAuthenticated = store.state.isAuthenticated;
  const currentUser = store.state.user;

  if (requiresAuth && !isAuthenticated) {
    const user = await new Promise((resolve, reject) => {
      onAuthStateChanged(auth, user => {
        if (user) {
          resolve(user);
        } else {
          reject();
        }
      });
    }).catch(() => null);

    if (!user) {
      next({ name: 'Home' }); // Redirect to the homepage
      return;
    }

    store.commit('setAuthenticationStatus', true); // Update the authentication status in the store
  }

  if (requiresSubscription && currentUser) {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.subscription && userData.subscription.status === 'active') {
          // User has an active subscription, allow navigation
          next();
        } else {
          // User doesn't have an active subscription, redirect to the subscription page
          next({ name: 'Subscription' });
        }
      } else {
        // User document doesn't exist, handle the error or redirect to an appropriate page
        next({ name: 'Login' });
      }
    } catch (error) {
      // Handle error
      console.error('Error checking subscription status:', error);
      next({ name: 'Subscription' });
    }
  } else {
    // User is not authenticated or the route doesn't require a subscription, allow navigation
    next();
  }
});



export default router;
