<template>
<div class="parent">



  <div class="div3assets" id="top-right-sidebar-assets"> 
    <!-- <h2 class="template-h2">Monthly Stats</h2> -->
    <div class="piechartdiv">
      <canvas class="canvas-styling" id="assetsPieChartCanvas"></canvas>
    </div>
  </div>

  <div class="mobile-container-assets">
    <div class="div4assets" id="2nd-row-left"> 
      <div class="innerdiv1">{{ totalLabel }}</div>
      <div class="innerdiv2"></div>
      <div class="innerdiv3">
        {{ formatCurrency(totalValue) }}
      </div>
      <div class="innerdiv4-assets">
        <assets-line-chart :items="displayedItems" :unifiedDates="unifiedDates"></assets-line-chart>
      </div>
    </div>

    <div class="div5assets" id="2nd-row-middle">

      <div class="assets-growth-div3">Monthly</div>
      <div class="assets-growth-div4">Weekly</div>
      <div class="assets-growth-div5">Daily</div>
      <div class="assets-growth-div6" :class="averageChangePerMonth >= 0 ? 'positive' : 'negative'">{{ formattedAverageChangePerMonth }}</div>
      <div class="assets-growth-div7" :class="averageChangePerWeek >= 0 ? 'positive' : 'negative'">{{ formattedAverageChangePerWeek }}</div>
      <div class="assets-growth-div8" :class="averageChangePerDay >= 0 ? 'positive' : 'negative'">{{ formattedAverageChangePerDay }}</div>
      <div v-if="isFinite(percentageChangePerWeek)" class="assets-growth-div9" :class="percentageChangeSelectedPeriod >= 0 ? 'positive' : 'negative'">
        {{ formatPercentageChange(percentageChangeSelectedPeriod) }}
      </div>
      <div v-if="isFinite(percentageChangePerWeek)" class="assets-growth-div10" :class="percentageChangePerWeek >= 0 ? 'positive' : 'negative'">
        {{ formatPercentageChange(percentageChangePerWeek) }}
      </div>
      <div v-if="isFinite(percentageChangePerDay)" class="assets-growth-div11" :class="percentageChangePerDay >= 0 ? 'positive' : 'negative'">
        {{ formatPercentageChange(percentageChangePerDay) }}
      </div>
    </div>
  </div>

  <div class="div6assets" id="2nd-row-right"> 
    <canvas class="canvas-styling" id="barChartCanvas"></canvas>
  </div>

  <div class="div7" id="3rd-row-large"> 
  
    <assets-bar-chart :items="displayedItems" :unifiedDates="unifiedDates" :selectedTimeFrame="selectedTimeFrame"></assets-bar-chart>
  </div>

  <div class="div8" id="4th-row-left">
    <div class="timeframe-buttons">
      <v-btn @click="setTimeFrame('all')" :class="{ active: selectedTimeFrame === 'all' }">All</v-btn>
      <v-btn @click="setTimeFrame('5Y')" :class="{ active: selectedTimeFrame === '5Y' }">5Y</v-btn>
      <v-btn @click="setTimeFrame('3Y')" :class="{ active: selectedTimeFrame === '3Y' }">3Y</v-btn>
      <v-btn @click="setTimeFrame('2Y')" :class="{ active: selectedTimeFrame === '2Y' }">2Y</v-btn>
      <v-btn @click="setTimeFrame('1Y')" :class="{ active: selectedTimeFrame === '1Y' }">1Y</v-btn>
      <v-btn @click="setTimeFrame('6M')" :class="{ active: selectedTimeFrame === '6M' }">6M</v-btn>
      <v-btn @click="setTimeFrame('3M')" :class="{ active: selectedTimeFrame === '3M' }">3M</v-btn>
      <v-btn @click="setTimeFrame('1M')" :class="{ active: selectedTimeFrame === '1M' }">1M</v-btn>
    </div>
    <div class="category-buttons">
      <button class="category-button-label" @click="selectCategory(null)" :class="{ active: selectedCategory === null || selectedCategory === defaultCategory }">All</button>
      <button v-for="categoryTotal in categoryTotals" :key="categoryTotal.category" @click="selectCategory(categoryTotal.category)" :class="{ active: selectedCategory === categoryTotal.category }">
        <div class="category-button-label">{{ categoryTotal.label }}</div>
        <div>{{ categoryTotal.category }}
          <span class="category-total-percentage">{{ categoryTotal.percentage }}%</span>
        </div>
      </button>
    </div>

    <h2 class="template-h2"></h2>  
    
    <div class="assets-list">
      <div
        v-for="item in filteredDisplayedItems"
        :key="item.id"
        class="asset-row"
        :class="{ 'asset-row-expanded': assetBeingTracked === item.id }"
      >
        <div class="current-assets" @click="toggleAssetHistory(item)">
          <p class="asset-name">{{ item.name }}</p>
          <p :class="['asset-value', getValueClass(item.currentValue)]">{{ formatCurrency(item.currentValue) }}</p>
          <div></div>
          <div class="asset-apy" v-if="calculateAPY(item) !== null">APY: {{ calculateAPY(item).toFixed(0) }}%</div>
          <div class="edit-assets-button-group">
            <v-btn class="edit-assets-buttons" @click.stop="editAsset(item)" v-if="assetBeingTracked === item.id">{{ assetBeingEdited === item.id ? 'Cancel' : 'Edit' }}</v-btn>
            <v-btn class="delete-button" type="button" @click="deleteAsset(item)" v-if="assetBeingEdited === item.id">Delete</v-btn>
          </div>
        </div>

        <!-- Asset History Table -->
        <transition name="slide">
          <div @click="assetBeingTrackedInput = item.id" class="asset-history" v-if="assetBeingTracked === item.id">
            <form v-if="assetBeingEdited === item.id" @submit.prevent="updateAsset(item)">
              <div class="edit-assets-button-group">
                <v-text-field
                  label="Name"
                  id="asset-name"
                  v-model="item.name"
                  placeholder="Name"
                  outlined
                  dense
                ></v-text-field>

                <v-textarea
                  label="Description"
                  id="asset-description"
                  v-model="item.description"
                  placeholder="Description"
                  outlined
                  dense
                ></v-textarea>

                <template v-if="item.accountType === 'Asset'">
                  <v-select
                    label="Asset Class"
                    id="asset-assetClass"
                    v-model="item.assetClass"
                    :items="assetClasses"
                    item-text="assetClass"
                    item-value="assetClass"
                    return-object
                    placeholder="Select Asset Class"
                    outlined
                    dense
                  ></v-select>
                </template>

                <template v-else-if="item.accountType === 'Liability'">
                  <v-select
                    label="Liability Class"
                    id="liability-assetClass"
                    v-model="item.assetClass"
                    :items="liabilityClasses"
                    item-text="liabilityClass"
                    item-value="liabilityClass"
                    return-object
                    placeholder="Select Liability Class"
                    outlined
                    dense
                  ></v-select>
                  <v-text-field
                    label="Loan Term"
                    id="loan-term"
                    type="number"
                    v-model="item.loanTerm"
                    placeholder="Loan Term"
                    outlined
                    dense
                  ></v-text-field>
                </template>

                <!-- Add other fields as necessary -->
                <v-text-field
                  label="Purchase Date"
                  id="purchase-date"
                  type="date"
                  v-model="item.purchaseDate"
                  placeholder="Purchase Date"
                  outlined
                  dense
                ></v-text-field>
                

                <v-text-field
                  label="Current Value"
                  id="current-value"
                  type="number"
                  v-model="item.currentValue"
                  placeholder="Current Value"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  label="Purchase Value"
                  id="purchase-value"
                  type="number"
                  v-model="item.purchaseValue"
                  placeholder="Purchase Value"
                  outlined
                  dense
                ></v-text-field>

                <!-- only display these fields if the asset type is "Liability" -->
                <v-text-field
                  v-if="item.accountType === 'Liability'"
                  label="Monthly Payment"
                  id="monthly-payment"
                  type="number"
                  v-model="item.monthlyPayment"
                  placeholder="Monthly Payment"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-if="item.accountType === 'Liability'"
                  label="Interest Rate"
                  id="interest-rate"
                  type="number"
                  v-model.number="item.interestRate"
                  placeholder="Interest Rate"
                  outlined
                  dense
                  step="0.01"
                  min="0"
                ></v-text-field>

                <div v-if="item.accountType === 'Liability'" id="expected-payoff-date">
                  <label>Expected Payoff Date: </label>
                  <span>{{ new Date(new Date(item.purchaseDate).setMonth(new Date(item.purchaseDate).getMonth() + item.loanTerm)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                </div>

                <v-btn class="update-assets-button" type="submit">Update</v-btn>
              </div>
            </form>



            <!-- <div class="track-value-input">
              <input class="assets-input track-value-input" type="date" v-model="item.newDate" placeholder="New Date">
              <input class="assets-input track-value-input" type="number" v-model="item.newCurrentValue" placeholder="New Value">
              <button class="track-assets-save" @click="addValueHistory(item)">Save</button>
              <button class="track-assets-cancel" @click="cancelTracking(item)">Cancel</button>
            </div> -->
            
            <table>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-center">Value</th>
                  <th class="text-right">% Change</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(entry, index) in item.valueHistory.slice().reverse()" :key="index">
                  <td>
                    <!-- <input class="assets-input edit-inline-input" type="date" v-model="entry.date" @change="updateEntry(item, entry)" ref="inputField"> -->
                    {{ formatMonthYear(entry.date) }}
                  </td>
                  <td @click="isEditing[index] = true">
                    <span v-if="!isEditing[index]">{{ formatCurrency(entry.value) }}</span>
                    <input 
                      v-else 
                      class="assets-input edit-inline-input" 
                      type="number" 
                      v-model="entry.value" 
                      @change="updateEntry(item, entry); isEditing[index] = false" 
                      @blur="isEditing[index] = false"
                      ref="inputField">
                  </td>
                  <td :class="getPercentageChangeValue(index, item) > 0 ? 'positive' : 'negative'">
                    {{ formatPercentageChange(getPercentageChangeValue(index, item)) }}
                  </td>
                </tr>
              </tbody>

            </table>
            
          </div>   
        </transition>
      </div>
      <div class="hide-zero-switch">
        <v-switch
          v-model="hideZeroBalance"
          color="primary"
          label="Hide Zero Balance Accounts"
          @change="updateHideZeroBalanceSetting"
        ></v-switch>
      </div>
    </div>
  </div>

  <div class="div9-assets" id="bottom-right-sidebar" style="width: 200px;"> 
    <h2 class="template-h2"></h2>
    <div class="mobile-top-bar-parent">
    <div class="add-assets mobile-top-bar">

      <div>
        <button class="beige-buttons" :class="{ 'selected-tab': selectedTab === 'both' }" @click="selectTab('both')">
          NETWORTH
        </button>
        <button class="beige-buttons" :class="{ 'selected-tab': selectedTab === 'assets' }" @click="selectTab('assets')">
          ASSETS
        </button>
        <button class="beige-buttons" :class="{ 'selected-tab': selectedTab === 'liabilities' }" @click="selectTab('liabilities')">
          LIABILITIES
        </button>
        <!-- <button class="beige-buttons" @click="showAddAssetForm = !showAddAssetForm">
          {{ showAddAssetForm ? 'Close' : 'Add Asset' }}
        </button> -->
        <form v-if="showAddAssetForm" @submit.prevent="addAsset">
          <select class="assets-select" v-model="newAsset.assetClass" required>
            <option disabled value="">Select Asset Class</option>
            <option v-for="assetClass in assetClasses" :key="assetClass">{{ assetClass }}</option>
          </select>

          <input class="assets-input"  v-model="newAsset.name" placeholder="Name" required>
          <textarea class="assets-textarea" v-model="newAsset.description" placeholder="Description"></textarea>
          <input class="assets-input"  v-model="newAsset.purchaseDate" type="date" placeholder="Purchase Date" required>
          <input class="assets-input"  v-model="newAsset.currentValue" type="number" placeholder="Current Value" required>
          <input class="assets-input"  v-model="newAsset.purchaseValue" type="number" placeholder="Purchase Value" required>

          <button class="beige-buttons" type="submit">Add Asset</button>
        </form>
        <!-- <button class="beige-buttons" @click="showAddLiabilityForm = !showAddLiabilityForm">
          {{ showAddLiabilityForm ? 'Close' : 'Add Liability' }}
        </button> -->
        <form v-if="showAddLiabilityForm" @submit.prevent="addLiability">
          <select class="assets-select" v-model="newLiability.liabilityClass" required>
            <option disabled value="">Select Liability Class</option>
            <option v-for="liabilityClass in liabilityClasses" :key="liabilityClass">{{ liabilityClass }}</option>
          </select>

          <input class="assets-input"  v-model="newLiability.name" placeholder="Name" required>
          <textarea class="assets-textarea" v-model="newLiability.description" placeholder="Description"></textarea>
          <input class="assets-input"  v-model="newLiability.purchaseDate" type="date" placeholder="Purchase Date" required>
          <input class="assets-input"  v-model="newLiability.currentValue" type="number" placeholder="Current Value" required>
          <input class="assets-input"  v-model="newLiability.purchaseValue" type="number" placeholder="Purchase Value" required>

          <button class="beige-buttons" type="submit">Add Liability</button>
        </form>
    </div>
      
      </div>
    </div>
  </div>

  <div class="div10">
  </div>
</div>


</template>

<script>
/* eslint-disable */
import { collection, addDoc, query, orderBy, onSnapshot, doc, setDoc, deleteDoc, updateDoc, getDoc, getDocs } from "firebase/firestore";
import { auth, db } from "../firebaseInit";
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AssetsBarChart from '../components/AssetsBarChart.vue';
import AssetsLineChart from '../components/AssetsLineChart.vue';



Chart.register(ChartDataLabels);

export default {
  data() {
    return {
      newAsset: {
        name: '',
        description: '',
        purchaseDate: this.getCurrentDate(),
        purchaseValue: '',
        currentValue: '',
        assetClass: '', 
        accountType: 'Asset',
      },
      newLiability: {
        name: '',
        description: '',
        purchaseDate: this.getCurrentDate(),
        purchaseValue: '',
        currentValue: '',
        assetClass: '', 
        accountType: 'Liability',
      },
      lastTabChangeTime: 0,
      selectedTab: 'both',
      assetClasses: [],
      liabilityClasses: [],
      assets: [],  
      liabilities: [],  
      // displayedItems: [],
      assetBeingEdited: null,
      assetBeingTracked: null,
      assetBeingTrackedInput: null,
      showAddAssetForm: false,
      showAddLiabilityForm: false,
      hideZeroBalance: false,
      chart: null,
      barChart: null,
      isEditing: {},
      selectedCategory: null,
      selectedTimeFrame: 'all',
      defaultCategory: null,
    }
  },
  components: {
    AssetsBarChart,
    AssetsLineChart,
  },
  inject: ['sidebarWidth'],
  mounted() {
    // this.generateChart();
    auth.onAuthStateChanged((user) => {
      if (user) {
        const assetClassesCollection = collection(db, `users/${user.uid}/assetclasses`);
        
        onSnapshot(assetClassesCollection, (snapshot) => {
          this.assetClasses = snapshot.docs.map(doc => doc.data().name);
        });

        const liabilityClassesCollection = collection(db, `users/${user.uid}/liabilityclasses`);
        
        onSnapshot(liabilityClassesCollection, (snapshot) => {
          this.liabilityClasses = snapshot.docs.map(doc => doc.data().name);
        });

        const assetsCollection = collection(db, `users/${user.uid}/assets`);
        const q = query(assetsCollection, orderBy("purchaseDate"));

        // Fetch liabilities
        const liabilitiesCollection = collection(db, `users/${user.uid}/liabilities`);
        const liabilitiesQuery = query(liabilitiesCollection, orderBy("purchaseDate"));

        onSnapshot(q, (snapshot) => {
          const changes = snapshot.docChanges();
          changes.forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const asset = { id: change.doc.id, ...change.doc.data() };
              asset.valueHistory = asset.valueHistory || [];
              asset.valueHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

              if (asset.valueHistory.length > 0) {
                asset.currentValue = asset.valueHistory[0].value;

                // Check if an entry for the current month already exists
                const currentDate = new Date();
                currentDate.setUTCHours(0, 0, 0, 0);

                const currentMonthEntry = asset.valueHistory.find(entry => {
                  const entryDate = new Date(entry.date);
                  return entryDate.getUTCMonth() === currentDate.getUTCMonth() && entryDate.getUTCFullYear() === currentDate.getUTCFullYear();
                });

                if (!currentMonthEntry) {
                  const newValueHistoryEntry = {
                    date: `${currentDate.getUTCFullYear()}-${String(currentDate.getUTCMonth() + 1).padStart(2, '0')}-${String(currentDate.getUTCDate()).padStart(2, '0')}`,
                    value: asset.valueHistory[0].value
                  };

                  // Check if the new entry is different from the most recent entry
                  const mostRecentEntry = asset.valueHistory[0];
                  if (newValueHistoryEntry.date !== mostRecentEntry.date || newValueHistoryEntry.value !== mostRecentEntry.value) {
                    asset.valueHistory.unshift(newValueHistoryEntry);

                    // Update the asset in Firestore with the new valueHistory
                    const assetRef = doc(db, `users/${user.uid}/assets`, asset.id);
                    updateDoc(assetRef, {
                      valueHistory: asset.valueHistory
                    }).then(() => {
                      console.log('Asset value history updated with the current month\'s entry');
                    }).catch((error) => {
                      console.error('Error updating asset value history:', error);
                    });
                  }
                }
              }

              if (change.type === 'added') {
                this.assets.push(asset);
              } else if (change.type === 'modified') {
                const index = this.assets.findIndex(a => a.id === change.doc.id);
                this.assets[index] = asset;
              }
            } else if (change.type === 'removed') {
              this.assets = this.assets.filter(asset => asset.id !== change.doc.id);
            }
          });
          this.generateChart(); 
          this.updateAPYForItems();
        });

        // Liabilities snapshot handling
        onSnapshot(liabilitiesQuery, (snapshot) => {
          const changes = snapshot.docChanges();
          changes.forEach(async (change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const liability = { id: change.doc.id, ...change.doc.data(), accountType: 'Liability' };
              liability.valueHistory = liability.valueHistory || [];
              liability.valueHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

              if (liability.valueHistory.length > 0) {
                liability.currentValue = liability.valueHistory[0].value;

                // Check if an entry for the current month already exists
                const currentDate = new Date();
                const currentMonthEntry = liability.valueHistory.find(entry => {
                  const entryDate = new Date(entry.date);
                  return entryDate.getMonth() === currentDate.getMonth() && entryDate.getFullYear() === currentDate.getFullYear();
                });

                if (!currentMonthEntry) {
                  const newValueHistoryEntry = {
                    date: `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`,
                    value: liability.valueHistory[0].value
                  };

                  // Check if the new entry is different from the most recent entry
                  const mostRecentEntry = liability.valueHistory[0];
                  if (newValueHistoryEntry.date !== mostRecentEntry.date || newValueHistoryEntry.value !== mostRecentEntry.value) {
                    liability.valueHistory.unshift(newValueHistoryEntry);

                    // Update the liability in Firestore with the new valueHistory
                    const liabilityRef = doc(db, `users/${user.uid}/liabilities`, liability.id);
                    await updateDoc(liabilityRef, {
                      valueHistory: liability.valueHistory
                    }).then(() => {
                      console.log('Liability value history updated with the current month\'s entry');
                    }).catch((error) => {
                      console.error('Error updating liability value history:', error);
                    });
                  }
                }
              }

              if (change.type === 'added') {
                this.liabilities.push(liability);
              } else if (change.type === 'modified') {
                const index = this.liabilities.findIndex(l => l.id === change.doc.id);
                this.liabilities[index] = liability;
              }
            } else if (change.type === 'removed') {
              this.liabilities = this.liabilities.filter(liability => liability.id !== change.doc.id);
            }
          });
        });

      } else {
        console.log('No user is logged in.');
      }
    });
  },

  computed: {
    totalLabel() {
      switch (this.selectedTab) {
        case 'assets':
          return '💲Assets';
        case 'liabilities':
          return '💲Liabilities';
        case 'both':
          return '💲Net Worth';
        default:
          return '💲Total';
      }
    },
    filteredDisplayedItems() {
      if (this.hideZeroBalance) {
        return this.displayedItems.filter(item => item.currentValue !== 0);
      }
      return this.displayedItems;
    },
    earliestDate() {
      const items = this.selectedTab === 'assets' ? this.assets : 
                    (this.selectedTab === 'liabilities' ? this.liabilities : 
                    [...this.assets, ...this.liabilities]);

      let earliestDate = new Date(8640000000000000);

      for (const item of items) {
        const itemValueHistory = item.valueHistory;
        if (itemValueHistory.length > 0) {
          const itemStartDate = new Date(Math.min(...itemValueHistory.map(entry => new Date(entry.date))));
          if (itemStartDate < earliestDate) {
            earliestDate = itemStartDate;
          }
        }
      }

      return earliestDate;
    },
    allValueHistory() {
      let history = [];

      for (const item of [...this.assets, ...this.liabilities]) {
        for (const entry of item.valueHistory) {
          history.push({
            date: entry.date,
            value: entry.value,
            name: item.name,
            type: item.accountType
          });
        }
      }

      history.sort((a, b) => new Date(a.date) - new Date(b.date));

      return history;
    },
    categoryTotals() {
      const totals = {};
      const combinedCategories = {
        '🏡 Real Estate': ['🏠 Mortgage', '🏗️ Construction Loans', '🏠 Home Equity Line of Credit (HELOC)'],
        '🏎️ Vehicles': ['🚗 Vehicle Loans'],
        '💼 Retirement': ['💼 Retirement Loan'],
        '📦 Personal Property': ['💎 Precious Metals', '💍 Jewelry', '🖼️ Collectibles'],
        '💰 Loans': ['🏢 Business Loans', '📉 Stock Margin Loans', '📑 Bond Financing', '🔗 Secured Loans', '🌐 Cryptocurrency Debt', '💰 Personal Loans', '📚 Educational Loans', '🛍️ Consumer Loans', '🔗 Line of Credit'],
        '💳 Credit Cards': ['💳 Credit Card Debt'],
      };

      for (const asset of this.displayedItems) {
        const category = asset.assetClass || asset.liabilityClass;
        const combinedCategory = Object.entries(combinedCategories).find(([key, values]) => values.includes(category));

        if (combinedCategory) {
          const [combinedCategoryName] = combinedCategory;
          if (!totals[combinedCategoryName]) {
            totals[combinedCategoryName] = {
              totalValue: 0,
              name: combinedCategoryName,
            };
          }
          totals[combinedCategoryName].totalValue += Number(asset.currentValue);
        } else {
          if (!totals[category]) {
            totals[category] = {
              totalValue: 0,
              name: category,
            };
          }
          totals[category].totalValue += Number(asset.currentValue);
        }
      }

      const totalValue = Object.values(totals).reduce((acc, { totalValue }) => acc + totalValue, 0);
      return Object.keys(totals).map(category => {
        const percentage = ((totals[category].totalValue / totalValue) * 100).toFixed(0);
        return {
          label: `${this.formatValue(totals[category].totalValue)}`,
          category: totals[category].name,
          percentage: percentage,
        };
      });
    },
  
    sortedItems() {
      const items = this.displayedItems.slice();
      return items.sort((a, b) => b.currentValue - a.currentValue);
    },
    sortedAssets() {
      return this.assets.slice().sort((a, b) => b.currentValue - a.currentValue);
    },
    sortedLiabilities() {
      return this.liabilities.slice().sort((a, b) => b.currentValue - a.currentValue);
    },
    displayedItems() {
      let items = [];
      if (this.selectedTab === 'assets' || this.selectedTab === 'both') {
        items = items.concat(this.sortedAssets);
      }
      if (this.selectedTab === 'liabilities' || this.selectedTab === 'both') {
        items = items.concat(this.sortedLiabilities);
      }

      if (this.selectedCategory) {
        const combinedCategories = {
          '🏡 Real Estate': ['🏠 Mortgage', '🏗️ Construction Loans', '🏠 Home Equity Line of Credit (HELOC)'],
          '🏎️ Vehicles': ['🚗 Vehicle Loans'],
          '💼 Retirement': ['💼 Retirement Loan'],
          '📦 Personal Property': ['💎 Precious Metals', '💍 Jewelry', '🖼️ Collectibles'],
          '💰 Loans': ['🏢 Business Loans', '📉 Stock Margin Loans', '📑 Bond Financing', '🔗 Secured Loans', '🌐 Cryptocurrency Debt', '💰 Personal Loans', '📚 Educational Loans', '🛍️ Consumer Loans', '🔗 Line of Credit'],
          '💳 Credit Cards': ['💳 Credit Card Debt'],
        };

        const combinedCategoryItems = combinedCategories[this.selectedCategory] || [];

        items = items.filter(item => {
          const category = item.assetClass || item.liabilityClass;
          return category === this.selectedCategory || combinedCategoryItems.includes(category);
        });
      }

      return items;
    },
    
    unifiedDates() {
      const dates = this.assets.reduce((dates, asset) => {
        for (const entry of asset.valueHistory) {
          if (!dates.includes(entry.date)) {
            dates.push(entry.date);
          }
        }
        return dates;
      }, []);
      return dates.sort();
    },
    currentValues() {
      return this.assets.map(asset => {
        // Sort by date in descending order
        const sortedHistory = [...asset.valueHistory].sort((a, b) => {
          if (b.date && a.date) {
            return new Date(b.date) - new Date(a.date);
          } else {
            return 0;
          }
        });
        // Extract the most recent value
        const mostRecentValueEntry = sortedHistory.length > 0 ? sortedHistory[0] : null;
        // Use the most recent value, or the initial currentValue if there's no history
        const currentValue = mostRecentValueEntry ? mostRecentValueEntry.value : asset.currentValue;
        return { ...asset, currentValue };
      });
    },

    totalValue() {
      let items = [];
      if (this.selectedTab === 'assets' || this.selectedTab === 'both') {
        items = items.concat(this.assets);
      }
      if (this.selectedTab === 'liabilities' || this.selectedTab === 'both') {
        items = items.concat(this.liabilities);
      }
      return items.reduce((total, item) => total + Number(item.currentValue), 0);
    },
    totalChangeSelectedPeriod() {
      const items = [...this.assets, ...this.liabilities];
      
      const today = new Date();
      let startDate = new Date();

      switch (this.selectedTimeFrame) {
        case '5Y':
          startDate.setFullYear(today.getFullYear() - 5);
          break;
        case '3Y':
          startDate.setFullYear(today.getFullYear() - 3);
          break;
        case '2Y':
          startDate.setFullYear(today.getFullYear() - 2);
          break;
        case '1Y':
          startDate.setFullYear(today.getFullYear() - 1);
          break;
        case '6M':
          startDate.setMonth(today.getMonth() - 6);
          break;
        case '3M':
          startDate.setMonth(today.getMonth() - 3);
          break;
        case '1M':
          startDate.setMonth(today.getMonth() - 1);
          break;
        default:
          startDate = new Date(0); // Use the earliest possible date for 'all'
          break;
      }

      const totalAssetsToday = this.assets.reduce((total, item) => total + Number(item.currentValue), 0);
      const totalLiabilitiesToday = this.liabilities.reduce((total, item) => total + Number(item.currentValue), 0);
      const totalNetWorthToday = totalAssetsToday + totalLiabilitiesToday;

      const includedValueHistory = items.reduce((valueHistory, item) => {
        const itemStartDate = item.valueHistory
          .filter(entry => {
            const entryDate = new Date(entry.date);
            return (
              entryDate.getFullYear() === startDate.getFullYear() &&
              entryDate.getMonth() === startDate.getMonth()
            );
          })
          .sort((a, b) => new Date(a.date) - new Date(b.date))[0];
        
        if (itemStartDate) {
          valueHistory.push({ name: item.name, ...itemStartDate });
        }
        return valueHistory;
      }, []);

      const totalNetWorthStartDate = includedValueHistory
        .reduce((total, entry) => total + entry.value, 0);

      console.log('Total Assets (Today):', totalAssetsToday);
      console.log('Total Liabilities (Today):', totalLiabilitiesToday);
      console.log('Total Net Worth (Today):', totalNetWorthToday);
      console.log('Total Net Worth (Start Date):', totalNetWorthStartDate);
      console.table(includedValueHistory);

      return totalNetWorthToday - totalNetWorthStartDate;
    },



    averageChangePerDay() {
      const days = this.getDaysInSelectedPeriod();
      const averageChange = days > 0 ? this.totalChangeSelectedPeriod / days : 0;
      console.log('Average Change Per Day:', averageChange);
      return averageChange;
    },

    averageChangePerWeek() {
      const weeks = this.getWeeksInSelectedPeriod();
      const averageChange = weeks > 0 ? this.totalChangeSelectedPeriod / weeks : 0;
      console.log('Average Change Per Week:', averageChange);
      return averageChange;
    },

    averageChangePerMonth() {
      const months = this.getMonthsInSelectedPeriod();
      const averageChange = months > 0 ? this.totalChangeSelectedPeriod / months : 0;
      console.log('Average Change Per Month:', averageChange);
      return averageChange;
    },
    formattedAverageChangePerDay() {
      return this.formatChange(this.averageChangePerDay);
    },
    formattedAverageChangePerWeek() {
      return this.formatChange(this.averageChangePerWeek);
    },
    formattedAverageChangePerMonth() {
      return this.formatChange(this.averageChangePerMonth);
    },
 
    percentageChangeSelectedPeriod() {
      const items = [...this.assets, ...this.liabilities];


      const today = new Date();
      let startDate = new Date();

      switch (this.selectedTimeFrame) {
        case '5Y':
          startDate.setFullYear(today.getFullYear() - 5);
          break;
        case '3Y':
          startDate.setFullYear(today.getFullYear() - 3);
          break;
        case '2Y':
          startDate.setFullYear(today.getFullYear() - 2);
          break;
        case '1Y':
          startDate.setFullYear(today.getFullYear() - 1);
          break;
        case '6M':
          startDate.setMonth(today.getMonth() - 6);
          break;
        case '3M':
          startDate.setMonth(today.getMonth() - 3);
          break;
        case '1M':
          startDate.setMonth(today.getMonth() - 1);
          break;
        default:
          startDate = new Date(0); // Use the earliest possible date for 'all'
          break;
      }

      const totalToday = items.reduce((total, item) => total + Number(item.currentValue), 0);
      const totalStartDate = items.reduce((total, item) => {
        const itemStartDate = item.valueHistory
          .filter(entry => new Date(entry.date) >= startDate)
          .sort((a, b) => new Date(a.date) - new Date(b.date))[0];
        return total + (itemStartDate ? itemStartDate.value : 0);
      }, 0);

      const percentageChange = ((totalToday - totalStartDate) / totalStartDate) * 100;

      console.log('Percentage Change (Selected Period):', percentageChange);
      console.log('Percentage Change Per Day:', this.percentageChangePerDay);
      console.log('Percentage Change Per Week:', this.percentageChangePerWeek);
      console.log('Percentage Change Per Month:', this.percentageChangePerMonth);

      return isNaN(percentageChange) ? 0 : percentageChange;
    },
    
    percentageChangePerDay() {
      const days = this.getDaysInSelectedPeriod();
      return days > 0 ? this.percentageChangeSelectedPeriod / days : 0;
    },

    percentageChangePerWeek() {
      const weeks = this.getWeeksInSelectedPeriod();
      return weeks > 0 ? this.percentageChangeSelectedPeriod / weeks : 0;
    },
    percentageChangePerMonth() {
      const months = this.getMonthsInSelectedPeriod();
      return months > 0 ? this.percentageChangeSelectedPeriod / months : 0;
    },
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    getValueClass(value) {
      if (value === 0) {
        return '';  // Return empty string for zero value
      }
      return value < 0 ? 'negative' : 'positive';
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    closeEditOptions(assetId) {
      this.assetBeingEdited = null; // Reset the asset being edited to null to close the edit options
    },
    formatValue(value) {
      let formattedValue;
      if (value >= 0) {
        formattedValue = `$${value >= 1000 ? `${(value / 1000).toFixed(0)}k` : value.toString()}`;
      } else {
        formattedValue = `-$${Math.abs(value) >= 1000 ? `${(Math.abs(value) / 1000).toFixed(0)}k` : Math.abs(value).toString()}`;
      }
      return formattedValue;
    },
    setTimeFrame(timeFrame) {
      this.selectedTimeFrame = timeFrame;
      this.filterDisplayedItems();
      if (timeFrame === 'all') {
        const earliestDate = this.allValueHistory[0]?.date;
        this.selectedStartDate = earliestDate ? new Date(earliestDate) : new Date();
      }
    },
    async updateHideZeroBalanceSetting() {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, `users/${user.uid}`);
        await updateDoc(userRef, { hideZeroBalance: this.hideZeroBalance });
      }
    },
    async loadUserSettings() {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, `users/${user.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          this.hideZeroBalance = userDoc.data().hideZeroBalance || false;
        }
      }
    },
    getDaysInSelectedPeriod() {
      const today = new Date();
      let startDate = this.earliestDate;

      switch (this.selectedTimeFrame) {
        case '5Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (5 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '3Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (3 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '2Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (2 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '1Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (365 * 24 * 60 * 60 * 1000)));
          break;
        case '6M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (6 * 30 * 24 * 60 * 60 * 1000)));
          break;
        case '3M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (3 * 30 * 24 * 60 * 60 * 1000)));
          break;
        case '1M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (30 * 24 * 60 * 60 * 1000)));
          break;
        default:
          break;
      }

      const timeDiff = today.getTime() - startDate.getTime();
      const days = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return days;
    },

    getWeeksInSelectedPeriod() {
      const days = this.getDaysInSelectedPeriod();
      return Math.floor(days / 7);
    },

    getMonthsInSelectedPeriod() {
      const today = new Date();
      let startDate = this.earliestDate;

      switch (this.selectedTimeFrame) {
        case '5Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (5 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '3Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (3 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '2Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (2 * 365 * 24 * 60 * 60 * 1000)));
          break;
        case '1Y':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (365 * 24 * 60 * 60 * 1000)));
          break;
        case '6M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (6 * 30 * 24 * 60 * 60 * 1000)));
          break;
        case '3M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (3 * 30 * 24 * 60 * 60 * 1000)));
          break;
        case '1M':
          startDate = new Date(Math.max(startDate.getTime(), today.getTime() - (30 * 24 * 60 * 60 * 1000)));
          break;
        default:
          break;
      }

      const yearDiff = today.getFullYear() - startDate.getFullYear();
      const monthDiff = today.getMonth() - startDate.getMonth();
      return yearDiff * 12 + monthDiff;
    },
    filterDisplayedItems() {
      const now = new Date();
      let cutoffDate = new Date();

      switch (this.selectedTimeFrame) {
        case '5Y':
          cutoffDate.setFullYear(now.getFullYear() - 5);
          break;
        case '3Y':
          cutoffDate.setFullYear(now.getFullYear() - 3);
          break;
        case '2Y':
          cutoffDate.setFullYear(now.getFullYear() - 2);
          break;
        case '1Y':
          cutoffDate.setFullYear(now.getFullYear() - 1);
          break;
        case '6M':
          cutoffDate.setMonth(now.getMonth() - 6);
          break;
        case '3M':
          cutoffDate.setMonth(now.getMonth() - 3);
          break;
        case '1M':
          cutoffDate.setMonth(now.getMonth() - 1);
          break;
        default:
          cutoffDate = new Date('1900-01-01'); // Show all if no filter
          break;
      }

      this.displayedItems = this.assets.filter(item => {
        const itemDate = new Date(item.purchaseDate);
        return itemDate >= cutoffDate;
      });
    },
    calculateAPY(asset) {
      const sortedHistory = asset.valueHistory?.sort((a, b) => new Date(a.date) - new Date(b.date));
      if (sortedHistory && sortedHistory.length > 1) {
        const initial = sortedHistory[0].value;
        const current = asset.currentValue;
        const years = (new Date() - new Date(sortedHistory[0].date)) / (1000 * 60 * 60 * 24 * 365);
        if (years > 0) {
          const rateOfReturn = current / initial - 1;
          const apy = (1 + rateOfReturn) ** (1 / years) - 1;
          return apy * 100; // APY in percentage
        }
      }
      return null; // Return null if APY cannot be calculated
    },
    async updateAPYForItems() {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      // Process both assets and liabilities
      const types = ['assets', 'liabilities'];
      for (const type of types) {
        const itemsRef = collection(db, `users/${user.uid}/${type}`);
        const querySnapshot = await getDocs(itemsRef);

        querySnapshot.forEach(async (doc) => {
          const item = { id: doc.id, ...doc.data() };

          // Calculate APY only once a month
          const lastCalculationDate = item.lastAPYCalculationDate ? new Date(item.lastAPYCalculationDate) : null;
          const currentDate = new Date();
          if (!lastCalculationDate || lastCalculationDate.getMonth() !== currentDate.getMonth() || lastCalculationDate.getFullYear() !== currentDate.getFullYear()) {
            const apy = this.calculateAPY(item);
            if (apy !== null) {
              await updateDoc(doc.ref, { historicalReturn: apy, lastAPYCalculationDate: currentDate.toISOString() });
            }
          }
        });
      }
    },
    getCurrentDate() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        let yyyy = today.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    },
    formatChange(change) {
      return change >= 0 ? `+${this.formatCurrency(change)}` : this.formatCurrency(change);
    },
    averageChange(startDate, endDate, unit) {
      const items = this.selectedTab === 'assets' ? this.assets : 
                    (this.selectedTab === 'liabilities' ? this.liabilities : 
                    [...this.assets, ...this.liabilities]);

      const changes = [];
      const date = new Date(startDate);

      while (date >= endDate) {
        const pastDate = new Date(date);
        pastDate[`set${unit.charAt(0).toUpperCase() + unit.slice(1)}`](pastDate[`get${unit.charAt(0).toUpperCase() + unit.slice(1)}`]() - 1);

        const totalThisPeriod = items.reduce((total, item) => {
          const itemThisPeriod = item.valueHistory
            .filter(entry => new Date(entry.date).getTime() <= date.getTime())
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
          return total + (itemThisPeriod ? itemThisPeriod.value : 0);
        }, 0);

        const totalPastPeriod = items.reduce((total, item) => {
          const itemPastPeriod = item.valueHistory
            .filter(entry => new Date(entry.date).getTime() <= pastDate.getTime())
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
          return total + (itemPastPeriod ? itemPastPeriod.value : 0);
        }, 0);

        const change = totalThisPeriod - totalPastPeriod;
        changes.push(change);

        date[`set${unit.charAt(0).toUpperCase() + unit.slice(1)}`](date[`get${unit.charAt(0).toUpperCase() + unit.slice(1)}`]() - 1);
      }

      const averageChange = changes.length > 0 ? changes.reduce((total, change) => total + change, 0) / changes.length : 0;
      return averageChange;
    },
    getRandomColor(index) {
      const hue = 180; // Fixed hue value for blueish colors - 900 = teal, 500 = green, 550 = blue/green, 750 orangeish, 200 blueish
      const saturation = 60 + Math.random() * 5;
      const lightness = 30 + index * 5; // Decrease lightness based on index
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
    generateChart() {
        if (this.pieChart) {
          this.pieChart.destroy();
        }

        if (this.barChart) {
          this.barChart.destroy();
        }
        
        const categoryValues = {};
        for (const asset of this.displayedItems) { 
            if (categoryValues[asset.assetClass]) {
                categoryValues[asset.assetClass] += Number(asset.currentValue);
            } else {
                categoryValues[asset.assetClass] = Number(asset.currentValue);
            }
        }
        console.log(categoryValues); // Check the final aggregated data

        const chartData = {
            labels: Object.keys(categoryValues),
            datasets: [
                {
                    data: Object.values(categoryValues),
                    backgroundColor: Object.values(categoryValues).map((_, index) => this.getRandomColor(index)),
                },
            ],
        };

        const chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          hoverOffset: 10,
          borderRadius: 0,
          borderWidth: 0,
          layout: {
              padding: {
                  left: 20,
                  right: 20,
                  top: 20,
                  bottom: 20
              }
          },
          plugins: {
            legend: {
              display: false
            },

          datalabels: {
              display: function(context) {
                var index = context.dataIndex;
                var dataset = context.dataset;
                var value = dataset.data[index];
                var total = dataset.data.reduce((total, num) => total + num, 0);
                var percentage = (value / total) * 100;
                return percentage >= 10;
              },
              color: 'white',
              anchor: 'center', 
              align: 'center',  
              formatter: function(value, context) {
              var index = context.dataIndex;
              var dataset = context.dataset;
              var total = dataset.data.reduce((total, num) => total + num, 0);
              var percentage = ((value / total) * 100).toFixed(0);
              return context.chart.data.labels[index] + '\n             ' + percentage + '%';
              }
            }
          }
        };

        this.$nextTick(() => {
        const ctx = document.getElementById('assetsPieChartCanvas').getContext('2d');
        this.pieChart = new Chart(ctx, {
          type: 'doughnut',
          data: chartData,
          options: chartOptions,
        });

        const barCtx = document.getElementById('barChartCanvas').getContext('2d');
        this.barChart = new Chart(barCtx, {
          type: 'bar',
          data: chartData,
          options: {
            ...chartOptions,
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                color: '#fff',  // Change the color to white
                display: true,

                align: 'top',    
                formatter: (value) => {
                  let formattedNumber = this.formatChartCurrency(value);
                    return formattedNumber;
                  },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false, 
                  drawOnChartArea: false, 
                },
              },
              y: {
                display: false,
                grid: {
                  display: false, 
                  drawOnChartArea: false, 
                },
              },
            },
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
        });
      });
    },
    editAsset(item) {
      if (this.assetBeingEdited === item.id) {
        this.assetBeingEdited = null;
      } else {
        this.assetBeingEdited = item.id;
      }
    },
    // Interpolates values between two dates
    interpolateValues(startDate, endDate, startValue, endValue) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffYear = end.getFullYear() - start.getFullYear();
      const diffMonth = end.getMonth() - start.getMonth();
      const monthDiff = diffYear * 12 + diffMonth;
      let interpolatedValues = [];
      
      // Ensure startValue and endValue are numbers to prevent NaN results
      startValue = Number(startValue);
      endValue = Number(endValue);

      for (let i = 0; i <= monthDiff; i++) {
        const monthOffset = start.getMonth() + i;
        const year = start.getFullYear() + Math.floor(monthOffset / 12);
        const month = monthOffset % 12;
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const day = start.getDate() > daysInMonth ? daysInMonth : start.getDate();
        const date = new Date(year, month, day);
        const fraction = monthDiff === 0 ? 1 : i / monthDiff;
        const interpolatedValue = startValue + (endValue - startValue) * fraction;
        interpolatedValues.push({
          date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
          value: Math.round(interpolatedValue)
        });
      }
      return interpolatedValues;
    },

    // Removes outdated value history entries based on a new purchase date
    removeOutdatedValueHistoryEntries(newPurchaseDate, valueHistory) {
      return valueHistory.filter(entry => new Date(entry.date) >= new Date(newPurchaseDate));
    },

    // Updated asset with consideration for new purchase dates and value interpolation
    async updateAsset(item) {
      const user = auth.currentUser;
      if (!user) {
        console.log('No user is logged in.');
        return;
      }

      const newPurchaseDate = item.purchaseDate;
      const existingValueHistory = item.valueHistory;
      const currentValue = item.currentValue;

      // Ensure the existing value history is sorted in ascending order by date
      existingValueHistory.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Initialize updated value history
      let updatedValueHistory = [];

      if (new Date(newPurchaseDate) < new Date(existingValueHistory[0].date)) {
        // Interpolate and combine values if the new purchase date is earlier
        const interpolatedEntries = this.interpolateValues(
          newPurchaseDate,
          existingValueHistory[0].date,
          currentValue,
          existingValueHistory[0].value
        );
        updatedValueHistory = [...interpolatedEntries, ...existingValueHistory];
      } else {
        // Remove outdated entries if the new purchase date is later
        updatedValueHistory = this.removeOutdatedValueHistoryEntries(newPurchaseDate, existingValueHistory);
        if (updatedValueHistory.length === 0) {
          // Add a current entry if all previous entries were removed
          updatedValueHistory.push({
            date: newPurchaseDate,
            value: currentValue
          });
        }
      }

      // Update Firestore document with the new or modified value history
      try {
        const collectionName = item.accountType === 'Liability' ? 'liabilities' : 'assets';
        const itemRef = doc(db, `users/${user.uid}/${collectionName}`, item.id);
        await updateDoc(itemRef, { ...item, valueHistory: updatedValueHistory });
        console.log(`${item.accountType} updated with ID: ${item.id}`);
      } catch (error) {
        console.error(`Error updating ${item.accountType}:`, error);
      }
      this.closeEditOptions(item.id);
    },


    async addAsset() {
      try {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const assetToAdd = {
          name: this.newAsset.name,
          description: this.newAsset.description,
          purchaseDate: this.newAsset.purchaseDate,
          purchaseValue: this.newAsset.purchaseValue,
          currentValue: this.newAsset.currentValue,
          assetClass: this.newAsset.assetClass,
          valueHistory: [{
            date: this.newAsset.purchaseDate,
            value: this.newAsset.purchaseValue
          }, {
            date: formattedDate,
            value: this.newAsset.currentValue
          }]
        };
        const user = auth.currentUser;

        if (user) {
          const assetsCollection = collection(db, `users/${user.uid}/assets`);
          const docRef = await addDoc(assetsCollection, assetToAdd);
          console.log(`Asset added with ID: ${docRef.id}`);
        } else {
          console.log('No user is logged in.');
        }

        this.newAsset = {
          name: '',
          description: '',
          purchaseDate: '',
          purchaseValue: '',
          currentValue: '',
          assetClass: ''
        };
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },
    async deleteAsset(item) {
      const user = auth.currentUser;
      if (user) {
        // Check if accountType is defined and is a string before calling toLowerCase()
        const accountType = typeof item.accountType === 'string' ? item.accountType.toLowerCase() : 'asset';

        if (window.confirm(`Are you sure you want to delete this ${accountType}?`)) {
          // Use the lowercase accountType to determine the collection name
          const collectionName = accountType === 'liability' ? 'liabilities' : 'assets';
          const itemRef = doc(db, `users/${user.uid}/${collectionName}/${item.id}`);
          await deleteDoc(itemRef);
          console.log(`Deleted ${accountType} with ID: ${item.id}`);
          this.assetBeingEdited = null; // Stop editing after delete
        }
      } else {
        console.log('No user is logged in.');
      }
    },
    async addValueHistory(item) {
      const user = auth.currentUser;
      if (user) {
        const collectionName = item.accountType === 'Liability' ? 'liabilities' : 'assets';
        const itemRef = doc(db, `users/${user.uid}/${collectionName}/${item.id}`);

        // create a valueHistory array if it doesn't exist, otherwise use the existing one
        item.valueHistory = item.valueHistory || [];
        
        // find an entry with the same date
        let existingEntry = item.valueHistory.find(entry => entry.date === item.newDate);

        if (existingEntry) {
          // if an entry with the same date exists, update its value
          existingEntry.value = item.newCurrentValue;
        } else {
          // if no entry with the same date exists, push a new one
          item.valueHistory.push({
            date: item.newDate,
            value: item.newCurrentValue
          });
        }
        
        const updatedItem = { ...item, currentValue: item.newCurrentValue };
        await setDoc(itemRef, updatedItem, { merge: true });
        console.log(`Updated ${item.accountType.toLowerCase()} with ID: ${item.id}`);
      } else {
        console.log('No user is logged in.');
      }
    },

    toggleAssetHistory(asset) {
      if (this.assetBeingTracked === asset.id) {
        this.assetBeingTracked = null;
        this.assetBeingEdited = null; // Close the edit view when collapsing the parent
      } else {
        this.assetBeingTracked = asset.id;
      }
    },
    getPercentageChangeValue(index, item) {
      const historyLength = item.valueHistory.length;
      if (index === historyLength - 1) {
        return 0; // No change for the last entry in the original array
      }
      const nextValue = item.valueHistory[historyLength - index - 2].value; // Get the next item in the original array
      const currentValue = item.valueHistory[historyLength - index - 1].value;
      const percentageChange = ((currentValue - nextValue) / nextValue) * 100;
      return percentageChange;
    },



    formatPercentageChange(change) {
      if (change === null) {
        return '-';
      } else {
        const sign = change > 0 ? '+' : '';
        return `${sign}${change.toFixed(2)}%`;
      }
    },
    formatCurrency(amount) {
      if (amount === 0) {
        return '$0';  // Return exactly '$0' for zero amount
      }
      if (Math.abs(amount) < 1 && Math.abs(amount) > 0) {
        // For values between -$1 and $1 (excluding 0), show 2 decimal places
        return new Intl.NumberFormat('en-US', { 
          style: 'currency', 
          currency: 'USD', 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        }).format(amount);
      }
      // For all other values, continue with the existing format (no decimal places)
      return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD', 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0 
      }).format(amount);
    },


    formatChartCurrency(amount) {
      if (amount > 999) {
        amount = (amount / 1000).toFixed(0) + 'k';
        return `$${amount}`;  // Return the formatted amount directly
      } else {
        return new Intl.NumberFormat('en-US', { 
          style: 'currency', 
          currency: 'USD', 
          minimumFractionDigits: 0, 
          maximumFractionDigits: 0 
        }).format(amount);
      }
    },
    cancelTracking(asset) {
      const index = this.assets.findIndex(a => a.id === asset.id);
      this.assets[index] = { ...asset, newCurrentValue: null, newDate: null };
      this.assetBeingTrackedInput = null;
    },
    async updateEntry(item, entry) {
      const user = auth.currentUser;
      if (user) {
        const collectionName = item.accountType === 'Liability' ? 'liabilities' : 'assets';
        const itemRef = doc(db, `users/${user.uid}/${collectionName}/${item.id}`);

        // Find the entry in valueHistory
        let existingEntry = item.valueHistory.find(e => e.date === entry.date);
        if (existingEntry) {
          // If the entry exists, update its value
          existingEntry.value = entry.value;
        }

        // Check if the edited entry is for the current month
        const currentDate = new Date();
        const entryDate = new Date(entry.date);
        const isCurrentMonth = entryDate.getMonth() === currentDate.getMonth() && entryDate.getFullYear() === currentDate.getFullYear();

        // Update currentValue only if the edited entry is for the current month
        const newItem = isCurrentMonth ? { ...item, currentValue: entry.value } : item;

        await setDoc(itemRef, newItem, { merge: true });
        console.log(`Updated ${item.accountType} with ID: ${item.id}`);
      } else {
        console.log('No user is logged in.');
      }
      entry.editMode = false; // Stop editing after update
    },
    async addLiability() {
      try {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const liabilityToAdd = {
          name: this.newLiability.name,
          description: this.newLiability.description,
          purchaseDate: this.newLiability.purchaseDate,
          purchaseValue: this.newLiability.purchaseValue,
          currentValue: this.newLiability.currentValue,
          liabilityClass: this.newLiability.liabilityClass,
          valueHistory: [{
            date: this.newLiability.purchaseDate,
            value: this.newLiability.purchaseValue
          }, {
            date: formattedDate,
            value: this.newLiability.currentValue
          }]
        };
        const user = auth.currentUser;

        if (user) {
          const liabilitiesCollection = collection(db, `users/${user.uid}/liabilities`);
          const docRef = await addDoc(liabilitiesCollection, liabilityToAdd);
          console.log(`Liability added with ID: ${docRef.id}`);
        } else {
          console.log('No user is logged in.');
        }

        this.newLiability = {
          name: '',
          description: '',
          purchaseDate: '',
          purchaseValue: '',
          currentValue: '',
          liabilityClass: ''
        };
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },
    formatMonthYear(dateString) {
      const options = { year: 'numeric', month: 'short', timeZone: 'UTC' }; // Add timeZone option
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    }
  },
  created() {
    this.filterDisplayedItems();
    this.loadUserSettings();
    document.documentElement.style.setProperty('--sidebar-width', this.$store.state.sidebarIsCollapsed ? '0px' : this.sidebarWidth);

    this.newAsset.purchaseDate = this.getCurrentDate();

    this.assets.forEach(asset => {
      asset.valueHistory.forEach(entry => {
        this.$set(entry, 'editMode', false);
      });
    });
  },
  watch: {
    // Existing watcher
    '$store.state.sidebarIsCollapsed'(newVal) {
      if (newVal) {
        document.documentElement.style.setProperty('--sidebar-width', '0px');
      } else {
        document.documentElement.style.setProperty('--sidebar-width', this.sidebarWidth);
      }
    },

    // New watcher for selectedTab
    selectedTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.generateChart();
      }
    },
    selectedTimeFrame(newVal) {
      this.filterDisplayedItems();
    },
  },
}
</script>

<style>

#app {
  background-color: #f9f9f9 !important;
}


.assets-input {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  margin: 1%;
  font-family: sans-serif;
}

.track-value-input {
  border: none;
  font-size: 14px;
  color: gray;
  display: flex;
}

.track-value-input input {
  background-color: #f7f7f7;
}




.assets-textarea {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  appearance: none;
  margin: 1%;
  font-family: sans-serif;
}

.assets-select {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: gray;
  margin: 1%;
  appearance: none;
}

.add-assets {
  align-items: baseline;
  padding: 3%;
  margin-bottom: 5%;
  justify-content: space-evenly;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 1% auto;
  text-align: left;
}

.current-assets {
  align-items: center;
  margin-bottom: 3%;
  padding: 20px 15px 0px 15px;
  border-radius: 10px;
  margin: 1% auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.asset-name {
  text-align: left;
  font-weight: 900;
}

.asset-value {
text-align: right;
font-weight: 900;
color: black;
}

.asset-value.negative {
  color: red;
}

.asset-value.positive {
  color: green;
  font-weight: bold;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  height: 100%;
  z-index: 1;
}

.edit-modal {
  position: fixed;
  top: 50%;
  /* left: var(--sidebar-width); */
  transform: translate(0, -50%); /* updated from translate(-50%, -50%) to translate(0, -50%) */
  background-color: white;
  padding: 1em;
  z-index: 1000; /* to make sure the modal appears above the overlay */
  width: 80%; /* adjust to desired size */
  max-width: 500px; /* adjust to desired size */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.asset-history {
  margin: 10px 10px;
  display: table;
  width:-webkit-fill-available;
}

.asset-row {
  align-items: center;
  margin-bottom: 3%;
  width: 95%;
  justify-content: space-evenly;
  border-radius: 10px;
  margin: 1% auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }


.asset-row:hover {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.asset-row-expanded {
  background-color: white;
}


.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto .75fr auto auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

/* .div1 { 
  grid-area: 1 / 1 / 2 / 2; 
} */
.div2 { 
  grid-area: 1 / 2 / 2 / 4; 
}
.div3assets { 
  grid-area: 1 / 4 / 3 / 5; 
  background-color: #f9f9f9;
}
.div4assets { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 10px;
  background-color: #fff;
  margin-left: 5%;
  margin-top: 5%;
  height: 25vh;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.innerdiv1 { 
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: center;
    text-align: right;
    float: right;
    margin: 0 auto;
    font-size: 1.5em;
    font-family: system-ui;
    font-weight: 600;
}

.innerdiv2 { 
  grid-area: 1 / 2 / 2 / 3; 
}

.innerdiv3 { 
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 2.5vw;
  font-weight: 800;
}

.innerdiv4-assets { 
  grid-area: 3 / 1 / 4 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any content that exceeds the container's bounds */
  max-height: 30vh; /* Set the maximum height */
}

.div5assets { 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 10px;
  /* background-color: #f4b266; */
  background-color: #fff;
  margin: 5%;
  height: 25vh;
  font-size: 16px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.assets-growth-div1 { 
  font-size: 1.5em;
  font-weight: 600;
}
/* .assets-growth-div2 { 

 } */
.assets-growth-div3 { 
  display: flex;
  align-items: flex-end; /* Aligns the child content to the bottom */
  justify-content: flex-end; /* Keeps the text aligned to the right */
  font-weight: 600;
  height: 100%; /* Ensures the div takes full height of its grid area */
}
.assets-growth-div4 { 
  display: flex;
  align-items: flex-end; /* Aligns the child content to the bottom */
  justify-content: center;
  font-weight: 600;
  height: 100%; /* Ensures the div takes full height of its grid area */
}
.assets-growth-div5 { 
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: flex-end; /* Aligns the child content to the bottom */
  height: 100%; /* Ensures the div takes full height of its grid area */
}
.assets-growth-div6 { 
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: 600;
  height: 100%;
}
.assets-growth-div7 { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 100%;
 }
.assets-growth-div8 { 
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 600;
  height: 100%;
}
.assets-growth-div9 { 
  text-align: right;
}
/* .assets-growth-div10 { 

} */

.assets-growth-div11 { 
  text-align: left;
}






/* .innerdiv5 { 
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: flex-end;
    text-align: right;
    float: right;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 600;
} */

/* .innerdiv6 { 
  grid-area: 1 / 2 / 2 / 3; 
}

.innerdiv7 { 
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 800;
} */

/* .last30 {
  font-size: 10px;
}

.innerdiv8 { 
  grid-area: 3 / 1 / 4 / 3; 
  font-size: 2em;
  font-weight: 600;     
} */



.div6assets { 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 10px;
  background-color: #fff;
  margin-right: 5%;
  margin-top: 5%;
  height: 25vh;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.innerdiv9 { 
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: flex-end;
    text-align: right;
    float: right;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 600;
}

.innerdiv10 { 
  grid-area: 1 / 2 / 2 / 3; 
  padding: 3%;
}

.innerdiv11 { 
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 3em;
  font-weight: 800;
}

.innerdiv12 { 
  grid-area: 3 / 1 / 4 / 3; 
}






.div7 { 
  grid-area: 3 / 1 / 4 / 4; 
  padding: 3%;

}
.div8 { 
  grid-area: 4 / 1 / 5 / 4; 
}
.div9-assets { 
  grid-area: 4 / 4 / 6 / 5; 
  position: fixed;
  /* height: 100%; */
  margin-right: 10px;
  margin-top: 5%;
  right: 0;
  height: 25vh;
  bottom: 0;
}

.div10 {
  grid-area: 5 / 1 / 5 / 4;
  padding: 3%;
}

.template-h2 {
  text-align: left;
  margin-left: 3%;
}



th,
td {
  padding: 8px;
  text-align: left;
  font-size: .9em;
  font-family: unset;
}

td:not(.date-td,.checkbox-td,.delete-td):hover {
  outline: #e3e3e3;
  outline-style: solid;
  border-radius: 5px;
  outline-width: thin;
  background-color: white;
  cursor: pointer;
}

th {
  border-bottom: #e3e3e3;
}

th.text-left, td.text-left {
  text-align: left;
}

tr:not(.date-row):hover {
  background-color: #fbfafa;
}

tr {
  height: 14px;
}

table {
  width:-webkit-fill-available;
  padding: 0 10px 2vw;
}

.track-assets-save {
  margin: 10px 10px;
  padding: 0px 30px;
  background-color: white;
  outline-style: solid;
  outline-color: #fff;
  color: black;
  transition: 0.3s ease-in-out;
}


.update-assets-button {
  padding: 10px 20px;
  background-color: #007bff; /* Bootstrap primary blue */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}


.update-assets-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


.track-assets-cancel {
  margin: 10px 10px;
  padding: 0px 30px;
  background-color: white;
  outline-style: solid;
  color: black;
  outline-color: #fff;
  transition: 0.3s ease-in-out;
}

.edit-assets-button-group {
  flex-direction: column;
  width: 100%;
  text-align: left;
  gap: 10px; /* Spacing between each form element */
}

.edit-assets-button-group label {
  font-size: 14px;
  color: #333; /* Dark grey for readability */
  margin-bottom: 5px; /* Small space between label and input */
  font-weight: bold; /* Make labels stand out */
}

.assets-input, .assets-select, .assets-textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Light grey border */
  font-size: 14px;
  color: #333; /* Dark grey for input text for readability */
  background-color: #f9f9f9; /* Light background color */
}

.edit-assets-button-group input, .edit-assets-button-group textarea, .edit-assets-button-group select {
  background-color: #f7f7f7;
  color: gray;
  font-family: sans-serif;
  font-size: 14px;
}

.assets-input:focus, .assets-select:focus, .assets-textarea:focus {
  border-color: #007bff; /* Highlight focus with a blue border */
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 0 2px rgba(0,123,255,.25); /* Soft glow effect on focus */
}



.add-assets-button2 {
  margin: 10px 10px;
  background-color: #5d5d5d;
  transition: 0.3s ease-in-out;
}

.beige-buttons {
  color: rgb(142, 142, 142);
  background-color: transparent;
  text-align: right;
  width: 200px;
  font-size: 22px;
  padding: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

.beige-buttons-edit-assets {
  margin: 10px 10px;
  padding: 10px 20px;
  width: 80px;
  background-color: white;
  outline-style: solid;
  outline-color: #fff;
  color: black;
  transition: 0.3s ease-in-out;
}

.beige-buttons:hover {
  color: #448cc4;
  background: white;
  transition: 0.3s ease-in-out;
  box-shadow: 19px 5px 9px 6px rgb(29 29 29 / 5%) !important;
}


input:focus-visible {
  /* background-color: white; */
  outline: none;
}

textarea:focus-visible {
  background-color: #fff;
}

p {
  margin-bottom: 1.5rem;
}



.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Apply the same text alignment to each column in the table body */
.asset-history tbody > tr > td:nth-child(1) {
  text-align: left;
}

.asset-history tbody > tr > td:nth-child(2) {
  text-align: center;
}

.asset-history tbody > tr > td:nth-child(3) {
  text-align: right;
}

.edit-inline-input {
  background-color: unset;
  text-align: inherit;
  color: gray;
  padding: 0;
  border: none;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}


td:focus-visible {
    outline: none;
}

.assets-list {
 margin-bottom: 8%;
}

.positive {
  color: green;
  font-weight: 500;
}

.negative {
  color: red;
}

.canvas-styling {
  padding: 3%;
}

.selected-tab {
  color: #448cc4;
  font-size: 28px;
  box-shadow: 20px 6px 7px 0px rgb(29 29 29 / 5%) !important;
}

.selected-tab:hover {
  color: black;
}

#top-right-sidebar-assets {
  position: sticky;
  /* height: 100%; */
  margin-right: 5%;
  margin-top: 5%;
  height: 25vh;
  top: 0;
  border-radius: 16px;
}

.asset-apy {
  text-align: right;
  margin-bottom: 10px;
  margin-top: -25px;
  font-size: 12px;
  color: grey;
}

.edit-assets-buttons {
  
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important;
  margin: 10px !important;
}

.delete-button:hover {
  color: white;
  background: red;
}

.category-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
}

.category-buttons > button {
  background-color: unset;
  color: #a3a1a1;
  font-size: 20px;
  font-weight: 600;
}

.category-buttons > button:hover {
  color: black;
}

.active {
  color: black !important;
}

.mobile-container-assets {
  grid-area: 2 / 1 / 3 / 4;
  display: contents;
}


.timeframe-buttons > button {
  background-color: #ffffff00;
  color: #aaaaaa;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
  box-shadow: none !important;
  margin: 5px;
}

.timeframe-buttons > button.active {
  color: white;
  background-color: white;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
}

.timeframe-buttons > button:hover {
  color: black;
  background-color: white;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
}

.hide-zero-switch {
  text-align: right;
  width: 95%;
  border-radius: 10px;
  margin: 1% auto;
}

.category-button-label {
  font-size: 30px; 
  font-weight: 900;
}

.category-total-percentage {
  font-size: 14px; 
  font-weight: 900;
}

@media screen and (max-width: 768px) {
  .parent {
    display: unset !important;
  }

  .canvas-styling {
    padding: 0;
  }

  .div3assets {
    background-color: unset;
  }

  #top-right-sidebar-assets {
    position: unset;
    height: unset;
    margin-right: unset;
    margin-top: 30px;
    height: unset;
  }

  .div4assets {
    margin-left: unset;
    margin-top: unset;
    height: 45vh;
    margin: 5px;
    box-shadow: unset;
  }

  .div4assets, .div5assets, .div6assets {
    background-color: unset;
  }

  .innerdiv1 {
    grid-area: 1 / 1 / 2 / 3 !important;
    font-size: 16px !important;
    margin-top: 28px !important;
  }

  .innerdiv3 {
    font-size: 40px !important;
  }

  .innerdiv4-assets {
    /* margin-bottom: 10px; */
    overflow: unset;
  }

  .div5assets {
    padding: 10px;
    margin: 5px;
    height: 25vh;
    font-size: 14px;
    box-shadow: unset;
  }

  .div6assets {
    height: 50vh;
    width: 90vw;
    margin: 20px auto 0px;
    box-shadow: unset;
  }

  .category-buttons > button {
    font-size: unset;
    margin: 10px;
  }

  .category-buttons {
    display: inline;
    width: 100vw;
    justify-content: space-evenly;
    margin: unset;
  }

  .category-button-label {
    font-size: 20px; 
    font-weight: 900;
  }

  .canvas-styling {
    padding: unset;
  }

  .add-assets {
    padding: unset;
    text-align: unset;
    margin: 0 auto;
    width: 100vw;
    background-color: white;
    border-radius: 0px !important;
  }

  .beige-buttons {
    width: 100px;
    text-align: center;
    font-size: 12px;
  }

  .div9-assets {
    margin-right: unset;
    margin-top: unset;
    right: 0;
    height: unset;
    bottom: 0;
  }

  .asset-name {
    font-size: 14px;
    width: max-content;
  }

  .asset-value {
    font-size: 18px;
  }

  .beige-buttons:hover {
    box-shadow: unset !important;
  }

  .selected-tab {
    box-shadow: unset !important;
  }

  #bottom-right-sidebar {
    position: fixed;
    width: auto !important;
    bottom: 0 !important;
    right: 0px;
  }

  .category-total-percentage {
    font-size: 14px; 
    font-weight: 900;
  }

  .edit-assets-button-group {
    text-align: center !important;
  }

  .mobile-top-bar {
    position: fixed;
    top: 50px;
  }

  .mobile-top-bar-parent {
    height: 100px;
    width: 100vw;
    top: 0px;
    position: fixed;
    box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
    background-color: white;
  }

  .piechartdiv {
    margin-top: 100px;
  }

}
</style>
