<template>
  <div class="fire-parent">
    <div class="fire-age">
      <div class="fire-text">
        <span style="font-size: 1.25em;">Financial Independence in <span style="font-weight: 900; color:#adadff">{{ timeUntilFIRE }} </span> on <br></span> <span style="font-weight: 900; color: #adadff; font-size: 1.25em;">{{ fireDate }} </span>  
        <div class="fire-age2">
          <span style="font-size: 12px; color:#adadff "> AGE </span>
          <span style="font-size: 1.25em; color: #adadff;">{{ fireAge }}</span>
        </div>
      </div>

    </div>
    
    <div class="fire-chart" :class="{ 'chart-expanded': chartExpanded }">
      <table id="chartDataTable" v-show="chartType === 'Table'">
        <thead>
          <tr>
            <th>Year</th>
            <th>FIRE Target Adjusted for Inflation</th>
            <th>Expected Savings</th>
            <th>FIRE Target Amount</th>
          </tr>
        </thead>
        <tbody>
          <!-- Data rows will be inserted here -->
        </tbody>
      </table>
      <canvas id="spendingChart" v-show="chartType === 'Chart'"></canvas>
      
    </div>
        <div class="fire-settings">
      <div class="FIRE">
        <div class="fire-settings" :class="{ 'drawer-closed': !drawerOpen }">
          <div class="drawer-toggle" @click="toggleDrawer">
            <v-icon>{{ drawerOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </div>
          <div class="drawer-toggle2" @click="toggleDrawer">
            <v-icon>{{ drawerOpen ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          </div>
          <div class="drawer-content" :class="{ 'content-hidden': !drawerContentVisible }">
            <!-- Existing fire-settings content goes here -->

            <v-card style="box-shadow: unset">
                <v-card-text style="padding: 1rem 1rem 0rem 1rem;">
                  <!-- <v-text-field style="background-color: white;" label="Desired FI Date" v-model="desiredFIDate" type="date" outlined dense></v-text-field> -->
                  <v-text-field
                    v-if="!customAnnualSpendToggle"
                    label="Total Annual Spend (Last Full Year)"
                    v-model.number="totalAnnualSpend"
                    :value="formatCurrency(totalAnnualSpend)"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field class="custom-annual-spend-field" v-if="customAnnualSpendToggle" label="Custom Annual Spend" v-model.number="customAnnualSpend" type="number" prefix="$" outlined dense></v-text-field>

                  
                  <v-text-field v-if="!customAnnualIncomeToggle" label="Total Annual Income (Last Full Year)" v-model.number="totalAnnualIncome" :value="formatCurrency(totalAnnualIncome)" :hint="totalIncomeHint" :persistent-hint="showHint" @focus="showHint = true" @blur="showHint = false" readonly outlined dense></v-text-field>

                  <v-text-field v-if="customAnnualIncomeToggle" label="Custom Annual Income" v-model.number="customAnnualIncome" type="number" prefix="$" outlined dense></v-text-field>

                  <v-switch style="margin-top: 0px; font-size: .8rem;" v-model="customAnnualSpendToggle" label="Custom Annual Spend" @change="toggleCustomSpend" :color="customAnnualSpendToggle ? 'blue' : 'default'"></v-switch>
                  <v-switch style="margin-top: -20px; font-size: .8rem;" v-model="customAnnualIncomeToggle" label="Custom Annual Income" @change="toggleCustomIncome" :color="customAnnualIncomeToggle ? 'blue' : 'default'"></v-switch>
                  <v-switch style="margin-top: -20px; font-size: .8rem;" v-model="manualFIAmountToggle" label="Custom FI Target" :color="manualFIAmountToggle ? 'blue' : 'default'"></v-switch>
                  <v-switch style="margin-top: -20px; font-size: .8rem;" v-model="includeSocialSecurity" label="Include Social Security" :color="includeSocialSecurity ? 'blue' : 'default'" @change="updateProjections"></v-switch>

                  <v-text-field v-if="manualFIAmountToggle" label="Custom Target FI Amount" v-model.number="manualFIAmount" type="number" prefix="$" outlined dense></v-text-field>
                  <v-text-field 
                    v-if="!manualFIAmountToggle" 
                    label="Target FI Amount" 
                    :value="formatCurrency(targetFIAmount)" 
                    v-model.number="targetFIAmount" 
                    :hint="targetFIHint" 
                    :persistent-hint="showHint" 
                    @focus="showHint = true" 
                    @blur="showHint = false" 
                    readonly 
                    outlined 
                    dense
                  ></v-text-field>
                  <!-- <v-text-field label="Inflation Rate (%)" v-model.number="inflationRate" :value="inflationRate" suffix="%" type="number" outlined dense></v-text-field> -->
                  <!-- <v-text-field label="Projected Income Increase (%)" v-model.number="incomeIncrease" :value="incomeIncrease" type="number" suffix="%" outlined dense></v-text-field> -->
                  <v-slider
                    v-model="inflationRate"
                    :min="0"
                    :max="10"
                    :step="0.5"
                    thumb-label="hover"
                    class="align-center"
                    color="#d9d8d8"
                    track-color="rgb(217 216 216)"
                    style="margin-top: 0px;"
                  >
                </v-slider>
                <div class="slider-label">Inflation Rate: {{ inflationRate }}%</div>
                  <v-slider
                    v-model="incomeIncrease"
                    :min="0"
                    :max="20"
                    :step="0.5"
                    thumb-label="hover"
                    class="align-center"
                    color="#d9d8d8"
                    track-color="rgb(217 216 216)"
                    style="margin-top: 0px;"
                  >
                    <!-- <template v-slot:prepend>
                      <v-icon color="blue" @click="decrementIncomeIncrease">mdi-minus</v-icon>
                    </template>
                    <template v-slot:append>
                      <v-icon color="blue" @click="incrementIncomeIncrease">mdi-plus</v-icon>
                    </template> -->
                  </v-slider>
                  <div class="slider-label">Annual Income Increase: {{ incomeIncrease }}%</div>
                <v-slider
                  v-model="savingsRate"
                  :min="-100"
                  :max="100"
                  :step="1"
                  color="#d9d8d8"
                  track-color="rgb(217 216 216)"
                  thumb-label="hover"
                  class="align-center"
                  style="margin-top: 0px;"
                >
                  <!-- <template v-slot:prepend>
                    <v-icon color="blue" @click="decrementSavingsRate">mdi-minus</v-icon>
                  </template>
                  <template v-slot:append>
                    <v-icon color="blue" @click="incrementSavingsRate">mdi-plus</v-icon>
                  </template> -->
                </v-slider>
                <div class="slider-label">Calculated Savings Rate: {{ calculatedSavingsRate.toFixed(2) }}%</div>
                  <!-- Withdrawal Rate Field -->
                  <!-- <v-text-field
                  label="Withdrawal Rate (%)"
                  v-model.number="withdrawalRate"
                  :value="withdrawalRate.toString()"
                  type="number"
                  suffix="%"
                  :hint="withdrawalRateHint"
                  :persistent-hint="showHint"
                  @focus="showHint = true"
                  @blur="showHint = false"
                  outlined
                  dense
                ></v-text-field> -->
                  
                <v-card style="box-shadow: none" v-if="includeSocialSecurity" class="mb-4 pa-4">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <div class="text-h7">SS Start Age</div>
                      <div class="d-flex align-center">
                        <v-btn density="compact"
                        icon xsmall @click="incrementSocialSecurityAge(-1)" :disabled="socialSecurityStartAge <= 62">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <div class="text-h4 mx-4">{{ socialSecurityStartAge }}</div>
                        <v-btn icon density="compact"  xsmall @click="incrementSocialSecurityAge(1)" :disabled="socialSecurityStartAge >= 70">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="text-right">
                      <div class="text-h7">Monthly Benefit</div>
                      <div class="text-h4">{{ formatCurrency(calculateSocialSecurityBenefit(socialSecurityStartAge)) }}</div>
                    </div>
                  </div>
                </v-card>
                  </v-card-text>
                <v-card-actions style="display: block;">
                  <v-btn
                    color="success"
                    @click="saveSettings"
                    :class="{ 'edited-field': fieldEdited }"
                    style="width: 100px;"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
            </v-card>
            <!-- Settings Dialog -->
            <v-btn style="box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%); margin-bottom: 20px;" @click="showSettings = true">Chart Settings</v-btn>
            <v-dialog v-model="showSettings" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Chart Settings</span>
                </v-card-title>
                <v-card-text>
                  <v-select v-model="chartType" :items="['Chart', 'Table']" label="Display As" @change="toggleDisplay"></v-select>
                  <v-select v-model="timeDisplayMode" :items="['Year', 'Age']" label="Chart Y Axis Mode" @change="updateChart" class="time-display-mode-selector"></v-select>
                  <v-select v-model="yearsToProject" :items="['20 years', '30 years', '40 years', '50 years', '60 years']" label="Years to Project" @change="updateProjections" class="years-to-project-selector"></v-select>
                  <v-select v-model="chartStyle" :items="['line', 'bar']" label="Chart Style" @change="updateProjections" class="chart-style-selector"></v-select>
                  Datasets:
                  <div class="dataset-checkboxes">
                    <v-checkbox class="custom-checkbox" v-model="showFutureSpending" label="Show Inflation Adjusted FI Target"></v-checkbox>
                    <v-checkbox class="custom-checkbox" v-model="showFIRETargetAmount" label="Show FIRE Target Amount"></v-checkbox>
                    <v-checkbox class="custom-checkbox" v-model="showExpectedSavings" label="Show Expected Savings"></v-checkbox>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="showSettings = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            </div>  
        </div>
      </div>
    </div>
    <div class="fire-progress-parent">
      <div class="fire-progress">
        <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
        <div class="progress-text">
          {{ formattedProgressPercentage }} 🔥
        </div>
      </div>
      Current Amount Available for FIRE
      <div class="fire-progress-amounts">
        <div class="fire-progress-now">
          <div class="progress-text">Annually {{ formatCurrency(currentFire) }}</div>
        </div>
        <div class="fire-progress-now">
          <div class="progress-text">Monthly {{ formatCurrency(currentMonthlyFire) }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Asset Class Buttons -->
  <div class="asset-classes">
    <button v-for="assetClassTotal in assetClassTotals" :key="assetClassTotal.type" @click="selectAssetClass(assetClassTotal.type)" :class="{ active: selectedAssetClass === assetClassTotal.type }">
      <div class="asset-class-total">{{ assetClassTotal.label }}</div>
      <div>{{ assetClassTotal.type }}
        <span class="asset-class-percentage">{{ assetClassTotal.percentage }}%</span>
      </div>
    </button>
  </div>
  <div class="asset-entries-parent">
    <div v-for="(asset, index) in filteredAssets" :key="asset.id || asset.name + index" class="asset-entry">
      <div class="asset-name-row">
        <div class="asset-name">
          <strong>{{ asset.name }}</strong>
        </div>
        <div class="asset-balance">
          <span v-if="asset.status === 'Financed'">{{ formatCurrency(asset.equity) }}</span>
          <span v-else>{{ formatCurrency(asset.currentBalance) }}</span> 
          <br>
          <span class="equity-label" v-if="asset.status === 'Financed'">Equity</span>
          <span class="balance-label" v-else>Balance</span>
        </div>
      </div>
      <div class="col col-6">
        <v-text-field v-model.number="asset.expectedReturn" :disabled="!asset.manualEntryEnabled" label="Expected Return (%)" :rules="expectedReturnRules" filled color="teal" compact :append-icon="asset.isFocused && asset.expectedReturn ? 'mdi-check' : ''" @focus="asset.isFocused = true" @blur="handleBlur(asset)" @click:append="asset.expectedReturn ? updateExpectedReturn(asset.id, asset.expectedReturn) : null" clearable @click:clear="clearExpectedReturn(asset)"></v-text-field>
      </div>
      <div class="loan-payoff-date" v-if="asset.status === 'Financed'"> Loan Payoff Date: {{ asset.payoffDate }}
      </div>
      <div class="asset-second-row">
        <!-- Toggle Manual Entry -->
        <div class="manual-entry-toggle">
          <div class="checkbox-wrapper-2" style="margin-right: 0px;">
            <input type="checkbox" class="sc-gJwTLC ikxBAC" v-model="asset.manualEntryEnabled" :id="'manual-entry-' + asset.name" @click="toggleManualEntry(asset.name)">
          </div>
          <label :for="'manual-entry-' + index">Manual Entry</label>
        </div>
        <div class="fire-toggle-label-parent" style="display:inline-flex">
          <label class="fire-toggle-label" :for="'include-' + index">Include in FIRE Calculation</label>
          <div class="checkbox-wrapper-2" style="margin-right: -10px;">
            <input type="checkbox" class="sc-gJwTLC ikxBAC" :id="'include-' + index" :checked="asset.includeInCalculation" @change="toggleIncludeInCalculation(asset, index)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    onAuthStateChanged
  } from "firebase/auth";
  import {
    collection,
    getDocs,
    doc,
    getDoc,
    addDoc,
    updateDoc
  } from "firebase/firestore";
  import {
    auth,
    db
  } from "../firebaseInit"; // Make sure this path is correct
  import {
    Chart
  } from 'chart.js';
  // import VueDatePicker from '@vuepic/vue-datepicker';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import 'chartjs-plugin-datalabels';
  
  
  export default {
    name: 'FIRE',
    components: {},
    watch: {
      // Watcher to update totalAnnualSpend when customAnnualSpend changes
      customAnnualSpend(newVal) {
        if (this.customAnnualSpendToggle) {
          this.totalAnnualSpend = newVal;
        }
        this.fieldEdited = true;
      },
      customAnnualSpendToggle(newValue) {
        console.log(`Toggle changed to: ${newValue}`);
        this.fieldEdited = true;
      },
      customAnnualIncome(newVal) {
        if (this.customAnnualIncomeToggle) {
          this.totalAnnualIncome = newVal;
        }
        this.fieldEdited = true;
      },
      customAnnualIncomeToggle(newValue) {
        console.log(`Toggle changed to: ${newValue}`);
        this.toggleCustomIncome(); // Call the method directly
        this.fieldEdited = true;
      },
      calculatedSavingsRate(newValue) {
        this.savingsRate = newValue;
      },

      manualFIAmountToggle(newValue, oldValue) {
        if (newValue !== oldValue) { // Ensure the value has actually changed
          console.log("Watcher: manualFIAmountToggle changed to:", newValue);
          this.saveToggleStateToDatabase();
        }
      },
      yearsToProject(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.renderSpendingChart();
        }
      },
      chartType() {
        this.toggleDisplay();
      },
      chartStyle(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.renderSpendingChart();
        }
      },
      showFutureSpending() {
        this.renderSpendingChart();
      },
      showFIRETargetAmount() {
        this.renderSpendingChart();
      },
      showExpectedSavings() {
        this.renderSpendingChart();
      },
      timeDisplayMode(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.renderSpendingChart();
        }
      },
      desiredFIDate() {
        this.fieldEdited = true;
      },
      totalAnnualIncome() {
        this.fieldEdited = true;
      },
      incomeIncrease() {
        this.fieldEdited = true;
      },
      savingsRate() {
        this.fieldEdited = true;
      },
      socialSecurityStartAge() {
        this.fieldEdited = true;
      },
      inflationRate() {
        this.fieldEdited = true;
      },
      
      withdrawalRate() {
        this.fieldEdited = true;
      },
      manualFIAmount() {
        this.fieldEdited = true;
      },
    },
    data() {
      return {
        assets: [],
        liabilities: [],
        drawerOpen: true,
        drawerContentVisible: true,
        chartExpanded: false,
        inflationRate: 3, // Default inflation rate
        desiredFIDate: '',
        incomeIncrease: 5, // Default projected income increase
        selectedAssetClass: '💵 Cash',
        fieldEdited: false,
        withdrawalRate: 3, // Default withdrawal rate
        manualFIAmountToggle: false,
        manualFIAmount: 0,
        showHint: false,
        totalIncomeHint: 'Update income on the Income page.',
        withdrawalRateHint: 'Recommended: 3-4% based on historical market returns...',
        targetFIHint: 'Calculated as 25 x your annual expenses',
        timeDisplayMode: 'Year',
        userBirthYear: '',
        birthdate: null,
        savingsRate: 20, // Default savings rate
        totalAnnualIncome: 0,
        totalAnnualSpend: 0,
        customAnnualSpendToggle: false,
        customAnnualSpend: 0,
        originalAnnualSpend: 0, // To store the original totalAnnualSpend
        customAnnualIncomeToggle: false,
        customAnnualIncome: 0,
        originalAnnualIncome: 0,
        spendingChart: null,
        includeSocialSecurity: false,
        earlySocialSecurity: false,
        maxSocialSecurityBenefitAtFRA: 3627,
        socialSecurityStartAge: 67,
        estimatedSocialSecurityBenefit: 0,
        expectedReturnRules: [
          value => !!value || 'Required.',
          value => !isNaN(parseFloat(value)) && isFinite(value) && value > 0 || 'Value must be positive.',
        ],
        // DefaultChart Options
        showSettings: false,
        chartType: 'chart', // default display
        yearsToProject: '40 years',
        chartStyle: 'line',
        showFutureSpending: false,
        showFIRETargetAmount: true,
        showExpectedSavings: true,
      };
    },
    async mounted() {
      await this.fetchUserData(); // Fetch user data on mount
      await this.fetchAssets(); // Assuming this is also asynchronous
      await this.fetchLiabilities(); // Fetch liabilities
      await this.fetchAnnualTotals();
      this.fieldEdited = false;
      await this.saveFIREHistory();
      this.debouncedUpdateProjections = this.debounce(this.updateProjections, 300);
      this.$nextTick(() => {
        this.renderSpendingChart();
      });
    },
    methods: {
      toggleDisplay() {
        if (this.chartType === 'Table') {
          this.renderTable();
        } else {
          this.renderSpendingChart();
        }
      },
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
        this.drawerContentVisible = !this.drawerContentVisible;
        this.chartExpanded = !this.drawerOpen;
        this.$nextTick(() => {
          this.renderSpendingChart();
        });
      },
      debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      },
      incrementIncomeIncrease() {
        if (this.incomeIncrease < 20) {
          this.incomeIncrease = parseFloat((this.incomeIncrease + 0.5).toFixed(1));
        }
      },
      decrementIncomeIncrease() {
        if (this.incomeIncrease > 0) {
          this.incomeIncrease = parseFloat((this.incomeIncrease - 0.5).toFixed(1));
        }
      },
      incrementSavingsRate() {
        if (this.savingsRate < 100) {
          this.savingsRate++;
        }
      },
      decrementSavingsRate() {
        if (this.savingsRate > 0) {
          this.savingsRate--;
        }
      },
      constrainSocialSecurityAge(value) {
        this.socialSecurityStartAge = Math.min(70, Math.max(62, value));
        this.updateProjections();
      },

      preventManualInput(event) {
        // Allow only arrow keys, backspace, delete, tab
        if (!['ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Tab'].includes(event.key)) {
          event.preventDefault();
        }
      },

      incrementSocialSecurityAge(increment) {
          const newAge = this.socialSecurityStartAge + increment;
          if (newAge >= 62 && newAge <= 70) {
              this.socialSecurityStartAge = newAge;
          }
      },
      calculateSocialSecurityData() {
        const ssiData = [];
        const currentYear = new Date().getFullYear();
        const yearsToProject = parseInt(this.yearsToProject);

        for (let year = 0; year < yearsToProject; year++) {
          const age = this.userBirthYear ? (currentYear + year) - this.userBirthYear : 0;
          if ((this.earlySocialSecurity && age >= 62) || (!this.earlySocialSecurity && age >= 67)) {
            ssiData.push(this.calculateSocialSecurityBenefit(age) * 12);
          } else {
            ssiData.push(0);
          }
        }

        return ssiData;
      },
      
      async saveFIREHistory() {
        const userId = auth.currentUser.uid;
        const fireHistoryRef = collection(db, `users/${userId}/fireHistory`);

        const fireHistoryData = {
          fireDate: this.fireDate,
          manualFIAmountToggle: this.manualFIAmountToggle,
          manualFIAmount: this.manualFIAmount,
          customAnnualSpendToggle: this.customAnnualSpendToggle,
          customAnnualIncomeToggle: this.customAnnualIncomeToggle,
          customAnnualSpend: this.customAnnualSpend,
          customAnnualIncome: this.customAnnualIncome,
          totalAnnualIncome: this.totalAnnualIncome,
          incomeIncrease: this.incomeIncrease,
          savingsRate: this.savingsRate,
          inflationRate: this.inflationRate,
          withdrawalRate: this.withdrawalRate,
          totalBalance: this.totalBalance,
          totalAnnualSpend: this.totalAnnualSpend,
          assetAllocation: this.assetAllocation,
          yearsUntilFIRE: this.yearsUntilFIRE,
          fireAge: this.fireAge,
          timestamp: new Date(),
        };

        try {
          await addDoc(fireHistoryRef, fireHistoryData);
          console.log("FIRE history saved successfully.");
        } catch (error) {
          console.error("Error saving FIRE history:", error);
        }
      },
      updateProjections() {
        this.renderSpendingChart();
        this.renderTable();
      },
      async saveSettings() {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        try {
          await updateDoc(userRef, {
            desiredFIDate: this.desiredFIDate,
            // totalAnnualSpend: this.totalAnnualSpend,
            totalAnnualIncome: this.totalAnnualIncome,
            incomeIncrease: this.incomeIncrease,
            savingsRate: this.calculatedSavingsRate,
            inflationRate: this.inflationRate,
            withdrawalRate: this.withdrawalRate,
            manualFIAmount: this.manualFIAmount,
            manualFIAmountToggle: this.manualFIAmountToggle, // Save the toggle state
            customAnnualSpend: this.customAnnualSpend,
            customAnnualSpendToggle: this.customAnnualSpendToggle,
            customAnnualIncomeToggle: this.customAnnualIncomeToggle,
            customAnnualIncome: this.customAnnualIncome,
            includeSocialSecurity: this.includeSocialSecurity,
            socialSecurityStartAge: this.socialSecurityStartAge,
            earlySocialSecurity: this.earlySocialSecurity,
            estimatedSocialSecurityBenefit: this.estimatedSocialSecurityBenefit,
            // Include any other settings you want to save
          });
          console.log("Settings saved successfully.");
          this.renderSpendingChart(); // Refresh the chart here
        } catch (error) {
          console.error("Error saving settings:", error);
        }
        this.fieldEdited = false;
        await this.saveFIREHistory();
      },
      handleBlur(asset) {
        asset.isFocused = false;
        if (asset.expectedReturn) {
          this.updateExpectedReturn(asset.id, asset.expectedReturn);
        }
      },
      async updateExpectedReturn(assetId, expectedReturn) {
        const assetRef = doc(db, `users/${auth.currentUser.uid}/assets/${assetId}`);
        try {
          await updateDoc(assetRef, {
            expectedReturn: expectedReturn,
          });
          console.log("Expected return updated successfully.");
          // Provide user feedback here, e.g., show a success message
        } catch (error) {
          console.error("Error updating expected return:", error);
          // Provide user feedback here, e.g., show an error message
        }
      },
      async updateAssetIncludeInCalculation(assetId, includeInCalculation) {
        const assetRef = doc(db, `users/${auth.currentUser.uid}/assets/${assetId}`);
        try {
          await updateDoc(assetRef, {
            includeInCalculation: includeInCalculation
          });
          console.log("Asset updated successfully");
        } catch (error) {
          console.error("Error updating asset:", error);
        }
      },
      toggleIncludeInCalculation(asset, index) {
        const newIncludeValue = !asset.includeInCalculation;
        // Find the asset in the original assets array to ensure you're updating the correct one
        const assetToUpdateIndex = this.assets.findIndex(a => a.id === asset.id || (a.name + index) === (asset.name + index));
        if (assetToUpdateIndex !== -1) {
          // Create a new updated asset object
          const updatedAsset = {
            ...this.assets[assetToUpdateIndex],
            includeInCalculation: newIncludeValue
          };
          // Update the assets array immutably
          this.assets = [...this.assets.slice(0, assetToUpdateIndex),
            updatedAsset, ...this.assets.slice(assetToUpdateIndex + 1)
          ];
          // Now update the asset in Firestore
          this.updateAssetIncludeInCalculation(asset.id, newIncludeValue);
        } else {
          console.error('Asset not found for updating includeInCalculation');
        }
      },
      toggleCustomSpend() {
        if (this.customAnnualSpendToggle) {
          if (this.customAnnualSpend) {
            this.totalAnnualSpend = this.customAnnualSpend;
          }
        } else {
          this.totalAnnualSpend = this.originalAnnualSpend;
          this.fetchOriginalAnnualSpend();
        }
        this.saveCustomSpendToDatabase();
      },
      
      async fetchOriginalAnnualSpend() {
        const userId = auth.currentUser.uid;
        const totalsRef = doc(db, `users/${userId}/totals/summary`);
        try {
          const docSnap = await getDoc(totalsRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            this.totalAnnualSpend = parseFloat(data.totalAnnualSpend[new Date().getFullYear() - 1] || 0);
          } else {
            console.log("No such document for original annual spend!");
          }
        } catch (error) {
          console.error("Error getting original annual spend:", error);
        }
      },
      async toggleCustomIncome() {
        if (this.customAnnualIncomeToggle) {
          if (this.customAnnualIncome) {
            this.totalAnnualIncome = this.customAnnualIncome;
          }
        } else {
          await this.fetchOriginalAnnualIncome();
        }
        await this.saveCustomIncomeToDatabase();
      },

      // Add this new method to fetch the original annual income
      async fetchOriginalAnnualIncome() {
        const userId = auth.currentUser.uid;
        const totalsRef = doc(db, `users/${userId}/totals/summary`);
        try {
          const docSnap = await getDoc(totalsRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            const lastFullYear = new Date().getFullYear() - 1;
            this.totalAnnualIncome = parseFloat(data.totalAnnualIncome[lastFullYear.toString()] || 0);
            this.originalAnnualIncome = this.totalAnnualIncome;
          } else {
            console.log("No such document for original annual income!");
          }
        } catch (error) {
          console.error("Error getting original annual income:", error);
        }
      },
      async saveCustomSpendToDatabase() {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        try {
          console.log(`Saving: ${this.customAnnualSpendToggle}, ${this.customAnnualSpend}`);
          await updateDoc(userRef, {
            customAnnualSpendToggle: this.customAnnualSpendToggle,
            customAnnualSpend: this.customAnnualSpend,
          });
          console.log("Custom spend settings saved successfully.");
        } catch (error) {
          console.error("Error saving custom spend settings:", error);
        }
      },
      async saveCustomIncomeToDatabase() {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        try {
          console.log(`Saving: ${this.customAnnualIncomeToggle}, ${this.customAnnualIncome}`);
          await updateDoc(userRef, {
            customAnnualIncomeToggle: this.customAnnualIncomeToggle,
            customAnnualIncome: this.customAnnualIncome,
          });
          console.log("Custom income settings saved successfully.");
        } catch (error) {
          console.error("Error saving custom spend settings:", error);
        }
      },
      toggleFIAmount() {
        this.manualFIAmountToggle = !this.manualFIAmountToggle;
        console.log("Toggle state after click:", this.manualFIAmountToggle);
      },
      async saveToggleStateToDatabase() {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        try {
          await updateDoc(userRef, {
            manualFIAmountToggle: this.manualFIAmountToggle,
          });
          console.log("Toggle state saved successfully.");
        } catch (error) {
          console.error("Error saving toggle state:", error);
        }
      },
      async fetchUserData() {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, `users/${userId}`);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          this.birthdate = userData.birthdate; // Store the birthdate
          this.userBirthYear = new Date(userData.birthdate).getFullYear();
          this.inflationRate = userData.inflationRate || this.inflationRate;
          this.desiredFIDate = userData.desiredFIDate || this.desiredFIDate;
          this.incomeIncrease = userData.incomeIncrease || this.incomeIncrease;
          this.withdrawalRate = userData.withdrawalRate || this.withdrawalRate;
          this.savingsRate = userData.savingsRate || this.savingsRate;
          this.manualFIAmountToggle = userData.manualFIAmountToggle || false; // Set the toggle state
          this.manualFIAmount = userData.manualFIAmountToggle ? userData.manualFIAmount : this.manualFIAmount;
          this.customAnnualSpendToggle = userData.customAnnualSpendToggle || false;
          this.customAnnualSpend = userData.customAnnualSpend || this.originalAnnualSpend;
          this.customAnnualIncomeToggle = userData.customAnnualIncomeToggle || false;
          this.customAnnualIncome = userData.customAnnualIncome || this.originalAnnualIncome;
          this.includeSocialSecurity = userData.includeSocialSecurity || false;
          this.socialSecurityStartAge = userData.socialSecurityStartAge || 67;
          this.earlySocialSecurity = userData.earlySocialSecurity || false;
          this.estimatedSocialSecurityBenefit = userData.estimatedSocialSecurityBenefit || 0;
          // Here, we decide which value to use for totalAnnualSpend based on the toggle's state
          if (this.customAnnualSpendToggle) {
            this.totalAnnualSpend = this.customAnnualSpend;
          } else {
            this.totalAnnualSpend = this.originalAnnualSpend;
          }
          if (this.customAnnualIncomeToggle) {
            this.totalAnnualIncome = this.customAnnualIncome;
          } else {
            this.totalAnnualIncome = this.originalAnnualIncome;
          }
        } else {
          console.log("No user document found!");
        }
      },
      updateChart() {
        this.renderSpendingChart(); // Rerender the chart when the display mode changes
      },
      async fetchLiabilities() {
        const userId = auth.currentUser.uid;
        const liabilitiesRef = collection(db, `users/${userId}/liabilities`);
        try {
          const querySnapshot = await getDocs(liabilitiesRef);
          this.liabilities = querySnapshot.docs.map(doc => {
            const data = doc.data();
            if (data.purchaseDate && data.loanTerm && data.monthlyPayment) { // Check if necessary fields are present
              const purchaseDate = new Date(data.purchaseDate);
              const payoffDate = new Date(purchaseDate.setMonth(purchaseDate.getMonth() + data.loanTerm));
              return {
                ...data,
                payoffDate: payoffDate.toISOString().split('T')[0] // Format as YYYY-MM-DD
              };
            }
            return null; // Return null for liabilities without the required fields
          }).filter(liability => liability !== null); // Filter out the null entries
        } catch (error) {
          console.error("Error fetching liabilities:", error);
        }
      },

      // calculateFutureSavings() {
      //   let futureSavings = [];
      //   let assetsCopy = JSON.parse(JSON.stringify(this.assets)); // Deep copy of assets
      //   let currentSavings = assetsCopy.reduce((total, asset) => {
      //     return total + (asset.includeInCalculation ? (asset.status === 'Financed' ? asset.equity : asset.currentBalance) : 0);
      //   }, 0);
      //   let annualIncome = this.totalAnnualIncome;
      //   const fireYearIndex = this.yearsUntilFIRE + 1;
      //   const yearsToProject = parseInt(this.yearsToProject);
      //   const currentYear = new Date().getFullYear();

      //   for (let year = 1; year <= yearsToProject; year++) {
      //     const yearToCheck = currentYear + year - 1;

      //     // Check for loan payoffs in the current year
      //     this.liabilities.forEach(liability => {
      //       const payoffYear = new Date(liability.payoffDate).getFullYear();
      //       if (payoffYear === yearToCheck) {
      //         const annualPayment = Math.abs(liability.monthlyPayment) * 12;
      //         currentSavings += annualPayment;
      //       }
      //     });

      //     let annualGrowth = 0;
      //     let assetGrowthLog = [];

      //     assetsCopy = assetsCopy.map(asset => {
      //       if (asset.includeInCalculation) {
      //         const apy = asset.manualEntryEnabled ? asset.expectedReturn : asset.historicalReturn || 0;
      //         let baseValue = asset.status === 'Financed' ? asset.equity : asset.currentBalance;
      //         let growth = baseValue * (apy / 100);
      //         annualGrowth += growth;
      //         baseValue += growth;

      //         let updatedAsset = { ...asset, currentBalance: baseValue, equity: asset.status === 'Financed' ? baseValue : asset.equity };
      //         assetGrowthLog.push({ name: asset.name, value: baseValue });
      //         return updatedAsset;
      //       }
      //       return asset;
      //     });

      //     console.log(`Year ${year}:`, assetGrowthLog);

      //     if (year < fireYearIndex) {
      //       const annualSavings = annualIncome * (this.savingsRate / 100);
      //       currentSavings += annualGrowth + annualSavings;
      //       annualIncome *= (1 + this.incomeIncrease / 100);
      //     } else {
      //       currentSavings = currentSavings * (1 - 0.03) + annualGrowth;
      //     }

      //     futureSavings.push(currentSavings);
      //   }

      //   return futureSavings;
      // },
      calculateFutureSavings() {
        let futureSavings = [];
        let assetsCopy = JSON.parse(JSON.stringify(this.assets));
        let currentDate = new Date();
        let currentSavings = assetsCopy.reduce((total, asset) => {
          return total + (asset.includeInCalculation ? (asset.status === 'Financed' ? asset.equity : asset.currentBalance) : 0);
        }, 0);
        let annualIncome = this.totalAnnualIncome;
        const yearsToProject = parseInt(this.yearsToProject);
        let fireReached = false;

        for (let year = 1; year <= yearsToProject; year++) {
          const currentAge = this.userBirthYear ? currentDate.getFullYear() - this.userBirthYear : 0;
          
          // Calculate Social Security benefit
          let ssiBenefit = 0;
          if (this.includeSocialSecurity) {
            if (this.includeSocialSecurity && currentAge >= this.socialSecurityStartAge) {
              ssiBenefit = this.calculateSocialSecurityBenefit(this.socialSecurityStartAge) * 12;
            }
          }

          let annualGrowth = 0;
          assetsCopy.forEach(asset => {
            if (asset.includeInCalculation) {
              const apy = asset.manualEntryEnabled ? asset.expectedReturn : asset.historicalReturn || 0;
              let assetBaseValue = asset.status === 'Financed' ? asset.equity : asset.currentBalance;
              let growth = assetBaseValue * (apy / 100);
              annualGrowth += growth;
              asset.currentBalance = assetBaseValue + growth;
              asset.equity = asset.status === 'Financed' ? asset.currentBalance : asset.equity;
            }
          });

          currentSavings += annualGrowth;

          if (!fireReached) {
            const annualSavings = (annualIncome * (this.savingsRate / 100));
            currentSavings += annualSavings;

            // If savings rate is negative, we need to ensure we're not withdrawing more than available
            if (this.savingsRate < 0) {
              currentSavings = Math.max(0, currentSavings);
            }

            annualIncome *= (1 + this.incomeIncrease / 100);
          } else {
            // Subtract annual living expenses
            const annualExpenses = this.customAnnualSpendToggle ? this.customAnnualSpend : this.totalAnnualSpend;
            currentSavings -= annualExpenses;
          }

          currentSavings += ssiBenefit;

          if (currentSavings >= this.targetFIAmount && !fireReached) {
            fireReached = true;
            console.log(`FIRE reached at year ${year}, age ${currentAge}`);
          }

          futureSavings.push(currentSavings);
          currentDate.setFullYear(currentDate.getFullYear() + 1);
        }

        return futureSavings;
      },

      calculateSocialSecurityBenefit(startAge) {
        const fullRetirementAge = 67; // Assuming FRA is 67 for simplicity
        let maxBenefit = this.maxSocialSecurityBenefitAtFRA;

        // Adjust max benefit based on start age
        if (startAge < fullRetirementAge) {
            const yearsEarly = fullRetirementAge - startAge;
            const reductionRate = yearsEarly <= 3 ? 0.0667 : 0.05;
            maxBenefit *= (1 - (yearsEarly * reductionRate));
        } else if (startAge > fullRetirementAge) {
            const yearsDelayed = startAge - fullRetirementAge;
            maxBenefit *= (1 + (yearsDelayed * 0.08));
        }

        // Use the smaller of the user's estimated benefit or the calculated max benefit
        let benefit = this.estimatedSocialSecurityBenefit > 0 ? Math.min(this.estimatedSocialSecurityBenefit, maxBenefit) : maxBenefit;


        return benefit;
      },
      validateEstimatedBenefit(value) {
        const maxPossibleBenefit = this.calculateSocialSecurityBenefit(70); // Max at age 70
        this.estimatedSocialSecurityBenefit = Math.min(value, maxPossibleBenefit);
        this.updateProjections();
      },





      
      calculateFutureSpending(annualSpend, inflationRate) {
        let futureSpending = [];
        let currentSpend = parseFloat(annualSpend);
        const rate = parseFloat(inflationRate);
        const currentYear = new Date().getFullYear();
        const yearsToProject = parseInt(this.yearsToProject);


        if (isNaN(currentSpend) || isNaN(rate)) {
          console.error('Annual spend or inflation rate is not a number:', currentSpend, rate);
          return [];
        }

        for (let year = 1; year <= yearsToProject; year++) {
          const yearToCheck = currentYear + year - 1;
          let activeLoanPayments = 0;

          // Calculate total annual loan payments and determine active loan payments for the year
          this.liabilities.forEach(liability => {
            const payoffYear = new Date(liability.payoffDate).getFullYear();
            const annualPayment = Math.abs(liability.monthlyPayment) * 12;
            
            if (payoffYear >= yearToCheck) {
              activeLoanPayments += annualPayment;
            }

            // Subtract loan payments only when the loan is paid off
            if (payoffYear === yearToCheck) {
              currentSpend -= annualPayment;
            }
          });

          // Subtract loan payments from current spend before applying inflation
          let spendWithoutLoans = currentSpend - activeLoanPayments;

          // Apply inflation to the spend without inactive loan payments
          spendWithoutLoans *= (1 + rate / 100);

          // Add active loan payments back
          let totalSpendForYear = spendWithoutLoans + activeLoanPayments;

          // Update currentSpend for the next year
          currentSpend = totalSpendForYear;

          // Push the total spend for the year to futureSpending
          futureSpending.push(Math.round(totalSpendForYear * 25)); // Multiply by 25 and then round
        }

        return futureSpending;
      },




      renderTable() {

        const yearsToProject = parseInt(this.yearsToProject);
        const labels = Array.from({ length: yearsToProject }, (_, i) => new Date().getFullYear() + i);
        const futureSpending = this.calculateFutureSpending(this.totalAnnualSpend, this.inflationRate);
        const futureSavings = this.calculateFutureSavings();
        const fireAmountLine = Array(labels.length).fill(this.targetFIAmount);
        const table = document.getElementById('chartDataTable');
        const thead = table.getElementsByTagName('thead')[0];
        const tbody = table.getElementsByTagName('tbody')[0];

        // Clear existing headers and rows
        thead.innerHTML = '';
        tbody.innerHTML = '';

        // Creating header row
        let headerRow = thead.insertRow();
        headerRow.insertCell().textContent = 'Year';
        headerRow.insertCell().textContent = 'FIRE Target Adjusted for Inflation';
        headerRow.insertCell().textContent = 'Expected Savings';
        headerRow.insertCell().textContent = 'FIRE Target Amount';

        // Populate rows with data
        labels.forEach((label, index) => {
          let row = tbody.insertRow();
          row.insertCell().textContent = label;

          let spendingCell = row.insertCell();
          spendingCell.textContent = futureSpending[index] ? futureSpending[index].toFixed(2) : '0.00';

          let savingsCell = row.insertCell();
          savingsCell.textContent = futureSavings[index] ? futureSavings[index].toFixed(2) : '0.00';

          let fireAmountCell = row.insertCell();
          fireAmountCell.textContent = fireAmountLine[index] ? fireAmountLine[index].toFixed(2) : '0.00';
        });
      },

      renderSpendingChart() {
        const canvas = document.getElementById('spendingChart');
        if (!canvas) {
          console.error('Canvas element not found');
          return;
        }
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          console.error('Unable to get 2D context for canvas');
          return;
        }

        // Destroy existing chart if it exists
        if (this.spendingChart) {
          this.spendingChart.destroy();
        }
        const SpendingChartTextPlugin = {
          id: 'SpendingChartTextPlugin',
          afterDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.save();

          // Iterate through all datasets
          chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            // Check for the "FIRE Target Amount" dataset and draw the label
            if (dataset.label === 'FIRE Target Amount' && meta.data.length > 0) {
              const firstPoint = meta.data[0];
              const fireTargetValue = dataset.data[0].toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              });
              const labelText = `Target: ${fireTargetValue}`;
              ctx.font = 'bold 12px Arial';
              const textWidth = ctx.measureText(labelText).width;
              const textHeight = 14; // Approximate height of the text
              const padding = 4;
              const backgroundX = chart.chartArea.left + 10;
              const backgroundY = firstPoint.y - textHeight / 2 - padding / 2;

              // Draw background rectangle
              ctx.fillStyle = '#f9f9f900'; 
              ctx.fillRect(backgroundX, backgroundY, textWidth + padding, textHeight + padding);

              // Draw text
              ctx.fillStyle = '#000000';
              ctx.fillText(labelText, backgroundX + padding / 2, firstPoint.y + 14 + textHeight / 4);
            }
          });

          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const index = activePoint.index;
            const label = chart.data.labels[index]; // This will either be year or age depending on `timeDisplayMode`
            const year = this.timeDisplayMode === 'Age' ? (this.userBirthYear + label) : label;
            const age = this.timeDisplayMode === 'Age' ? label : (year - this.userBirthYear);  // Calculate age
            const x = activePoint.element.x;

            const savingsDatasetIndex = chart.data.datasets.findIndex(dataset => dataset.label === 'Expected Savings');
            const targetDatasetIndex = chart.data.datasets.findIndex(dataset => dataset.label === 'FIRE Target Amount');
            const savingsY = chart.getDatasetMeta(savingsDatasetIndex).data[index].y;
            const targetY = chart.getDatasetMeta(targetDatasetIndex).data[index].y;

            ctx.beginPath();
            ctx.setLineDash([5, 5]); // Dashed line pattern
            ctx.moveTo(x, savingsY);
            ctx.lineTo(x, targetY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#777';
            ctx.stroke();

            // Safely extract and format the savings value
            let savingsValue = chart.data.datasets[savingsDatasetIndex].data[index];
            savingsValue = typeof savingsValue === 'object' ? savingsValue.y : savingsValue;

            const formattedSavingsValue = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(savingsValue);

            // Determine text alignment based on chart midpoint
            const midpointX = chart.chartArea.right / 2;
            const textAlignment = x < midpointX ? 'left' : 'right';
            const textOffset = 10;
            const textX = textAlignment === 'left' ? x + textOffset : x - textOffset;

            // Set text alignment and draw text
            ctx.textAlign = textAlignment;
            ctx.textBaseline = 'middle';
            ctx.font = 'bold 30px Arial';
            ctx.fillText(`${formattedSavingsValue}`, textX, targetY + (savingsY - targetY) / 2 + 20);
            ctx.font = 'bold 14px Arial';
            ctx.fillText(`Forecasted Savings`, textX, targetY + (savingsY - targetY) / 2 + 45);
            ctx.font = 'bold 20px Arial';
            ctx.fillText(`${year} | Age: ${age}`, textX, targetY + (savingsY - targetY) / 2 + 70);
          }


            ctx.restore();
          }
        };

        const liabilities = this.liabilities;
        const futureSpending = this.calculateFutureSpending(this.totalAnnualSpend, this.inflationRate);
        const futureSavings = this.calculateFutureSavings(this.totalAnnualIncome, this.savingsRate, this.inflationRate);
        // const ctx = document.getElementById('spendingChart').getContext('2d');
        const currentYear = new Date().getFullYear();
        const yearsToProject = parseInt(this.yearsToProject);
        const datasets = [];
        let labels = Array.from({ length: yearsToProject }, (_, i) => currentYear + i);

        // Adjust labels based on the timeDisplayMode
        if (this.timeDisplayMode === 'Age') {
          const currentAge = currentYear - this.userBirthYear;
          labels = labels.map(year => currentAge + (year - currentYear));
        }

        const fireAmountLine = Array(labels.length).fill(this.targetFIAmount);

        if (this.chartType === 'Table') {
          this.renderTable();
          return;
        }

        document.getElementById('spendingChart').style.display = '';
        document.getElementById('chartDataTable').style.display = 'none';
        const fireYearIndex = futureSavings.findIndex(savings => savings >= this.targetFIAmount);
        if (this.spendingChart) {
          this.spendingChart.destroy();
        }

        ctx.canvas.width = 700;
        ctx.canvas.height = 400;

        let gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        gradient.addColorStop(0, "#78edff"); // Starting color
        gradient.addColorStop(0.4, "rgba(120, 237, 255, .5)"); // Intermediate color to ensure more solid color lower
        gradient.addColorStop(1, "rgba(120, 237, 255, 0)"); // Transparent color

        const loanPayoffLabels = {};
        liabilities.forEach(liability => {
          const payoffYear = new Date(liability.payoffDate).getFullYear() - 1;
          loanPayoffLabels[payoffYear] = liability.name;
        });
        
        if (this.showFutureSpending) {
        datasets.push({
          label: 'FIRE Target Adjusted for Inflation',
          data: futureSpending,
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          tension: 0.4,
          spanGaps: true,
          pointRadius: 0,
          fill: false,
          datalabels: {
            align: 'top',
            formatter: function(value, context) {
              const yearToCheck = currentYear + context.dataIndex;
              const payoffLoanName = loanPayoffLabels[yearToCheck];

              if (payoffLoanName) {
                return `🎉 Paid Off ${payoffLoanName}`;
              }
              return null;
            },
          }
        });
      }

        if (this.showFIRETargetAmount) {
          datasets.push({
            label: 'FIRE Target Amount',
            type: 'line',
            data: fireAmountLine,
            borderColor: '#b7b7b7',
            borderWidth: 2,
            pointRadius: 0,
            fill: false,
            borderDash: [10, 5],
            datalabels: {
              display: false,
            }
          });
        }

        if (this.showExpectedSavings) {
          datasets.push({
            label: 'Expected Savings',
            data: futureSavings,
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
            tension: 0.4,
            backgroundColor: gradient,
            pointRadius: 0,
            pointHoverRadius: 5,
            fill: true,
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function(value, context) {
                if (context.dataIndex === fireYearIndex) {
                  return '🔥';
                }
                return null;
              }
            },
          });
        }

      // Destroy the existing chart instance if it exists
      if (this.spendingChart) {
        this.spendingChart.destroy();
      }

        this.spendingChart = new Chart(ctx, {
          type: this.chartStyle,
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
                axis: 'x'
              },
            },
            layout: {
              padding: {
                top: 50, // Increase this value to add more space at the top
              }
            },  
            scales: {
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                },
                ticks: {
                  callback: function(val) {
                    // Get the full year value from the labels array using the tick index
                    const year = this.getLabelForValue(val);
                    // Check if the year ends in 0 or 5
                    if (year % 5 === 0) {
                      return year;
                    }
                  }
                }
              },
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                },
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    if (value >= 1000000) {
                      return `${(value / 1000000).toFixed(1)}m`;
                    } else if (value >= 1000) {
                      return `${(value / 1000000).toFixed(1)}m`;
                    }
                    return value;
                  }
                }
              }
            },
            onHover: (event, chartElement) => {
              event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
            },
            hover: {
              mode: 'index',
              intersect: false,
              animationDuration: 400
            },
            animation: {
              duration: 1000,
              hover: {
                duration: 400
              }
            },
          },
          plugins: [SpendingChartTextPlugin, ChartDataLabels]
        });
        console.log("Future Savings: ", futureSavings);
        console.log("Future Spending: ", futureSpending);
      },

      async fetchAnnualTotals() {
        const currentYear = new Date().getFullYear();
        const lastFullYear = currentYear - 1;
        const userId = auth.currentUser.uid; 
        const totalsRef = doc(db, `users/${userId}/totals/summary`);
        try {
          const docSnap = await getDoc(totalsRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            // console.log('Fetched data:', data); 
            
            if (!this.customAnnualIncomeToggle) {
              this.totalAnnualIncome = parseFloat(data.totalAnnualIncome[lastFullYear.toString()] || 0);
              this.originalAnnualIncome = this.totalAnnualIncome; // Update originalAnnualIncome
            }
            // Only update this if customAnnualSpendToggle is false
            if (!this.customAnnualSpendToggle) {
              this.totalAnnualSpend = parseFloat(data.totalAnnualSpend[lastFullYear.toString()] || 0);
              this.originalAnnualSpend = this.totalAnnualSpend; // Update originalAnnualSpend
            }
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error getting document:", error);
        }
        this.originalAnnualSpend = this.totalAnnualSpend; // Store the original totalAnnualSpend
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(value);
      },
      formatValue(value) {
        if (value >= 1000) {
          return `${(value / 1000).toFixed(0)}k`; // Formats 45000 to "45k"
        }
        return value.toString(); // For values less than 1000
      },
      selectAssetClass(assetClass) {
        this.selectedAssetClass = assetClass;
      },
      async fetchAssets() {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            try {
              const assetsRef = collection(db, `users/${user.uid}/assets`);
              const querySnapshot = await getDocs(assetsRef);
              if (querySnapshot.empty) {
                console.log('No assets found.');
                return;
              }
              this.assets = querySnapshot.docs.map(doc => {
                const assetData = doc.data();
                let equity = assetData.currentValue - assetData.currentLoanBalance;
                let payoffDate = null; // Initialize payoffDate
                if (assetData.status === "Financed" && assetData.purchaseDate && assetData.loanTerm) {
                  const purchaseDate = new Date(assetData.purchaseDate);
                  purchaseDate.setFullYear(purchaseDate.getFullYear() + assetData.loanTerm / 12);
                  payoffDate = purchaseDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
                }
                // Determine the expectedReturn based on the manualEntryEnabled status
                let expectedReturn = assetData.manualEntryEnabled ? parseFloat((assetData.expectedReturn || 0).toFixed(2)) : parseFloat((assetData.historicalReturn || 0).toFixed(2));
                // console.log(`Asset: ${assetData.name}, Historical Return: ${assetData.historicalReturn}`);

                let currentBalance = assetData.currentValue; 
                return {
                  id: doc.id,
                  type: assetData.assetClass,
                  name: assetData.name,
                  value: assetData.currentValue,
                  currentBalance: currentBalance,
                  equity: equity,
                  expectedReturn: expectedReturn, // Use the determined expectedReturn
                  manualEntry: false, // This should be set based on your logic for manual entry
                  includeInCalculation: assetData.includeInCalculation ?? true,
                  status: assetData.status,
                  payoffDate: payoffDate,
                  manualEntryEnabled: assetData.manualEntryEnabled ?? false, // Set based on the data
                };
              });
            } catch (error) {
              console.error("Error fetching assets:", error);
            }
          } else {
            console.log('No user is logged in.');
          }
        });
      },
      toggleManualEntry(assetName) {
        console.log("Toggling manual entry for asset:", assetName);
        const asset = this.assets.find(a => a.name === assetName);
        if (asset) {
          asset.manualEntryEnabled = !asset.manualEntryEnabled;
          this.updateManualEntryEnabled(asset.id, asset.manualEntryEnabled);
        } else {
          console.error("Asset not found:", assetName);
        }
      },
      async updateManualEntryEnabled(assetId, manualEntryEnabled) {
        const assetRef = doc(db, `users/${auth.currentUser.uid}/assets/${assetId}`);
        try {
          await updateDoc(assetRef, {
            manualEntryEnabled: manualEntryEnabled,
          });
          console.log("Manual entry toggle updated successfully.");
        } catch (error) {
          console.error("Error updating manual entry toggle:", error);
        }
      },
    },
    computed: {
      reactiveTrigger() {
        return this.assets.map((asset, index) => `${asset.name || 'Asset ' + index}: ${asset.includeInCalculation}`).join(', ');
      },
      calculatedSavingsRate() {
        // Determine which income to use
        const income = this.customAnnualIncomeToggle ? this.customAnnualIncome : this.totalAnnualIncome;
        
        // Determine which spend to use
        const spend = this.customAnnualSpendToggle ? this.customAnnualSpend : this.totalAnnualSpend;
        
        // Perform the calculation
        if (income > 0) {
          const savingsRate = ((income - spend) / income) * 100;
          return Math.round(savingsRate * 100) / 100; // Round to 2 decimal places
        } else {
          return 0; // Return 0 if income is 0 or negative to avoid division by zero
        }
      },
      formattedProgressPercentage() {
        return isNaN(this.progressPercentage) ? '0%' : `${Math.round(this.progressPercentage)}%`;
      },
      progressPercentage() {
        const totalIncludedAssets = this.totalBalance;
        const fireTarget = this.targetFIAmount;
        return (totalIncludedAssets / fireTarget) * 100;
      },
      currentFire() {
        const totalIncludedAssets = this.totalBalance;
        return (totalIncludedAssets * 0.04 );
      },
      currentMonthlyFire() {
        const totalIncludedAssets = this.totalBalance;
        return (totalIncludedAssets * 0.04 / 12 ).toFixed(0);
      },
      yearsUntilFIRE() {
        if (!this.totalAnnualSpend) {
          return Infinity;
        }
        let assetsCopy = JSON.parse(JSON.stringify(this.assets));
        let currentDate = new Date();
        let currentSavings = assetsCopy.reduce((total, asset) => {
          return total + (asset.includeInCalculation ? (asset.status === 'Financed' ? asset.equity : asset.currentBalance) : 0);
        }, 0);
        let initialFIRETarget = this.targetFIAmount;
        let annualIncome = this.totalAnnualIncome;
        let years = 0;

        console.log('Initial Asset State:');
        console.table(assetsCopy.map(asset => ({
          Name: asset.name,
          Type: asset.type,
          IncludedInCalc: asset.includeInCalculation ? 'Yes' : 'No',
          CurrentBalance: this.formatCurrency(asset.currentBalance),
          Equity: this.formatCurrency(asset.equity),
          ReturnRate: `${asset.manualEntryEnabled ? asset.expectedReturn : asset.historicalReturn || 0}%`
        })));

        console.table([{
          Year: 'Initial',
          Age: this.userBirthYear ? currentDate.getFullYear() - this.userBirthYear : 'N/A',
          CurrentSavings: this.formatCurrency(currentSavings),
          AnnualIncome: this.formatCurrency(annualIncome),
          FIRETarget: this.formatCurrency(initialFIRETarget),
          SavingsRate: `${this.savingsRate}%`,
          SSIBenefit: this.formatCurrency(0)
        }]);

        let yearlyData = [];
        let previousYearSavings = currentSavings;

        while (currentSavings < initialFIRETarget && years < 200) {
          const currentAge = this.userBirthYear ? currentDate.getFullYear() - this.userBirthYear : 0;
          
          let ssiBenefit = 0;
          if (this.includeSocialSecurity) {
            if ((this.earlySocialSecurity && currentAge >= 62) || (!this.earlySocialSecurity && currentAge >= 67)) {
              ssiBenefit = this.calculateSocialSecurityBenefit(currentAge) * 12;
            }
          }

          let annualGrowth = 0;
          let assetGrowthDetails = [];
          assetsCopy.forEach(asset => {
            if (asset.includeInCalculation) {
              const apy = asset.manualEntryEnabled ? asset.expectedReturn : asset.historicalReturn || 0;
              let assetBaseValue = asset.status === 'Financed' ? asset.equity : asset.currentBalance;
              let growth = assetBaseValue * (apy / 100);
              annualGrowth += growth;
              asset.currentBalance = assetBaseValue + growth;
              asset.equity = asset.status === 'Financed' ? asset.currentBalance : asset.equity;

              assetGrowthDetails.push({
                Name: asset.name,
                PreviousValue: this.formatCurrency(assetBaseValue),
                Growth: this.formatCurrency(growth),
                NewValue: this.formatCurrency(asset.currentBalance),
                ReturnRate: `${apy}%`
              });
            }
          });

          previousYearSavings = currentSavings;
          currentSavings += annualGrowth;

          const annualSavings = (annualIncome * (this.savingsRate / 100));
          currentSavings += annualSavings;
          // Handle negative savings rate
          if (this.savingsRate < 0) {
            currentSavings = Math.max(0, currentSavings);
          }
          
          annualIncome *= (1 + this.incomeIncrease / 100)

          currentSavings += ssiBenefit;

          yearlyData.push({
            Year: currentDate.getFullYear(),
            Age: currentAge,
            CurrentSavings: this.formatCurrency(currentSavings),
            FIRETarget: this.formatCurrency(initialFIRETarget),
            AnnualIncome: this.formatCurrency(annualIncome),
            AnnualGrowth: this.formatCurrency(annualGrowth),
            SSIBenefit: this.formatCurrency(ssiBenefit),
            FireReached: currentSavings >= initialFIRETarget ? 'Yes' : 'No'
          });

          console.log(`Year ${currentDate.getFullYear()} Asset Growth:`);
          console.table(assetGrowthDetails);

          if (currentSavings >= initialFIRETarget) {
            // Linear interpolation to find the exact point within the year
            const excessSavings = currentSavings - initialFIRETarget;
            const savingsGrowthThisYear = currentSavings - previousYearSavings;
            const fractionOfYear = 1 - (excessSavings / savingsGrowthThisYear);
            years += fractionOfYear;
            console.log(`FIRE reached at ${years.toFixed(2)} years, age ${(currentAge + fractionOfYear).toFixed(2)}`);
            break;
          }

          currentDate.setFullYear(currentDate.getFullYear() + 1);
          years++;
        }

        console.log('Yearly Summary:');
        console.table(yearlyData);

        console.log(`Final years until FIRE: ${years.toFixed(2)}`);
        return years < 100 ? parseFloat(years.toFixed(2)) : Infinity;
      },



      timeUntilFIRE() {
        if (!this.fireDate) {
          return '';
        }

        const currentDate = new Date();
        const fireDate = new Date(this.fireDate);

        if (isNaN(fireDate.getTime()) || fireDate <= currentDate) {
          return '0 years';
        }

        const diffInMs = fireDate - currentDate;
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365.25);

        return `${diffInYears.toFixed(2)} years`; // Show two decimal places
      },
      fireAge() {
        if (!this.birthdate) {
          console.log("Birthdate is not available.");
          return 'N/A';
        }
        const birthdate = new Date(this.birthdate);
        const currentDate = new Date();
        const yearsUntilFIRE = this.yearsUntilFIRE;
        
        // Calculate current age more precisely
        const ageInMilliseconds = currentDate - birthdate;
        const currentAge = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

        // Check if FIRE is reached
        if (yearsUntilFIRE === Infinity) {
          console.log("FIRE not reached within the given constraints.");
          return 'Not reached';
        }
        
        // Add yearsUntilFIRE to current age
        const fireAge = currentAge + yearsUntilFIRE - 1;
        
        console.log(`Current Age: ${currentAge.toFixed(2)}, Years Until FIRE: ${yearsUntilFIRE.toFixed(1)}, FIRE Age: ${fireAge.toFixed(2)}`);
        return fireAge.toFixed(1); // Two decimal points for more accuracy
      },
      includedAssetsTotalBalance() {
        let total = 0;
        this.assets.forEach((asset) => {
          // console.log(`Asset: ${asset.name}, Current Balance: ${asset.currentBalance}, Include: ${asset.includeInCalculation}`);
          if (asset.includeInCalculation) {
            total += asset.currentBalance; // Use currentBalance instead of value
          }
        });
        console.log(`Total Included Assets Balance: ${total}`);
        return total;
      },
      fireDate() {
        const currentDate = new Date();
        const yearsUntilFIRE = this.yearsUntilFIRE - 1;
        const daysUntilFIRE = Math.floor(yearsUntilFIRE * 365.25); // Using 365.25 to account for leap years
        const targetDate = new Date(currentDate.getTime() + daysUntilFIRE * 24 * 60 * 60 * 1000);
        return targetDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      },
      uniqueAssetClasses() {
        return [...new Set(this.assets.map(asset => asset.type))];
      },
      targetFIAmount() {
        return this.manualFIAmountToggle ? this.manualFIAmount : this.totalAnnualSpend * 25;
      },
      totalBalance() {
        return this.assets.reduce((total, asset) => {
          // If the asset is financed, use the equity value, otherwise use the current value
          const valueToAdd = asset.status === 'Financed' ? asset.equity : asset.currentBalance;
          return total + valueToAdd;
        }, 0);
      },
      assetClassTotals() {
        const totals = this.assets.reduce((acc, asset) => {
          // Determine the value to use based on whether the asset is financed
          const valueToUse = asset.status === 'Financed' ? asset.equity : asset.currentBalance;
          if (!acc[asset.type]) {
            acc[asset.type] = {
              totalValue: 0,
              name: asset.type
            };
          }
          acc[asset.type].totalValue += valueToUse;
          return acc;
        }, {});
        const totalValue = Object.values(totals).reduce((acc, {
          totalValue
        }) => acc + totalValue, 0);
        return Object.keys(totals).map(type => {
          const percentage = ((totals[type].totalValue / totalValue) * 100).toFixed(0);
          return {
            label: `$${this.formatValue(totals[type].totalValue)}`,
            type: type,
            percentage: percentage // Store the calculated percentage for each asset class
          };
        });
      },
      filteredAssets() {
        if (!this.selectedAssetClass) {
          return this.assets;
        }
        return this.assets.filter(asset => asset.type === this.selectedAssetClass);
      },
      assetAllocation() {
        const includedAssets = this.assets.filter(asset => asset.includeInCalculation);
        const totalValue = includedAssets.reduce((acc, asset) => acc + (asset.status === "Financed" ? asset.equity : asset.currentBalance), 0);
        return includedAssets.reduce((acc, asset) => {
          const value = asset.status === "Financed" ? asset.equity : asset.currentBalance;
          acc[asset.type] = ((value / totalValue) * 100).toFixed(0);
          return acc;
        }, {});
      },
    },
  };
</script>
<style scoped>
  h2 {
    text-align: center;
  }

  .settings label {
    display: block;
    margin-bottom: 10px;
  }



  .years-text {
    font-size: 40px;
  }

  .fire-date {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .active {
    color: black !important;
  }

  .asset-classes {
    display: flex;
    margin: 50px 50px;
    justify-content: space-evenly;
  }

  .asset-classes>button:hover {
    color: black;
  }

  .asset-second-row {
    display: flex; 
    width: 100%; 
    justify-content: space-between;
  }

  .asset-entry {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
    width: 80%;
    margin: 0px;
    padding: 20px;
    text-align: left;
  }

  .asset-entries-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 50px;
  }

  .asset-name {
    font-size: 20px;
    margin: auto 0;
  }

  .asset-classes>button {
    background-color: unset;
    color: #a3a1a1;
    font-size: 20px;
    font-weight: 600;
  }

  input {
    width: 150px;
    padding: 10px;
    border: 1px solid #cbd5e1;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    outline-color: transparent;
    background-color: #fff;
    font-size: 14px;
    color: #64748b;
    margin: 1%;
    font-family: sans-serif;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }

  .fire-progress {
    width: 80%;
    height: 20px;
    background-color: #f4f4f4;
    border: solid thin #f2f2f2;
    border-radius: 15px;
    margin: 20px auto;
    position: relative;
  }
  .fire-progress-now {
    width: 80%;
    height: 20px;
    border-radius: 15px;
    margin: 20px auto;
    position: relative;
  }

  .progress-bar {
    height: 100%;
    min-width: 5px;
    background-color: #7cbef5;
    border-radius: 15px 0px 0px 15px;
    transition: width 0.5s ease;
  }

  .fire-settings {
    grid-area: 1 / 4 / 5 / 5;
    width: 95%;
    margin: 10px auto;
    transition: transform 0.3s ease-in-out;
    position: relative;
  }

  .drawer-closed {
    transform: translateX(calc(100% - 20px));
  }

  .drawer-toggle {
    position: absolute;
    top: -15px;
    left: calc(50% - 25px);
    width: 50px;
    height: 15px;
    background-color: #c4c4c4;
    border-radius: 10px 10px 0px 0px;
    display: none;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); */
    z-index: 1;
  }

  .drawer-toggle2 {
    position: absolute;
    top: 50%;
    left: -10px;
    width: 20px;
    height: 60px;
    background-color: #fff;
    border-radius: 50% 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }

  .drawer-toggle2:hover {
    box-shadow: -9px 0px 11px 0px  rgba(0, 0, 0, 9%);
  }

  .drawer-toggle:hover {
      box-shadow: -9px 0px 11px 0px  rgba(0, 0, 0, 9%);
      color: black;
  }

  .drawer-content {
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    box-shadow: rgba(29, 29, 29, 0.05) 0px 6px 15px 0px;
    border-radius: 10px;
  }

  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    font-weight: bold;
  }
  .asset-name-row {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .asset-balance {
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    visibility: hidden;
    width: 150px;
    font-size: 16px;
    background-color: #7a7a7a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    /* Use half of the width value to align it centered */
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .asset-balance:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .checkbox-wrapper-2 {
    padding-left: 4px;
    height: 20px;
  }

  .checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
    margin-right: 10px;
  }

  .checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  .checkbox-wrapper-2 .ikxBAC,
  .checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
  }

  .checkbox-wrapper-2 .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
  }

  .checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
  }

  .checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
  }

  .checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #6e79d6;
  }

  .checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
  }

  .checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
  }

  .checkbox-wrapper-2 .ikxBAC:checked:hover {
    background-color: #535db3;
  }


  .asset-class-total {
  font-size: 30px; 
  font-weight: 900;
}

  .vueInputField {
    margin: 10px auto;
  }

  .fire-toggle-label {
    visibility: hidden;
    margin: auto 0;
  }

  .fire-toggle-label-parent:hover>.fire-toggle-label {
    visibility: visible;
    cursor: pointer;
    align-items: center;
  }

  input[type="checkbox"]:hover {
    cursor: pointer;
  }



  .fire-parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .fire-age {
    grid-area: 1 / 1 / 2 / 4;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: .25fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .fire-text { 
    grid-area: 1 / 1 / 2 / 3; 
    padding: 20px 0px 0px 50px;
    text-align: left;
  }
  .fire-age2 { 
    grid-area: 1 / 2 / 2 / 3; 
    margin-top: -100px;
    padding-right: 30px;
    padding-top: 20px;
    text-align: right;
    font-size: 60px;
    font-weight: 900;
  }

  .fire-settings {
    grid-area: 1 / 4 / 5 / 5;
    width: 95%;
    margin: 10px auto;
  }

  .fire-chart {
    grid-area: 1 / 1 / 5 / 4;
    padding: 100px 0px 0px;
    margin: 10px 10px 10px 30px;
    border-radius: 10px;
    transition: all 0.3s ease;
  }

  .fire-chart.chart-expanded {
    grid-area: 1 / 1 / 5 / 5;
    margin-right: 30px; /* Add right margin when expanded */
  }

  #chartDataTable {
    height: 0px;
}

.custom-checkbox {
  margin-bottom: 4px; /* Reduce bottom margin */
}

.loan-payoff-date {
    margin-bottom: 10px;
    margin-top: -16px;
  }

/* If you want to apply the spacing reduction globally to all v-checkbox in the dialog */
.v-dialog .v-checkbox {
  margin-bottom: -40px;
}

.edited-field {
  box-shadow: 0px 6px 15px 0px rgba(29, 29, 29, 0.05);
  animation: glowing 2.5s infinite;
  border: solid thin #9fb8cd;
}

.text-h4 {
  font-size: 1rem !important;
}

.balance-label, .equity-label {
  font-size: 14px;
}



.content-hidden {
  display: none;
}

.fire-progress-amounts {
  display: flex;
  margin-bottom: 20px;
}

.manual-entry-toggle {
  display:inline-flex; 
  align-items: center;
}

.asset-class-percentage {
  font-size: 14px; 
  font-weight: 900;
}

.custom-annual-spend-field {
  margin-top: 10px;
}

.fire-progress-parent {
  margin: 0 auto;
  grid-area: 5 / 1 / 5 / 5;
  height: auto;
  width: 90%;
  overflow-y: auto;
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .fire-age2 {
    margin-top: -70px;
  }
}

@media screen and (max-width: 768px) {
  
  .fire-parent {
    display: unset !important;
    font-size: small;
  }

  .asset-entry {
    width: 90%;
    padding: 10px;
  }

  .fire-text {
    padding: 20px;
    display: ruby-text;
    text-align: center;
    margin-top: 40px !important;
  }

  .fire-age2 {
    margin: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-align: center;
  }

  .asset-classes {
    /* display: grid; */
    margin: 10px 10px;
  }
  .asset-classes>button {
    font-size: 10px;
  }
  .asset-name {
    font-size: 14px;
  }

  .asset-balance {
    font-size: 14px;
  }

  .asset-class-total {
    font-size: 20px; 
    font-weight: 900;
  }

  .asset-class-percentage {
    font-size: 10px; 
  }

  .asset-second-row {
    font-size: 12px;
  }

  .balance-label, .equity-label {
    display: none;
  }

  .fire-toggle-label {
    display: block;
    text-align: right;
    font-size: 10px;
    visibility: visible;
  }

  .manual-entry-toggle {
    font-size: 10px;
    gap: 6px;
  }

  .fire-chart {
    padding: 0px;
    margin: 0px; 
    max-width: 100vw;
  }

  .fire-settings {
    margin: 25px auto;
  }

  .drawer-toggle {
    position: absolute;
    top: -20px;
    left: calc(50% - 25px);
    width: 60px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    color: #000000;
    border: solid thin #f8f8f8;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); */
    z-index: 1;
  }

  .drawer-toggle2 {
    display: none;
  }

  .drawer-closed {
    transform: none !important;
  }

  .loan-payoff-date {
    font-size: 10px;
  }

  .drawer-content {
    margin-top: 25px;
  }

}



@keyframes glowing {
  0% {
    transform: scale(1);
    box-shadow: 0px 6px 15px 0px rgba(29, 29, 29, 0.05);
  }
  50% {
    transform: scale(1.15);
    box-shadow: 0px 8px 20px 0px rgba(29, 29, 29, 0.1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 6px 15px 0px rgba(29, 29, 29, 0.05);
  }
}

.v-slider {
    margin-top: 20px;
    margin-bottom: -30px;
  }

  .v-slider__thumb-label {
    background-color: #1976D2 !important;
  }

  .income-increase-slider .v-slider__thumb-label {
    background-color: #4CAF50 !important;
  }

  .slider-label {
    padding-bottom: 10px;
    text-align: right;
    font-size: .8rem;
  }
</style>